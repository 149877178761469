import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Paper,
  TextField,
  Button,
  FormHelperText
} from "@material-ui/core";
import { Formik, Form, FieldArray } from "formik";
import Rating from "react-rating";
import * as yup from "yup";
import footer from "../../svg/logo-footer.svg";
import mainLogo from "../../svg/logo-utcb.svg";
import iconFilled from "../../svg/icon-filled.svg";
import iconOutline from "../../svg/icon-outline.svg";
import {
  getEvaluareByCode,
  getCurrentEvaluareInfo,
  getStatusEvaluare
} from "../../reducers/evaluariReducer";
import { fetchEvaluareByCode, addRaspunsToEvaluare } from "../../actions";
import Snack from "../General/Snackbar";

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%"
  },
  flex: {
    flexGrow: 1
  },
  appBar: {
    position: "absolute",
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('md')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 2px -1px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.03)"
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    overflow: "auto"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

export const generateIntrebare = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  isViewing,
  setFieldValue
}) => ({ tipRaspuns, intrebare }, index) => {
  if (!isViewing) {
    switch (tipRaspuns) {
      case "text":
        return (
          <Paper
            key={intrebare}
            style={{ maxWidth: "1100px", margin: "15px auto" }}
            className="lvd-container-form"
          >
            <Typography variant="subheading">{intrebare}</Typography>
            <TextField
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.intrebari[index].raspuns}
              disabled={isViewing}
              margin="dense"
              name={`intrebari.${index}.raspuns`}
              fullWidth
            />
          </Paper>
        );
      case "rating":
        return (
          <Paper
            key={intrebare}
            style={{ maxWidth: "1100px", margin: "15px auto" }}
            className="lvd-container-form"
          >
            <Typography variant="subheading">
              {intrebare} <span style={{ color: "red" }}>*</span>
            </Typography>
            <div className="lvd-spacer10" />
            <Rating
              onChange={value =>
                setFieldValue(`intrebari.${index}.raspuns`, value)
              }
              initialRating={
                values.intrebari[index].raspuns === ""
                  ? 0
                  : parseInt(values.intrebari[index].raspuns, 10)
              }
              readonly={isViewing}
              emptySymbol={
                <img
                  alt="outline"
                  src={iconOutline}
                  className="lvd-rating-icon"
                />
              }
              fullSymbol={
                <img
                  alt="filled"
                  src={iconFilled}
                  className="lvd-rating-icon"
                />
              }
            />
            {!!errors.intrebari &&
              !!touched.intrebari &&
              !!errors.intrebari[index] &&
              !!touched.intrebari[index] && (
                <FormHelperText error>
                  {errors.intrebari[index].raspuns}
                </FormHelperText>
              )}
          </Paper>
        );
      default:
        return null;
    }
  } else {
    switch (tipRaspuns) {
      case "text":
        return (
          <Paper
            key={intrebare}
            style={{ maxWidth: "1100px", margin: "15px auto" }}
            className="lvd-container-form"
          >
            <Typography variant="subheading">{intrebare}</Typography>
            <TextField disabled={isViewing} margin="dense" fullWidth />
          </Paper>
        );
      case "rating":
        return (
          <Paper
            key={intrebare}
            style={{ maxWidth: "1100px", margin: "15px auto" }}
            className="lvd-container-form"
          >
            <Typography variant="subheading">{intrebare}</Typography>
            <div className="lvd-spacer10" />
            <Rating
              readonly={isViewing}
              emptySymbol={
                <img
                  alt="outline"
                  src={iconOutline}
                  className="lvd-rating-icon"
                />
              }
              fullSymbol={
                <img
                  alt="filled"
                  src={iconFilled}
                  className="lvd-rating-icon"
                />
              }
            />
          </Paper>
        );
      default:
        return null;
    }
  }
};

class Evalueaza extends Component {
  static propTypes = {
    evalInfo: PropTypes.shape({
      idCampanie: PropTypes.number.isRequired,
      codDeEvaluare: PropTypes.number.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        idCampanie: PropTypes.string.isRequired,
        codDeEvaluare: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    fetchEvaluareByCode: PropTypes.func.isRequired,
    addRaspunsToEvaluare: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({}).isRequired,
    template: PropTypes.shape({}).isRequired
  };
  state = {};
  componentDidMount = () => {
    if (
      this.props.evalInfo.idCampanie === -1 &&
      this.props.evalInfo.codDeEvaluare === -1
    ) {
      this.props.fetchEvaluareByCode({
        idCampanie: this.props.match.params.idCampanie,
        codDeEvaluare: this.props.match.params.codDeEvaluare
      });
    }
  };

  handleSubmit = values => {
    const { idCampanie, codDeEvaluare } = this.props.evalInfo;
    this.props.addRaspunsToEvaluare({ ...values, idCampanie, codDeEvaluare });
  };

  handleClickLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    const { classes, theme, template, hasFinishedEvaluare } = this.props;
    const intrebari = template && template.intrebari ? template.intrebari : [];
    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <img src={mainLogo} style={{ maxWidth: "80px" }} alt="logo" />
            <Typography
              style={{ paddingLeft: "30px" }}
              variant="subtitle1"
              color="textPrimary"
              noWrap
              className={classes.flex}
            >
              Scoala Doctorala
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div
            style={{
              paddingTop: "15px",
              margin: "15px auto",
              maxWidth: "1140px"
            }}
          >
            <Typography
              style={{
                paddingLeft: "0px",
                fontWeight: 700,
                paddingBottom: "30px",
                maxWidth: "85%",
                fontSize: "28px"
              }}
              variant="display1"
              color="textPrimary"
              className={classes.flex}
            >
              {!hasFinishedEvaluare
                ? template.titlu
                : "Evaluarea a fost trimisa. Va multumim!"}
            </Typography>
          </div>
          {!hasFinishedEvaluare && intrebari.length > 0 && (
            <Formik
              enableReinitialize
              initialValues={{
                intrebari: intrebari.map(intrebare => ({
                  intrebare: intrebare.intrebare,
                  tipRaspuns: intrebare.tipRaspuns,
                  raspuns: ""
                }))
              }}
              validationSchema={yup.object().shape({
                intrebari: yup.array().of(
                  yup.object().shape({
                    tipRaspuns: yup.string(),
                    raspuns: yup.string().when("tipRaspuns", {
                      is: tipRaspuns => tipRaspuns === "rating",
                      then: yup
                        .string()
                        .required("Trebuie sa bifati cel putin o stea"),
                      otherwise: yup.string()
                    })
                  })
                )
              })}
              onSubmit={this.handleSubmit}
              render={({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                setFieldValue
              }) => (
                <Form>
                  {values.intrebari.length > 0 && (
                    <FieldArray
                      name="intrebari"
                      render={arrayHelpers =>
                        intrebari
                          ? intrebari.map(
                              generateIntrebare({
                                handleChange,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                setFieldValue
                              })
                            )
                          : null
                      }
                    />
                  )}

                  <div
                    style={{
                      paddingTop: "15px",
                      margin: "15px auto",
                      maxWidth: "1140px"
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Trimite evaluare
                    </Button>
                  </div>
                </Form>
              )}
            />
          )}
          <div className="lvd-footer">
            <img src={footer} alt="footer" />
            <span style={{ color: theme.palette.text.primary }}>
              IOSUD UTCB
            </span>
            <br />
            <span style={{ color: theme.palette.text.primary }}>
              Developed by{" "}
              <a
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "none"
                }}
                href="http://livedesign.ro"
              >
                Live Design
              </a>
            </span>
          </div>
        </main>
        <Snack />
      </div>
    );
  }
}

Evalueaza.propTypes = {};

const mapStateToProps = state => ({
  template: getEvaluareByCode(state),
  evalInfo: getCurrentEvaluareInfo(state),
  hasFinishedEvaluare: getStatusEvaluare(state)
});

const mapDispatchToProps = { fetchEvaluareByCode, addRaspunsToEvaluare };

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Evalueaza)
);
