import React, { Component } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import moment from "moment";
import AddEditForm from "../General/AddEditForm";
import {
  fetchNomenclatoarePublice,
  addInscris,
  fetchDosarInscris,
  updateMyInscris,
} from "../../actions";
import { getUser } from "../../reducers/authReducer";
import { getDosarInscris } from "../../reducers/inscrisiReducer";

const generateInputs = (t, props) => [
  {
    label: t.identificationData,
    type: "textDelimiter",
  },
  {
    type: "children",
    name: "a7",
    children: [
      {
        name: "nume",
        label: t.familyName,
        type: "text",
        required: true,
        helperText: t.familyNameExplanation,
      },
      {
        name: "initialaTatalui",
        label: t.fatherInitial,
        type: "text",
        required: true,
      },
      {
        name: "numeCasatorie",
        label: t.marriageName,
        type: "text",
      },
      {
        name: "prenume",
        label: t.firstName,
        type: "text",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a8",
    children: [
      {
        name: "cnp",
        label: t.cnp,
        type: "text",
        required: true,
      },
      {
        name: "sex",
        label: "Sex",
        type: "select",
        options: [
          {
            label: "M",
            value: "masculin",
          },
          {
            label: "F",
            value: "feminin",
          },
        ],
        required: true,
      },
      {
        name: "stareCivila",
        label: t.maritalStatus,
        type: "select",
        options: [
          {
            label: t.married,
            value: "casatorit",
          },
          {
            label: t.notMarried,
            value: "necasatorit",
          },
        ],
        required: true,
      },
      {
        name: "dataNasterii",
        label: t.dateOfBirth,
        type: "datepicker",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a9",
    children: [
      {
        name: "nationalitate",
        label: t.nationality,
        type: "text",
        required: true,
      },
      {
        name: "cetatenie",
        label: t.citizenship,
        type: "select",
        options: props.nomenclatoare.cetatenii.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "loculNasterii",
        label: t.birthplace,
        type: "text",
        required: true,
      },
    ],
  },
  {
    name: "domiciliuStabil",
    label: t.residence,
    type: "text",
    required: true,
  },
  {
    type: "children",
    name: "a10",
    children: [
      {
        name: "strada",
        label: t.street,
        type: "text",
        required: true,
      },
      {
        name: "numar",
        label: t.number,
        type: "text",
        required: true,
      },
      {
        name: "codPostal",
        label: t.postalCode,
        type: "text",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a11",
    children: [
      {
        name: "bloc",
        label: t.bloc,
        type: "text",
      },
      {
        name: "scara",
        label: t.entrance,
        type: "text",
      },
      {
        name: "etaj",
        label: t.floor,
        type: "text",
      },
      {
        name: "apartament",
        label: t.apartment,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a14",
    children: [
      {
        name: "telefonMobil",
        label: t.mobilePhone,
        type: "text",
        required: true,
      },
      {
        name: "telefonFix",
        label: t.landline,
        type: "text",
      },
    ],
  },
  {
    type: "textDelimiter",
    name: "9999",
    label: t.identityCardText,
    captionText: t.identityCardCaptionText,
  },
  {
    type: "children",
    name: "a12",
    children: [
      {
        name: "serieCarteIdentitate",
        label: t.identityCardSerial,
        type: "text",
      },
      {
        name: "nrCarteIdentitate",
        label: t.identityCardNo,
        type: "text",
      },
      {
        name: "dataEliberarii",
        label: t.identityCardIssueDate,
        type: "datepicker",
      },
    ],
  },
  {
    type: "children",
    name: "a13",
    children: [
      {
        name: "seriePasaport",
        label: t.passportSerial,
        type: "text",
      },
      {
        name: "anEliberarePasaport",
        label: t.passportIssueDate,
        type: "number",
      },
      {
        name: "valabilitatePasaport",
        label: t.passportValabilityYears,
        type: "number",
      },
    ],
  },
  {
    label: t.dataOnGraduatedLicenceStudies,
    type: "textDelimiter",
  },
  {
    name: "institutieInvatamantSuperior",
    label: t.nameOfHighEducationInstitution,
    type: "text",
    required: true,
  },
  {
    type: "children",
    name: "a15",
    children: [
      {
        name: "tara",
        label: t.country,
        type: "text",
        required: true,
      },
      {
        name: "localitatea",
        label: t.city,
        type: "text",
        required: true,
      },
    ],
  },
  {
    name: "facultatea",
    label: t.faculty,
    type: "text",
    required: true,
  },
  {
    type: "children",
    name: "a16",
    children: [
      {
        name: "domeniul",
        label: t.field,
        type: "text",
        required: true,
      },
      {
        name: "profilul",
        label: t.specialization,
        type: "text",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a17",
    children: [
      {
        name: "anulAbsolvirii",
        label: t.graduationYear,
        type: "number",
        required: true,
      },
      {
        name: "durata",
        label: t.durationSem,
        type: "number",
        required: true,
      },
      {
        name: "titluObtinut",
        label: t.obtainedTitle,
        type: "text",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a18",
    children: [
      {
        name: "nrDiplomaLicenta",
        label: t.diplomaCertificateNo,
        type: "text",
        required: true,
      },
      {
        name: "nrFoaieMatricolaLicenta",
        label: t.matricolSheetCertificateNo,
        type: "text",
        required: true,
      },
    ],
  },
  {
    label: t.dataOnUniversityMasterStudies,
    type: "textDelimiter",
  },
  {
    name: "institutieInvatamantSuperiorMaster",
    label: t.nameOfHighEducationInstitution,
    type: "text",
  },
  {
    type: "children",
    name: "a19",
    children: [
      {
        name: "taraMaster",
        label: t.country,
        type: "text",
      },
      {
        name: "localitateaMaster",
        label: t.city,
        type: "text",
      },
    ],
  },
  {
    name: "facultateaMaster",
    label: t.faculty,
    type: "text",
  },
  {
    type: "children",
    name: "a20",
    children: [
      {
        name: "domeniulMaster",
        label: t.field,
        type: "text",
      },
      {
        name: "profilulMaster",
        label: t.specialization,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a21",
    children: [
      {
        name: "anulAbsolviriiMaster",
        label: t.graduationYear,
        type: "number",
      },
      {
        name: "durataMaster",
        label: t.durationSem,
        type: "number",
      },
      {
        name: "titluObtinutMaster",
        label: t.obtainedTitle,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a1",
    children: [
      {
        name: "nrDiploma",
        label: t.diplomaCertificateNo,
        type: "text",
      },
      {
        name: "nrFoaieMatricola",
        label: t.matricolSheetCertificateNo,
        type: "text",
      },
    ],
  },
  {
    label: t.dataOnUniversityDoctoralStudies,
    type: "textDelimiter",
  },
  {
    name: "formaDoctorat",
    label: t.formOfDoctoralStudy,
    children: [
      {
        name: "formaDoctoratCuBursa",
        label: t.withScholarship,
      },
      {
        name: "formaDoctoratFaraBursa",
        label: t.withoutScholarship,
      },
      {
        name: "formaDoctoratCuTaxa",
        label: t.withRegularTax,
      },
      {
        name: "formaDoctoratAltele",
        label: t.others,
      },
    ],
    type: "multipleCheckbox",
  },
  {
    type: "children",
    name: "a3",
    children: [
      {
        name: "conducatorDoctorat",
        label: t.phdAdvisor,
        type: "text",
        required: true,
      },
      {
        name: "domeniulDeDoctorat",
        label: t.doctoralField,
        type: "select",
        options: props.nomenclatoare.domeniiInscriere.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a4",
    children: [
      {
        name: "intrebareStudiiUTCB",
        label: t.studyQuestion,
        type: "select",
        options: [
          {
            value: "da",
            label: t.yes,
          },
          {
            value: "nu",
            label: t.no,
          },
        ],
        required: true,
      },
      {
        name: "perioadaStudiiDoctoratBugetUTCB",
        label: t.period,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a5",
    children: [
      {
        name: "intrebareStudiiAltaUniversitate",
        label: t.studyQuestionOtherUniversity,
        type: "select",
        options: [
          {
            value: "da",
            label: t.yes,
          },
          {
            value: "nu",
            label: t.no,
          },
        ],
        required: true,
      },
      {
        name: "perioadaStudiiDoctoratBuget",
        label: t.period,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a6",
    children: [
      {
        name: "limbaStraina",
        label: t.foreignLanguage,
        type: "text",
        required: true,
      },
      {
        name: "certificatCompetenteLingvistice",
        label: t.proficiencyCertificate,
        type: "text",
        required: true,
      },
    ],
  },
  {
    name: "cazareCamin",
    label: t.accomodationInUniversitaryResidence,
    type: "select",
    required: true,
    options: [
      {
        value: "da",
        label: t.yes,
      },
      {
        value: "nu",
        label: t.no,
      },
    ],
  },
  {
    name: "acceptDeclaratie",
    label: t.declaration,
    type: "realCheckbox",
    required: true,
  },
];

class CompletareFisa extends Component {
  state = {
    inputs: [],
    isEditing: false,
  };

  async componentDidMount() {
    this.props.setIsLoading(1);
    await this.props.fetchNomenclatoarePublice();
    const { t, user } = this.props;
    if (user.dosarInscris) {
      this.setState({ isEditing: true });
      await this.props.fetchDosarInscris();
    }
    this.setState({
      inputs: generateInputs(t, this.props),
    });
    this.props.setIsLoading(-1);
  }

  componentDidUpdate(prevProps, prevState) {
    const { lang, t } = this.props;
    if (lang !== prevProps.lang) {
      this.setState({
        inputs: generateInputs(t, this.props),
      });
    }
  }

  handleAddDosar = async (values) => {
    const { isEditing } = this.state;
    let res = false;
    if (isEditing) {
      res = await this.props.updateMyInscris({
        ...values,
        dataNasterii: moment(values.dataNasterii).toISOString(),
        dataEliberarii: moment(values.dataEliberarii).toISOString(),
      });
    } else {
      res = await this.props.addInscris({
        ...values,
        dataNasterii: moment(values.dataNasterii).toISOString(),
        dataEliberarii: moment(values.dataEliberarii).toISOString(),
      });
    }
    if (res) {
      this.props.handleNext();
    }
  };

  render() {
    const { t, user, isLoading } = this.props;
    const { inputs, isEditing } = this.state;
    return (
      <div>
        {!isLoading && (
          <AddEditForm
            name="inscrisi"
            inputs={inputs}
            isViewing={user.completatDosarInscris}
            handleNext={this.handleNext}
            handleSubmit={this.handleAddDosar}
            saveButtonLabel={t.save}
            validationSchema={yup.object().shape({
              nume: yup.string().required(t.requiredField),
              prenume: yup.string().required(t.requiredField),
              sex: yup.string().required(t.requiredField),
              cnp: yup.string().required(t.requiredField),
              numeCasatorie: yup.string(),
              initialaTatalui: yup.string().required(t.requiredField),
              dataNasterii: yup
                .date()
                .required(t.requiredField)
                .transform((value, originalValue) =>
                  originalValue === null ? undefined : value
                ),
              stareCivila: yup.string().required(t.requiredField),
              nationalitate: yup.string().required(t.requiredField),
              cetatenie: yup.string().required(t.requiredField),
              loculNasterii: yup.string().required(t.requiredField),
              domiciliuStabil: yup.string().required(t.requiredField),
              strada: yup.string().required(t.requiredField),
              numar: yup.string().required(t.requiredField),
              codPostal: yup.string().required(t.requiredField),
              bloc: yup.string(),
              scara: yup.string(),
              etaj: yup.string(),
              apartament: yup.string(),
              serieCarteIdentitate: yup.string(),
              nrCarteIdentitate: yup.string(),
              dataEliberarii: yup
                .date()
                .transform((value, originalValue) =>
                  originalValue === null ? undefined : value
                ),
              seriePasaport: yup.string(),
              anEliberarePasaport: yup.string().nullable(),
              valabilitatePasaport: yup.string(),
              telefonMobil: yup.string().required(t.requiredField),
              telefonFix: yup.string(),
              institutieInvatamantSuperior: yup
                .string()
                .required(t.requiredField),
              tara: yup.string().required(t.requiredField),
              localitatea: yup.string().required(t.requiredField),
              facultatea: yup.string().required(t.requiredField),
              domeniul: yup.string().required(t.requiredField),
              profilul: yup.string().required(t.requiredField),
              anulAbsolvirii: yup.string().required(t.requiredField),
              durata: yup.string().required(t.requiredField),
              titluObtinut: yup.string().required(t.requiredField),
              nrDiplomaLicenta: yup.string().required(t.requiredField),
              nrFoaieMatricolaLicenta: yup.string().required(t.requiredField),
              institutieInvatamantSuperiorMaster: "",
              // taraMaster: "",
              // localitateaMaster: "",
              // facultateaMaster: "",
              // domeniulMaster: "",
              // profilulMaster: "",
              // anulAbsolviriiMaster: "",
              // durataMaster: "",
              // titluObtinutMaster: "",
              // nrDiploma: "",
              // nrFoaieMatricola: "",
              // formaDoctorat: yup.string().required(t.requiredField),
              conducatorDoctorat: yup.string().required(t.requiredField),
              domeniulDeDoctorat: yup.string().required(t.requiredField),
              intrebareStudiiUTCB: yup.string().required(t.requiredField),
              perioadaStudiiDoctoratBugetUTCB: yup.string(),
              intrebareStudiiAltaUniversitate: yup
                .string()
                .required(t.requiredField),
              perioadaStudiiDoctoratBuget: yup.string(),
              limbaStraina: yup.string().required(t.requiredField),
              certificatCompetenteLingvistice: yup
                .string()
                .required(t.requiredField),
              cazareCamin: yup.string().required(t.requiredField),
              acceptDeclaratie: yup.bool().required(t.requiredField),
            })}
            initialValues={
              isEditing
                ? {
                    nume: "",
                    prenume: "",
                    sex: "",
                    cnp: "",
                    numeCasatorie: "",
                    initialaTatalui: "",
                    dataNasterii: null,
                    stareCivila: "",
                    nationalitate: "",
                    cetatenie: "",
                    loculNasterii: "",
                    domiciliuStabil: "",
                    strada: "",
                    numar: "",
                    codPostal: "",
                    bloc: "",
                    scara: "",
                    etaj: "",
                    apartament: "",
                    serieCarteIdentitate: "",
                    nrCarteIdentitate: "",
                    dataEliberarii: null,
                    seriePasaport: "",
                    anEliberarePasaport: "",
                    valabilitatePasaport: "",
                    telefonMobil: "",
                    telefonFix: "",
                    institutieInvatamantSuperior: "",
                    tara: "",
                    localitatea: "",
                    facultatea: "",
                    domeniul: "",
                    profilul: "",
                    anulAbsolvirii: "",
                    durata: "",
                    titluObtinut: "",
                    nrDiplomaLicenta: "",
                    nrFoaieMatricolaLicenta: "",
                    institutieInvatamantSuperiorMaster: "",
                    taraMaster: "",
                    localitateaMaster: "",
                    facultateaMaster: "",
                    domeniulMaster: "",
                    profilulMaster: "",
                    anulAbsolviriiMaster: "",
                    durataMaster: "",
                    titluObtinutMaster: "",
                    nrDiploma: "",
                    nrFoaieMatricola: "",
                    anulInscrieriiDoctorat: "",
                    formaDoctoratCuBursa: false,
                    formaDoctoratFaraBursa: false,
                    formaDoctoratCuTaxa: false,
                    formaDoctoratAltele: false,
                    conducatorDoctorat: "",
                    domeniulDeDoctorat: "",
                    intrebareStudiiUTCB: "",
                    perioadaStudiiDoctoratBugetUTCB: "",
                    intrebareStudiiAltaUniversitate: "",
                    perioadaStudiiDoctoratBuget: "",
                    limbaStraina: "",
                    certificatCompetenteLingvistice: "",
                    cazareCamin: "",
                    acceptDeclaratie: "",
                    ...this.props.dosar,
                  }
                : {
                    nume: "",
                    prenume: "",
                    sex: "",
                    cnp: "",
                    numeCasatorie: "",
                    initialaTatalui: "",
                    dataNasterii: null,
                    stareCivila: "",
                    nationalitate: "",
                    cetatenie: "",
                    loculNasterii: "",
                    domiciliuStabil: "",
                    strada: "",
                    numar: "",
                    codPostal: "",
                    bloc: "",
                    scara: "",
                    etaj: "",
                    apartament: "",
                    serieCarteIdentitate: "",
                    nrCarteIdentitate: "",
                    dataEliberarii: null,
                    seriePasaport: "",
                    anEliberarePasaport: "",
                    valabilitatePasaport: "",
                    telefonMobil: "",
                    telefonFix: "",
                    institutieInvatamantSuperior: "",
                    tara: "",
                    localitatea: "",
                    facultatea: "",
                    domeniul: "",
                    profilul: "",
                    anulAbsolvirii: "",
                    durata: "",
                    titluObtinut: "",
                    nrDiplomaLicenta: "",
                    nrFoaieMatricolaLicenta: "",
                    institutieInvatamantSuperiorMaster: "",
                    taraMaster: "",
                    localitateaMaster: "",
                    facultateaMaster: "",
                    domeniulMaster: "",
                    profilulMaster: "",
                    anulAbsolviriiMaster: "",
                    durataMaster: "",
                    titluObtinutMaster: "",
                    nrDiploma: "",
                    nrFoaieMatricola: "",
                    anulInscrieriiDoctorat: "",
                    formaDoctoratCuBursa: false,
                    formaDoctoratFaraBursa: false,
                    formaDoctoratCuTaxa: false,
                    formaDoctoratAltele: false,
                    conducatorDoctorat: "",
                    domeniulDeDoctorat: "",
                    intrebareStudiiUTCB: "",
                    perioadaStudiiDoctoratBugetUTCB: "",
                    intrebareStudiiAltaUniversitate: "",
                    perioadaStudiiDoctoratBuget: "",
                    limbaStraina: "",
                    certificatCompetenteLingvistice: "",
                    cazareCamin: "",
                    acceptDeclaratie: "",
                    // date de test
                    // acceptDeclaratie: true,
                    // anEliberarePasaport: "",
                    // anulAbsolvirii: 2021,
                    // anulAbsolviriiMaster: "",
                    // anulInscrieriiDoctorat: "",
                    // apartament: "123",
                    // bloc: "asd",
                    // cazareCamin: "",
                    // certificatCompetenteLingvistice: "",
                    // cetatenie: {
                    //   value: "5b9ba390db90b5001cba66e9",
                    //   label: "test",
                    // },
                    // cnp: "",
                    // codPostal: "",
                    // conducatorDoctorat: "",
                    // dataEliberarii: null,
                    // dataNasterii: null,
                    // domeniul: "asd",
                    // // domeniulDeDoctorat: "",
                    // domeniulDeDoctorat: "",
                    // domeniulMaster: "",
                    // domiciliuStabil: "Bucharest",
                    // durata: 12,
                    // durataMaster: "",
                    // etaj: "asd",
                    // facultatea: "asd",
                    // facultateaMaster: "",
                    // formaDoctoratAltele: false,
                    // formaDoctoratCuBursa: true,
                    // formaDoctoratCuTaxa: true,
                    // formaDoctoratFaraBursa: false,
                    // initialaTatalui: "AD",
                    // institutieInvatamantSuperior: "",
                    // institutieInvatamantSuperiorMaster: " -",
                    // intrebareStudiiAltaUniversitate: {
                    //   value: "nu",
                    //   label: "No",
                    // },
                    // intrebareStudiiUTCB: { value: "nu", label: "No" },
                    // limbaStraina: "",
                    // localitatea: "",
                    // localitateaMaster: "",
                    // loculNasterii: "",
                    // nationalitate: "",
                    // nrCarteIdentitate: "123123",
                    // nrDiploma: "",
                    // nrDiplomaLicenta: "asd",
                    // nrFoaieMatricola: "",
                    // nrFoaieMatricolaLicenta: "asd",
                    // numar: "33",
                    // nume: "X",
                    // numeCasatorie: "",
                    // perioadaStudiiDoctoratBuget: "",
                    // perioadaStudiiDoctoratBugetUTCB: "",
                    // prenume: "",
                    // profilul: "",
                    // profilulMaster: "",
                    // scara: "",
                    // serieCarteIdentitate: "",
                    // seriePasaport: "",
                    // sex: { label: "M", value: "masculin" },
                    // stareCivila: { label: "Not married", value: "necasatorit" },
                    // strada: "",
                    // tara: "",
                    // taraMaster: "",
                    // telefonFix: "",
                    // telefonMobil: "",
                    // titluObtinut: "",
                    // titluObtinutMaster: "",
                    // valabilitatePasaport: 3,
                  }
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  nomenclatoare: state.nomenclatoare,
  user: getUser(state),
  dosar: getDosarInscris(state),
});

const mapDispatchToProps = {
  fetchNomenclatoarePublice,
  addInscris,
  fetchDosarInscris,
  updateMyInscris,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletareFisa);
