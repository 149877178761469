import request from "superagent";
import _ from "lodash";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";
import { getToken } from "../reducers/authReducer";

const fetchUtilizatoriRequest = () => ({
  type: TYPES.UTILIZATORI_FETCH_REQUEST
});
const fetchUtilizatoriSuccess = (payload, query) => ({
  type: TYPES.UTILIZATORI_FETCH_SUCCESS,
  payload,
  query
});
const fetchUtilizatoriFailure = error => ({
  type: TYPES.UTILIZATORI_FETCH_FAILURE,
  error
});

export const fetchUtilizatori = (query, pageInfo) => async (
  dispatch,
  getState
) => {
  dispatch(fetchUtilizatoriRequest());
  query = _.isEmpty(query) ? undefined : query;
  const jwt = getToken(getState());
  try {
    const res = await request
      .post(`${serverUrl}/users/search`)
      .set("Authorization", `Bearer ${jwt}`)
      .send({ query, pageInfo });
    dispatch(fetchUtilizatoriSuccess(res.body, query));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchUtilizatoriFailure(e));
  }
};

export const addUtilizator = user => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await request
      .post(`${serverUrl}/users`)
      .send(user)
      .set("Authorization", `Bearer ${jwt}`);
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const editUtilizator = user => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await request
      .patch(`${serverUrl}/users/${user.id}`)
      .send(user)
      .set("Authorization", `Bearer ${jwt}`);
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};
export const fetchUtilizatorByIdRequest = () => ({
  type: TYPES.UTILIZATORI_BYID_REQUEST
});
export const fetchUtilizatorByIdSuccess = payload => ({
  type: TYPES.UTILIZATORI_BYID_SUCCESS,
  payload
});
export const fetchUtilizatorByIdFail = error => ({
  type: TYPES.UTILIZATORI_BYID_FAIL,
  error
});

export const fetchUtilizatorById = userId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(fetchUtilizatorByIdRequest());
  try {
    const res = await request
      .get(`${serverUrl}/users/${userId}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchUtilizatorByIdSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchUtilizatorByIdFail(e));
  }
};

export const deleteUtilizatorRequest = () => ({
  type: TYPES.UTILIZATORI_DELETE_REQUEST
});
export const deleteUtilizatorSuccess = payload => ({
  type: TYPES.UTILIZATORI_DELETE_SUCCESS,
  payload
});
export const deleteUtilizatorFail = error => ({
  type: TYPES.UTILIZATORI_DELETE_FAIL,
  error
});

export const deleteUtilizator = userId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(deleteUtilizatorRequest());
  try {
    const res = await request
      .delete(`${serverUrl}/users/${userId}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(deleteUtilizatorSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(deleteUtilizatorFail(e));
  }
};
