import React, { Component } from "react";
import { TextField, Button, Grid, FormControl } from "@material-ui/core";
import { connect } from "react-redux";
import { validateManually } from "../../../actions";

class Payments extends Component {
  state = { paymentType: "", paymentAmount: "" };

  componentDidMount() {
    this.setState({
      paymentType: this.props.dosar.paymentType,
      paymentAmount: this.props.dosar.paymentAmount,
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleValidatePayment = () => {
    const { paymentType, paymentAmount } = this.state;
    const { dosar } = this.props;
    this.props.validateManually({
      paymentType,
      paymentAmount,
      dosarId: dosar._id,
    });
  };

  render() {
    const { dosar } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              label="Id plata"
              disabled
              value={dosar.paymentId || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Total de platit"
              disabled={dosar.status === "plataValidata"}
              margin="normal"
              value={dosar.paymentAmount || ""}
              onChange={this.handleChange}
              value={this.state.paymentAmount}
              name="paymentAmount"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="paymentType"
              label="Mentiuni plata"
              disabled={
                !(
                  dosar.status === "plataInitiata" || dosar.status === "validat"
                )
              }
              margin="normal"
              onChange={this.handleChange}
              value={this.state.paymentType}
            />
          </Grid>
          {(dosar.status === "plataInitiata" || dosar.status === "validat") && (
            <Grid item xs={12}>
              <FormControl margin="normal">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleValidatePayment}
                >
                  Valideaza manual
                </Button>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  validateManually,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
