import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as yup from "yup";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
  MobileStepper,
  withMobileDialog
} from "@material-ui/core";
import {
  fetchNomenclatoare,
  fetchTipuriDeDocumente,
  addCandidat
} from "../../actions";
import { getTipuriDeDocumente } from "../../reducers/tipuriDeDocumenteReducer";
import AddEditForm from "../General/AddEditForm";
import EmailSelect from "../General/EmailSelect";
import DocumentTable from "../General/DocumentTable";
import { getCandidatById } from "../../reducers/candidatiReducer";

const steps = ["Adauga/Asociaza cont", "Completeaza dosar", "Adauga documente"];

class CandidatiAdd extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchTipuriDeDocumente: PropTypes.func.isRequired,
    addCandidat: PropTypes.func.isRequired
  };

  state = {
    inputs: [],
    activeStep: 0
  };

  static getDerivedStateFromProps = (props, state) => ({
    inputs: [
      {
        name: "email",
        label: "Email",
        type: "text",
        disabled: true
      },
      {
        name: "nume",
        label: "Nume",
        type: "text",
        required: true
      },
      {
        name: "prenume",
        label: "Prenume",
        type: "text",
        required: true
      },
      {
        name: "cnp",
        label: "CNP",
        type: "text"
      },
      {
        name: "titulatura",
        label: "Titulatura",
        type: "text"
      },
      {
        name: "anObtinereTitluDoctor",
        label: "An Obtinere Titlu Doctor",
        type: "number"
      },
      {
        name: "facultate",
        label: "Facultate",
        type: "select",
        options: props.nomenclatoare.facultati.map(n => ({
          value: n._id,
          label: n.nume
        }))
      },
      {
        name: "departament",
        label: "Departament",
        type: "select",
        options: props.nomenclatoare.departamente.map(n => ({
          value: n._id,
          label: n.nume
        }))
      },
      {
        name: "domeniuDoctorat",
        label: "Domeniu Doctorat",
        type: "select",
        options: props.nomenclatoare.domenii.map(n => ({
          value: n._id,
          label: n.nume
        }))
      },
      {
        name: "specializare",
        label: "Specializare",
        type: "select",
        options: props.nomenclatoare.specializari.map(n => ({
          value: n._id,
          label: n.nume
        }))
      },
      {
        name: "comisiaDeAbilitare",
        label: "Comisia de abilitare",
        type: "text"
      },
      {
        name: "domeniuDoctoratSolicitat",
        label: "Domeniu Doctorat Solicitat",
        type: "select",
        options: props.nomenclatoare.domenii.map(n => ({
          value: n._id,
          label: n.nume
        }))
      },
      {
        name: "specializariDoctoratSolicitate",
        label: "Specializari Doctorat Solicitate",
        type: "chips",
        options: props.nomenclatoare.specializari.map(n => ({
          value: n._id,
          label: n.nume
        }))
      },
      {
        name: "telefon",
        label: "Telefon",
        type: "text"
      },
      {
        name: "linkScopus",
        label: "Link Scopus",
        type: "text"
      },
      {
        name: "linkGoogleScholar",
        label: "Link Google Scholar",
        type: "text"
      },
      {
        name: "linkISIWebOfScience",
        label: "Link ISI Web Of Science",
        type: "text"
      },
      {
        name: "linkResearchGate",
        label: "Link Research Gate",
        type: "text"
      }
    ],
    tipuriDocumente: props.tipuriDeDocumente.candidati
  });

  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchTipuriDeDocumente();
  }

  handleSaveUser = user => {
    this.setState({ user });
    this.handleNext();
  };

  getStepContent = step => {
    const { inputs, tipuriDocumente, user } = this.state;
    const { dosar } = this.props;
    switch (step) {
      case 0:
        return <EmailSelect handleNext={this.handleSaveUser} />;
      case 1:
        return (
          <AddEditForm
            name="candidati"
            inputs={inputs}
            handleNext={this.handleNext}
            handleSubmit={this.handleAddDosar}
            validationSchema={yup.object().shape({
              email: yup.string().required("Camp obligatoriu"),
              nume: yup.string().required("Camp obligatoriu"),
              prenume: yup.string().required("Camp obligatoriu")
              // cnp: yup.string().required('Camp obligatoriu'),
              // titulatura: yup.string().required('Camp obligatoriu'),
              // anObtinereTitluDoctor: yup.number().required('Camp obligatoriu'),
              // facultate: yup
              //   .object()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // departament: yup
              //   .object()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // domeniuDoctorat: yup
              //   .object()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // specializare: yup
              //   .object()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // telefon: yup.string().required('Camp obligatoriu'),
              // linkScopus: yup.string().required('Camp obligatoriu'),
              // linkGoogleScholar: yup.string().required('Camp obligatoriu'),
              // linkISIWebOfScience: yup.string().required('Camp obligatoriu'),
              // linkResearchGate: yup.string().required('Camp obligatoriu'),
            })}
            initialValues={{
              email: user.email || "",
              cnp: "",
              nume: "",
              prenume: "",
              titulatura: "",
              anObtinereTitluDoctor: "",
              facultate: {},
              departament: {},
              domeniuDoctorat: {},
              specializare: {},
              comisiaDeAbilitare: "",
              domeniuDoctoratSolicitat: {},
              specializariDoctoratSolicitate: {},
              telefon: "",
              linkScopus: "",
              linkISIWebOfScience: "",
              linkResearchGate: "",
              linkGoogleScholar: user.linkGoogleScholar || "",
              userId: user._id
            }}
          />
        );
      case 2:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente}
            tipuriDocumente={tipuriDocumente}
            name="candidati"
            type="edit"
          />
        );
      default:
        return "Unknown step";
    }
  };

  handleNext = () => {
    this.setState(({ activeStep }) => ({ activeStep: activeStep + 1 }));
  };

  handleAddDosar = async values => {
    const x = await this.props.addCandidat(values);
    if (x) this.handleNext();
  };

  handleBackClick = () => {
    this.props.history.push("/candidati");
  };

  handleNewClick = () => {
    this.setState({
      activeStep: 0
    });
  };

  render() {
    const { activeStep } = this.state;
    const { fullScreen } = this.props;
    return (
      <>
        {fullScreen ? (
          <MobileStepper
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            className="lvd-mobile-stepper"
            // className={classes.mobileStepper}
          />
        ) : (
          <Stepper
            activeStep={activeStep}
            style={{ marginTop: "20px" }}
            className="lvd-stepper"
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Paper className="lvd-container-form">
          <div>{this.getStepContent(activeStep)}</div>
        </Paper>
        {activeStep === steps.length - 1 && (
          <div className="lvd-control-buttons">
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
              onClick={this.handleBackClick}
            >
              <ArrowBackIos />
              Inapoi la lista
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
            >
              <AddIcon />
              Dosar nou
            </Button>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  nomenclatoare: state.nomenclatoare,
  tipuriDeDocumente: getTipuriDeDocumente(state),
  dosar: getCandidatById(state)
});

export default withMobileDialog()(
  connect(mapStateToProps, {
    fetchNomenclatoare,
    fetchTipuriDeDocumente,
    addCandidat
  })(CandidatiAdd)
);
