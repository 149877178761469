import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Hidden,
  Drawer,
  Typography,
  StepLabel,
  Step,
  Stepper,
  withStyles,
  // StepConnector,
  Paper,
  CircularProgress,
  Fab,
} from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import LoginAppBar from "./LoginAppBar";
import ContNou from "../Inscriere/ContNou";
import CompletareFisa from "../Inscriere/CompletareFisa";
import IncarcareDocumente from "../Inscriere/IncarcareDocumente";
import PendingValidation from "../Inscriere/PendingValidation";
import { refreshLogin } from "../../actions";
import { getUser, getIsLoggedIn } from "../../reducers/authReducer";
import { getInfo } from "../../utils/helpers";
import Plata from "../Inscriere/Plata";
import ValidareFinala from "../Inscriere/ValidareFinala";
import { getCandidatType } from "../../reducers/inscrisiReducer";
import PhoneIcon from "@material-ui/icons/Phone";
import CustomizedDialogs from "./ContactDialog";
const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    overflow: "auto",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      marginTop: "64px",
      height: "calc(100% - 64px)",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "58px",
      height: "calc(100% - 58px)",
    },
  },
  // stepperRoot: {},
  drawerRoot: {
    height: "100%",
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      marginTop: "64px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "58px",
    },
    // backgroundColor: '#000',
  },
  drawerPaperWithoutMargin: {
    width: drawerWidth,
    height: "100%",
    // backgroundColor: '#000',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});
function getSteps(t) {
  return [
    {
      label: t.createAccount,
      description: t.createAccountDescription,
    },
    // {
    //   label: "Validare cont",
    //   description: "Primesti pe mail un link de activare"
    // },
    {
      label: t.completeRegistrationPaper,
      description: t.completeRegistrationPaperDescription,
    },
    {
      label: t.loadDocuments,
      description: t.loadDocumentsDescription,
    },
    {
      label: t.pendingValidation,
      description: t.pendingValidationDescription,
    },
    {
      label: t.registrationPayment,
      description: t.registrationPaymentDescription,
    },
    {
      label: t.finalizeRegistration,
      description: t.finalizeRegistrationDescription,
    },
  ];
}
function getStepTitle(activeStep, t) {
  const steps = getSteps(t);
  if (activeStep === -1) return "";
  return steps[activeStep].label;
}
function getStepContent(step, functions) {
  switch (step) {
    case 0:
      return <ContNou {...functions} />;
    case 1:
      return <CompletareFisa {...functions} />;
    case 2:
      return <IncarcareDocumente {...functions} />;
    case 3:
      return <PendingValidation {...functions} />;
    case 4:
      return <Plata {...functions} />;
    case 5:
      return <ValidareFinala {...functions} />;
    // case 1:
    //   return 'An ad group contains one or more ads which target a shared set of keywords.';
    // case 2:
    //   return `Try out different ad text to see what brings in the most customers,
    //           and learn how to enhance your ads using features like ad extensions.
    //           If you run into any problems with your ads, find out how to tell if
    //           they're running and how to resolve approval issues.`;
    default:
      return "";
  }
}
// const QontoConnector = withStyles({
//   alternativeLabel: {
//     top: 10,
//     left: "calc(-50% + 16px)",
//     right: "calc(50% + 16px)"
//   },
//   active: {
//     "& $line": {
//       borderColor: "#81C534"
//     }
//   },
//   completed: {
//     "& $line": {
//       borderColor: "#81C534"
//     }
//   },
//   line: {
//     // borderColor: '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1
//   }
// })(StepConnector);

class RegisterWizardLayout extends Component {
  state = {
    activeStep: -1,
    mobileOpen: false,
    isSaved: false,
    isEditing: true,
    isPendingEmailVerification: false,
    isLoading: 1,
    dialogOpen: false,
  };

  async componentDidMount() {
    const { jwt, refreshToken, email } = getInfo();
    const { user, isLoggedIn } = this.props;

    if (!!jwt && !!refreshToken && !!email && !user.role) {
      await this.props.refreshLogin();
    }
    if (isLoggedIn) {
      switch (this.props.user.statusInscriere) {
        case "finalizat":
        case "plataValidata":
          this.setActiveStep(5);
          break;
        case "validat":
          this.setActiveStep(4);
          break;
        case "trimisCatreValidare":
          this.setActiveStep(3);
          break;
        case "completatDosar":
          this.setActiveStep(2);
          break;
        case "verificatEmail":
        case "respins":
          this.setActiveStep(1);
          break;
        case "neverificatEmail":
        case "":
          this.setActiveStep(0);
          break;
        default:
          this.setActiveStep(-1);
          break;
      }
      // if (user.trimisCatreValidare) {
      //   console.log("active step set to 3");
      //   this.setActiveStep(3);
      // } else if (user.completatDosarInscris && !user.trimisCatreValidare) {
      //   console.log("active step set to 2");
      //   this.setActiveStep(2);
      // } else if (!user.completatDosarInscris && !user.trimisCatreValidare) {
      //   console.log("active step set to 1");
      //   this.setActiveStep(1);
      // } else {
      //   console.log("active step set to 0");
      //   this.setActiveStep(0);
      // }
    } else {
      if (this.props.candidatType === "") {
        this.props.history.push("/");
      }
      this.setActiveStep(0);
    }
    this.setIsLoading(-1);
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (
  //     !nextProps.user.emailVerified &&
  //     !prevState.isPendingEmailVerification
  //   ) {
  //     return { isPendingEmailVerification: true };
  //   }
  //   if (nextProps.user.emailVerified && prevState.activeStep === 0) {
  //     return { activeStep: 1 };
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.emailVerified !== this.props.user.emailVerified &&
      !this.props.user.emailVerified &&
      this.state.isPendingEmailVerification !== true
    ) {
      this.setState({
        isPendingEmailVerification: true,
      });
    }
    if (this.props.user.statusInscriere !== prevProps.user.statusInscriere)
      switch (this.props.user.statusInscriere) {
        case "finalizat":
        case "platit":
          this.setActiveStep(5);
          break;
        case "validat":
          this.setActiveStep(4);
          break;
        case "trimisCatreValidare":
          this.setActiveStep(3);
          break;
        case "completatDosar":
          this.setActiveStep(2);
          break;
        case "verificatEmail":
        case "respins":
          this.setActiveStep(1);
          break;
        case "neverificatEmail":
        case "":
          this.setActiveStep(0);
          break;
        default:
          this.setActiveStep(-1);
          break;
      }
  }

  setIsLoading = (loadingNumber) => {
    this.setState(({ isLoading }) => ({
      isLoading: isLoading + loadingNumber,
    }));
  };

  handleOpen = () => {
    this.setState({ dialogOpen: true });
  };
  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  // componentDidUpdate(prevProps, prevState) {
  //   const { user, isLoggedIn } = this.props;
  //   if (isLoggedIn && user.emailVerified) {
  //     this.setActiveStep(1);
  //   }
  // }

  handleNext = () => {
    this.setActiveStep(this.state.activeStep + 1);
  };

  handleBack = () => {
    this.setActiveStep(this.state.activeStep - 1);
  };

  handleReset = () => {
    this.setActiveStep(0);
  };

  setActiveStep = (step) => {
    this.setState({
      activeStep: step,
    });
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { activeStep, mobileOpen, isLoading } = this.state;
    const { classes, theme, width, t } = this.props;
    const steps = getSteps(t);
    return (
      <>
        <LoginAppBar
          handleDrawerToggle={this.handleDrawerToggle}
          showButton={isWidthDown("sm", width)}
        />
        <div className="lvd-register">
          <div className={classes.drawer} aria-label="mailbox folders">
            <Hidden mdUp implementation="js">
              <div className="lvd-drawer">
                <Drawer
                  variant="temporary"
                  className="lvd-drawer"
                  open={mobileOpen}
                  onClose={this.handleDrawerToggle}
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  classes={{
                    paper: classes.drawerPaperWithoutMargin,
                    root: classes.drawerRoot,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  <div className="lvd-stepper-left">
                    <Stepper
                      // connector={<QontoConnector />}
                      orientation="vertical"
                      activeStep={activeStep}
                      classes={{
                        root: classes.stepperRoot,
                      }}
                    >
                      {steps.map(({ label, description }, index) => (
                        <Step key={label}>
                          <StepLabel>
                            {label}
                            <br />
                            <Typography variant="caption">
                              {description}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </Drawer>
              </div>
            </Hidden>
            <Hidden smDown implementation="js">
              <div className="lvd-drawer">
                <Drawer
                  variant="permanent"
                  classes={{
                    paper: classes.drawerPaper,
                    root: classes.drawerRoot,
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <div className="lvd-stepper-left">
                    <Stepper
                      // connector={<QontoConnector />}
                      orientation="vertical"
                      activeStep={activeStep}
                      classes={{
                        root: classes.stepperRoot,
                      }}
                    >
                      {steps.map(({ label, description }, index) => (
                        <Step key={label}>
                          <StepLabel>
                            {label}
                            <br />
                            <Typography variant="caption">
                              {description}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </Drawer>
              </div>
            </Hidden>
          </div>
          <main className={classes.content}>
            {/* {this.props.children} */}
            {/* <StepContent> */}
            <div>
              <h1 className="lvd-register-title">
                {getStepTitle(activeStep, t)}
              </h1>
              <Paper className="lvd-container-form">
                {isLoading !== 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                {getStepContent(activeStep, {
                  handleNext: this.handleNext,
                  isPendingEmailVerification: this.state
                    .isPendingEmailVerification,
                  setIsLoading: this.setIsLoading,
                  isLoading: this.state.isLoading,
                })}
              </Paper>
            </div>
          </main>
        </div>
        <CustomizedDialogs
          open={this.state.dialogOpen}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
        ></CustomizedDialogs>
        <Fab
          style={{ position: "fixed", right: "25px", bottom: "25px" }}
          color="primary"
          onClick={this.handleOpen}
        >
          <PhoneIcon style={{ color: "white" }} />
        </Fab>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  user: getUser(state),
  isLoggedIn: getIsLoggedIn(state),
  candidatType: getCandidatType(state),
});

const mapDispatchToProps = { refreshLogin };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(styles, { withTheme: true })(RegisterWizardLayout)));
