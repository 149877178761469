// import * as TYPES from '../types';

const userReducer = (state = { items: [], isFetching: false, error: undefined }, action) => {
  // const { payload, error } = action;
  switch (action.type) {
    default:
      return state;
  }
};

export default userReducer;
