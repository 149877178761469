import * as TYPES from '../types';

const conducatoriReducer = (
  state = {
    items: [],
    forDropdown: [],
    byId: {},
    query: undefined,
    isFetching: false,
    error: undefined,
    pageInfo: {
      pageCount: undefined,
      itemCount: undefined,
      page: undefined,
      pageSize: undefined,
    },
  },
  action,
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.CONDUCATORI_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CONDUCATORI_FETCH_SUCCESS:
      return { ...state, isFetching: false, items: payload };
    case TYPES.CONDUCATORI_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.CONDUCATORI_BYID_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CONDUCATORI_BYID_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.CONDUCATORI_BYID_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.CONDUCATORI_ADD_SUCCESS:
      return { ...state, byId: payload };
    case TYPES.CONDUCATORI_UPDATE_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CONDUCATORI_UPDATE_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.CONDUCATORI_UPDATE_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.CONDUCATORI_DELETE_SUCCESS:
      return { ...state, items: state.items.filter(dosar => dosar._id !== payload) };
    case TYPES.CONDUCATORI_SEARCH_SUCCESS:
      return {
        ...state,
        items: payload.dosare,
        pageInfo: payload.pageInfo,
        query: action.query,
      };
    case TYPES.CONDUCATORI_KEYVALUE_SUCCESS:
      return { ...state, forDropdown: payload };
    default:
      return state;
  }
};

export const getConducatori = state => state.conducatori.items;
export const getConducatoriQuery = state => state.conducatori.query;
export const getConducatoriPageInfo = state => state.conducatori.pageInfo;
export const getConducatorById = state => state.conducatori.byId;
export const getConducatoriForDropdown = state => state.conducatori.forDropdown;
export const getIsFetchingConducatori = state => state.conducatori.isFetching;

export default conducatoriReducer;
