import React, { Component } from "react";
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import Grid from "@material-ui/core/Grid";
import {
  // Paper,
  //   Button,
  withStyles
  // IconButton,
  // AppBar,
  // Toolbar,
  // Menu,
  // MenuItem,
  // Typography
  //   InputAdornment,
  //   FormControl,
  //   InputLabel,
  //   Input,
  //   FormHelperText,
} from "@material-ui/core";
// import { Formik, Form } from 'formik';
// import { connect } from 'react-redux';
// import Visibility from '@material-ui/icons/Visibility';
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import { showSnackbar, login } from '../actions';
// import LoginForm from "../LoginForm";
import { refreshLogin } from "../../actions";
// import mainLogo from "../../svg/logo-utcb.svg";
// import footer from "../../svg/logo-footer.svg";
import Snack from "../General/Snackbar";
// import RegisterForm from "../RegisterForm";
import LoginAppBar from "./LoginAppBar";
import { getInfo } from "../../utils/helpers";

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%"
  },
  flex: {
    flexGrow: 1
  },
  appBar: {
    position: "absolute",
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('md')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 2px -1px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.03)"
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    overflow: "auto"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

class LoginLayout extends Component {
  state = {
    anchorEl: null
  };

  async componentDidMount() {
    const { jwt, refreshToken, email } = getInfo();
    if (!!jwt && !!refreshToken && !!email) {
      await this.props.refreshLogin();
    }
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { theme } = this.props;
    // const { anchorEl } = this.state;
    // const openUser = Boolean(anchorEl);
    return (
      <>
        <LoginAppBar />
        <div
          style={{
            backgroundColor: theme.palette.background.default,
            height: "calc(100% - 80px)",
            paddingTop: "80px"
          }}
        >
          {this.props.children}
        </div>
        {/* <div
          className="lvd-login-container"
          style={{ backgroundColor: theme.palette.background.default, height: '100%' }}
        >
          <div className="lvd-login-form">
            <Paper className="lvd-login-paper">
              <Typography variant="title">Mă înscriu</Typography>
              <Register />
            </Paper>
          </div>
          <div className="lvd-login-form">
            <Paper className="lvd-login-paper">
              <Typography variant="title">Am deja cont</Typography>
              <Login />
            </Paper>
          </div> */}
        {/* <div style={{ flexGrow: 1 }} /> */}
        <Snack />
        {/* <div className="lvd-login-footer">
                <img src={footer} alt="footer-logo" />
                <span style={{ color: theme.palette.text.primary }}>IOSUD UTCB 2</span>
              </div> */}
        {/* </div> */}
      </>
    );
  }
}
const mapStateToProps = state => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang]
});

const mapDispatchToProps = { refreshLogin };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(LoginLayout)));
