import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

class PasswordTextField extends Component {
  static propTypes = {
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    margin: PropTypes.string,
    noVisibilityToggle: PropTypes.bool,
    required: PropTypes.bool
  };

  static defaultProps = {
    margin: "normal",
    noVisibilityToggle: false,
    touched: false,
    error: false,
    required: false
  };

  state = {
    showPassword: false
  };

  handleClickShowPassword = () => {
    this.setState(({ showPassword }) => ({
      showPassword: !showPassword
    }));
  };

  handleMouseDownPassword = passwordType => event => {
    event.preventDefault();
  };

  render() {
    const {
      handleBlur,
      handleChange,
      value,
      error,
      touched,
      label,
      name,
      margin,
      noVisibilityToggle,
      required
    } = this.props;
    const { showPassword } = this.state;
    return (
      <TextField
        fullWidth
        required={required}
        onBlur={handleBlur}
        margin={margin || "normal"}
        error={!!touched && !!error}
        type={showPassword === true ? "text" : "password"}
        InputProps={
          noVisibilityToggle
            ? {}
            : {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      tabIndex="-1"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }
        }
        onChange={handleChange}
        helperText={!!touched && !!error && error}
        FormHelperTextProps={{ error: true }}
        label={label}
        value={value}
        name={name}
      />
    );
  }
}

export default PasswordTextField;
