import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import moment from 'moment';
import {
  Button,
  Paper,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { getDoctorandById } from '../../reducers/doctoranziReducer';
import { fetchDoctorandById, setAppBarTitle, showSnackbar } from '../../actions';
import DocumentTable from '../General/DocumentTable';
import * as utils from '../../utils/helpers';
import { getToken } from '../../reducers/authReducer';
import DosarView from './DosarView';

const mappedNames = {
  email: { label: 'Email' },
  nrMatricol: { label: 'Numar matricol' },
  nume: { label: 'Nume' },
  numeCasatorie: { label: 'Nume casatorie' },
  initialaTatalui: { label: 'Initiala Tatalui' },
  prenume: { label: 'Prenume' },
  cnp: { label: 'CNP' },
  dataNasterii: {
    label: 'Data nasterii',
    accessor: data => (moment(data).isValid() ? moment(data).format('DD/MM/YYYY') : '-'),
  },
  loculNasterii: { label: 'Locul nasterii' },
  anInmatriculare: { label: 'An inmatriculare' },
  cetatenie: { label: 'Cetatenie' },
  telefon: { label: 'Telefon' },
  domeniuDoctorat: { label: 'Domeniu doctorat' },
  conducatorDoctorat: { label: 'Conducator doctorat' },
  conducatorInitial: { label: 'Conducator initial' },
  conducatorCotutela: { label: 'Conducator cotutela' },
  transferRetrasDeces: { label: 'Transfer/Retras/Deces' },
  specializari: { label: 'Specializari' },
  formaDeInvatamantInitiala: { label: 'Forma de invatamant initiala' },
  formaDeInvatamantCurenta: { label: 'Forma de invatamant curenta' },
  situatiaScolara: { label: 'Situatia scolara' },
  decizieExmatriculare: { label: 'Decizie exmatriculare' },
  limbaStrainaAdmitere: { label: 'Limba straina admitere' },
  calificativAdmitere: { label: 'Calificativ admitere limba straina' },
  comisieDeIndrumare: { label: 'Comisie de indrumare' },
  disciplinaOptionala1: { label: 'Disciplina optionala 1' },
  disciplinaOptionala2: { label: 'Disciplina optionala 2' },
  disciplinaOptionala3: { label: 'Disciplina optionala 3' },
  denumireProiectCercetare: { label: 'Denumire proiect cercetare' },
  disciplinaElectiva1: { label: 'Disciplina electiva 1' },
  disciplinaElectiva2: { label: 'Disciplina electiva 2' },
  raportDeCercetare1: { label: 'Raport de cercetare 1' },
  raportDeCercetare2: { label: 'Raport de cercetare 2' },
  raportDeCercetare3: { label: 'Raport de cercetare 3' },
  do1Credite: { label: 'Credite disciplina optionala 1' },
  do2Credite: { label: 'Credite disciplina optionala 2' },
  do3Credite: { label: 'Credite disciplina optionala 3' },
  de1Credite: { label: 'Credite disciplina electiva 1' },
  de2Credite: { label: 'Credite disciplina electiva 2' },
  de3Credite: { label: 'Credite disciplina electiva 3' },
  rc1Credite: { label: 'Credite raport cercetare 1' },
  rc2Credite: { label: 'Credite raport cercetare 2' },
  rc3Credite: { label: 'Credite raport cercetare 3' },
  tezaCredite: { label: 'Credite teza' },
  totalCredite: { label: 'Credite total' },
  do1Rezultat: { label: 'Rezultat disciplina optionala 1' },
  do2Rezultat: { label: 'Rezultat disciplina optionala 2' },
  do3Rezultat: { label: 'Rezultat disciplina optionala 3' },
  de1Rezultat: { label: 'Rezultat disciplina electiva 1' },
  de2Rezultat: { label: 'Rezultat disciplina electiva 2' },
  de3Rezultat: { label: 'Rezultat disciplina electiva 3' },
  rc1Rezultat: { label: 'Rezultat raport cercetare 1' },
  rc2Rezultat: { label: 'Rezultat raport cercetare 2' },
  rc3Rezultat: { label: 'Rezultat raport cercetare 3' },
  tezaRezultat: { label: 'Rezultat teza' },
  totalRezultat: { label: 'Rezultat total' },
  mediaAnilor: { label: 'Media anilor' },
  notaLicenta: { label: 'Nota licenta' },
  mediaAnilorMasterat: { label: 'Media anilor masterat' },
  notaMasterat: { label: 'Nota masterat' },
  notaDoctorat: { label: 'Nota doctorat' },
  situatiaTaxelorPlatiteTotal: { label: 'Situatia taxelor platite total' },
  situatiaTaxelorPlatite: {
    label: 'Situatia taxelor platite',
    accessor: data =>
      data.map((row, index) => (
        <TableRow key={row.detaliiPlata + index}>
          <TableCell>{row.detaliiPlata}</TableCell>
          <TableCell>{row.suma}</TableCell>
        </TableRow>
      )),
  },
  dePlataTaxaCrediteRestanteTotal: { label: 'De plata taxa credite restante total' },
  dePlataTaxaCrediteRestante: {
    label: 'De plata taxa credite restante',
    accessor: data =>
      data.map((row, index) => (
        <TableRow key={row.detaliiPlata + index}>
          <TableCell>{row.detaliiPlata}</TableCell>
          <TableCell>{row.suma}</TableCell>
        </TableRow>
      )),
  },
  plataCPLSauCPV: { label: 'De plata CPL sau CPV' },
  comisiaDeSustinere: { label: 'Comisia de sustinere' },
  dataSustinereTezaInDept: {
    label: 'Data sustinere teza in departament',
    accessor: data => (moment(data).isValid() ? moment(data).format('DD/MM/YYYY') : '-'),
  },
  dataSustinerePublica: {
    label: 'Data sustinere publica',
    accessor: data => (moment(data).isValid() ? moment(data).format('DD/MM/YYYY') : '-'),
  },
  calificativObtinutDupaSustinereaPublica: { label: 'Calificativ obtinut dupa sustinerea publica' },
  linkGoogleScholar: { label: 'Link Google Scholar' },
};

class DoctoranziView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        dosarId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    fetchDoctorandById: PropTypes.func.isRequired,
    setAppBarTitle: PropTypes.func.isRequired,
    dosar: PropTypes.shape({
      nume: PropTypes.string,
      prenume: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };
  state = {
    currentTab: 0,
  };
  async componentDidMount() {
    if (this.props.match) {
      await this.props.fetchDoctorandById(this.props.match.params.dosarId);
    }
    this.props.setAppBarTitle(`${this.props.dosar.nume} ${this.props.dosar.prenume}`);
  }
  componentWillUnmount() {
    this.props.setAppBarTitle();
  }

  handleBackClick = () => {
    this.props.history.push('/doctoranzi');
  };
  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab,
    });
  };
  handleExport = async () => {
    const { jwt, dosar } = this.props;
    await utils.exportDosar(jwt, dosar._id, this.props.showSnackbar);
  };
  renderTabContent = () => {
    const { currentTab } = this.state;
    const { dosar } = this.props;
    const rows = Object.keys(pick(dosar, [
      'email',
      'nrMatricol',
      'nume',
      'numeCasatorie',
      'initialaTatalui',
      'prenume',
      'cnp',
      'dataNasterii',
      'loculNasterii',
      'anInmatriculare',
      'cetatenie',
      'telefon',
      'specializari',
      'domeniuDoctorat',
      'conducatorDoctorat',
      'conducatorInitial',
      'conducatorCotutela',
      'transferRetrasDeces',
      'specializari',
      'formaDeInvatamantInitiala',
      'formaDeInvatamantCurenta',
      'situatiaScolara',
      'decizieExmatriculare',
      'limbaStrainaAdmitere',
      'calificativAdmitere',
      'comisieDeIndrumare',
      'disciplinaOptionala1',
      'disciplinaOptionala2',
      'disciplinaOptionala3',
      'denumireProiectCercetare',
      'disciplinaElectiva1',
      'disciplinaElectiva2',
      'raportDeCercetare1',
      'raportDeCercetare2',
      'raportDeCercetare3',
      'do1Credite',
      'do2Credite',
      'do3Credite',
      'de1Credite',
      'de2Credite',
      'de3Credite',
      'rc1Credite',
      'rc2Credite',
      'rc3Credite',
      'tezaCredite',
      'totalCredite',
      'do1Rezultat',
      'do2Rezultat',
      'do3Rezultat',
      'de1Rezultat',
      'de2Rezultat',
      'de3Rezultat',
      'rc1Rezultat',
      'rc2Rezultat',
      'rc3Rezultat',
      'tezaRezultat',
      'totalRezultat',
      'mediaAnilor',
      'notaLicenta',
      'mediaAnilorMasterat',
      'notaMasterat',
      'notaDoctorat',
      'situatiaTaxelorPlatiteTotal',
      'situatiaTaxelorPlatite',
      'dePlataTaxaCrediteRestanteTotal',
      'dePlataTaxaCrediteRestante',
      'plataCPLSauCPV',
      'comisiaDeSustinere',
      'dataSustinereTezaInDept',
      'dataSustinerePublica',
      'calificativObtinutDupaSustinereaPublica',
      'linkGoogleScholar',
    ]));
    switch (currentTab) {
      case 0:
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Proprietate</TableCell>
                <TableCell>Valoare</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <DosarView mappedNames={mappedNames} rows={rows} dosar={dosar} />
            </TableBody>
          </Table>
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente}
            type="edit"
            name="doctoranzi"
          />
        );
      default:
        return <div>Unknown tab</div>;
    }
  };
  render() {
    const { currentTab } = this.state;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-padded-icon"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleExport}
          >
            <CloudDownloadIcon />
            Exporta
          </Button>
        </div>
        <AppBar position="static" color="default">
          <Tabs indicatorColor="primary" value={currentTab} onChange={this.handleChangeTab}>
            <Tab label="Dosar" />
            <Tab label="Documente" />
          </Tabs>
        </AppBar>
        <Paper>{this.renderTabContent()}</Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dosar: getDoctorandById(state),
  jwt: getToken(state),
});

export default connect(
  mapStateToProps,
  { fetchDoctorandById, setAppBarTitle, showSnackbar },
)(DoctoranziView);
