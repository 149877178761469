import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import Select from "react-select/lib/Creatable";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: "flex"
    // padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center"
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: "1px",
    left: 0,
    right: 0,
    boxShadow:
      "0px 2px 2px -1px rgba(0, 0, 0,1) 0px 1px 2px 0px rgba(0, 0, 0, 1) 0px 1px 4px 0px rgba(0, 0, 0, 1)"
  },
  divider: {
    height: theme.spacing(2)
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      margin="dense"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      error={
        !!props.selectProps.textFieldProps.iError &&
        !!props.selectProps.textFieldProps.iTouched
      }
      FormHelperTextProps={{
        error:
          !!props.selectProps.textFieldProps.iError &&
          !!props.selectProps.textFieldProps.iTouched
      }}
      helperText={props.selectProps.textFieldProps.iError}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class IOSUDSelect extends React.Component {
  state = { typing: false };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      _.isEqual(nextProps.value, this.props.value) &&
      _.isEqual(nextProps.options, this.props.options) &&
      _.isEqual(nextProps.error, this.props.error) &&
      _.isEqual(nextProps.touched, this.props.touched) &&
      _.isEqual(this.state.typing, nextState.typing)
    ) {
      return false;
    }
    return true;
  }

  changeInputVal = val => {
    this.setState({
      typing: !!val
    });
  };

  render() {
    const {
      classes,
      theme,
      name,
      options,
      label,
      error,
      touched,
      value,
      onChange,
      disabled,
      addOption
    } = this.props;
    const { typing } = this.state;
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary
      })
    };
    return (
      <NoSsr>
        <Select
          onInputChange={(val, action) => this.changeInputVal(val, action)}
          noOptionsMessage={() => <span>Fara optiuni</span>}
          classes={classes}
          styles={selectStyles}
          createOptionPosition="first"
          formatCreateLabel={inputValue =>
            inputValue === "" ? (
              <span>
                {addOption || "[Introdu persoana sau alege din lista]"}
              </span>
            ) : (
              <span>Adauga nou: {inputValue}</span>
            )
          }
          isValidNewOption={() => true}
          // options={suggestions}
          // options={[{ value: null, label: 'Adauga persoana' }, ...options]}
          onCreateOption={val => {
            if (val !== "") {
              onChange(name, { value: val, label: val, __isNew__: true });
            }
          }}
          options={options}
          isDisabled={disabled}
          components={components}
          value={value}
          onChange={val => {
            onChange(name, val);
          }}
          placeholder=""
          textFieldProps={{
            error: !!error && !!touched,
            FormHelperTextProps: {
              error: !!error && !!touched
            },
            helperText: !!error && !!touched && error,
            // test: 'test',
            label,
            InputLabelProps: {
              shrink:
                this.props.menuIsOpen ||
                typing ||
                (!!value && value.length !== 0 && !_.isEmpty(value))
            }
          }}
        />
      </NoSsr>
    );
  }
}

IOSUDSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(IOSUDSelect);
