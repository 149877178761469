import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  withStyles,
  InputAdornment,
  FormControl,
  InputLabel,
  Input,
  FormHelperText
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import Visibility from "@material-ui/icons/Visibility";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { showSnackbar, login } from "../actions";

const styles = () => {};

class LoginForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    theme: PropTypes.shape({}).isRequired
  };

  state = {};

  handleSubmit = async values => {
    await this.props.login(values);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          onSubmit={this.handleSubmit}
          render={({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <FormControl fullWidth margin="dense">
                <InputLabel>Email</InputLabel>
                <Input
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={!!touched.email && !!errors.email}
                  endAdornment={
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
                {!!touched.email && !!errors.email && (
                  <FormHelperText error={!!touched.email && !!errors.email}>
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel>{t.password}</InputLabel>
                <Input
                  name="password"
                  fullWidth
                  onChange={handleChange}
                  type="password"
                  onBlur={handleBlur}
                  value={values.password}
                  error={!!touched.password && !!errors.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <Visibility />
                    </InputAdornment>
                  }
                />
                {!!touched.password && !!errors.password && (
                  <FormHelperText
                    error={!!touched.password && !!errors.password}
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>

              <div className="lvd-spacer20" />
              <Button variant="contained" color="primary" type="submit">
                {t.access}
              </Button>
              <span className="lvd-text-spacer" />
              <Button
                component={React.forwardRef((props, ref) => (
                  <Link {...props} ref={ref} />
                ))}
                to="/uitat-parola"
                href="/uitat-parola"
              >
                {t.recoverPassword}
              </Button>
            </Form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang]
});

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      { showSnackbar, login }
    )(LoginForm)
  )
);
