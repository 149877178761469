import * as TYPES from '../types';

const utilizatoriReducer = (
  state = {
    items: [],
    byId: {},
    isFetching: false,
    error: undefined,
    query: undefined,
    pageInfo: {
      pageCount: undefined,
      itemCount: undefined,
      page: undefined,
      pageSize: undefined,
    },
  },
  action,
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.UTILIZATORI_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.UTILIZATORI_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: payload.users,
        pageInfo: payload.pageInfo,
      };
    case TYPES.UTILIZATORI_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.UTILIZATORI_BYID_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.UTILIZATORI_BYID_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.UTILIZATORI_BYID_FAIL:
      return { ...state, isFetching: false, error };
    default:
      return state;
  }
};

export const getUtilizatori = state => state.utilizatori.items;
export const getUtilizatoriPageInfo = state => state.utilizatori.pageInfo;
export const getUserById = state => state.utilizatori.byId;
export default utilizatoriReducer;
