import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Button,
  withStyles,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { showSnackbar, fetchEvaluareByCode } from '../../actions';
import mainLogo from '../../svg/logo-utcb.svg';
import footer from '../../svg/logo-footer.svg';
import Snack from '../General/Snackbar';

const styles = () => {};

class MainPage extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    theme: PropTypes.shape({}).isRequired,
    fetchEvaluareByCode: PropTypes.func.isRequired,
  };
  state = {};
  handleSubmit = async (values) => {
    await this.props.fetchEvaluareByCode(values);
  };
  render() {
    const { theme } = this.props;
    return (
      <div
        className="lvd-login-container"
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <div className="lvd-login-logo">
          <img src={mainLogo} alt="logo" />
        </div>
        <div className="lvd-login-form">
          <Paper className="lvd-login-paper">
            <Typography variant="title" style={{ fontWeight: 700 }}>
              Evaluarea se face anonimizat. Identitatea dumneavoastra nu poate fi stabilita in
              niciun fel.
            </Typography>
            <Formik
              initialValues={{
                idCampanie: '',
                codDeEvaluare: '',
              }}
              onSubmit={this.handleSubmit}
              render={({
 handleChange, handleBlur, values, errors, touched,
}) => (
  <Form>
    <FormControl fullWidth margin="dense">
      <InputLabel>Campanie</InputLabel>
      <Input
        name="idCampanie"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.idCampanie}
        error={!!touched.idCampanie && !!errors.idCampanie}
      />
      {!!touched.idCampanie && !!errors.idCampanie && (
      <FormHelperText error={!!touched.idCampanie && !!errors.idCampanie}>
        {errors.idCampanie}
      </FormHelperText>
                    )}
    </FormControl>
    <FormControl fullWidth margin="dense">
      <InputLabel>Cod de evaluare</InputLabel>
      <Input
        name="codDeEvaluare"
        fullWidth
        onChange={handleChange}
        type="text"
        onBlur={handleBlur}
        value={values.codDeEvaluare}
        error={!!touched.codDeEvaluare && !!errors.codDeEvaluare}
      />
      {!!touched.codDeEvaluare && !!errors.codDeEvaluare && (
      <FormHelperText error={!!touched.codDeEvaluare && !!errors.codDeEvaluare}>
        {errors.password}
      </FormHelperText>
                    )}
    </FormControl>

    <div className="lvd-spacer20" />
    <Button variant="contained" color="primary" type="submit">
                    Incepe Evaluare
    </Button>
  </Form>
              )}
            />
            <div className="lvd-login-footer">
              <img src={footer} alt="footer-logo" />
              <span style={{ color: theme.palette.text.primary }}>IOSUD UTCB</span>
            </div>
          </Paper>
        </div>
        <Snack />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  { showSnackbar, fetchEvaluareByCode },
)(MainPage));
