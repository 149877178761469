import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Paper } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { fetchEvaluareById, updateEvaluare } from '../../actions';
import EvaluareForm from './EvaluareForm';
import { getEvaluareById } from '../../reducers/evaluariReducer';

class EvaluareEdit extends Component {
  static propTypes = {
    fetchEvaluareById: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        evaluareId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    evaluare: PropTypes.shape({}).isRequired,
    updateEvaluare: PropTypes.func.isRequired,
  };
  componentDidMount = () => {
    this.props.fetchEvaluareById(this.props.match.params.evaluareId);
  };
  handleSubmit = (values) => {
    this.props.updateEvaluare(values);
  };
  handleBackClick = () => {
    this.props.history.push('/evaluari');
  };
  render() {
    const { evaluare } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <EvaluareForm
            handleSubmit={this.handleSubmit}
            disabled={{
              nrCoduri: true,
              profAsociat: true,
              disciplina: true,
            }}
            isEditing
            initialValues={evaluare}
          />
        </Paper>
      </div>
    );
  }
}

EvaluareEdit.propTypes = {};

const mapStateToProps = state => ({
  evaluare: getEvaluareById(state),
});

const mapDispatchToProps = {
  fetchEvaluareById,
  updateEvaluare,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EvaluareEdit);
