import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import request from "superagent";
import FileSaver from "file-saver";
import {
  fetchEvaluari,
  showSnackbar,
  searchEvaluari,
  deleteEvaluare
} from "../../actions";
import {
  getEvaluari,
  getIsFetchingEvaluari,
  getEvaluariQuery,
  getEvaluariPageInfo
} from "../../reducers/evaluariReducer";
import TableWithFilter from "../General/TableWithFilter";
import { serverUrl } from "../../utils";
import { getToken } from "../../reducers/authReducer";

export const getStatus = ({ isDeleted, dataStart, dataEnd }) => {
  if (isDeleted) {
    return "Sters";
  }
  return moment().isBetween(moment(dataStart), moment(dataEnd))
    ? "Activ"
    : "Inactiv";
};

class Evaluare extends Component {
  static propTypes = {
    evaluari: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    isFetching: PropTypes.bool.isRequired,
    fetchEvaluari: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
    searchEvaluari: PropTypes.func.isRequired,
    deleteEvaluare: PropTypes.func.isRequired
  };
  state = {
    filterConfig: [
      {
        id: "titlu",
        label: "Titlu",
        type: "string",
        input: "text"
      },
      {
        id: "disciplina",
        label: "Disciplina",
        type: "string",
        input: "text"
      },
      {
        id: "profAsociat",
        label: "Profesor Asociat",
        type: "string",
        input: "text"
      }
      // {
      //   id: 'isDeleted',
      //   label: 'Este sters',
      //   type: 'boolean',
      //   input: 'radio',
      //   values: [true, false],
      // },
    ]
  };
  componentDidMount = () => {
    // this.props.searchEvaluari();
  };
  handleDownloadCoduri = async evaluareId => {
    const { jwt } = this.props;
    try {
      const res = await request(
        `${serverUrl}/export/evaluari/${evaluareId}/coduri`
      )
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  handleDownloadCampaign = async evaluareId => {
    const { jwt } = this.props;
    try {
      const res = await request(
        `${serverUrl}/export/evaluari/${evaluareId}/metadate`
      )
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  handleDownloadResults = async evaluareId => {
    const { jwt } = this.props;
    try {
      const res = await request(
        `${serverUrl}/export/evaluari/${evaluareId}/rezultate`
      )
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  handleDelete = id => {
    this.props.deleteEvaluare(id);
  };
  render() {
    const { evaluari, isFetching, query, pageInfo } = this.props;
    const { filterConfig } = this.state;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick,
      handleExportDosar,
      handleDownloadCoduri,
      handleDownloadCampaign,
      handleDownloadResults
    ) => [
      {
        id: "optiuni",
        Header: "Optiuni",
        width: 65,
        resizable: false,
        accessor: row => (
          <React.Fragment>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={event => openMenu(event, row._id)}
            >
              <MoreVertIcon />
            </IconButton>
          </React.Fragment>
        )
      },
      {
        id: "_id",
        Header: "Id",
        show: false
      },
      {
        accessor: "titlu",
        Header: "Titlu"
      },
      {
        id: "status",
        accessor: getStatus,
        Header: "Status"
      },
      {
        accessor: "cod",
        Header: "Cod"
      },
      {
        accessor: "nrCoduri",
        Header: "Numar de coduri"
      },
      {
        accessor: "disciplina",
        Header: "Disciplina"
      },
      {
        accessor: "profAsociat",
        Header: "Profesor Asociat"
      },
      {
        accessor: ({ dataStart }) => moment(dataStart).format("DD/MM/YYYY"),
        id: "dataStart",
        Header: "Data Start"
      },
      {
        accessor: ({ dataEnd }) => moment(dataEnd).format("DD/MM/YYYY"),
        Header: "Data Sfarsit",
        id: "dataEnd"
      }
    ];
    return (
      <div>
        <TableWithFilter
          name="evaluari"
          // isFetchingFilters={isFetchingFilters}
          isFetching={isFetching}
          query={query}
          pageInfo={pageInfo}
          onExportDosar={this.handleExportDosar}
          addButtonText="Adauga evaluare"
          onDelete={this.handleDelete}
          baseColumns={baseColumns}
          filterConfig={filterConfig}
          fetchItems={this.props.searchEvaluari}
          applyFilter={this.props.searchEvaluari}
          items={evaluari}
          handleDownloadCoduri={this.handleDownloadCoduri}
          handleDownloadCampaign={this.handleDownloadCampaign}
          handleDownloadResults={this.handleDownloadResults}
          menuShowView
          menuShowEdit
          menuShowDelete
          menuShowDownloadCoduri
          menuShowDownloadCampaign
          menuShowDownloadResults
          menuShowStatistics
        />
      </div>
    );
  }
}

Evaluare.propTypes = {};

const mapStateToProps = state => ({
  evaluari: getEvaluari(state),
  isFetching: getIsFetchingEvaluari(state),
  jwt: getToken(state),
  query: getEvaluariQuery(state),
  pageInfo: getEvaluariPageInfo(state)
});

const mapDispatchToProps = {
  fetchEvaluari,
  showSnackbar,
  searchEvaluari,
  deleteEvaluare
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Evaluare);
