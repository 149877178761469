import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Button,
  Paper,
  Typography,
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { fetchEvaluareById } from '../../actions';
import { getEvaluareById } from '../../reducers/evaluariReducer';
import { getStatus } from './EvaluareList';

class EvaluareView extends Component {
  static propTypes = {
    fetchEvaluareById: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        evaluareId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };
  componentDidMount() {
    this.props.fetchEvaluareById(this.props.match.params.evaluareId);
  }
  handleBackClick = () => {
    this.props.history.push('/evaluari');
  };
  render() {
    const { evaluare } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <Typography variant="subheading">
            {`Au fost folosite ${evaluare.nrCoduriFolosite} coduri din ${evaluare.nrCoduri}`}
            <br />
            {`Evaluarea este valabila de la ${moment(evaluare.dataStart).format('DD/MM/YYYY')} pana la ${moment(evaluare.dataEnd).format('DD/MM/YYYY')}`}
            <br />
            {`Status curent: ${getStatus({
              isDeleted: evaluare.isDeleted,
              dataStart: evaluare.dataStart,
              dataEnd: evaluare.dataEnd,
            })}`}
          </Typography>
          <div style={{ width: '100%' }}>
            <Line data={evaluare.chartData} />
          </div>
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Cod</TableCell>
                  <TableCell>Folosit</TableCell>
                  <TableCell>Data generare</TableCell>
                  <TableCell>Data raspuns</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evaluare.coduri &&
                  _.sortBy(evaluare.coduri, 'folosit')
                    .reverse()
                    .map(cod => (
                      <TableRow key={cod.cod}>
                        <TableCell>{cod.cod}</TableCell>
                        <TableCell>{cod.folosit ? 'Da' : 'Nu'}</TableCell>
                        <TableCell>
                          {moment(cod.generatedAt)
                            .utc()
                            .format('DD/MM/YYYY HH:mm')}
                        </TableCell>
                        <TableCell>
                          {cod.folosit
                            ? moment(cod.answeredAt)
                                .utc()
                                .format('DD/MM/YYYY HH:mm')
                            : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  evaluare: getEvaluareById(state),
});

const mapDispatchToProps = {
  fetchEvaluareById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EvaluareView);
