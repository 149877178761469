import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

class PendingValidation extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Typography gutterBottom variant="h6">
          {t.awaitingValidation}
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang]
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingValidation);
