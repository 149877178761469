import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Paper, Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Player, BigPlayButton } from 'video-react';
import { fetchConferintaById } from '../../actions';
import { getConferintaById } from '../../reducers/videoconferinteReducer';
import { serverUrl } from '../../utils';

class ViewVideoconferinta extends Component {
  static propTypes = {
    fetchConferintaById: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        conferintaId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    conferinta: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };
  componentDidMount() {
    this.props.fetchConferintaById(this.props.match.params.conferintaId);
  }
  handleBackClick = () => {
    this.props.history.push('/videoconferinte');
  };

  render() {
    const { conferinta } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div>
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <div className="lvd-normal-text-color">
          <div className="lvd-spacer20" />
          <Paper className="lvd-container-form" style={{ maxWidth: '800px' }}>
            {conferinta.videoEmbed ? (
              <div
                className="videoWrapper lvd-video-container"
                dangerouslySetInnerHTML={{ __html: conferinta.videoEmbed }}
              />
            ) : (
              <div className="lvd-video-container">
                <Player src={`${serverUrl}/${conferinta.videoPath}`}>
                  <BigPlayButton position="center" />
                </Player>
              </div>
            )}
            <div className="lvd-spacer20" />
            <div className="lvd-video-title">{conferinta.titlu}</div>
            <span>{conferinta.descriere}</span>
            <ul className="lvd-dialog-list">
              <li>
                <span>Tip:</span> {conferinta.tip}
              </li>
              <li>
                {conferinta.tip === 'conferinta' ? (
                  <React.Fragment>
                    <span>Autori:</span> {conferinta.autori}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span>Doctorand:</span> {conferinta.doctorand && conferinta.doctorand.label}
                  </React.Fragment>
                )}
              </li>
              <li>
                <span>Data eveniment:</span>{' '}
                {moment(conferinta.dataEveniment).format('DD/MM/YYYY HH:mm')}
              </li>
              <li>
                <span>Data ultimei editari:</span>{' '}
                {moment(conferinta.updatedAt).format('DD/MM/YYYY HH:mm')}
              </li>
            </ul>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  conferinta: getConferintaById(state),
});

export default connect(
  mapStateToProps,
  { fetchConferintaById },
)(ViewVideoconferinta);
