import React, { Component } from "react";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Link,
  Button
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import mainLogo from "../../svg/logo-utcb.svg";
import LanguageBox from "./LanguageBox";
import { getIsLoggedIn, getUser } from "../../reducers/authReducer";
import { logout } from "../../actions";

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%"
  },
  flex: {
    flexGrow: 1
  },
  appBar: {
    // position: "absolute",
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('md')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 2px -1px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.03)"
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    overflow: "auto"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

class LoginAppBar extends Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = async () => await this.props.logout();

  render() {
    const { classes, isLoggedIn } = this.props;
    // const { anchorEl } = this.state;
    // const openUser = Boolean(anchorEl);
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {this.props.showButton && (
            <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={this.props.handleDrawerToggle}
              className={classes.navIconHide}
              classes={{
                root: "lvd-drawer-icon"
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div>
            <Link to="/login" href="/login">
              <img
                style={{ height: "50px", width: "50px" }}
                src={mainLogo}
                alt="logo"
              />
            </Link>
          </div>
          {/* <div style={{ margin: "20px" }}>RO / EN</div> */}
          <div style={{}}>
            <LanguageBox />
          </div>
          <div style={{ flexGrow: 1 }} />
          <div style={{ marginRight: "12px" }}>
            <span>{this.props.user.email || ""}</span>
          </div>
          <div>
            {isLoggedIn && (
              <Button onClick={this.handleLogout}>Iesi din cont</Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

LoginAppBar.propTypes = {};

const mapStateToProps = state => ({
  isLoggedIn: getIsLoggedIn(state),
  user: getUser(state)
});

const mapDispatchToProps = { logout };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(LoginAppBar));
