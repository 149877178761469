export default {
  en: {
    privacyPolicy: "Privacy policy",
    identityCardText: "Id card",
    identityCardCaptionText: "One of the documents below is required",
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password",
    identificationData: "Identification data",
    familyName: "Family name",
    familyNameExplanation: "From the birth certificate",
    fatherInitial: `Father's initial`,
    marriageName: "Marriage name (if it applies)",
    maritalStatus: "Marital status",
    married: "Married",
    notMarried: "Not married",
    dateOfBirth: "Date of birth",
    nationality: "Nationality",
    citizenship: "Citizenship",
    birthplace: "Birth place address (City, department, country)",
    residence: "Residence address (City, department, country)",
    street: "Street",
    cnp: "Numerical identification code",
    phoneNumber: "Phone Number",
    register: "Register",
    alreadyHaveAccountQuestion: "Already have an account?",
    signIn: "Sign In",
    bloc: "Building",
    number: "Number",
    email: "Email",
    invalidEmail: "Invalid email address",
    requiredField: "This field is required",
    shortPassword: "Password is too short",
    passwordsNoMatch: "Passwords don't match",
    postalCode: "Postal code",
    entrance: "Entrance",
    floor: "Floor",
    apartment: "Apartment",
    identityCardSerial: "Identity card serial",
    identityCardNo: "Identity card no.",
    identityCardIssueDate: "Identity card issue date",
    passportSerial: "Passport serial no.",
    passportIssueDate: "Passport issue year",
    passportValabilityYears: "Valability (years)",
    mobilePhone: "Mobile phone number",
    landline: "Landline phone number",
    dataOnGraduatedLicenceStudies: "Data on graduated licence studies",
    nameOfHighEducationInstitution: "Name of the high education institution",
    country: "Country",
    city: "City",
    faculty: "Faculty",
    field: "Field",
    specialization: "Specialization",
    graduationYear: "Graduation year",
    durationSem: "Duration (no. of semesters)",
    obtainedTitle: "Obtained title",
    diplomaCertificateNo: "Diploma / Certificate no.",
    matricolSheetCertificateNo: "Matricol sheet / Certificate no.",
    dataOnUniversityMasterStudies: "Data on graduated master studies",
    dataOnUniversityDoctoralStudies:
      "Data on the universitary doctoral studies",
    registrationYear: "Year of registration",
    formOfDoctoralStudy: "Form (select and specify if applicable)",
    withScholarship: "With scholarship",
    withoutScholarship: "Without scholarship",
    withRegularTax: "With regular tax",
    phdAdvisor: "Ph.D Advisor",
    doctoralField: "Doctoral field",
    studyQuestion:
      "Have you ever undergone doctoral studies funded by the national budget at UTCB ?",
    period: "Period",
    studyQuestionOtherUniversity:
      "Have you ever undergone doctoral studies funded by the national budget at a different university in Romania?",
    yes: "Yes",
    no: "No",
    foreignLanguage: "Foreign language",
    proficiencyCertificate: "Proficiency Certificate",
    accomodationInUniversitaryResidence:
      "Do you require accommodation in UTCB universitary residences?",
    declaration:
      "I, the undersigned, declare that I understand the meaning of all the data requested in this sheet. I have to notify the Secretariat of the Doctoral School of any changes in the declared data in the shortest possible time.",
    save: "Save",
    createAccount: "Create account",
    createAccountDescription: "Create an account to manage registration",
    completeRegistrationPaper: "Complete registration paper",
    completeRegistrationPaperDescription: "Fill in registration paper",
    loadDocuments: "Load documents",
    loadDocumentsDescription: "Load the scans of the requested documents",
    registrationPayment: "Registration fee",
    paymentId: "Payment id",
    registrationPaymentDescription: "Pay the registration fee (CARD or OP)",
    finalizeRegistration: "Finalization",
    finalizeRegistrationDescription:
      "From this point forward you can follow the registration from your account",
    back: "Back",
    finalize: "Finish",
    forward: "Forward",
    registerMe: "I'm registering",
    alreadyHaveAccount: "I already have an account",
    euCandidate: "I am an EU candidate",
    nonEuCandidate: "I am a NON-EU candidate",
    startRegistration: "Start registration",
    recoverPassword: "Recover password",
    access: "Access",
    pendingValidation: "Validation pending",
    pendingValidationDescription:
      "Your information is going to be validated by a human",
    others: "Others",
    options: "Options",
    name2: "Name",
    template: "Template",
    file: "File",
    type: "Type",
    size: "Size",
    uploadDate: "Upload date",
    validationDate: "Validation Date",
    sendToValidation: "Send to validation",
    awaitingValidation: "Your registration request is pending IOSUD validation",
    acceptTerms: "I agree to the terms and conditions",
    verifyEmailPart1: "Please check your e-mail address",
    verifyEmailPart2:
      "and click the validation link in the message in order to continue the registration process.",
    acceptGDPR: "I agree to IOSUD's ",
    requestResendVerificationToken: "Resend validation link",
    expiredLinkTitle: "Expired validation link",
    expiredLinkDescription:
      "Your validation link is valid for 48 hours. Please request a new one by logging in.",
    backToLogin: "Back to login",
    downloadUpload: "Upload/Download",
    download: "Download",
    upload: "Upload",
    sendEmail: "Email the instructions",
    payFee: "Pay securely",
    payFeeContinue: "Continue to payment",
    payByCreditCard:
      "Paying by credit card will make the admission process faster", // Plata cu cardul va face procesul de inscriere mai rapid
    tabLabelCard: "Card",
    tabLabelOP: "OP",
    tabLabelCash: "Cash",
  },
  ro: {
    privacyPolicy: "Politica de confidentialitate",
    identityCardText: "Act de identitate",
    identityCardCaptionText: "Unul din documentele de mai jos este obligatoriu",
    firstName: "Prenume",
    lastName: "Nume",
    emailAddress: "Email",
    password: "Parola",
    confirmPassword: "Confirmare parola",
    identificationData: "Date de identificare",
    familyName: "Nume de familie",
    familyNameExplanation: "Din certificatul de nastere",
    fatherInitial: "Initiala Tatalui",
    marriageName: "Nume dobandit (daca este cazul)",
    maritalStatus: "Stare civila",
    married: "Casatorit",
    notMarried: "Necasatorit",
    dateOfBirth: "Data nasterii",
    nationality: "Nationalitate",
    citizenship: "Cetatenie",
    birthplace: "Locul Nasterii (localitate, judet, tara)",
    residence: "Domiciliu stabil (localitate, judet, tara)",
    street: "Strada",
    cnp: "Cod numeric personal",
    idCardSeries: "Serie si Numar Buletin",
    studyYear: "An de studiu",
    phoneNumber: "Numar de telefon",
    register: "Inregistrare",
    alreadyHaveAccountQuestion: "Ai deja cont?",
    signIn: "Logheaza-te",
    bloc: "Bloc",
    number: "Numar",
    email: "Email",
    invalidEmail: "Adresa de email invalida",
    requiredField: "Acest camp este obligatoriu",
    shortPassword: "Parola este prea scurta",
    passwordsNoMatch: "Parolele nu se potrivesc",
    postalCode: "Cod Postal",
    entrance: "Scara",
    floor: "Etaj",
    apartment: "Apartament",
    identityCardSerial: "Serie carte de identitate",
    identityCardNo: "Nr. Carte de Identitate",
    identityCardIssueDate: "Data eliberarii cartii de identitate",
    passportSerial: "Serie Pasaport",
    passportIssueDate: "An eliberare",
    passportValabilityYears: "Valabilitate (ani)",
    mobilePhone: "Telefon Mobil",
    landline: "Telefon fix",
    dataOnGraduatedLicenceStudies: "Date privind studiile de licenta absolvite",
    nameOfHighEducationInstitution:
      "Denumirea instituţiei de învăţământ superior",
    country: "Tara",
    city: "Localitatea",
    faculty: "Facultatea",
    field: "Domeniul",
    specialization: "Specializarea",
    graduationYear: "Anul absolvirii",
    durationSem: "Durata (sem)",
    obtainedTitle: "Titlu obtinut",
    diplomaCertificateNo: "Diploma / Adeverinta nr.",
    matricolSheetCertificateNo: "Foaia matricola/Adeverinta nr.",
    dataOnUniversityMasterStudies: "Date privind studiile de master absolvite",
    dataOnUniversityDoctoralStudies:
      "Date privind studiile universitare de doctorat",
    registrationYear: "Anul inscrierii",
    formOfDoctoralStudy: "Forma (selectati si specificati daca este cazul)",
    withScholarship: "Cu bursa",
    withoutScholarship: "Fara bursa",
    withRegularTax: "Cu taxa",
    phdAdvisor: "Conducator Doctorat",
    doctoralField: "Domeniul de doctorat",
    studyQuestion:
      "Ati mai urmat studii universitare de doctorat finantate de la buget in UTCB?",
    period: "Perioada",
    studyQuestionOtherUniversity:
      "Ati mai urmat studii universitare de doctorat finantate de la buget in alta universitate din Romania?",
    yes: "Da",
    no: "Nu",
    foreignLanguage: "Limba straina cunoscuta",
    proficiencyCertificate: "Certificat de atestare competente lingvistice",
    accomodationInUniversitaryResidence: "Solicitati cazare in caminele UTCB?",
    declaration:
      "Subsemnatul, declar ca am înţeles semnificaţia tuturor datelor solicitate în această fişă. Mă oblig să anunţ secretariatului Scolii Doctorale orice modificare în ceea ce priveşte datele declarate, în cel mai scurt timp.",
    save: "Salveaza",
    createAccount: "Creare cont",
    createAccountDescription:
      "Creezi un cont prin care vei manageria inscrierea",
    completeRegistrationPaper: "Completare fisa",
    completeRegistrationPaperDescription: "Completezi fisa de inscriere",
    loadDocuments: "Incarcare documente",
    loadDocumentsDescription:
      "Incarci in profil scan-uri cu documentele solicitate",
    registrationPayment: "Plata inscriere",
    paymentId: "Id de plata",
    registrationPaymentDescription: "Platesti taxa de inscriere (CARD sau OP)",
    finalizeRegistration: "Finalizare",
    finalizeRegistrationDescription:
      "In acest moment, poti urmari inscrierea direct din contul tau",
    back: "Inapoi",
    forward: "Inainte",
    finalize: "Finalizare",
    registerMe: "Mă înscriu",
    alreadyHaveAccount: "Am deja cont",
    euCandidate: "Sunt candidat UE",
    nonEuCandidate: "Sunt candidat NON-UE",
    startRegistration: "Incepe inscrierea",
    recoverPassword: "Recupereaza parola",
    access: "Acces",
    pendingValidation: "Asteapta validare",
    pendingValidationDescription:
      "Datele tale vor fi validate de catre un operator",
    others: "Altele",
    options: "Optiuni",
    name2: "Denumire",
    template: "Template",
    file: "Fisier",
    type: "Tip",
    size: "Dimensiune",
    uploadDate: "Data incarcare",
    validationDate: "Data validare",
    sendToValidation: "Trimite catre validare",
    awaitingValidation: "Cererea de înscriere așteaptă validarea IOSUD",
    acceptTerms: "Sunt de acord cu termenii și condițile de utilizare",
    verifyEmailPart1: "Te rugam sa verifici adresa ta de email",
    verifyEmailPart2:
      "si sa dai click pe link-ul de validare din mesaj pentru a putea continua procesul de inscriere.",
    acceptGDPR: "Sunt de acord cu ",
    requestResendVerificationToken: "Retrimite link validare",
    expiredLinkTitle: "Expired validation link",
    expiredLinkDescription:
      "Your validation link is valid for 48 hours. Please request a new one by logging in.",
    backToLogin: "Inapoi la login",
    downloadUpload: "Adauga/Descarca",
    download: "Descarca",
    upload: "Adauga",
    sendEmail: "Trimite instructiunile pe mail",
    payFee: "Plateste securizat",
    payFeeContinue: "Continua plata",
    payByCreditCard: "Plata cu cardul va face procesul de inscriere mai rapid",
    tabLabelCard: "Card",
    tabLabelOP: "OP",
    tabLabelCash: "Casierie",
  },
};
