import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSnackbar } from '../../actions';
import {
  getRole,
  getDosareCandidat,
  getDosareDoctorand,
  getDosareConducator,
  getToken,
} from '../../reducers/authReducer';
import DoctoranziEdit from '../Dosare/DoctoranziEdit';
import ConducatoriEdit from '../Dosare/ConducatoriEdit';
import CandidatiEdit from '../Dosare/CandidatiEdit';

class DosarulMeu extends Component {
  static propTypes = {
    role: PropTypes.string,
    dosareCandidat: PropTypes.arrayOf(PropTypes.string),
    dosareDoctorand: PropTypes.arrayOf(PropTypes.string),
    dosareConducator: PropTypes.arrayOf(PropTypes.string),
  };
  static defaultProps = {
    dosareCandidat: [],
    dosareDoctorand: [],
    dosareConducator: [],
    role: 'utilizator',
  };
  state = {};
  render() {
    const {
      role, dosareCandidat, dosareDoctorand, dosareConducator,
    } = this.props;
    if (dosareCandidat[0] || dosareDoctorand[0] || dosareConducator[0]) {
      switch (role) {
        case 'doctorand':
          return (
            <DoctoranziEdit
              dosarId={dosareDoctorand && dosareDoctorand[0]}
              stayAfterEdit
              isUserView
            />
          );
        case 'conducator':
          return (
            <ConducatoriEdit
              dosarId={dosareConducator && dosareConducator[0]}
              stayAfterEdit
              isUserView
            />
          );
        case 'candidat':
          return (
            <CandidatiEdit dosarId={dosareCandidat && dosareCandidat[0]} stayAfterEdit isUserView />
          );
        default:
          return <div>Rol necunoscut!</div>;
      }
    } else {
      return <div>Loading...</div>;
    }
  }
}

const mapStateToProps = state => ({
  role: getRole(state),
  jwt: getToken(state),
  dosareCandidat: getDosareCandidat(state),
  dosareDoctorand: getDosareDoctorand(state),
  dosareConducator: getDosareConducator(state),
});

export default connect(
  mapStateToProps,
  { showSnackbar },
)(DosarulMeu);
