import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import {
  Button,
  Paper,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { fetchCandidatById, setAppBarTitle } from '../../actions';
import DocumentTable from '../General/DocumentTable';
import { getCandidatById } from '../../reducers/candidatiReducer';
import DosarView from './DosarView';

const mappedNames = {
  email: { label: 'Email' },
  nume: { label: 'Nume' },
  prenume: { label: 'Prenume' },
  cnp: { label: 'CNP' },
  titulatura: { label: 'Titulatura' },
  anObtinereTitluDoctor: { label: 'An obtinere titlu doctor' },
  facultate: { label: 'Facultate' },
  departament: { label: 'Departament' },
  domeniuDoctorat: { label: 'Domeniu doctorat' },
  specializare: { label: 'Specializare' },
  telefon: { label: 'Telefon' },
  linkScopus: { label: 'Link Scopus' },
  linkGoogleScholar: { label: 'Link Google Scholar' },
  linkISIWebOfScience: { label: 'Link ISI Web of Science' },
  linkResearchGate: { label: 'Link Research Gate' },
};

class CandidatiView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        dosarId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    fetchCandidatById: PropTypes.func.isRequired,
    setAppBarTitle: PropTypes.func.isRequired,
    dosar: PropTypes.shape({
      nume: PropTypes.string,
      prenume: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };
  state = {
    currentTab: 0,
  };
  async componentDidMount() {
    await this.props.fetchCandidatById(this.props.match.params.dosarId);
    this.props.setAppBarTitle(`${this.props.dosar.nume} ${this.props.dosar.prenume}`);
  }
  componentWillUnmount() {
    this.props.setAppBarTitle();
  }

  handleBackClick = () => {
    this.props.history.push('/candidati');
  };
  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab,
    });
  };
  renderTabContent = () => {
    const { currentTab } = this.state;
    const { dosar } = this.props;
    const rows = Object.keys(pick(dosar, [
      'email',
      'nume',
      'prenume',
      'cnp',
      'titulatura',
      'anObtinereTitluDoctor',
      'facultate',
      'departament',
      'domeniuDoctorat',
      'specializare',
      'telefon',
      'linkScopus',
      'linkGoogleScholar',
      'linkISIWebOfScience',
      'linkResearchGate',
    ]));
    switch (currentTab) {
      case 0:
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Proprietate</TableCell>
                <TableCell>Valoare</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <DosarView mappedNames={mappedNames} rows={rows} dosar={dosar} />
            </TableBody>
          </Table>
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente}
            type="view"
            name="conducatori"
          />
        );
      default:
        return <div>Unknown tab</div>;
    }
  };
  render() {
    const { currentTab } = this.state;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-padded-icon"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <AppBar position="static" color="default">
          <Tabs indicatorColor="primary" value={currentTab} onChange={this.handleChangeTab}>
            <Tab label="Dosar" />
            <Tab label="Documente" />
          </Tabs>
        </AppBar>
        <Paper>{this.renderTabContent()}</Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dosar: getCandidatById(state),
});

export default connect(
  mapStateToProps,
  { fetchCandidatById, setAppBarTitle },
)(CandidatiView);
