import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Button,
  Paper,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Form, Formik } from 'formik';
import { editUtilizator, fetchUtilizatorById } from '../../actions';
import { getUserById } from '../../reducers/utilizatoriReducer';

class EditUtilizator extends Component {
  static propTypes = {
    editUtilizator: PropTypes.func.isRequired,
    fetchUtilizatorById: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        userId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };
  componentDidMount() {
    this.props.fetchUtilizatorById(this.props.match.params.userId);
  }
  handleSubmit = async (values) => {
    await this.props.editUtilizator(values);
    this.props.history.push('/utilizatori');
  };
  handleBackClick = () => {
    this.props.history.push('/utilizatori');
  };
  render() {
    const { user } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <Formik
            onSubmit={this.handleSubmit}
            enableReinitialize
            initialValues={{
              id: user._id,
              email: user.email || '',
              role: user.role || '',
              password: '',
            }}
            render={({
 handleChange, handleBlur, errors, values, touched,
}) => (
  <Form>
    <TextField
      fullWidth
      margin="dense"
      value={values.email}
      name="email"
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!touched.email && !!errors.email}
      label="Email"
    />
    <FormControl fullWidth margin="dense">
      <InputLabel>Rol</InputLabel>
      <Select
        value={values.role}
        name="role"
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!touched.role && !!errors.role}
        label="Rol"
      >
        <MenuItem value="others">Altii</MenuItem>
        <MenuItem value="utilizator">Utilizator</MenuItem>
        <MenuItem value="masterand">Masterand</MenuItem>
        <MenuItem value="secretara">Secretara</MenuItem>
        <MenuItem value="administrator">Administrator</MenuItem>
        {!['others', 'utilizator', 'masterand', 'secretara', 'administrator'].includes(values.role) && <MenuItem value={values.role}>{_.upperFirst(values.role)}</MenuItem>}
      </Select>
    </FormControl>
    <TextField
      name="password"
      label="Parola"
      fullWidth
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!touched.password && !!errors.password}
      value={values.password}
      type="password"
    />
    <div className="lvd-spacer10" />
    <Button type="submit" variant="contained" color="primary">
                  Salveaza
    </Button>
  </Form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: getUserById(state),
});

export default connect(
  mapStateToProps,
  { editUtilizator, fetchUtilizatorById },
)(EditUtilizator);
