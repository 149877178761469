export const VERIFICATION_TOKEN_RESEND_REQUEST =
  "VERIFICATION_TOKEN_RESEND_REQUEST";
export const VERIFICATION_TOKEN_RESEND_SUCCESS =
  "VERIFICATION_TOKEN_RESEND_SUCCESS";
export const VERIFICATION_TOKEN_RESEND_FAIL = "VERIFICATION_TOKEN_RESEND_FAIL";
// #region doctoranzi types
export const DOCTORANZI_FETCH_REQUEST = "DOCTORANZI_FETCH_REQUEST";
export const DOCTORANZI_FETCH_SUCCESS = "DOCTORANZI_FETCH_SUCCESS";
export const DOCTORANZI_FETCH_FAILURE = "DOCTORANZI_FETCH_FAILURE";
export const DOCTORANZI_ADD_REQUEST = "DOCTORANZI_ADD_REQUEST";
export const DOCTORANZI_ADD_SUCCESS = "DOCTORANZI_ADD_SUCCESS";
export const DOCTORANZI_ADD_FAIL = "DOCTORANZI_ADD_FAIL";
export const DOCTORANZI_FETCH_BYID_REQUEST = "DOCTORANZI_FETCH_BYID_REQUEST";
export const DOCTORANZI_FETCH_BYID_SUCCESS = "DOCTORANZI_FETCH_BYID_SUCCESS";
export const DOCTORANZI_FETCH_BYID_FAIL = "DOCTORANZI_FETCH_BYID_FAIL";
export const DOCTORANZI_UPDATE_REQUEST = "DOCTORANZI_UPDATE_REQUEST";
export const DOCTORANZI_UPDATE_SUCCESS = "DOCTORANZI_UPDATE_SUCCESS";
export const DOCTORANZI_UPDATE_FAIL = "DOCTORANZI_UPDATE_FAIL";
export const DOCTORANZI_SEARCH_REQUEST = "DOCTORANZI_SEARCH_REQUEST";
export const DOCTORANZI_SEARCH_SUCCESS = "DOCTORANZI_SEARCH_SUCCESS";
export const DOCTORANZI_SEARCH_FAIL = "DOCTORANZI_SEARCH_FAIL";
export const DOCTORANZI_DELETE_REQUEST = "DOCTORANZI_DELETE_REQUEST";
export const DOCTORANZI_DELETE_SUCCESS = "DOCTORANZI_DELETE_SUCCESS";
export const DOCTORANZI_DELETE_FAIL = "DOCTORANZI_DELETE_FAIL";
export const DOCTORANZI_SET_QUERY = "DOCTORANZI_SET_QUERY";
export const DOCTORANZI_DROPDOWN_FETCH_REQUEST =
  "DOCTORANZI_DROPDOWN_FETCH_REQUEST";
export const DOCTORANZI_DROPDOWN_FETCH_SUCCESS =
  "DOCTORANZI_DROPDOWN_FETCH_SUCCESS";
export const DOCTORANZI_DROPDOWN_FETCH_FAILURE =
  "DOCTORANZI_DROPDOWN_FETCH_FAILURE";
// #endregion doctoranzi types
// #region inscrisi types
export const INSCRIS_FINALIZE_REQUEST = "INSCRIS_FINALIZE_REQUEST";
export const INSCRIS_FINALIZE_SUCCESS = "INSCRIS_FINALIZE_SUCCESS";
export const INSCRIS_FINALIZE_FAIL = "INSCRIS_FINALIZE_FAIL";
export const INSCRIS_SENDTOVALIDATION_REQUEST =
  "INSCRIS_SENDTOVALIDATION_REQUEST";
export const INSCRIS_SENDTOVALIDATION_SUCCESS =
  "INSCRIS_SENDTOVALIDATION_SUCCESS";
export const INSCRIS_REJECT_REQUEST = "INSCRIS_REJECT_REQUEST";
export const INSCRIS_REJECT_SUCCESS = "INSCRIS_REJECT_SUCCESS";
export const INSCRIS_REJECT_FAIL = "INSCRIS_REJECT_FAIL";
export const INSCRIS_VALIDATE_REQUEST = "INSCRIS_VALIDATE_REQUEST";
export const INSCRIS_VALIDATE_SUCCESS = "INSCRIS_VALIDATE_SUCCESS";
export const INSCRIS_VALIDATE_FAIL = "INSCRIS_VALIDATE_FAIL";
export const INSCRIS_SENDTOVALIDATION_FAIL = "INSCRIS_SENDTOVALIDATION_FAIL";
export const INSCRISI_SET_CANDIDAT_TYPE = "INSCRISI_SET_CANDIDAT_TYPE";
export const INSCRISI_FETCH_REQUEST = "INSCRISI_FETCH_REQUEST";
export const INSCRISI_FETCH_SUCCESS = "INSCRISI_FETCH_SUCCESS";
export const INSCRISI_FETCH_FAILURE = "INSCRISI_FETCH_FAILURE";
export const INSCRISI_ADD_REQUEST = "INSCRISI_ADD_REQUEST";
export const INSCRISI_ADD_SUCCESS = "INSCRISI_ADD_SUCCESS";
export const INSCRISI_ADD_FAIL = "INSCRISI_ADD_FAIL";
export const INSCRISI_FETCH_BYID_REQUEST = "INSCRISI_FETCH_BYID_REQUEST";
export const INSCRISI_FETCH_BYID_SUCCESS = "INSCRISI_FETCH_BYID_SUCCESS";
export const INSCRISI_FETCH_BYID_FAIL = "INSCRISI_FETCH_BYID_FAIL";
export const INSCRISI_UPDATE_REQUEST = "INSCRISI_UPDATE_REQUEST";
export const INSCRISI_UPDATE_SUCCESS = "INSCRISI_UPDATE_SUCCESS";
export const INSCRISI_UPDATE_FAIL = "INSCRISI_UPDATE_FAIL";
export const INSCRISI_SEARCH_REQUEST = "INSCRISI_SEARCH_REQUEST";
export const INSCRISI_SEARCH_SUCCESS = "INSCRISI_SEARCH_SUCCESS";
export const INSCRISI_SEARCH_FAIL = "INSCRISI_SEARCH_FAIL";
export const INSCRISI_DELETE_REQUEST = "INSCRISI_DELETE_REQUEST";
export const INSCRISI_DELETE_SUCCESS = "INSCRISI_DELETE_SUCCESS";
export const INSCRISI_DELETE_FAIL = "INSCRISI_DELETE_FAIL";
export const INSCRISI_SET_QUERY = "INSCRISI_SET_QUERY";
export const INSCRISI_DROPDOWN_FETCH_REQUEST =
  "INSCRISI_DROPDOWN_FETCH_REQUEST";
export const INSCRISI_DROPDOWN_FETCH_SUCCESS =
  "INSCRISI_DROPDOWN_FETCH_SUCCESS";
export const INSCRISI_DROPDOWN_FETCH_FAILURE =
  "INSCRISI_DROPDOWN_FETCH_FAILURE";
export const INSCRIS_FETCH_BYID_REQUEST = "INSCRIS_FETCH_BYID_REQUEST";
export const INSCRIS_FETCH_BYID_SUCCESS = "INSCRIS_FETCH_BYID_SUCCESS";
export const INSCRIS_FETCH_BYID_FAIL = "INSCRIS_FETCH_BYID_FAIL";
export const INSCRIS_FETCH_REQUEST = "INSCRIS_FETCH_REQUEST";
export const INSCRIS_FETCH_SUCCESS = "INSCRIS_FETCH_SUCCESS";
export const INSCRIS_FETCH_FAIL = "INSCRIS_FETCH_FAIL";
export const INSCRISI_MY_UPDATE_REQUEST = "INSCRISI_MY_UPDATE_REQUEST";
export const INSCRISI_MY_UPDATE_SUCCESS = "INSCRISI_MY_UPDATE_SUCCESS";
export const INSCRISI_MY_UPDATE_FAIL = "INSCRISI_MY_UPDATE_FAIL";
// #endregion inscrisi types
// #region conducatori types
export const CONDUCATORI_FETCH_REQUEST = "CONDUCATORI_FETCH_REQUEST";
export const CONDUCATORI_FETCH_SUCCESS = "CONDUCATORI_FETCH_SUCCESS";
export const CONDUCATORI_FETCH_FAILURE = "CONDUCATORI_FETCH_FAILURE";
export const CONDUCATORI_BYID_REQUEST = "CONDUCATORI_BYID_REQUEST";
export const CONDUCATORI_BYID_SUCCESS = "CONDUCATORI_BYID_SUCCESS";
export const CONDUCATORI_BYID_FAIL = "CONDUCATORI_BYID_FAIL";
export const CONDUCATORI_UPDATE_REQUEST = "CONDUCATORI_UPDATE_REQUEST";
export const CONDUCATORI_UPDATE_SUCCESS = "CONDUCATORI_UPDATE_SUCCESS";
export const CONDUCATORI_UPDATE_FAIL = "CONDUCATORI_UPDATE_FAIL";
export const CONDUCATORI_ADD_REQUEST = "CONDUCATORI_ADD_REQUEST";
export const CONDUCATORI_ADD_SUCCESS = "CONDUCATORI_ADD_SUCCESS";
export const CONDUCATORI_ADD_FAIL = "CONDUCATORI_ADD_FAIL";
export const CONDUCATORI_DELETE_REQUEST = "CONDUCATORI_DELETE_REQUEST";
export const CONDUCATORI_DELETE_SUCCESS = "CONDUCATORI_DELETE_SUCCESS";
export const CONDUCATORI_DELETE_FAIL = "CONDUCATORI_DELETE_FAIL";
export const CONDUCATORI_SEARCH_REQUEST = "CONDUCATORI_SEARCH_REQUEST";
export const CONDUCATORI_SEARCH_SUCCESS = "CONDUCATORI_SEARCH_SUCCESS";
export const CONDUCATORI_SEARCH_FAIL = "CONDUCATORI_SEARCH_FAIL";
export const CONDUCATORI_KEYVALUE_REQUEST = "CONDUCATORI_KEYVALUE_REQUEST";
export const CONDUCATORI_KEYVALUE_SUCCESS = "CONDUCATORI_KEYVALUE_SUCCESS";
export const CONDUCATORI_KEYVALUE_FAILURE = "CONDUCATORI_KEYVALUE_FAILURE";
// #endregion conducatori types
// #region evaluari types
export const EVALUARI_FETCH_REQUEST = "EVALUARI_FETCH_REQUEST";
export const EVALUARI_FETCH_SUCCESS = "EVALUARI_FETCH_SUCCESS";
export const EVALUARI_FETCH_FAILURE = "EVALUARI_FETCH_FAILURE";
export const EVALUARI_BYID_REQUEST = "EVALUARI_BYID_REQUEST";
export const EVALUARI_BYID_SUCCESS = "EVALUARI_BYID_SUCCESS";
export const EVALUARI_BYID_FAIL = "EVALUARI_BYID_FAIL";
export const EVALUARI_UPDATE_REQUEST = "EVALUARI_UPDATE_REQUEST";
export const EVALUARI_UPDATE_SUCCESS = "EVALUARI_UPDATE_SUCCESS";
export const EVALUARI_UPDATE_FAIL = "EVALUARI_UPDATE_FAIL";
export const EVALUARI_ADD_REQUEST = "EVALUARI_ADD_REQUEST";
export const EVALUARI_ADD_SUCCESS = "EVALUARI_ADD_SUCCESS";
export const EVALUARI_ADD_FAIL = "EVALUARI_ADD_FAIL";
export const EVALUARI_DELETE_REQUEST = "EVALUARI_DELETE_REQUEST";
export const EVALUARI_DELETE_SUCCESS = "EVALUARI_DELETE_SUCCESS";
export const EVALUARI_DELETE_FAIL = "EVALUARI_DELETE_FAIL";
export const EVALUARI_SEARCH_REQUEST = "EVALUARI_SEARCH_REQUEST";
export const EVALUARI_SEARCH_SUCCESS = "EVALUARI_SEARCH_SUCCESS";
export const EVALUARI_SEARCH_FAIL = "EVALUARI_SEARCH_FAIL";
export const EVALUARI_FETCH_BYCODE_REQUEST = "EVALUARI_FETCH_BYCODE_REQUEST";
export const EVALUARI_FETCH_BYCODE_SUCCESS = "EVALUARI_FETCH_BYCODE_SUCCESS";
export const EVALUARI_FETCH_BYCODE_FAIL = "EVALUARI_FETCH_BYCODE_FAIL";
export const EVALUARI_ADD_RASPUNS_REQUEST = "EVALUARI_ADD_RASPUNS_REQUEST";
export const EVALUARI_ADD_RASPUNS_SUCCESS = "EVALUARI_ADD_RASPUNS_SUCCESS";
export const EVALUARI_ADD_RASPUNS_FAIL = "EVALUARI_ADD_RASPUNS_FAIL";
// #endregion evaluari types
// #region evaluari types
export const TEMPLATEURI_FETCH_REQUEST = "TEMPLATEURI_FETCH_REQUEST";
export const TEMPLATEURI_FETCH_SUCCESS = "TEMPLATEURI_FETCH_SUCCESS";
export const TEMPLATEURI_FETCH_FAILURE = "TEMPLATEURI_FETCH_FAILURE";
export const TEMPLATEURI_BYID_REQUEST = "TEMPLATEURI_BYID_REQUEST";
export const TEMPLATEURI_BYID_SUCCESS = "TEMPLATEURI_BYID_SUCCESS";
export const TEMPLATEURI_BYID_FAIL = "TEMPLATEURI_BYID_FAIL";
export const TEMPLATEURI_UPDATE_REQUEST = "TEMPLATEURI_UPDATE_REQUEST";
export const TEMPLATEURI_UPDATE_SUCCESS = "TEMPLATEURI_UPDATE_SUCCESS";
export const TEMPLATEURI_UPDATE_FAIL = "TEMPLATEURI_UPDATE_FAIL";
export const TEMPLATEURI_ADD_REQUEST = "TEMPLATEURI_ADD_REQUEST";
export const TEMPLATEURI_ADD_SUCCESS = "TEMPLATEURI_ADD_SUCCESS";
export const TEMPLATEURI_ADD_FAIL = "TEMPLATEURI_ADD_FAIL";
export const TEMPLATEURI_DELETE_REQUEST = "TEMPLATEURI_DELETE_REQUEST";
export const TEMPLATEURI_DELETE_SUCCESS = "TEMPLATEURI_DELETE_SUCCESS";
export const TEMPLATEURI_DELETE_FAIL = "TEMPLATEURI_DELETE_FAIL";
export const TEMPLATEURI_SEARCH_REQUEST = "TEMPLATEURI_SEARCH_REQUEST";
export const TEMPLATEURI_SEARCH_SUCCESS = "TEMPLATEURI_SEARCH_SUCCESS";
export const TEMPLATEURI_SEARCH_FAIL = "TEMPLATEURI_SEARCH_FAIL";
export const TEMPLATEURI_DROPDOWN_FETCH_REQUEST =
  "TEMPLATEURI_DROPDOWN_FETCH_REQUEST";
export const TEMPLATEURI_DROPDOWN_FETCH_SUCCESS =
  "TEMPLATEURI_DROPDOWN_FETCH_SUCCESS";
export const TEMPLATEURI_DROPDOWN_FETCH_FAILURE =
  "TEMPLATEURI_DROPDOWN_FETCH_FAILURE";
// #endregion evaluari types
// #region candidati types
export const CANDIDATI_FETCH_REQUEST = "CANDIDATI_FETCH_REQUEST";
export const CANDIDATI_FETCH_SUCCESS = "CANDIDATI_FETCH_SUCCESS";
export const CANDIDATI_FETCH_FAILURE = "CANDIDATI_FETCH_FAILURE";
export const CANDIDATI_BYID_REQUEST = "CANDIDATI_BYID_REQUEST";
export const CANDIDATI_BYID_SUCCESS = "CANDIDATI_BYID_SUCCESS";
export const CANDIDATI_BYID_FAIL = "CANDIDATI_BYID_FAIL";
export const CANDIDATI_ADD_REQUEST = "CANDIDATI_ADD_REQUEST";
export const CANDIDATI_ADD_SUCCESS = "CANDIDATI_ADD_SUCCESS";
export const CANDIDATI_ADD_FAIL = "CANDIDATI_ADD_FAIL";
export const CANDIDATI_UPDATE_REQUEST = "CANDIDATI_UPDATE_REQUEST";
export const CANDIDATI_UPDATE_SUCCESS = "CANDIDATI_UPDATE_SUCCESS";
export const CANDIDATI_UPDATE_FAIL = "CANDIDATI_UPDATE_FAIL";
export const CANDIDATI_DELETE_REQUEST = "CANDIDATI_DELETE_REQUEST";
export const CANDIDATI_DELETE_SUCCESS = "CANDIDATI_DELETE_SUCCESS";
export const CANDIDATI_DELETE_FAIL = "CANDIDATI_DELETE_FAIL";
export const CANDIDATI_SEARCH_REQUEST = "CANDIDATI_SEARCH_REQUEST";
export const CANDIDATI_SEARCH_SUCCESS = "CANDIDATI_SEARCH_SUCCESS";
export const CANDIDATI_SEARCH_FAIL = "CANDIDATI_SEARCH_FAIL";
// #endregion conducatori types
// #region cursuri types
export const CURSURI_FETCH_REQUEST = "CURSURI_FETCH_REQUEST";
export const CURSURI_FETCH_SUCCESS = "CURSURI_FETCH_SUCCESS";
export const CURSURI_FETCH_FAILURE = "CURSURI_FETCH_FAILURE";
export const CURSURI_BYID_REQUEST = "CURSURI_BYID_REQUEST";
export const CURSURI_BYID_SUCCESS = "CURSURI_BYID_SUCCESS";
export const CURSURI_BYID_FAIL = "CURSURI_BYID_FAIL";
export const CURSURI_ADD_REQUEST = "CURSURI_ADD_REQUEST";
export const CURSURI_ADD_SUCCESS = "CURSURI_ADD_SUCCESS";
export const CURSURI_ADD_FAIL = "CURSURI_ADD_FAIL";
export const CURSURI_UPDATE_REQUEST = "CURSURI_UPDATE_REQUEST";
export const CURSURI_UPDATE_SUCCESS = "CURSURI_UPDATE_SUCCESS";
export const CURSURI_UPDATE_FAIL = "CURSURI_UPDATE_FAIL";
export const CURSURI_DELETE_REQUEST = "CURSURI_DELETE_REQUEST";
export const CURSURI_DELETE_SUCCESS = "CURSURI_DELETE_SUCCESS";
export const CURSURI_DELETE_FAIL = "CURSURI_DELETE_FAIL";
export const CURSURI_SEARCH_REQUEST = "CURSURI_SEARCH_REQUEST";
export const CURSURI_SEARCH_SUCCESS = "CURSURI_SEARCH_SUCCESS";
export const CURSURI_SEARCH_FAIL = "CURSURI_SEARCH_FAIL";
// #endregion cursuri types
// #region publicatii types
export const PUBLICATII_FETCH_REQUEST = "PUBLICATII_FETCH_REQUEST";
export const PUBLICATII_FETCH_SUCCESS = "PUBLICATII_FETCH_SUCCESS";
export const PUBLICATII_FETCH_FAILURE = "PUBLICATII_FETCH_FAILURE";
export const PUBLICATII_BYID_REQUEST = "PUBLICATII_BYID_REQUEST";
export const PUBLICATII_BYID_SUCCESS = "PUBLICATII_BYID_SUCCESS";
export const PUBLICATII_BYID_FAIL = "PUBLICATII_BYID_FAIL";
export const PUBLICATII_ADD_REQUEST = "PUBLICATII_ADD_REQUEST";
export const PUBLICATII_ADD_SUCCESS = "PUBLICATII_ADD_SUCCESS";
export const PUBLICATII_ADD_FAIL = "PUBLICATII_ADD_FAIL";
export const PUBLICATII_UPDATE_REQUEST = "PUBLICATII_UPDATE_REQUEST";
export const PUBLICATII_UPDATE_SUCCESS = "PUBLICATII_UPDATE_SUCCESS";
export const PUBLICATII_UPDATE_FAIL = "PUBLICATII_UPDATE_FAIL";
export const PUBLICATII_DELETE_REQUEST = "PUBLICATII_DELETE_REQUEST";
export const PUBLICATII_DELETE_SUCCESS = "PUBLICATII_DELETE_SUCCESS";
export const PUBLICATII_DELETE_FAIL = "PUBLICATII_DELETE_FAIL";
export const PUBLICATII_SEARCH_REQUEST = "PUBLICATII_SEARCH_REQUEST";
export const PUBLICATII_SEARCH_SUCCESS = "PUBLICATII_SEARCH_SUCCESS";
export const PUBLICATII_SEARCH_FAIL = "PUBLICATII_SEARCH_FAIL";
// #endregion publicatii types
// #region nomenclatoare types
export const NOMENCLATOARE_FETCH_REQUEST = "NOMENCLATOARE_FETCH_REQUEST";
export const NOMENCLATOARE_FETCH_SUCCESS = "NOMENCLATOARE_FETCH_SUCCESS";
export const NOMENCLATOARE_FETCH_FAILURE = "NOMENCLATOARE_FETCH_FAILURE";
export const NOMENCLATOARE_DELETE_REQUEST = "NOMENCLATOARE_DELETE_REQUEST";
export const NOMENCLATOARE_DELETE_SUCCESS = "NOMENCLATOARE_DELETE_SUCCESS";
export const NOMENCLATOARE_DELETE_FAILURE = "NOMENCLATOARE_DELETE_FAILURE";
export const NOMENCLATOARE_ADD_REQUEST = "NOMENCLATOARE_ADD_REQUEST";
export const NOMENCLATOARE_ADD_SUCCESS = "NOMENCLATOARE_ADD_SUCCESS";
export const NOMENCLATOARE_ADD_FAILURE = "NOMENCLATOARE_ADD_FAILURE";
export const NOMENCLATOARE_EDIT_REQUEST = "NOMENCLATOARE_EDIT_REQUEST";
export const NOMENCLATOARE_EDIT_SUCCESS = "NOMENCLATOARE_EDIT_SUCCESS";
export const NOMENCLATOARE_EDIT_FAILURE = "NOMENCLATOARE_EDIT_FAILURE";
// #endregion nomenclatoare types
// #region tipuri de documente types
export const TIPURIDEDOCUMENTE_FETCH_REQUEST =
  "TIPURIDEDOCUMENTE_FETCH_REQUEST";
export const TIPURIDEDOCUMENTE_FETCH_SUCCESS =
  "TIPURIDEDOCUMENTE_FETCH_SUCCESS";
export const TIPURIDEDOCUMENTE_FETCH_FAILURE =
  "TIPURIDEDOCUMENTE_FETCH_FAILURE";
export const TIPURIDEDOCUMENTE_DELETE_REQUEST =
  "TIPURIDEDOCUMENTE_DELETE_REQUEST";
export const TIPURIDEDOCUMENTE_DELETE_SUCCESS =
  "TIPURIDEDOCUMENTE_DELETE_SUCCESS";
export const TIPURIDEDOCUMENTE_DELETE_FAILURE =
  "TIPURIDEDOCUMENTE_DELETE_FAILURE";
export const TIPURIDEDOCUMENTE_ADD_REQUEST = "TIPURIDEDOCUMENTE_ADD_REQUEST";
export const TIPURIDEDOCUMENTE_ADD_SUCCESS = "TIPURIDEDOCUMENTE_ADD_SUCCESS";
export const TIPURIDEDOCUMENTE_ADD_FAILURE = "TIPURIDEDOCUMENTE_ADD_FAILURE";
export const TIPURIDEDOCUMENTE_EDIT_REQUEST = "TIPURIDEDOCUMENTE_EDIT_REQUEST";
export const TIPURIDEDOCUMENTE_EDIT_SUCCESS = "TIPURIDEDOCUMENTE_EDIT_SUCCESS";
export const TIPURIDEDOCUMENTE_EDIT_FAILURE = "TIPURIDEDOCUMENTE_EDIT_FAILURE";
// #endregion tipuri de documente types
// #region snackbar
export const SNACK_SHOW = "SNACK_SHOW";
export const SNACK_HIDE = "SNACK_HIDE";
// #endregion snackbar
// #region utilizatori
export const UTILIZATORI_FETCH_REQUEST = "UTILIZATORI_FETCH_REQUEST";
export const UTILIZATORI_FETCH_SUCCESS = "UTILIZATORI_FETCH_SUCCESS";
export const UTILIZATORI_FETCH_FAILURE = "UTILIZATORI_FETCH_FAILURE";

export const UTILIZATORI_BYID_REQUEST = "UTILIZATORI_BYID_REQUEST";
export const UTILIZATORI_BYID_SUCCESS = "UTILIZATORI_BYID_SUCCESS";
export const UTILIZATORI_BYID_FAIL = "UTILIZATORI_BYID_FAIL";

export const UTILIZATORI_DELETE_REQUEST = "UTILIZATORI_DELETE_REQUEST";
export const UTILIZATORI_DELETE_SUCCESS = "UTILIZATORI_DELETE_SUCCESS";
export const UTILIZATORI_DELETE_FAIL = "UTILIZATORI_DELETE_FAIL";

// #endregion utilizatori
// #region auth
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGIN_REFRESH_REQUEST = "LOGIN_REFRESH_REQUEST";
export const LOGIN_REFRESH_SUCCESS = "LOGIN_REFRESH_SUCCESS";
export const LOGIN_REFRESH_FAIL = "LOGIN_REFRESH_FAIL";
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";
export const SET_RETURN_URL = "SET_RETURN_URL";
// #endregion auth
export const APPBAR_TITLE_SET = "APPBAR_TITLE_SET";
export const APPBAR_TITLE_REMOVE = "APPBAR_TITLE_REMOVE";

// #region videoconferinte
export const CONFERINTE_FETCH_REQUEST = "CONFERINTE_FETCH_REQUEST";
export const CONFERINTE_FETCH_SUCCESS = "CONFERINTE_FETCH_SUCCESS";
export const CONFERINTE_FETCH_FAILURE = "CONFERINTE_FETCH_FAILURE";
export const CONFERINTE_BYID_REQUEST = "CONFERINTE_BYID_REQUEST";
export const CONFERINTE_BYID_SUCCESS = "CONFERINTE_BYID_SUCCESS";
export const CONFERINTE_BYID_FAIL = "CONFERINTE_BYID_FAIL";
export const CONFERINTE_ADD_REQUEST = "CONFERINTE_ADD_REQUEST";
export const CONFERINTE_ADD_SUCCESS = "CONFERINTE_ADD_SUCCESS";
export const CONFERINTE_ADD_FAIL = "CONFERINTE_ADD_FAIL";
export const CONFERINTE_UPDATE_REQUEST = "CONFERINTE_UPDATE_REQUEST";
export const CONFERINTE_UPDATE_SUCCESS = "CONFERINTE_UPDATE_SUCCESS";
export const CONFERINTE_UPDATE_FAIL = "CONFERINTE_UPDATE_FAIL";
export const CONFERINTE_DELETE_REQUEST = "CONFERINTE_DELETE_REQUEST";
export const CONFERINTE_DELETE_SUCCESS = "CONFERINTE_DELETE_SUCCESS";
export const CONFERINTE_DELETE_FAIL = "CONFERINTE_DELETE_FAIL";
export const CONFERINTE_SEARCH_REQUEST = "CONFERINTE_SEARCH_REQUEST";
export const CONFERINTE_SEARCH_SUCCESS = "CONFERINTE_SEARCH_SUCCESS";
export const CONFERINTE_SEARCH_FAIL = "CONFERINTE_SEARCH_FAIL";
// #endregion videoconferinte

// #region auth
export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_FORGOT_REQUEST = "PASSWORD_FORGOT_REQUEST";
export const PASSWORD_FORGOT_SUCCESS = "PASSWORD_FORGOT_SUCCESS";
export const PASSWORD_FORGOT_FAIL = "PASSWORD_FORGOT_FAIL";
// #endregion auth

// #region register
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const SET_REGISTER_ACCOUNT_TYPE = "SET_REGISTER_ACCOUNT_TYPE";
// #endregion register
export const VALIDATE_TOKEN_REQUEST = "VALIDATE_TOKEN_REQUEST";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAIL = "VALIDATE_TOKEN_FAIL";
