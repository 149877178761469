import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Select,
  DialogActions,
  Button,
  MenuItem,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  withMobileDialog,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Paper,
  Table,
  TableHead,
  Menu,
  TablePagination,
  DialogContentText,
  FormControl,
  InputLabel
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import { Formik } from "formik";
import * as yup from "yup";
import {
  fetchTipuriDeDocumente,
  addTipDeDocument,
  editTipDeDocument,
  deleteTipDeDocument
} from "../../actions";
import { getTipuriDeDocumente } from "../../reducers/tipuriDeDocumenteReducer";

const collectionNames = {
  conducatori: "DocumenteConducator",
  candidati: "DocumenteCandidat",
  doctoranzi: "DocumenteDoctorand",
  inscrisi: "DocumenteInscris"
};

const tipuriDeDocumenteMenu = [
  { name: "doctoranzi", label: "Doctoranzi" },
  { name: "conducatori", label: "Conducatori doctorat" },
  { name: "candidati", label: "Candidati la abilitare" },
  { name: "inscrisi", label: "Inscrisi" }
];

class TipuriDeDocumente extends Component {
  static propTypes = {
    fetchTipuriDeDocumente: PropTypes.func.isRequired,
    deleteTipDeDocument: PropTypes.func.isRequired,
    addTipDeDocument: PropTypes.func.isRequired,
    editTipDeDocument: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired
  };

  state = {
    anchorEl: null,
    selectedEntity: "doctoranzi",
    current: [],
    openDeleteModal: false,
    openModal: false,
    nume: "",
    template: "",
    action: "add",
    obligatoriu: false,
    afecteazaSiElementeActive: false
  };

  static getDerivedStateFromProps = (props, state) => ({
    current: props.all[state.selectedEntity] || []
  });

  componentDidMount() {
    this.props.fetchTipuriDeDocumente();
  }

  handleEditClick = () => {
    this.handleCloseMenu();
    this.setState({
      action: "edit",
      openModal: true
    });
  };

  handleDeleteClick = () => {
    this.handleCloseMenu();
    this.setState({
      openDeleteModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      openDeleteModal: false
    });
  };

  handleDeleteAccept = () => {
    const { id, selectedEntity } = this.state;
    this.handleCloseModal();
    this.props.deleteTipDeDocument({
      id,
      type: collectionNames[selectedEntity],
      entityName: selectedEntity
    });
  };

  handleOpenMenu = (event, item) => {
    this.setState({
      anchorEl: event.currentTarget,
      id: item._id,
      nume: item.nume,
      obligatoriu: item.obligatoriu,
      template: item.template
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(() => ({
      [name]: value,
      current: this.props.all[value] || []
    }));
  };

  openAddModal = () => {
    this.setState({
      openModal: true,
      action: "add",
      nume: "",
      template: "",
      obligatoriu: false,
      afecteazaSiElementeActive: false
    });
  };

  handleClose = (action, func) => {
    if (action === "reset") {
      this.setState(() => ({
        openModal: false
      }));
    } else {
      this.setState({
        openModal: false,
        nume: "",
        obligatoriu: false,
        afecteazaSiElementeActive: false
      });
    }
  };

  handleSubmit = async values => {
    this.setState({
      openModal: false,
      nume: "",
      obligatoriu: false,
      afecteazaSiElementeActive: false
    });
    const { id, selectedEntity, action } = this.state;
    if (action === "add") {
      this.props.addTipDeDocument({ ...values, entityName: selectedEntity });
    } else {
      this.props.editTipDeDocument({
        id,
        ...values,
        entityName: selectedEntity
      });
    }
  };

  handleChangePage = () => {};

  handleChangeRowsPerPage = () => {};

  render() {
    const {
      anchorEl,
      selectedEntity,
      current,
      openModal,
      nume,
      template,
      obligatoriu,
      afecteazaSiElementeActive,
      action
    } = this.state;
    const { fullScreen } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Select
            value={selectedEntity}
            name="selectedEntity"
            onChange={this.handleChange}
            style={{ marginRight: "16px" }}
          >
            {tipuriDeDocumenteMenu.map(tipDocument => (
              <MenuItem value={tipDocument.name} key={tipDocument.name}>
                {tipDocument.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-padded-icon"
            onClick={this.openAddModal}
          >
            <AddIcon />
            Adauga
          </Button>
        </div>
        <Paper style={{ height: "100%", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "50px" }}>Optiuni</TableCell>
                <TableCell>Denumire</TableCell>
                <TableCell>Obligatoriu</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {current.map(row => (
                <TableRow key={row._id}>
                  <TableCell style={{ width: "50px" }}>
                    <IconButton
                      aria-owns={anchorEl ? "simple-menu" : null}
                      aria-haspopup="true"
                      onClick={event => this.handleOpenMenu(event, row)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.nume}</TableCell>
                  <TableCell>{row.obligatoriu ? "Da" : "Nu"}</TableCell>
                  <Menu
                    className="lvd-options-container"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseMenu}
                  >
                    <MenuItem onClick={this.handleEditClick}>Editeaza</MenuItem>
                    <MenuItem onClick={this.handleDeleteClick}>Sterge</MenuItem>
                  </Menu>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={current.length}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={25}
            page={0}
            backIconButtonProps={{
              "aria-label": "Anterioara"
            }}
            nextIconButtonProps={{
              "aria-label": "Urmatoare"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Formik
          onSubmit={this.handleSubmit}
          enableReinitialize
          validationSchema={yup.object().shape({
            nume: yup.string().required("Camp obligatoriu")
          })}
          initialValues={{
            nume: nume || "",
            template: template || "",
            obligatoriu,
            afecteazaSiElementeActive,
            type: collectionNames[selectedEntity]
          }}
          render={({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
            handleReset,
            handleSubmit
          }) => (
            <Dialog
              fullScreen={fullScreen}
              open={openModal}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                {action === "add"
                  ? `Adauga tip document pentru ${selectedEntity}`
                  : `Editeaza ${nume} pentru ${selectedEntity}`}
                <Button
                  className="lvd-button-close-modal"
                  onClick={this.handleClose}
                >
                  Inchide
                </Button>
              </DialogTitle>
              <DialogContent style={{ minWidth: "550px" }}>
                <TextField
                  margin="dense"
                  error={!!errors.nume && !!touched.nume}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Denumire"
                  fullWidth
                  value={values.nume}
                  name="nume"
                />
                {selectedEntity === "inscrisi" && (
                  <TextField
                    margin="dense"
                    error={!!errors.template && !!touched.template}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Template"
                    fullWidth
                    value={values.template}
                    name="template"
                  />
                )}
                <FormControl fullWidth margin="dense">
                  <InputLabel htmlFor="obligatoriu">Obligatoriu</InputLabel>
                  <Select
                    error={!!errors.obligatoriu && !!touched.obligatoriu}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.obligatoriu}
                    name="obligatoriu"
                  >
                    <MenuItem value>Da</MenuItem>
                    <MenuItem value={false}>Nu</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <InputLabel htmlFor="afecteazaSiElementeActive">
                    Afecteaza si dosarele active
                  </InputLabel>
                  <Select
                    error={
                      !!errors.afecteazaSiElementeActive &&
                      !!touched.afecteazaSiElementeActive
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.afecteazaSiElementeActive}
                    name="afecteazaSiElementeActive"
                  >
                    <MenuItem value>Da</MenuItem>
                    <MenuItem value={false}>Nu</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions style={{ padding: "14px" }}>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {action === "add" ? "Adauga" : "Salveaza"}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        />
        <Dialog
          open={this.state.openDeleteModal}
          onClose={this.handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Sterge element</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sunteti sigur ca vreti sa stergeti &quot;
              {nume}
              &quot;?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal} color="secondary">
              Renunta
            </Button>
            <Button onClick={this.handleDeleteAccept} color="primary">
              Da
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  all: getTipuriDeDocumente(state)
});

export default withMobileDialog()(
  connect(
    mapStateToProps,
    {
      fetchTipuriDeDocumente,
      addTipDeDocument,
      deleteTipDeDocument,
      editTipDeDocument
    }
  )(TipuriDeDocumente)
);
