import request from "superagent";
import FileSaver from "file-saver";
import { serverUrl } from ".";

export const saveInfo = ({ jwt, email, refreshToken }) => {
  localStorage.setItem("utcbJWT", jwt);
  localStorage.setItem("utcbEmail", email);
  localStorage.setItem("utcbRefreshToken", refreshToken);
};

export const getInfo = () => ({
  jwt: localStorage.getItem("utcbJWT"),
  email: localStorage.getItem("utcbEmail"),
  refreshToken: localStorage.getItem("utcbRefreshToken")
});

export const deleteInfo = () => {
  localStorage.removeItem("utcbJWT");
  localStorage.removeItem("utcbEmail");
  localStorage.removeItem("utcbRefreshToken");
};

export const exportDosar = async (jwt, dosarId, showSnackbar) => {
  try {
    const res = await request(`${serverUrl}/export/doctoranzi/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`)
      .responseType("blob");
    const { headers } = res;
    const contentDisposition = headers["content-disposition"];
    const blob = res.body;
    FileSaver.saveAs(
      blob,
      contentDisposition.substr(contentDisposition.indexOf("=") + 1)
    );
  } catch (e) {
    showSnackbar(e, "error");
  }
};

export const countFilters = query => {
  if (typeof query === "undefined" || query === null) {
    return 0;
  }
  let count = 0;
  let complex = false;
  // console.log("Verifying query", query);
  if (Array.isArray(query)) {
    // console.log("Found array");
    complex = true;
    query.forEach(q => {
      count += countFilters(q);
    });
  } else if (typeof query === "symbol") {
    return 1;
  } else if (typeof query === "object") {
    // console.log("Found object")
    const keys = Object.keys(query);
    // console.log("With keys", keys);
    complex = true;
    keys.forEach(key => {
      // console.log("Checking key", key);
      count += countFilters(query[key]);
    });
  }
  // console.log("Returning count", count)
  return complex ? count : 1;
};

export const getReadableStatus = status => {
  switch (status) {
    case "incomplet":
      return "Incomplet";
    case "trimisCatreValidare":
      return "Trimis catre validare";
    case "validat":
      return "Validat";
    case "respins":
      return "Respins";
    case "plataInitiata":
      return "Plata initiata";
    case "plataValidata":
      return "Plata validata";
    case "finalizat":
      return "Finalizat";
    case "transformat":
      return "Transformat";
    case "":
      return "Incomplet";
    default:
      return "-";
  }
};
