import React, { Component } from "react";
// import PropTypes from "prop-types";

class Main extends Component {
  render() {
    return <div></div>;
  }
}

export default Main;
