import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { fetchTemplateuriForDropdown, addEvaluare } from '../../actions';
import { getTemplateuriForDropdown } from '../../reducers/templateuriReducer';
import EvaluareForm from './EvaluareForm';

class EvaluareAdd extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };
  componentDidMount = () => {
    this.props.fetchTemplateuriForDropdown();
  };

  handleBackClick = () => {
    this.props.history.push('/evaluari');
  };
  handleSubmit = (values) => {
    this.props.addEvaluare(values);
  };
  render() {
    const { templates } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <EvaluareForm templates={templates} handleSubmit={this.handleSubmit} />
        </Paper>
      </div>
    );
  }
}

EvaluareAdd.propTypes = {};

const mapStateToProps = state => ({
  templates: getTemplateuriForDropdown(state),
});

const mapDispatchToProps = {
  fetchTemplateuriForDropdown,
  addEvaluare,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EvaluareAdd);
