import request from "superagent";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";
import { saveInfo, getInfo, deleteInfo } from "../utils/helpers";
import { getToken, getUser, getRefreshToken } from "../reducers/authReducer";
import history from "../history";
import { getCandidatType } from "../reducers/inscrisiReducer";

const loginRequest = () => ({
  type: TYPES.LOGIN_REQUEST,
});
const loginSuccess = (payload) => ({
  type: TYPES.LOGIN_SUCCESS,
  payload,
});
const loginFail = (error) => ({
  type: TYPES.LOGIN_FAIL,
  error,
});

export const login = (user) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const res = await request.post(`${serverUrl}/auth/login`).send(user);
    saveInfo({
      jwt: res.body.token.accessToken,
      refreshToken: res.body.token.refreshToken,
      email: res.body.user.email,
    });
    dispatch(loginSuccess(res.body));
    history.push("/");
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(loginFail(e));
  }
};

const registerRequest = () => ({
  type: TYPES.REGISTER_REQUEST,
});
const registerSuccess = (payload) => ({
  type: TYPES.REGISTER_SUCCESS,
  payload,
});
const registerFail = (error) => ({
  type: TYPES.REGISTER_FAIL,
  error,
});

export const register = (user) => async (dispatch, getState) => {
  dispatch(registerRequest());
  const registerAccountType = getCandidatType(getState());
  try {
    const res = await request
      .post(`${serverUrl}/auth/register`)
      .send({ ...user, candidatType: registerAccountType.candidatType });
    saveInfo({
      jwt: res.body.token.accessToken,
      refreshToken: res.body.token.refreshToken,
      email: res.body.user.email,
    });
    dispatch(registerSuccess(res.body));
    return true;
  } catch (e) {
    if (e.response && e.response.body && e.response.body.code === 409) {
      dispatch(showSnackbar("This email is already registered", "error"));
    } else {
      dispatch(showSnackbar(e, "error"));
    }
    dispatch(registerFail(e));
    return false;
  }
};

const refreshLoginRequest = () => ({
  type: TYPES.LOGIN_REFRESH_REQUEST,
});
const refreshLoginSuccess = (payload) => ({
  type: TYPES.LOGIN_REFRESH_SUCCESS,
  payload,
});
const refreshLoginFail = (error) => ({
  type: TYPES.LOGIN_REFRESH_FAIL,
  error,
});

export const refreshLogin = () => async (dispatch) => {
  const { jwt, refreshToken, email } = getInfo();
  dispatch(refreshLoginRequest());
  try {
    const res = await request
      .post(`${serverUrl}/auth/refresh-token`)
      .send({ email, refreshToken })
      .set("Authorization", `Bearer ${jwt}`);
    saveInfo({
      jwt: res.body.accessToken,
      refreshToken: res.body.refreshToken,
      email,
    });
    dispatch(refreshLoginSuccess(res.body));
    if (res.body.user.role === "inscris") {
      history.push("/inscriere");
    }
  } catch (e) {
    dispatch(refreshLoginFail(e));
    dispatch(showSnackbar(e, "error"));
    history.push("/");
  }
};

export const fetchUserProfileRequest = () => ({
  type: TYPES.USER_PROFILE_REQUEST,
});
export const fetchUserProfileSuccess = (payload) => ({
  type: TYPES.USER_PROFILE_SUCCESS,
  payload,
});
export const fetchUserProfileFail = (e) => ({
  type: TYPES.USER_PROFILE_FAIL,
  e,
});

export const fetchUserProfile = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(fetchUserProfileRequest());
  try {
    const res = await request
      .get(`${serverUrl}/users/profile`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchUserProfileSuccess(res.body));
  } catch (e) {
    dispatch(fetchUserProfileFail(e));
    dispatch(showSnackbar(e, "error"));
  }
};

export const resendVerificationTokenRequest = () => ({
  type: TYPES.VERIFICATION_TOKEN_RESEND_REQUEST,
});
export const resendVerificationTokenSuccess = () => ({
  type: TYPES.VERIFICATION_TOKEN_RESEND_SUCCESS,
});
export const resendVerificationTokenFail = () => ({
  type: TYPES.VERIFICATION_TOKEN_RESEND_FAIL,
});

export const resendVerificationToken = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(resendVerificationTokenRequest());
  try {
    const res = await request
      .get(`${serverUrl}/auth/resendVerificationToken`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(resendVerificationTokenSuccess(res.body));
  } catch (e) {
    dispatch(resendVerificationTokenFail(e));
    dispatch(showSnackbar(e, "error"));
  }
};

export const setReturnUrl = (returnUrl) => ({
  type: TYPES.SET_RETURN_URL,
  returnUrl,
});

const logoutRequest = () => ({
  type: TYPES.LOGOUT_REQUEST,
});
const logoutSuccess = (payload) => ({
  type: TYPES.LOGOUT_SUCCESS,
  payload,
});
const logoutFail = (error) => ({
  type: TYPES.LOGOUT_FAIL,
  error,
});

export const logout = () => async (dispatch, getState) => {
  dispatch(logoutRequest());
  const { email } = getUser(getState());
  const refreshToken = getRefreshToken(getState());
  try {
    await request
      .post(`${serverUrl}/auth/logout`)
      .send({ email, refreshToken });
    deleteInfo();
    dispatch(logoutSuccess());
    history.push("/");
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(logoutFail(e));
  }
};

export const setAppBarTitle = (title) => ({
  type: TYPES.APPBAR_TITLE_SET,
  title,
});

const forgotPasswordRequest = () => ({
  type: TYPES.PASSWORD_FORGOT_REQUEST,
});
const forgotPasswordSuccess = (payload) => ({
  type: TYPES.PASSWORD_FORGOT_SUCCESS,
  payload,
});
const forgotPasswordFail = (error) => ({
  type: TYPES.PASSWORD_FORGOT_FAIL,
  error,
});

export const forgotPassword = (formInfo) => async (dispatch) => {
  dispatch(forgotPasswordRequest());
  const { email } = formInfo;
  try {
    await request.post(`${serverUrl}/auth/forgot-password`).send({ email });
    dispatch(forgotPasswordSuccess());
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(forgotPasswordFail(e));
    return false;
  }
};

const resetPasswordRequest = () => ({
  type: TYPES.PASSWORD_RESET_REQUEST,
});
const resetPasswordSuccess = (payload) => ({
  type: TYPES.PASSWORD_RESET_SUCCESS,
  payload,
});
const resetPasswordFail = (error) => ({
  type: TYPES.PASSWORD_RESET_FAIL,
  error,
});

export const resetPassword = (formInfo) => async (dispatch) => {
  dispatch(resetPasswordRequest());
  try {
    await request.post(`${serverUrl}/auth/reset-password`).send(formInfo);
    dispatch(resetPasswordSuccess());
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(resetPasswordFail(e));
    return false;
  }
};

export const verifyToken = (verificationToken) => async (dispatch) => {
  dispatch({
    type: TYPES.VALIDATE_TOKEN_REQUEST,
  });
  try {
    const res = await request.post(`${serverUrl}/auth/verify`).send({
      verificationToken,
    });
    dispatch({
      type: TYPES.VALIDATE_TOKEN_SUCCESS,
      payload: {
        user: res.body.user,
        token: {
          accessToken: res.body.accessToken,
          expiresIn: res.body.expiresIn,
          refreshToken: res.body.refreshToken,
          tokenType: res.body.tokenType,
        },
      },
    });
    saveInfo({
      jwt: res.body.accessToken,
      refreshToken: res.body.refreshToken,
      email: res.body.user.email,
    });
    setTimeout(() => {
      history.push("/inscriere");
    }, 1000);
    return true;
  } catch (e) {
    dispatch({
      type: TYPES.VALIDATE_TOKEN_FAIL,
    });
    dispatch(showSnackbar(e));
    return false;
  }
};
