import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import {
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Input,
  FormHelperText
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { resetPassword } from "../../actions";
import OldLoginLayout from "../Layout/OldLoginLayout";

class ResetPassword extends Component {
  state = {
    mailSent: false
  };

  handleSubmit = async values => {
    const res = await this.props.resetPassword(values);
    if (res) this.setState({ mailSent: true });
  };

  render() {
    if (this.state.mailSent) {
      return (
        <OldLoginLayout>
          Parola a fost schimbata
          <div className="lvd-spacer20" />
          <Button component={Link} to="/login" href="/login">
            Inapoi la login
          </Button>
        </OldLoginLayout>
      );
    }
    return (
      <OldLoginLayout>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
            resetPasswordToken: this.props.match.params.resetPasswordToken
          }}
          validationSchema={yup.object().shape({
            password: yup
              .string()
              .min(6, "Parola trebuie sa aibe minim 6 caractere")
              .required("Camp obligatoriu")
              .sameAs(yup.ref("confirmPassword"), "Parolele nu se potrivesc"),
            confirmPassword: yup
              .string()
              .required("Camp obligatoriu")
              .sameAs(yup.ref("password"), "Parolele nu se potrivesc")
          })}
          onSubmit={this.handleSubmit}
          render={({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <FormControl fullWidth margin="dense">
                <InputLabel>Parola</InputLabel>
                <Input
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={!!touched.password && !!errors.password}
                  type="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <Visibility />
                    </InputAdornment>
                  }
                />
                {!!touched.password && !!errors.password && (
                  <FormHelperText
                    error={!!touched.password && !!errors.password}
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel>Confirmare parola</InputLabel>
                <Input
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  type="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <Visibility />
                    </InputAdornment>
                  }
                />
                {!!touched.confirmPassword && !!errors.confirmPassword && (
                  <FormHelperText
                    error={
                      !!touched.confirmPassword && !!errors.confirmPassword
                    }
                  >
                    {errors.confirmPassword}
                  </FormHelperText>
                )}
              </FormControl>
              <div className="lvd-spacer20" />
              <Button variant="contained" color="primary" type="submit">
                Schimba parola
              </Button>
              <span className="lvd-text-spacer" />
              <Button component={Link} to="/login" href="/login">
                Inapoi la login
              </Button>
            </Form>
          )}
        />
      </OldLoginLayout>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  resetPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
