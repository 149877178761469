import React, { Component } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import LoginLayout from "./LoginLayout";
import RegisterForm from "../RegisterForm";
import LoginForm from "../LoginForm";

class Login extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <LoginLayout>
        <Grid
          container
          wrap="wrap"
          justify="center"
          style={{
            alignItems: "stretch"
          }}
        >
          <Grid item xs={12} sm={4}>
            <Paper className="lvd-login-paper">
              <Typography variant="h4">{t.registerMe}</Typography>
              <RegisterForm />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className="lvd-login-paper">
              <Typography variant="h4">{t.alreadyHaveAccount}</Typography>
              <LoginForm />
            </Paper>
          </Grid>
        </Grid>
      </LoginLayout>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang]
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
