import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { fetchTemplateById } from "../../actions";
import { getTemplateById } from "../../reducers/templateuriReducer";
import { generateIntrebare } from "../ViewerEvaluari/Evalueaza";

class ViewTemplate extends Component {
  static propTypes = {
    fetchTemplateById: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        templateId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };
  componentDidMount() {
    this.props.fetchTemplateById(this.props.match.params.templateId);
  }
  handleBackClick = () => {
    this.props.history.push("/templateuri");
  };
  render() {
    const { template } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        {template &&
          template.intrebari &&
          template.intrebari.map(generateIntrebare({ isViewing: true }))}
      </div>
    );
  }
}

ViewTemplate.propTypes = {};

const mapStateToProps = state => ({
  template: getTemplateById(state)
});

const mapDispatchToProps = {
  fetchTemplateById
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTemplate);
