import request from "superagent";
import _ from "lodash";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";
import { getToken, getIsAdmin } from "../reducers/authReducer";
import history from "../history";

const fetchEvaluariRequest = () => ({
  type: TYPES.EVALUARI_FETCH_REQUEST
});
const fetchEvaluariSuccess = payload => ({
  type: TYPES.EVALUARI_FETCH_SUCCESS,
  payload
});
const fetchEvaluariFailure = error => ({
  type: TYPES.EVALUARI_FETCH_FAILURE,
  error
});

export const fetchEvaluari = () => async (dispatch, getState) => {
  dispatch(fetchEvaluariRequest());
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/evaluari`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchEvaluariSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchEvaluariFailure(e));
  }
};

const requestEvaluareById = () => ({
  type: TYPES.EVALUARI_BYID_REQUEST
});
const successEvaluareById = payload => ({
  type: TYPES.EVALUARI_BYID_SUCCESS,
  payload
});
const failEvaluareById = error => ({
  type: TYPES.EVALUARI_BYID_FAIL,
  error
});
export const fetchEvaluareById = evaluareId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestEvaluareById());
  try {
    const res = await request
      .get(`${serverUrl}/evaluari/${evaluareId}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successEvaluareById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failEvaluareById(e));
  }
};
const requestUpdateEvaluare = () => ({
  type: TYPES.EVALUARI_UPDATE_REQUEST
});
const successUpdateEvaluare = payload => ({
  type: TYPES.EVALUARI_UPDATE_SUCCESS,
  payload
});
const failUpdateEvaluare = error => ({
  type: TYPES.EVALUARI_UPDATE_FAIL,
  error
});

export const updateEvaluare = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateEvaluare());
  try {
    const res = await request
      .put(`${serverUrl}/evaluari/${dosar._id}`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successUpdateEvaluare(res.body));
    dispatch(showSnackbar("Editare salvata cu succes", "success"));
    const isAdmin = getIsAdmin(getState());
    if (isAdmin) history.push("/evaluari");
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failUpdateEvaluare(e));
  }
};
const requestAddEvaluare = () => ({
  type: TYPES.EVALUARI_ADD_REQUEST
});
const successAddEvaluare = payload => ({
  type: TYPES.EVALUARI_ADD_SUCCESS,
  payload
});
const failAddEvaluare = error => ({
  type: TYPES.EVALUARI_ADD_FAIL,
  error
});

export const addEvaluare = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestAddEvaluare());
  try {
    const res = await request
      .post(`${serverUrl}/evaluari`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successAddEvaluare(res.body));
    dispatch(showSnackbar("Campanie de evaluare salvata cu succes", "success"));
    history.push("/evaluari");
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failAddEvaluare(e));
  }
};

const requestDeleteEvaluare = () => ({
  type: TYPES.EVALUARI_DELETE_REQUEST
});
const successDeleteEvaluare = payload => ({
  type: TYPES.EVALUARI_DELETE_SUCCESS,
  payload
});
const failDeleteEvaluare = error => ({
  type: TYPES.EVALUARI_DELETE_FAIL,
  error
});
export const deleteEvaluare = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeleteEvaluare());
  try {
    await request
      .delete(`${serverUrl}/evaluari/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successDeleteEvaluare(dosarId));
    dispatch(showSnackbar("Dosar sters cu succes", "success"));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failDeleteEvaluare(e));
  }
};
export const requestSearchEvaluari = () => ({
  type: TYPES.EVALUARI_SEARCH_REQUEST
});
export const successSearchEvaluari = payload => ({
  type: TYPES.EVALUARI_SEARCH_SUCCESS,
  payload
});
export const failSearchEvaluari = error => ({
  type: TYPES.EVALUARI_SEARCH_FAIL,
  error
});

export const searchEvaluari = (query, pageInfo) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  query = _.isEmpty(query) ? undefined : query;
  dispatch(requestSearchEvaluari());
  try {
    const res = await request
      .post(`${serverUrl}/evaluari/search`)
      .send({ query, pageInfo })
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successSearchEvaluari(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failSearchEvaluari(e));
  }
};

const requestFetchEvaluareByCode = payload => ({
  type: TYPES.EVALUARI_FETCH_BYCODE_REQUEST,
  payload
});
const successFetchEvaluareByCode = ({
  payload,
  idCampanie,
  codDeEvaluare
}) => ({
  type: TYPES.EVALUARI_FETCH_BYCODE_SUCCESS,
  payload,
  idCampanie,
  codDeEvaluare
});
const failFetchEvaluareByCode = payload => ({
  type: TYPES.EVALUARI_FETCH_BYCODE_FAIL,
  payload
});

export const fetchEvaluareByCode = evaluare => async (dispatch, getState) => {
  try {
    dispatch(requestFetchEvaluareByCode());
    const res = await request
      .post(`${serverUrl}/evaluari/bycode`)
      .send(evaluare);
    dispatch(
      successFetchEvaluareByCode({
        payload: res.body,
        idCampanie: evaluare.idCampanie,
        codDeEvaluare: evaluare.codDeEvaluare
      })
    );
    history.push(`/evalueaza/${evaluare.idCampanie}/${evaluare.codDeEvaluare}`);
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failFetchEvaluareByCode(e));
  }
};

const requestAddRaspunsEvaluare = () => ({
  type: TYPES.EVALUARI_ADD_RASPUNS_REQUEST
});
const successAddRaspunsEvaluare = payload => ({
  type: TYPES.EVALUARI_ADD_RASPUNS_SUCCESS,
  payload
});
const failAddRaspunsEvaluare = () => ({
  type: TYPES.EVALUARI_ADD_RASPUNS_FAIL
});

export const addRaspunsToEvaluare = evaluare => async (dispatch, getState) => {
  try {
    dispatch(requestAddRaspunsEvaluare());
    const res = await request.post(`${serverUrl}/raspunsuri`).send(evaluare);
    dispatch(successAddRaspunsEvaluare(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failAddRaspunsEvaluare(e));
  }
};
