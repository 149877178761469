import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import moment from "moment";
import {
  Menu,
  MenuItem,
  Dialog,
  withMobileDialog,
  DialogTitle,
  Button,
  DialogActions,
  TablePagination,
  TableCell,
  IconButton,
  TableRow,
  TableHead,
  TableBody,
  DialogContentText,
  DialogContent
} from "@material-ui/core";
import request from "superagent";
import FileSaver from "file-saver";
import {
  fetchCursuri,
  downloadDocumentFromCurs,
  deleteCurs,
  searchCursuri,
  fetchNomenclatoare,
  showSnackbar
} from "../../actions";
import {
  getCursuri,
  getCursuriQuery,
  getCursuriPageInfo
} from "../../reducers/elearningReducer";
import FilterModal from "../General/FilterModal";
import { getIsAdmin, getToken } from "../../reducers/authReducer";
import DeleteModal from "../General/DeleteModal";
import * as utils from "../../utils/helpers";
import { serverUrl } from "../../utils";

const baseColumns = [
  {
    name: "optiuni",
    label: "Optiuni",
    default: true
  },
  {
    name: "nume",
    label: "Denumire",
    default: true
  },
  {
    name: "categorie",
    label: "Categorie",
    default: true
  },
  {
    name: "disciplina",
    label: "Disciplina",
    default: true
  },
  {
    name: "tip",
    label: "Tip",
    default: true
  },
  {
    name: "autor",
    label: "Autor",
    default: true
  }
];

// const filterConfig = [
//   {
//     name: 'cnp',
//     label: 'CNP',
//     type: 'text',
//   },
// ];

class ELearning extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    searchCursuri: PropTypes.func.isRequired,
    fetchCursuri: PropTypes.func.isRequired,
    deleteCurs: PropTypes.func.isRequired,
    downloadDocumentFromCurs: PropTypes.func.isRequired,
    fetchNomenclatoare: PropTypes.func.isRequired,
    query: PropTypes.shape({})
  };
  static defaultProps = { query: undefined };
  state = {
    openViewModal: false,
    openModal: false,
    filter: {},
    anchorEl: null,
    id: undefined,
    selectedItem: {},
    filterNo: 0,
    openDeleteModal: false,
    deleteName: undefined
  };
  static getDerivedStateFromProps = (props, state) => ({
    filterConfig: props.isAdmin
      ? [
          {
            id: "denumire",
            label: "Denumire",
            type: "string",
            input: "text"
          },
          {
            id: "disciplina.value",
            label: "Disciplina",
            type: "string",
            input: "select",
            values: props.nomenclatoare.discipline.reduce(
              (pv, cv) => [
                ...pv,
                {
                  [cv._id]: `${cv.nume}`
                }
              ],
              []
            ),
            operators: ["equal", "not_equal"]
          },
          {
            id: "categorie.value",
            label: "Categorie",
            type: "string",
            input: "select",
            values: props.nomenclatoare.categoriiELearning.reduce(
              (pv, cv) => [
                ...pv,
                {
                  [cv._id]: `${cv.nume}`
                }
              ],
              []
            ),
            operators: ["equal", "not_equal"]
          },
          {
            id: "autori",
            label: "Autor",
            type: "string",
            input: "text"
          },
          {
            id: "roluriVizibil",
            label: "Roluri pentru care cursul este vizibil",
            type: "string",
            input: "select",
            values: [
              { others: "Altii" },
              { masterand: "Masterand" },
              { doctorand: "Doctorand" },
              { candidat: "Candidat la abilitare" },
              { conducator: "Conducator de doctorat" }
            ]
          }
        ]
      : [
          {
            id: "denumire",
            label: "Denumire",
            type: "string",
            input: "text"
          },
          {
            id: "disciplina.value",
            label: "Disciplina",
            type: "string",
            input: "select",
            values: props.nomenclatoare.discipline.reduce(
              (pv, cv) => [
                ...pv,
                {
                  [cv._id]: `${cv.nume}`
                }
              ],
              []
            ),
            operators: ["equal", "not_equal"]
          },
          {
            id: "categorie.value",
            label: "Categorie",
            type: "string",
            input: "select",
            values: props.nomenclatoare.categoriiELearning.reduce(
              (pv, cv) => [
                ...pv,
                {
                  [cv._id]: `${cv.nume}`
                }
              ],
              []
            ),
            operators: ["equal", "not_equal"]
          },
          {
            id: "autori",
            label: "Autor",
            type: "string",
            input: "text"
          }
        ]
  });
  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.fetchData(this.props.query, {});
  }
  fetchData = query => {
    if (query) {
      this.props.searchCursuri(query, {});
    } else {
      this.props.searchCursuri({}, {});
    }
    this.setState({
      filterNo: utils.countFilters(query)
    });
  };
  handleViewDetailsClick = () => {
    this.setState({
      anchorEl: null,
      openViewModal: true
    });
  };
  handleViewClick = () => {
    this.handleClose();
  };
  handleEditClick = () => {
    this.props.history.push(`/elearning/editeaza/${this.state.id}`);
    this.handleClose();
  };
  handleDeleteRequestClick = () => {
    this.setState({
      openDeleteModal: true,
      deleteName: `${this.state.selectedItem.denumire}`
    });
    this.handleClose();
  };
  handleDeleteClick = async () => {
    this.handleCloseDeleteModal();
    await this.props.deleteCurs(this.state.id);
    this.props.fetchCursuri();
  };
  handleCloseDeleteModal = () =>
    this.setState({ openDeleteModal: false, deleteName: undefined });
  handleOpenMenu = (event, item) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedItem: item,
      id: item._id
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };
  openFilterModal = () => {
    this.setState({
      openModal: true
    });
  };
  handleCloseFilterModal = action => {
    if (action === "reset") {
      this.setState(({ columns, filter }) => ({
        openModal: false,
        filter: {},
        columns: columns.map(column => ({ ...column, show: false }))
      }));
    } else {
      this.setState({
        openModal: false
      });
    }
  };
  handleAddClick = () => {
    this.props.history.push("/elearning/adauga");
  };
  handleCloseViewModal = () => {
    this.setState({
      openViewModal: false
    });
  };
  handleSetFilterNo = filterNo => {
    this.setState({
      filterNo
    });
  };
  handleChangePage = page => {
    const { query } = this.props;
    const { pageInfo } = this.props;
    this.props.searchCursuri(query, { pageSize: pageInfo.pageSize, page });
  };
  handleChangeRowsPerPage = pageSize => {
    const { query } = this.props;
    const { pageInfo } = this.props;
    this.props.searchCursuri(query, { ...pageInfo, pageSize, page: 0 });
  };
  handleSetQuery = mongoQuery => {
    this.props.searchCursuri(mongoQuery, {});
  };
  handleDownloadClick = async () => {
    await this.props.downloadDocumentFromCurs(
      this.state.selectedItem.documentId
    );
    this.setState({
      openViewModal: false
    });
    this.handleClose();
  };
  handleExport = async () => {
    this.setState({ isExporting: true });
    const { jwt } = this.props;
    try {
      const res = await request
        .post(`${serverUrl}/export/cursuri`)
        .send(this.state.query)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
    this.setState({ isExporting: false });
  };
  render() {
    const {
      openModal,
      filter,
      anchorEl,
      filterConfig,
      openViewModal,
      selectedItem,
      filterNo,
      openDeleteModal,
      deleteName
    } = this.state;
    const { fullScreen, isAdmin, items, query, pageInfo } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          {isAdmin && (
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
              onClick={this.handleAddClick}
            >
              <AddIcon />
              Adauga curs
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.openFilterModal}
          >
            <SearchIcon />
            {filterNo !== 0
              ? filterNo === 1
                ? `${filterNo} filtru activ`
                : `${filterNo} filtre active`
              : "Aplica filtre"}
          </Button>
          {isAdmin && (
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
              onClick={this.handleExport}
            >
              <CloudDownloadIcon />
              Exporta
            </Button>
          )}
        </div>
        <Paper style={{ height: "100%", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                {baseColumns
                  .filter(column => column.name !== "id")
                  .map(column => (
                    <TableCell key={column.name}>{column.label}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(row => (
                // const keys = Object.keys(row);
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    <IconButton
                      aria-owns={anchorEl ? "simple-menu" : null}
                      aria-haspopup="true"
                      onClick={event => this.handleOpenMenu(event, row)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.denumire}</TableCell>
                  <TableCell>
                    {row.categorie && row.categorie[0]
                      ? row.categorie.reduce(
                          (pv, cv) => `${pv} ${cv.label}`,
                          ""
                        )
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {row.disciplina && row.disciplina[0]
                      ? row.disciplina.reduce(
                          (pv, cv) => `${pv} ${cv.label}`,
                          ""
                        )
                      : "-"}
                  </TableCell>
                  <TableCell>{row.tip}</TableCell>
                  <TableCell>{row.autori}</TableCell>
                  <Menu
                    className="lvd-options-container"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem
                      disabled={!row.documentId}
                      onClick={this.handleDownloadClick}
                    >
                      Descarca
                    </MenuItem>
                    <MenuItem onClick={this.handleViewDetailsClick}>
                      Detalii complete
                    </MenuItem>
                    {isAdmin && (
                      <MenuItem onClick={this.handleEditClick}>
                        Editeaza
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem onClick={this.handleDeleteRequestClick}>
                        Sterge
                      </MenuItem>
                    )}
                  </Menu>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={pageInfo.itemCount}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={pageInfo.pageSize || 25}
            page={pageInfo.page}
            backIconButtonProps={{
              "aria-label": "Anterioara"
            }}
            nextIconButtonProps={{
              "aria-label": "Urmatoare"
            }}
            onChangePage={(e, page) => this.handleChangePage(page)}
            onChangeRowsPerPage={e =>
              this.handleChangeRowsPerPage(e.target.value)
            }
          />
        </Paper>
        <FilterModal
          filterNo={filterNo}
          returnOnlyDirty
          handleSubmit={this.handleSubmit}
          handleClose={this.handleCloseFilterModal}
          query={query}
          filter={filter}
          openModal={openModal}
          fullScreen={fullScreen}
          filterConfig={filterConfig}
          handleSetFilterNo={this.handleSetFilterNo}
          handleSetQuery={this.handleSetQuery}
        />
        <Dialog
          fullScreen={fullScreen}
          open={openViewModal}
          onClose={this.handleCloseViewModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className="lvd-dialog-title">
            {selectedItem.denumire}
            <Button
              className="lvd-button-close-modal"
              onClick={this.handleCloseViewModal}
            >
              Inchide
            </Button>
          </DialogTitle>
          <DialogContentText color="textPrimary" className="lvd-dialog-text">
            {selectedItem.descriere}
          </DialogContentText>
          <DialogContent>
            <ul className="lvd-dialog-list">
              <li>
                <span>Categorie:</span>
                {selectedItem.categorie && selectedItem.categorie[0]
                  ? selectedItem.categorie.reduce(
                      (pv, cv) => `${pv} ${cv.label}`,
                      ""
                    )
                  : "-"}
              </li>
              <li>
                <span>Disciplina:</span>
                {selectedItem.disciplina && selectedItem.disciplina[0]
                  ? selectedItem.disciplina.reduce(
                      (pv, cv) => `${pv} ${cv.label}`,
                      ""
                    )
                  : "-"}
              </li>
              <li>
                <span>Autor:</span> {selectedItem.autori}
              </li>
              <li>
                <span>Dimensiune:</span>{" "}
                {selectedItem.dimensiune ? `${selectedItem.dimensiune} MB` : ""}
              </li>
              <li>
                <span>Tip:</span> {selectedItem.tip || ""}
              </li>
              <li>
                <span>Actualizat la:</span>{" "}
                {moment(selectedItem.updatedAt).format("DD/MM/YYYY HH:mm")}
              </li>
            </ul>
          </DialogContent>
          <DialogActions style={{ padding: "14px" }}>
            <div style={{ flexGrow: 1 }} />
            {!!selectedItem.dimensiune && (
              <Button
                onClick={this.handleDownloadClick}
                variant="contained"
                color="primary"
              >
                Descarca
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <DeleteModal
          open={openDeleteModal}
          handleClose={this.handleCloseDeleteModal}
          name={deleteName}
          handleDelete={this.handleDeleteClick}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  items: getCursuri(state),
  isAdmin: getIsAdmin(state),
  query: getCursuriQuery(state),
  nomenclatoare: state.nomenclatoare,
  jwt: getToken(state),
  pageInfo: getCursuriPageInfo(state)
});

export default withMobileDialog()(
  connect(
    mapStateToProps,
    {
      fetchCursuri,
      downloadDocumentFromCurs,
      deleteCurs,
      searchCursuri,
      fetchNomenclatoare,
      showSnackbar
    }
  )(ELearning)
);

/*
<TableWithFilter
        name="elearning"
        addButtonText="Adauga curs"
        baseColumns={baseColumns}
        filterConfig={filterConfig}
        fetchItems={fetchCursuri}
        initialFilterValues={{
          cnp: '',
          titulatura: '',
          anObtinereTitluDoctor: '',
          facultate: '',
          Departament: '',
          domeniuDoctorat: '',
          specializare: '',
          telefon: '',
          email: '',
          linkScopus: '',
          linkGoogleScholar: '',
          linkISIWebOfScience: '',
          linkResearchGate: '',
        }}
      />
*/
