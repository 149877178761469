import request from 'superagent';
import _ from 'lodash';
import * as TYPES from '../types';
import { serverUrl } from '../utils';
import { showSnackbar } from './snackbar';
import { getToken } from '../reducers/authReducer';

const fetchCandidatiRequest = () => ({
  type: TYPES.CANDIDATI_FETCH_REQUEST,
});
const fetchCandidatiSuccess = payload => ({
  type: TYPES.CANDIDATI_FETCH_SUCCESS,
  payload,
});
const fetchCandidatiFailure = error => ({
  type: TYPES.CANDIDATI_FETCH_FAILURE,
  error,
});

export const fetchCandidati = () => async (dispatch, getState) => {
  dispatch(fetchCandidatiRequest());
  const jwt = getToken(getState());
  try {
    const res = await request.get(`${serverUrl}/candidati`).set('Authorization', `Bearer ${jwt}`);
    dispatch(fetchCandidatiSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(fetchCandidatiFailure(e));
  }
};
export const requestCandidatById = () => ({
  type: TYPES.CANDIDATI_BYID_REQUEST,
});
export const successCandidatById = payload => ({
  type: TYPES.CANDIDATI_BYID_SUCCESS,
  payload,
});
export const failCandidatById = error => ({
  type: TYPES.CANDIDATI_BYID_FAIL,
  error,
});

export const fetchCandidatById = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestCandidatById());
  try {
    const res = await request
      .get(`${serverUrl}/candidati/${dosarId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successCandidatById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failCandidatById(e));
  }
};

const requestAddCandidat = () => ({
  type: TYPES.CANDIDATI_ADD_REQUEST,
});
const successAddCandidat = payload => ({
  type: TYPES.CANDIDATI_ADD_SUCCESS,
  payload,
});
const failAddCandidat = error => ({
  type: TYPES.CANDIDATI_ADD_FAIL,
  error,
});

export const addCandidat = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestAddCandidat());
  try {
    const res = await request
      .post(`${serverUrl}/candidati`)
      .set('Authorization', `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successAddCandidat(res.body));
    dispatch(showSnackbar('Dosar salvat cu succes', 'success'));
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failAddCandidat(e));
    return false;
  }
};

export const requestUpdateCandidat = () => ({
  type: TYPES.CANDIDATI_UPDATE_REQUEST,
});
export const successUpdateCandidat = payload => ({
  type: TYPES.CANDIDATI_UPDATE_SUCCESS,
  payload,
});
export const failUpdateCandidat = error => ({
  type: TYPES.CANDIDATI_UPDATE_FAIL,
  error,
});
export const updateCandidat = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateCandidat());
  try {
    const res = await request
      .put(`${serverUrl}/candidati/${dosar._id}`)
      .set('Authorization', `Bearer ${jwt}`)
      .send(dosar);
    dispatch(showSnackbar('Dosar salvat cu success!', 'success'));
    dispatch(successUpdateCandidat(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failUpdateCandidat(e));
  }
};

export const requestSearchCandidati = () => ({
  type: TYPES.CANDIDATI_SEARCH_REQUEST,
});
export const successSearchCandidati = ({ dosare, pageInfo }, query) => ({
  type: TYPES.CANDIDATI_SEARCH_SUCCESS,
  payload: {
    dosare,
    pageInfo,
  },
  query,
});
export const failSearchCandidati = error => ({
  type: TYPES.CANDIDATI_SEARCH_FAIL,
  error,
});

export const searchCandidati = (query, pageInfo) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  query = _.isEmpty(query) ? undefined : query;
  dispatch(requestSearchCandidati());
  try {
    const res = await request
      .post(`${serverUrl}/candidati/search`)
      .send({ query, pageInfo })
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successSearchCandidati(res.body, query));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failSearchCandidati(e));
  }
};

const requestDeleteCandidat = () => ({
  type: TYPES.CANDIDATI_DELETE_REQUEST,
});
const successDeleteCandidat = payload => ({
  type: TYPES.CANDIDATI_DELETE_SUCCESS,
  payload,
});
const failDeleteCandidat = error => ({
  type: TYPES.CANDIDATI_DELETE_FAIL,
  error,
});
export const deleteCandidat = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeleteCandidat());
  try {
    await request.delete(`${serverUrl}/candidati/${dosarId}`).set('Authorization', `Bearer ${jwt}`);
    dispatch(successDeleteCandidat(dosarId));
    dispatch(showSnackbar('Dosar sters cu succes', 'success'));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failDeleteCandidat(e));
  }
};
