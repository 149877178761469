import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import request from "superagent";
import FileSaver from "file-saver";
import {
  Menu,
  MenuItem,
  withMobileDialog,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import {
  fetchPublicatii,
  downloadDocumentFromPublicatie,
  deletePublicatie,
  searchPublicatii,
  fetchNomenclatoare,
  showSnackbar,
} from "../../actions";
import {
  getPublicatii,
  getPublicatiiQuery,
  getPublicatiiPageInfo,
} from "../../reducers/publicatiiReducer";
import { getIsAdmin, getUser, getToken } from "../../reducers/authReducer";
import { serverUrl } from "../../utils";
import TableWithFilter from "../General/TableWithFilter";

class Publicatii extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    searchPublicatii: PropTypes.func.isRequired,
    fetchPublicatii: PropTypes.func.isRequired,
  };

  state = {
    openModal: false,
    filter: {},
    anchorEl: null,
    id: undefined,
    selectedItem: {},
    filterNo: 0,
    openDeleteModal: false,
    deleteName: undefined,
    isDownloading: false,
    isExporting: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      filterConfig: [
        {
          id: "titlu",
          label: "Titlu",
          type: "string",
          input: "text",
        },
        {
          id: "autori",
          label: "Autori",
          type: "string",
          input: "text",
        },
        {
          id: "tipPublicatie",
          label: "Tip publicatie",
          type: "string",
          input: "radio",
          values: ["jurnal", "proceeding"],
          operators: ["equal", "not_equal"],
        },
        {
          id: "jurnal.value",
          label: "Jurnal",
          type: "string",
          input: "select",
          values: nextProps.nomenclatoare.jurnale.reduce(
            (pv, cv) => [
              ...pv,
              {
                [cv._id]: `${cv.nume}`,
              },
            ],
            []
          ),
          operators: ["equal", "not_equal"],
        },
        {
          id: "volum",
          label: "Volum",
          type: "integer",
          input: "number",
        },
        {
          id: "numar",
          label: "Numar",
          type: "integer",
          input: "number",
        },
        {
          id: "pagini",
          label: "Pagini",
          type: "string",
          input: "text",
        },
        {
          id: "editor",
          label: "Editor",
          type: "string",
          input: "text",
        },
        // {
        //   id: 'isDeleted',
        //   label: 'Este sters',
        //   type: 'boolean',
        //   input: 'radio',
        //   values: [true, false],
        // },
      ],
    };
  }

  async componentDidMount() {
    await this.props.fetchNomenclatoare();
  }

  handleViewDetailsClick = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleViewClick = () => {
    this.props.history.push(`/publicatii/vizualizeaza/${this.state.id}`);
    this.handleClose();
  };

  handleEditClick = () => {
    this.props.history.push(`/publicatii/editeaza/${this.state.id}`);
    this.handleClose();
  };

  handleDeleteRequestClick = () => {
    this.setState({
      openDeleteModal: true,
      deleteName: `${this.state.selectedItem.titlu}`,
    });
    this.handleClose();
  };

  handleDeleteClick = async () => {
    this.handleCloseDeleteModal();
    await this.props.deletePublicatie(this.state.id);
    this.props.fetchPublicatii();
  };

  handleCloseDeleteModal = () =>
    this.setState({ openDeleteModal: false, deleteName: undefined });

  handleOpenMenu = (event, item) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedItem: item,
      id: item._id,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  openFilterModal = () => {
    this.setState({
      openModal: true,
    });
  };

  handleCloseFilterModal = (action) => {
    if (action === "reset") {
      this.setState(({ columns, filter }) => ({
        openModal: false,
        filter: {},
        columns: columns.map((column) => ({ ...column, show: false })),
      }));
    } else {
      this.setState({
        openModal: false,
      });
    }
  };

  handleAddClick = () => {
    this.props.history.push("/publicatii/adauga");
  };

  handleDownloadClick = async (rowId, { documentId }) => {
    this.setState({ isDownloading: true });
    await this.props.downloadDocumentFromPublicatie(documentId);
    this.setState({
      isDownloading: false,
    });
  };

  handleExport = async () => {
    this.setState({ isExporting: true });
    const { jwt } = this.props;
    try {
      const res = await request
        .post(`${serverUrl}/export/publicatii`)
        .send(this.state.query)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
    this.setState({ isExporting: false });
  };

  render() {
    const { filterConfig, isDownloading } = this.state;
    const { isAdmin, items, query, user, pageInfo } = this.props;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick,
      handleExportDosar
    ) => [
      {
        id: "optiuni",
        width: 65,
        resizable: false,
        Header: "Optiuni",
        accessor: (row) => (
          <>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={(event) => openMenu(event, row._id, row)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        accessor: "_id",
        Header: "Id",
        show: false,
      },
      {
        accessor: "titlu",
        Header: "Titlu",
      },
      {
        accessor: "tipPublicatie",
        Header: "Tip publicatie",
      },
      {
        id: "jurnal",
        accessor: ({ jurnal, tipPublicatie }) =>
          tipPublicatie === "jurnal" ? (jurnal ? jurnal.label : "-") : "N/A",
        Header: "Jurnal",
      },
      {
        id: "proceeding",
        accessor: ({ proceeding, tipPublicatie }) =>
          tipPublicatie === "proceeding" ? proceeding || "-" : "N/A",
        Header: "Proceeding",
      },
      {
        accessor: "autori",
        Header: "Autori",
      },
      {
        accessor: "volum",
        Header: "Volum",
      },
      {
        accessor: "numar",
        Header: "Numar",
      },
      {
        accessor: "pagini",
        Header: "Pagini",
      },
      {
        accessor: "editor",
        Header: "Editor",
      },
    ];
    return (
      <TableWithFilter
        name="publicatii"
        query={query}
        pageInfo={pageInfo}
        filterConfig={filterConfig}
        baseColumns={baseColumns}
        fetchItems={this.props.searchPublicatii}
        onExport={isAdmin ? this.handleExport : undefined}
        onDelete={this.handleDelete}
        isFetchingFilters={false}
        isFetching={false}
        applyFilter={this.props.searchPublicatii}
        addButtonText="Adauga Publicatie"
        onExportDosar={this.handleDownloadClick}
        items={items}
        menuComponent={(props) => {
          return (
            <Menu
              className="lvd-options-container"
              anchorEl={props.anchorEl}
              open={Boolean(props.anchorEl)}
              // open={false}
              onClose={props.onClose}
            >
              <MenuItem
                disabled={!props.row.documentId || isDownloading}
                onClick={props.handleExportDosar}
              >
                Descarca
                {isDownloading && (
                  <CircularProgress
                    size={24}
                    className="lvd-progress-button"
                    style={{ marginLeft: "24px" }}
                  />
                )}
              </MenuItem>
              <MenuItem onClick={props.handleViewClick}>Vizualizeaza</MenuItem>
              {(isAdmin ||
                (user && user.id) ===
                  (props.row.asociatCu && props.row.asociatCu._id)) && (
                <MenuItem onClick={props.handleEditClick}>Editeaza</MenuItem>
              )}
              {isAdmin && (
                <MenuItem onClick={props.handleDeleteClick}>Sterge</MenuItem>
              )}
            </Menu>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isAdmin: getIsAdmin(state),
  items: getPublicatii(state),
  user: getUser(state),
  nomenclatoare: state.nomenclatoare,
  query: getPublicatiiQuery(state),
  pageInfo: getPublicatiiPageInfo(state),
  jwt: getToken(state),
});

const mapDispatchToProps = {
  fetchNomenclatoare,
  fetchPublicatii,
  downloadDocumentFromPublicatie,
  deletePublicatie,
  searchPublicatii,
  showSnackbar,
};

export default withMobileDialog()(
  connect(mapStateToProps, mapDispatchToProps)(Publicatii)
);

/*
 {/* <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          {isAdmin && (
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
              onClick={this.handleAddClick}
            >
              <AddIcon />
              Adauga Publicatie
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.openFilterModal}
          >
            <SearchIcon />
            {filterNo !== 0
              ? filterNo === 1
                ? `${filterNo} filtru activ`
                : `${filterNo} filtre active`
              : 'Aplica filtre'}
          </Button>
          {isAdmin && (
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
              disabled={isExporting}
              onClick={this.handleExport}
            >
              <CloudDownloadIcon />
              Exporta
            </Button>
          )}
        </div>
        <Paper style={{ height: '100%', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                {baseColumns
                  .filter(column => column.name !== 'id')
                  .map(column => (
                    <TableCell key={column.name}>{column.label}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(row => (
                // const keys = Object.keys(row);
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    <IconButton
                      aria-owns={anchorEl ? 'simple-menu' : null}
                      aria-haspopup="true"
                      onClick={event => this.handleOpenMenu(event, row)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.titlu}</TableCell>
                  {isAdmin && (
                    <TableCell>{row.asociatCu ? `${row.asociatCu.email}` : '-'}</TableCell>
                  )}
                  <TableCell>{row.autori}</TableCell>
                  <TableCell>{row.jurnal ? row.jurnal.label : '-'}</TableCell>
                  <TableCell>{row.volum}</TableCell>
                  <TableCell>{row.numar}</TableCell>
                  <TableCell>{row.pagini}</TableCell>
                  <TableCell>{row.editor}</TableCell>
                  <Menu
                    className="lvd-options-container"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem
                      disabled={!row.documentId || isDownloading}
                      onClick={this.handleDownloadClick}
                    >
                      Descarca
                      {isDownloading && (
                        <CircularProgress
                          size={24}
                          className="lvd-progress-button"
                          style={{ marginLeft: '24px' }}
                        />
                      )}
                    </MenuItem>
                    <MenuItem onClick={this.handleViewClick}>Vizualizeaza</MenuItem>
                    {(isAdmin || user.id === row.asociatCu._id) && (
                      <MenuItem onClick={this.handleEditClick}>Editeaza</MenuItem>
                    )}
                    {isAdmin && <MenuItem onClick={this.handleDeleteRequestClick}>Sterge</MenuItem>}
                  </Menu>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={items.length}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={25}
            page={0}
            backIconButtonProps={{
              'aria-label': 'Anterioara',
            }}
            nextIconButtonProps={{
              'aria-label': 'Urmatoare',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <FilterModal
          filterNo={filterNo}
          returnOnlyDirty
          handleSubmit={this.handleSubmit}
          handleClose={this.handleCloseFilterModal}
          query={query}
          filter={filter}
          openModal={openModal}
          fullScreen={fullScreen}
          filterConfig={filterConfig}
          handleSetFilterNo={this.handleSetFilterNo}
          handleSetQuery={this.handleSetQuery}
        />
        <DeleteModal
          open={openDeleteModal}
          handleClose={this.handleCloseDeleteModal}
          name={deleteName}
          handleDelete={this.handleDeleteClick}
        />
      </div> */
