import React, { Component } from "react";
import { Button, Link } from "@material-ui/core";
import { connect } from "react-redux";
import queryString from "query-string";
import OldLoginLayout from "../Layout/OldLoginLayout";

class PaymentSuccess extends Component {
  state = { orderId: "" };

  componentDidMount() {
    const { orderId } = queryString.parse(this.props.location.search);
    this.setState({
      orderId
    });
  }

  render() {
    const { orderId } = this.state;
    return (
      <OldLoginLayout>
        Plata cu nr. {orderId} a fost inregistrata.
        <div className="lvd-spacer20" />
        <Button component={Link} to="/inscriere" href="/inscriere">
          Inapoi
        </Button>
      </OldLoginLayout>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSuccess);
