import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withMobileDialog, IconButton } from "@material-ui/core";
import FileSaver from "file-saver";
import request from "superagent";
import moment from "moment";
import {
  fetchInscrisi,
  fetchNomenclatoare,
  showSnackbar,
  fetchConducatori,
  searchInscrisi,
  deleteInscris,
} from "../../../actions";
import {
  getInscrisi,
  getInscrisiQuery,
  getIsFetchingInscrisi,
  getInscrisiPageInfo,
} from "../../../reducers/inscrisiReducer";
import { serverUrl } from "../../../utils";
import { getToken } from "../../../reducers/authReducer";
import TableWithFilter from "../../General/TableWithFilter";
import { getConducatori } from "../../../reducers/conducatoriReducer";
import { getReadableStatus } from "../../../utils/helpers";

class InscrisiList extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    fetchInscrisi: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchConducatori: PropTypes.func.isRequired,
    searchInscrisi: PropTypes.func.isRequired,
    deleteInscris: PropTypes.func.isRequired,
  };

  state = {
    filterconfig: [],
  };

  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchConducatori();
  }

  static getDerivedStateFromProps = (props, state) => ({
    filterConfig: [
      {
        id: "email",
        label: "Email",
        type: "string",
        input: "text",
      },
      {
        id: "nume",
        label: "Nume",
        type: "string",
        input: "text",
      },
      {
        id: "prenume",
        label: "Prenume",
        type: "string",
        input: "text",
      },
      {
        id: "status",
        label: "Status",
        type: "string",
        input: "select",
        values: [
          {
            incomplet: "Incomplet",
          },
          {
            trimisCatreValidare: "Trimis catre validare",
          },
          {
            respins: "Respins",
          },
          {
            validat: "Validat",
          },
          {
            platit: "Platit",
          },
        ],
        operators: ["equal", "not_equal"],
      },
      {
        id: "candidatType",
        label: "Tip candidat",
        type: "string",
        input: "select",
        values: [
          {
            eu: "UE",
          },
          {
            "non-eu": "NON-UE",
          },
        ],
        operators: ["equal", "not_equal"],
      },
    ],
  });

  handleExportDosar = async (id) => {
    try {
      const { jwt } = this.props;
      const res = await request
        .get(`${serverUrl}/inscrisi/export/${id}`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      const fileName = contentDisposition.substring(
        contentDisposition.indexOf("=") + 2,
        contentDisposition.lastIndexOf(".")
      );
      const ext = contentDisposition.substring(
        contentDisposition.lastIndexOf(".") + 1,
        contentDisposition.length - 1
      );
      FileSaver.saveAs(blob, `${fileName}.${ext}`);
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  handleDelete = (dosarId) => {
    this.props.deleteInscris(dosarId);
  };

  handleConfirmDeleteAll = async () => {
    try {
      const { jwt } = this.props;
      await request
        .delete(`${serverUrl}/inscrisi/delete-all-rejected`)
        .set("Authorization", `Bearer ${jwt}`);
      showSnackbar("Toate dosarele respinse au fost sterse", "success");
    } catch (e) {
      showSnackbar(e);
    }
  };
  handleTransformInscrisi = async () => {
    try {
      const { jwt } = this.props;
      await request
        .get(`${serverUrl}/inscrisi/transform-all-finalized`)
        .set("Authorization", `Bearer ${jwt}`);
      showSnackbar("Dosarele finalizate au fost transformate", "success");
    } catch (e) {
      showSnackbar(e);
    }
  };

  handleExport = async (query) => {
    const { jwt } = this.props;
    try {
      const res = await request
        .post(`${serverUrl}/export/inscrisi`)
        .send(query)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  render() {
    const {
      items,
      isFetching,
      query,
      isFetchingFilters,
      pageInfo,
    } = this.props;
    const { filterConfig } = this.state;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick,
      handleExportDosar
    ) => [
      {
        id: "optiuni",
        width: 65,
        resizable: false,
        Header: "Optiuni",
        accessor: (row) => (
          <>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={(event) => openMenu(event, row._id)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        accessor: "_id",
        Header: "Id",
        show: false,
      },
      {
        accessor: "email",
        Header: "Email",
        show: true,
      },
      {
        accessor: "nume",
        Header: "Nume",
        show: true,
      },
      {
        accessor: "prenume",
        Header: "Prenume",
        show: true,
      },
      {
        accessor: ({ createdAt }) =>
          moment(createdAt).format("DD/MM/YYYY HH:mm"),
        id: "createdAt",
        Header: "Data creare cont",
      },
      {
        accessor: ({ status }) => getReadableStatus(status),
        id: "status",
        Header: "Status",
      },
      {
        accessor: ({ dataTrimitereValidare }) =>
          dataTrimitereValidare
            ? moment(dataTrimitereValidare).format("DD/MM/YYYY HH:mm")
            : "-",
        id: "dataTrimitereValidare",
        Header: "Data trimitere validare",
      },
      {
        accessor: ({ candidatType }) => {
          switch (candidatType) {
            case "eu":
              return "UE";
            case "non-eu":
              return "NON-UE";
            default:
              return "-";
          }
        },
        id: "candidatType",
        Header: "Tip candidat",
      },
    ];
    return (
      <TableWithFilter
        showDeleteAllButton
        deleteAll={this.handleConfirmDeleteAll}
        transformInscrisi={this.handleTransformInscrisi}
        name="inscrisi"
        baseColumns={baseColumns}
        pageInfo={pageInfo}
        query={query}
        filterConfig={filterConfig}
        fetchItems={this.props.searchInscrisi}
        onExport={this.handleExport}
        onDelete={this.handleDelete}
        isFetchingFilters={isFetchingFilters}
        isFetching={isFetching}
        applyFilter={this.props.searchInscrisi}
        addButtonText="Adauga Inscris"
        onExportDosar={this.handleExportDosar}
        menuShowEdit
        menuShowExport
        items={items}
        hideAddButton
      />
    );
  }
}
const mapStateToProps = (state) => ({
  items: getInscrisi(state),
  isFetchingFilters: state.nomenclatoare.isFetching,
  isFetching: getIsFetchingInscrisi(state),
  nomenclatoare: state.nomenclatoare,
  conducatori: getConducatori(state),
  jwt: getToken(state),
  query: getInscrisiQuery(state),
  pageInfo: getInscrisiPageInfo(state),
});

export default withMobileDialog()(
  connect(mapStateToProps, {
    fetchInscrisi,
    showSnackbar,
    fetchNomenclatoare,
    fetchConducatori,
    searchInscrisi,
    deleteInscris,
  })(InscrisiList)
);
