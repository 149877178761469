import React, { Component } from "react";
import {
  Tab,
  Tabs,
  AppBar,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import * as yup from "yup";
import { connect } from "react-redux";
import { setLanguage } from "redux-i18n";
import { Formik, Form } from "formik";
import FileSaver from "file-saver";
import request from "superagent";
import {
  fetchNomenclatoarePublice,
  fetchInscrisById,
  fetchTipuriDeDocumente,
  updateInscris,
  handleReject,
  handleValidate,
  showSnackbar,
  finalizeDosar,
  setAppBarTitle,
} from "../../../actions";
import AddEditForm from "../../General/AddEditForm";
import { getInscrisById } from "../../../reducers/inscrisiReducer";
import { getTipuriDeDocumente } from "../../../reducers/tipuriDeDocumenteReducer";
import DocumentTable from "../../General/DocumentTable";
import { serverUrl } from "../../../utils";
import { getToken } from "../../../reducers/authReducer";
import Payments from "./Payments";
import { getReadableStatus } from "../../../utils/helpers";

const generateInputs = (t, props) => [
  {
    label: t.identificationData,
    type: "textDelimiter",
  },
  {
    type: "children",
    name: "a7",
    children: [
      {
        name: "nume",
        label: t.familyName,
        type: "text",
        required: true,
        helperText: t.familyNameExplanation,
      },
      {
        name: "initialaTatalui",
        label: t.fatherInitial,
        type: "text",
        required: true,
      },
      {
        name: "numeCasatorie",
        label: t.marriageName,
        type: "text",
      },
      {
        name: "prenume",
        label: t.firstName,
        type: "text",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a8",
    children: [
      {
        name: "cnp",
        label: t.cnp,
        type: "text",
        required: true,
      },
      {
        name: "sex",
        label: "Sex",
        type: "select",
        options: [
          {
            label: "M",
            value: "masculin",
          },
          {
            label: "F",
            value: "feminin",
          },
        ],
        required: true,
      },
      {
        name: "stareCivila",
        label: t.maritalStatus,
        type: "select",
        options: [
          {
            label: t.married,
            value: "casatorit",
          },
          {
            label: t.notMarried,
            value: "necasatorit",
          },
        ],
        required: true,
      },
      {
        name: "dataNasterii",
        label: t.dateOfBirth,
        type: "datepicker",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a9",
    children: [
      {
        name: "nationalitate",
        label: t.nationality,
        type: "text",
        required: true,
      },
      {
        name: "cetatenie",
        label: t.citizenship,
        type: "select",
        options: props.nomenclatoare.cetatenii.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "loculNasterii",
        label: t.birthplace,
        type: "text",
        required: true,
      },
    ],
  },
  {
    name: "domiciliuStabil",
    label: t.residence,
    type: "text",
    required: true,
  },
  {
    type: "children",
    name: "a10",
    children: [
      {
        name: "strada",
        label: t.street,
        type: "text",
        required: true,
      },
      {
        name: "numar",
        label: t.number,
        type: "text",
        required: true,
      },
      {
        name: "codPostal",
        label: t.postalCode,
        type: "text",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a11",
    children: [
      {
        name: "bloc",
        label: t.bloc,
        type: "text",
      },
      {
        name: "scara",
        label: t.entrance,
        type: "text",
      },
      {
        name: "etaj",
        label: t.floor,
        type: "text",
      },
      {
        name: "apartament",
        label: t.apartment,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a14",
    children: [
      {
        name: "telefonMobil",
        label: t.mobilePhone,
        type: "text",
        required: true,
      },
      {
        name: "telefonFix",
        label: t.landline,
        type: "text",
      },
    ],
  },
  {
    type: "textDelimiter",
    name: "9999",
    label: t.identityCardText,
    captionText: t.identityCardCaptionText,
  },
  {
    type: "children",
    name: "a12",
    children: [
      {
        name: "serieCarteIdentitate",
        label: t.identityCardSerial,
        type: "text",
      },
      {
        name: "nrCarteIdentitate",
        label: t.identityCardNo,
        type: "text",
      },
      {
        name: "dataEliberarii",
        label: t.identityCardIssueDate,
        type: "datepicker",
      },
    ],
  },
  {
    type: "children",
    name: "a13",
    children: [
      {
        name: "seriePasaport",
        label: t.passportSerial,
        type: "text",
      },
      {
        name: "anEliberarePasaport",
        label: t.passportIssueDate,
        type: "number",
      },
      {
        name: "valabilitatePasaport",
        label: t.passportValabilityYears,
        type: "number",
      },
    ],
  },
  {
    label: t.dataOnGraduatedLicenceStudies,
    type: "textDelimiter",
  },
  {
    name: "institutieInvatamantSuperior",
    label: t.nameOfHighEducationInstitution,
    type: "text",
    required: true,
  },
  {
    type: "children",
    name: "a15",
    children: [
      {
        name: "tara",
        label: t.country,
        type: "text",
        required: true,
      },
      {
        name: "localitatea",
        label: t.city,
        type: "text",
        required: true,
      },
    ],
  },
  {
    name: "facultatea",
    label: t.faculty,
    type: "text",
    required: true,
  },
  {
    type: "children",
    name: "a16",
    children: [
      {
        name: "domeniul",
        label: t.field,
        type: "text",
        required: true,
      },
      {
        name: "profilul",
        label: t.specialization,
        type: "text",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a17",
    children: [
      {
        name: "anulAbsolvirii",
        label: t.graduationYear,
        type: "number",
        required: true,
      },
      {
        name: "durata",
        label: t.durationSem,
        type: "number",
        required: true,
      },
      {
        name: "titluObtinut",
        label: t.obtainedTitle,
        type: "text",
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a18",
    children: [
      {
        name: "nrDiplomaLicenta",
        label: t.diplomaCertificateNo,
        type: "text",
        required: true,
      },
      {
        name: "nrFoaieMatricolaLicenta",
        label: t.matricolSheetCertificateNo,
        type: "text",
        required: true,
      },
    ],
  },
  {
    label: t.dataOnUniversityMasterStudies,
    type: "textDelimiter",
  },
  {
    name: "institutieInvatamantSuperiorMaster",
    label: t.nameOfHighEducationInstitution,
    type: "text",
  },
  {
    type: "children",
    name: "a19",
    children: [
      {
        name: "taraMaster",
        label: t.country,
        type: "text",
      },
      {
        name: "localitateaMaster",
        label: t.city,
        type: "text",
      },
    ],
  },
  {
    name: "facultateaMaster",
    label: t.faculty,
    type: "text",
  },
  {
    type: "children",
    name: "a20",
    children: [
      {
        name: "domeniulMaster",
        label: t.field,
        type: "text",
      },
      {
        name: "profilulMaster",
        label: t.specialization,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a21",
    children: [
      {
        name: "anulAbsolviriiMaster",
        label: t.graduationYear,
        type: "number",
      },
      {
        name: "durataMaster",
        label: t.durationSem,
        type: "number",
      },
      {
        name: "titluObtinutMaster",
        label: t.obtainedTitle,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a1",
    children: [
      {
        name: "nrDiploma",
        label: t.diplomaCertificateNo,
        type: "text",
      },
      {
        name: "nrFoaieMatricola",
        label: t.matricolSheetCertificateNo,
        type: "text",
      },
    ],
  },
  {
    label: t.dataOnUniversityDoctoralStudies,
    type: "textDelimiter",
  },
  {
    name: "formaDoctorat",
    label: t.formOfDoctoralStudy,
    children: [
      {
        name: "formaDoctoratCuBursa",
        label: t.withScholarship,
      },
      {
        name: "formaDoctoratFaraBursa",
        label: t.withoutScholarship,
      },
      {
        name: "formaDoctoratCuTaxa",
        label: t.withRegularTax,
      },
      {
        name: "formaDoctoratAltele",
        label: t.others,
      },
    ],
    type: "multipleCheckbox",
  },
  {
    type: "children",
    name: "a3",
    children: [
      {
        name: "conducatorDoctorat",
        label: t.phdAdvisor,
        type: "text",
        required: true,
      },
      {
        name: "domeniulDeDoctorat",
        label: t.doctoralField,
        type: "select",
        options: props.nomenclatoare.domeniiInscriere.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
        required: true,
      },
    ],
  },
  {
    type: "children",
    name: "a4",
    children: [
      {
        name: "intrebareStudiiUTCB",
        label: t.studyQuestion,
        type: "select",
        options: [
          {
            value: "da",
            label: t.yes,
          },
          {
            value: "nu",
            label: t.no,
          },
        ],
        required: true,
      },
      {
        name: "perioadaStudiiDoctoratBugetUTCB",
        label: t.period,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a5",
    children: [
      {
        name: "intrebareStudiiAltaUniversitate",
        label: t.studyQuestionOtherUniversity,
        type: "select",
        options: [
          {
            value: "da",
            label: t.yes,
          },
          {
            value: "nu",
            label: t.no,
          },
        ],
        required: true,
      },
      {
        name: "perioadaStudiiDoctoratBuget",
        label: t.period,
        type: "text",
      },
    ],
  },
  {
    type: "children",
    name: "a6",
    children: [
      {
        name: "limbaStraina",
        label: t.foreignLanguage,
        type: "text",
        required: true,
      },
      {
        name: "certificatCompetenteLingvistice",
        label: t.proficiencyCertificate,
        type: "text",
        required: true,
      },
    ],
  },
  {
    name: "cazareCamin",
    label: t.accomodationInUniversitaryResidence,
    type: "select",
    required: true,
    options: [
      {
        value: "da",
        label: t.yes,
      },
      {
        value: "nu",
        label: t.no,
      },
    ],
  },
  {
    name: "acceptDeclaratie",
    label: t.declaration,
    type: "realCheckbox",
    required: true,
  },
  {
    label: "Date care se completează de către Secretariatul Şcolii Doctorale",
    name: "doarDeSecretara",
    type: "textDelimiter",
  },

  {
    type: "children",
    name: "aniLicenta",
    children: [
      {
        type: "number",
        name: "mediaAnilorLicenta",
        label: "Media anilor de studii de licenta",
      },
      {
        name: "notaExamenLicenta",
        label: "Nota examen de licenta",
        type: "number",
      },
    ],
  },
  {
    type: "children",
    name: "aniMaster",
    children: [
      {
        name: "mediaAnilorMaster",
        label: "Media anilor de studii de master",
        type: "number",
      },
      {
        name: "notaExamenDisertatie",
        label: "Nota examen de disertatie",
        type: "number",
      },
    ],
  },
];

class InscrisiEdit extends Component {
  state = {
    inputs: [],
    isLoading: false,
    isEditing: false,
    currentTab: 0,
    isValidateDialogOpen: false,
    isRejectDialogOpen: false,
  };

  async componentDidMount() {
    const { t, nomenclatoare, match } = this.props;
    this.props.setLanguage("ro");
    await this.props.fetchInscrisById(match.params.dosarId);
    await this.props.fetchNomenclatoarePublice();
    await this.props.fetchTipuriDeDocumente();
    this.props.setAppBarTitle(
      `${this.props.dosar.nume} ${this.props.dosar.prenume}`
    );
    this.setState({
      inputs: generateInputs(t, this.props),
    });
  }

  handleSubmit = async (values) => {
    await this.props.updateInscris(values);
  };

  handleExport = async () => {
    try {
      const { dosar, jwt } = this.props;
      const res = await request
        .get(`${serverUrl}/inscrisi/export/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      const fileName = contentDisposition.substring(
        contentDisposition.indexOf("=") + 2,
        contentDisposition.lastIndexOf(".")
      );
      const ext = contentDisposition.substring(
        contentDisposition.lastIndexOf(".") + 1,
        contentDisposition.length - 1
      );
      FileSaver.saveAs(blob, `${fileName}.${ext}`);
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  handleFinalizeDosar = (event) => {
    this.props.finalizeDosar(this.props.dosar, event.target.checked);
  };
  renderTabContent = () => {
    const { inputs, currentTab, isEditing } = this.state;
    const { dosar, t, tipuriDoc } = this.props;
    const isViewing = dosar.status === "finalizat";
    switch (currentTab) {
      case 0:
        return (
          <AddEditForm
            name="inscrisi"
            inputs={inputs}
            isViewing={isViewing}
            handleNext={this.handleNext}
            handleSubmit={this.handleSubmit}
            saveButtonLabel={t.save}
            validationSchema={yup.object().shape({
              nume: yup.string().required(t.requiredField),
              prenume: yup.string().required(t.requiredField),
              sex: yup.string().required(t.requiredField),
              cnp: yup.string().required(t.requiredField),
              numeCasatorie: yup.string(),
              initialaTatalui: yup.string().required(t.requiredField),
              dataNasterii: yup
                .date()
                .required(t.requiredField)
                .transform((value, originalValue) =>
                  originalValue === null ? undefined : value
                ),
              stareCivila: yup.string().required(t.requiredField),
              nationalitate: yup.string().required(t.requiredField),
              cetatenie: yup.string().required(t.requiredField),
              loculNasterii: yup.string().required(t.requiredField),
              domiciliuStabil: yup.string().required(t.requiredField),
              strada: yup.string().required(t.requiredField),
              numar: yup.string().required(t.requiredField),
              codPostal: yup.string().required(t.requiredField),
              bloc: yup.string().required(t.requiredField),
              scara: yup.string().required(t.requiredField),
              etaj: yup.string().required(t.requiredField),
              apartament: yup.string().required(t.requiredField),
              serieCarteIdentitate: yup.string(),
              nrCarteIdentitate: yup.string(),
              dataEliberarii: yup
                .date()
                .required(t.requiredField)
                .transform((value, originalValue) =>
                  originalValue === null ? undefined : value
                ),
              seriePasaport: yup.string(),
              anEliberarePasaport: yup.string().nullable(),
              valabilitatePasaport: yup.string(),
              telefonMobil: yup.string().required(t.requiredField),
              telefonFix: yup.string(),
              institutieInvatamantSuperior: yup
                .string()
                .required(t.requiredField),
              tara: yup.string().required(t.requiredField),
              localitatea: yup.string().required(t.requiredField),
              facultatea: yup.string().required(t.requiredField),
              domeniul: yup.string().required(t.requiredField),
              profilul: yup.string().required(t.requiredField),
              anulAbsolvirii: yup.string().required(t.requiredField),
              durata: yup.string().required(t.requiredField),
              titluObtinut: yup.string().required(t.requiredField),
              nrDiplomaLicenta: yup.string().required(t.requiredField),
              nrFoaieMatricolaLicenta: yup.string().required(t.requiredField),
              institutieInvatamantSuperiorMaster: "",
              // taraMaster: "",
              // localitateaMaster: "",
              // facultateaMaster: "",
              // domeniulMaster: "",
              // profilulMaster: "",
              // anulAbsolviriiMaster: "",
              // durataMaster: "",
              // titluObtinutMaster: "",
              // nrDiploma: "",
              // nrFoaieMatricola: "",
              // formaDoctorat: yup.string().required(t.requiredField),
              conducatorDoctorat: yup.string().required(t.requiredField),
              domeniulDeDoctorat: yup.string().required(t.requiredField),
              intrebareStudiiUTCB: yup.string().required(t.requiredField),
              perioadaStudiiDoctoratBugetUTCB: yup.string(),
              intrebareStudiiAltaUniversitate: yup
                .string()
                .required(t.requiredField),
              perioadaStudiiDoctoratBuget: yup.string(),
              limbaStraina: yup.string().required(t.requiredField),
              certificatCompetenteLingvistice: yup
                .string()
                .required(t.requiredField),
              cazareCamin: yup.string().required(t.requiredField),
              acceptDeclaratie: yup.bool().required(t.requiredField),
            })}
            initialValues={
              isEditing
                ? {
                    // acceptDeclaratie: true,
                    // anEliberarePasaport: "",
                    // anulAbsolvirii: 2021,
                    // anulAbsolviriiMaster: "",
                    // anulInscrieriiDoctorat: "",
                    // apartament: "123",
                    // bloc: "asd",
                    // cazareCamin: "",
                    // certificatCompetenteLingvistice: "",
                    // cetatenie: {
                    //   value: "5b9ba390db90b5001cba66e9",
                    //   label: "test"
                    // },
                    // cnp: "",
                    // codPostal: "",
                    // conducatorDoctorat: "",
                    // dataEliberarii: "",
                    // dataNasterii: null,
                    // domeniul: "asd",
                    // domeniulDeDoctorat: "",
                    // domeniulMaster: "",
                    // domiciliuStabil: "",
                    // durata: 12,
                    // durataMaster: "",
                    // etaj: "asd",
                    // facultatea: "asd",
                    // facultateaMaster: "",
                    // formaDoctoratAltele: false,
                    // formaDoctoratCuBursa: true,
                    // formaDoctoratCuTaxa: true,
                    // formaDoctoratFaraBursa: false,
                    // initialaTatalui: "",
                    // institutieInvatamantSuperior: "",
                    // institutieInvatamantSuperiorMaster: " -",
                    // intrebareStudiiAltaUniversitate: { value: "nu", label: "No" },
                    // intrebareStudiiUTCB: { value: "nu", label: "No" },
                    // limbaStraina: "",
                    // localitatea: "",
                    // localitateaMaster: "",
                    // loculNasterii: "",
                    // nationalitate: "",
                    // nrCarteIdentitate: "",
                    // nrDiploma: "",
                    // nrDiplomaLicenta: "asd",
                    // nrFoaieMatricola: "",
                    // nrFoaieMatricolaLicenta: "asd",
                    // numar: "",
                    // nume: "",
                    // numeCasatorie: "",
                    // perioadaStudiiDoctoratBuget: "",
                    // perioadaStudiiDoctoratBugetUTCB: "",
                    // prenume: "",
                    // profilul: "",
                    // profilulMaster: "",
                    // scara: "",
                    // serieCarteIdentitate: "",
                    // seriePasaport: "asd",
                    // sex: { label: "M", value: "masculin" },
                    // stareCivila: { label: "Not married", value: "necasatorit" },
                    // strada: "",
                    // tara: "Romania",
                    // taraMaster: "",
                    // telefonFix: "",
                    // telefonMobil: "",
                    // titluObtinut: "",
                    // titluObtinutMaster: "",
                    // valabilitatePasaport: 3
                    ...this.props.dosar,
                  }
                : {
                    nume: "",
                    prenume: "",
                    sex: "",
                    cnp: "",
                    numeCasatorie: "",
                    initialaTatalui: "",
                    dataNasterii: null,
                    stareCivila: "",
                    nationalitate: "",
                    cetatenie: "",
                    loculNasterii: "",
                    domiciliuStabil: "",
                    strada: "",
                    numar: "",
                    codPostal: "",
                    bloc: "",
                    scara: "",
                    etaj: "",
                    apartament: "",
                    serieCarteIdentitate: "",
                    nrCarteIdentitate: "",
                    dataEliberarii: "",
                    seriePasaport: "",
                    anEliberarePasaport: "",
                    valabilitatePasaport: "",
                    telefonMobil: "",
                    telefonFix: "",
                    institutieInvatamantSuperior: "",
                    tara: "",
                    localitatea: "",
                    facultatea: "",
                    domeniul: "",
                    profilul: "",
                    anulAbsolvirii: "",
                    durata: "",
                    titluObtinut: "",
                    nrDiplomaLicenta: "",
                    nrFoaieMatricolaLicenta: "",
                    institutieInvatamantSuperiorMaster: "",
                    taraMaster: "",
                    localitateaMaster: "",
                    facultateaMaster: "",
                    domeniulMaster: "",
                    profilulMaster: "",
                    anulAbsolviriiMaster: "",
                    durataMaster: "",
                    titluObtinutMaster: "",
                    nrDiploma: "",
                    nrFoaieMatricola: "",
                    anulInscrieriiDoctorat: "",
                    formaDoctoratCuBursa: false,
                    formaDoctoratFaraBursa: false,
                    formaDoctoratCuTaxa: false,
                    formaDoctoratAltele: false,
                    conducatorDoctorat: "",
                    domeniulDeDoctorat: "",
                    intrebareStudiiUTCB: "",
                    perioadaStudiiDoctoratBugetUTCB: "",
                    intrebareStudiiAltaUniversitate: "",
                    perioadaStudiiDoctoratBuget: "",
                    limbaStraina: "",
                    certificatCompetenteLingvistice: "",
                    cazareCamin: "",
                    acceptDeclaratie: "",
                    ...dosar,
                  }
            }
          />
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente || []}
            tipuriDocumente={tipuriDoc}
            name="inscrisi"
            type="edit"
          />
        );
      case 2:
        return <Payments dosar={dosar} />;
      default:
        return "Unknown Tab";
    }
  };

  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab,
    });
  };

  handleBackClick = () => {
    this.props.history.push("/inscrisi");
  };

  handleOpenDialog = (dialogType) => () => {
    if (dialogType === "reject") {
      this.setState({
        isRejectDialogOpen: true,
      });
    } else {
      this.setState({
        isValidateDialogOpen: true,
      });
    }
  };

  closeDialog = (dialogType) => () => {
    if (dialogType === "reject") {
      this.setState({
        isRejectDialogOpen: false,
      });
    } else {
      this.setState({
        isValidateDialogOpen: false,
      });
    }
  };

  handleReject = async (values) => {
    const { dosar, match } = this.props;
    await this.props.handleReject(dosar, values);
    await this.props.fetchInscrisById(match.params.dosarId);
    this.closeDialog("reject")();
  };

  handleValidate = async () => {
    const { dosar, match } = this.props;
    await this.props.handleValidate(dosar);
    await this.props.fetchInscrisById(match.params.dosarId);
    this.closeDialog("validate")();
  };

  render() {
    const { currentTab, isRejectDialogOpen, isValidateDialogOpen } = this.state;
    const { dosar } = this.props;
    // if (!dosar.email) {
    //   return <CircularProgress />;
    // }
    return (
      <div>
        <div className="lvd-spacer20" />
        {!this.props.stayAfterEdit && (
          <div className="lvd-control-buttons">
            <Button
              onClick={this.handleBackClick}
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
            >
              <ArrowBackIos />
              Inapoi
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
              onClick={this.handleExport}
            >
              <CloudDownloadIcon />
              Exporta
            </Button>
          </div>
        )}
        <div>Status: {getReadableStatus(dosar.status)}</div>
        <div className="lvd-spacer20" />
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            value={currentTab}
            onChange={this.handleChangeTab}
          >
            <Tab label="Fisa" />
            <Tab label="Documente" />
            <Tab label="Plata" />
          </Tabs>
        </AppBar>
        <Paper className="lvd-container-form">
          {(dosar.status === "plataValidata" ||
            dosar.status === "finalizat") && (
            <div className="lvd-validate-reject-buttons">
              <FormControlLabel
                control={
                  <Switch
                    onChange={this.handleFinalizeDosar}
                    checked={dosar.status === "finalizat"}
                    // value={dosar.status === "finalizat"}
                    style={{ marginRight: "16px" }}
                    variant="contained"
                    color="primary"
                  ></Switch>
                }
                label="Finalizeaza"
              >
                Finalizat
              </FormControlLabel>
            </div>
          )}
          {dosar.status === "trimisCatreValidare" && (
            <div className="lvd-validate-reject-buttons">
              {dosar.areToateDocumenteleObligatoriiValidate && (
                <Button
                  onClick={this.handleOpenDialog("validate")}
                  style={{ marginRight: "16px" }}
                  variant="contained"
                  color="primary"
                >
                  Valideaza
                </Button>
              )}
              <Button
                onClick={this.handleOpenDialog("reject")}
                variant="contained"
                color="secondary"
              >
                Respinge
              </Button>
            </div>
          )}
          {this.renderTabContent(dosar)}
        </Paper>
        <Dialog
          open={isValidateDialogOpen}
          fullWidth
          maxWidth="xs"
          onClose={this.closeDialog("validate")}
        >
          <DialogContent>
            Esti sigur ca vrei sa validezi acest dosar?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.closeDialog("validate")}
            >
              Renunta
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleValidate}
            >
              Valideaza
            </Button>
          </DialogActions>
        </Dialog>
        <Formik
          onSubmit={this.handleReject}
          initialValues={{ motiv: "" }}
          validationSchema={yup.object().shape({
            motiv: yup.string().required("Camp obligatoriu"),
          })}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            handleSubmit,
          }) => (
            <Dialog
              open={isRejectDialogOpen}
              onClose={this.closeDialog("reject")}
              fullWidth
              maxWidth="xs"
            >
              <DialogContent>
                <Form>
                  <TextField
                    label="Motiv respingere"
                    fullWidth
                    name="motiv"
                    error={!!errors.motiv && !!touched.motiv}
                    FormHelperTextProps={{ error: true }}
                    helperText={
                      !!errors.motiv && !!touched.motiv && errors.motiv
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.motivRespingere}
                  />
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="default"
                  onClick={this.closeDialog("reject")}
                >
                  Renunta
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Respinge
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations["ro"],
  jwt: getToken(state),
  nomenclatoare: state.nomenclatoare,
  dosar: getInscrisById(state),
  tipuriDeDocumente: getTipuriDeDocumente(state),
});

const mapDispatchToProps = {
  fetchNomenclatoarePublice,
  fetchInscrisById,
  fetchTipuriDeDocumente,
  updateInscris,
  setLanguage,
  handleReject,
  handleValidate,
  showSnackbar,
  finalizeDosar,
  setAppBarTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(InscrisiEdit);
