import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import request from 'superagent';
import { showSnackbar } from '../../actions';
import { serverUrl } from '../../utils';
import { getToken } from '../../reducers/authReducer';

class EmailSelect extends Component {
  static propTypes = {
    handleNext: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
    jwt: PropTypes.string.isRequired,
  };
  state = { email: '' };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    try {
      const res = await request
        .post(`${serverUrl}/users/email`)
        .set('Authorization', `Bearer ${this.props.jwt}`)
        .send({ email });
      this.props.handleNext(res.body.user);
    } catch (e) {
      this.props.showSnackbar(e, 'error');
    }
  };
  render() {
    const { email } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div style={{ paddingBottom: '20px' }}>
            <TextField
              name="email"
              label="Email"
              onChange={this.handleChange}
              value={email}
              fullWidth
            />
          </div>
          <Button variant="contained" color="primary" onClick={this.handleSubmit}>
            Salveaza
          </Button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  jwt: getToken(state),
});

export default connect(
  mapStateToProps,
  { showSnackbar },
)(EmailSelect);
