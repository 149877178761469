import request from 'superagent';
import * as TYPES from '../types';
import { serverUrl } from '../utils';
import { showSnackbar } from './snackbar';

const fetchTipuriDeDocumenteRequest = () => ({
  type: TYPES.TIPURIDEDOCUMENTE_FETCH_REQUEST,
});
const fetchTipuriDeDocumenteSuccess = payload => ({
  type: TYPES.TIPURIDEDOCUMENTE_FETCH_SUCCESS,
  payload,
});
const fetchTipuriDeDocumenteFailure = error => ({
  type: TYPES.TIPURIDEDOCUMENTE_FETCH_FAILURE,
  error,
});

export const fetchTipuriDeDocumente = () => async (dispatch) => {
  dispatch(fetchTipuriDeDocumenteRequest());
  try {
    const res = await request.get(`${serverUrl}/tipuridedocumente`);
    dispatch(fetchTipuriDeDocumenteSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(fetchTipuriDeDocumenteFailure(e));
  }
};

const deleteTipDeDocumentRequest = payload => ({
  type: TYPES.TIPURIDEDOCUMENTE_DELETE_REQUEST,
  payload,
});
const deleteTipDeDocumentSuccess = payload => ({
  type: TYPES.TIPURIDEDOCUMENTE_DELETE_SUCCESS,
  payload,
});
const deleteTipDeDocumentFailure = (payload, error) => ({
  type: TYPES.TIPURIDEDOCUMENTE_DELETE_FAILURE,
  payload,
  error,
});

export const deleteTipDeDocument = ({ id, type, entityName }) => async (dispatch) => {
  dispatch(deleteTipDeDocumentRequest({ id, type, entityName }));
  try {
    await request.delete(`${serverUrl}/tipuridedocumente`).send({ id, type });
    dispatch(deleteTipDeDocumentSuccess({ id, type, entityName }));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(deleteTipDeDocumentFailure({ id, type, entityName }, e));
  }
};

const addTipDeDocumentRequest = payload => ({
  type: TYPES.TIPURIDEDOCUMENTE_ADD_REQUEST,
  payload,
});
const addTipDeDocumentSuccess = payload => ({
  type: TYPES.TIPURIDEDOCUMENTE_ADD_SUCCESS,
  payload,
});
const addTipDeDocumentFailure = (payload, error) => ({
  type: TYPES.TIPURIDEDOCUMENTE_ADD_FAILURE,
  payload,
  error,
});

export const addTipDeDocument = payload => async (dispatch) => {
  dispatch(addTipDeDocumentRequest(payload));
  try {
    const res = await request.post(`${serverUrl}/tipuridedocumente`).send(payload);
    dispatch(addTipDeDocumentSuccess({ ...payload, ...res.body }));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(addTipDeDocumentFailure(payload, e));
  }
};

const editTipDeDocumentRequest = payload => ({
  type: TYPES.TIPURIDEDOCUMENTE_EDIT_REQUEST,
  payload,
});
const editTipDeDocumentSuccess = payload => ({
  type: TYPES.TIPURIDEDOCUMENTE_EDIT_SUCCESS,
  payload,
});
const editTipDeDocumentFailure = (payload, error) => ({
  type: TYPES.TIPURIDEDOCUMENTE_EDIT_FAILURE,
  payload,
  error,
});

export const editTipDeDocument = payload => async (dispatch) => {
  dispatch(editTipDeDocumentRequest(payload));
  try {
    const res = await request.put(`${serverUrl}/tipuridedocumente`).send(payload);
    dispatch(editTipDeDocumentSuccess({ ...payload, ...res.body }));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(editTipDeDocumentFailure(payload, e));
  }
};
