import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FolderShared from "@material-ui/icons/FolderShared";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import CloudDownload from "@material-ui/icons/CloudDownload";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Settings from "@material-ui/icons/Settings";
import mainLogo from "../../svg/logo-utcb.svg";
import { getIsAdmin, getRole } from "../../reducers/authReducer";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflow: "auto",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

const getLinksByRole = (role) => {
  switch (role) {
    case "administrator":
      return [
        {
          name: "Dosare",
          icon: FolderShared,
          children: [
            {
              name: "Inscrisi",
              path: "/inscrisi",
            },
            {
              name: "Doctoranzi",
              path: "/doctoranzi",
            },
            {
              name: "Conducatori de doctorat",
              path: "/conducatori",
            },
            {
              name: "Candidati la abilitare",
              path: "/candidati",
            },
          ],
        },
        {
          name: "Review",
          icon: LibraryBooks,
          children: [
            {
              name: "Review articole",
              path: "/review-articole",
            },
            {
              name: "Review competitii",
              path: "/review-competitii",
            },
          ],
        },
        {
          name: "Publicatii",
          icon: LibraryBooks,
          path: "/publicatii",
        },
        {
          name: "E-learning",
          icon: CloudDownload,
          path: "/elearning",
        },
        {
          name: "Videoconferinte",
          icon: PlayCircleFilled,
          path: "/videoconferinte",
        },
        {
          name: "Evaluari",
          icon: CheckCircleOutline,
          path: "/evaluari",
        },
        {
          name: "Setari",
          icon: Settings,
          children: [
            {
              name: "Nomenclatoare",
              path: "/nomenclatoare",
            },
            {
              name: "Tipuri de documente",
              path: "/documente",
            },
            {
              name: "Evaluari template",
              path: "/templateuri",
            },
            {
              name: "Utilizatori",
              path: "/utilizatori",
            },
          ],
        },
      ];
    case "conducator":
      return [
        {
          name: "Actvitatea mea",
          icon: FolderShared,
          children: [
            {
              name: "Dosarul meu",
              path: "/dosar",
            },
            {
              name: "Doctoranzii mei",
              path: "/doctoranzi",
            },
          ],
        },
        {
          name: "Publicatii",
          icon: LibraryBooks,
          path: "/publicatii",
        },
        {
          name: "E-learning",
          icon: CloudDownload,
          path: "/elearning",
        },
        {
          name: "Videoconferinte",
          icon: PlayCircleFilled,
          children: [
            {
              name: "Sustineri",
              path: "/sustineri",
            },
            {
              name: "Conferinte",
              path: "/conferinte",
            },
          ],
        },
      ];
    case "candidat":
      return [
        {
          name: "Dosarul meu",
          icon: FolderShared,
          path: "/dosar",
        },
      ];
    case "doctorand":
      return [
        {
          name: "Dosarul meu",
          icon: FolderShared,
          path: "/dosar",
        },
        {
          name: "Publicatii",
          icon: LibraryBooks,
          path: "/publicatii",
        },
        {
          name: "E-learning",
          icon: CloudDownload,
          path: "/elearning",
        },
        {
          name: "Videoconferinte",
          icon: PlayCircleFilled,
          children: [
            {
              name: "Sustinerea mea",
              path: "/sustinereamea",
            },
            {
              name: "Conferinte",
              path: "/conferinte",
            },
          ],
        },
      ];
    case "secretara":
      return [
        {
          name: "Dosare",
          icon: FolderShared,
          children: [
            {
              name: "Doctoranzi",
              path: "/doctoranzi",
            },
            {
              name: "Conducatori de doctorat",
              path: "/conducatori",
            },
            {
              name: "Candidati la abilitare",
              path: "/candidati",
            },
            // {
            //   name: 'Rapoarte',
            //   path: '/rapoarte',
            // },
          ],
        },
        {
          name: "Publicatii",
          icon: LibraryBooks,
          path: "/publicatii",
        },
        {
          name: "E-learning",
          icon: CloudDownload,
          path: "/elearning",
        },
        {
          name: "Videoconferinte",
          icon: PlayCircleFilled,
          path: "/videoconferinte",
        },
        {
          name: "Evaluare",
          icon: CheckCircleOutline,
          path: "/evaluare",
        },
        {
          name: "Setari",
          icon: Settings,
          children: [
            {
              name: "Utilizatori",
              path: "/utilizatori",
            },
          ],
        },
      ];
    case "others":
      return [
        {
          name: "Publicatii",
          icon: LibraryBooks,
          path: "/publicatii",
        },
        {
          name: "E-learning",
          icon: CloudDownload,
          path: "/elearning",
        },
        {
          name: "Videoconferinte",
          icon: PlayCircleFilled,
          path: "/videoconferinte",
        },
      ];
    default:
      return [];
  }
};

class NavList extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({}).isRequired,
  };

  state = {
    mobileOpen: false,
    Dosare: true,
    Setari: true,
    Videoconferinte: true,
  };

  static getDerivedStateFromProps = (props, state) => ({
    links: getLinksByRole(props.role),
  });

  handleClick = (name) => () => {
    this.setState({
      [name]: !this.state[name],
      mobileOpen: false,
    });
  };

  render() {
    // const { classes, theme } = this.props;
    const { links } = this.state;
    return (
      <List
        component="nav"
        subheader={
          <ListSubheader
            component={React.forwardRef((props, ref) => (
              <NavLink {...props} innerRef={ref} />
            ))}
            to="/"
          >
            <img src={mainLogo} className="lvd-logo" alt="logo" />
          </ListSubheader>
        }
        className="lvd-menu-left"
      >
        {links.length === 0 ? (
          <div className="lvd-nav-progress">
            <CircularProgress />
          </div>
        ) : (
          links.map((item) => {
            if (item.children) {
              return (
                <React.Fragment key={item.name}>
                  <ListItem
                    className="lvd-nav"
                    button
                    onClick={this.handleClick(item.name)}
                  >
                    <ListItemIcon>
                      <item.icon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                    {this.state[item.name] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={this.state[item.name]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      style={{ paddingBottom: "16px" }}
                    >
                      {item.children.map((x) => (
                        <ListItem
                          onClick={this.handleClick}
                          dense
                          button
                          key={x.name}
                          component={React.forwardRef((props, ref) => (
                            <NavLink to={x.path} innerRef={ref} {...props} />
                          ))}
                          className="lvd-nav-sub"
                          style={{ paddingBottom: "4px", paddingTop: "4px" }}
                        >
                          <ListItemText
                            primary={x.name}
                            style={{ paddingLeft: "40px" }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }
            return (
              <ListItem
                TouchRippleProps={{
                  color: "red",
                }}
                className="lvd-nav"
                button
                key={item.name}
                component={React.forwardRef((props, ref) => (
                  <NavLink {...props} innerRef={ref} />
                ))}
                to={item.path}
              >
                <ListItemIcon>
                  <item.icon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            );
          })
        )}
      </List>
    );
  }
}

const mapStateToProps = (state) => ({
  // isAdmin: false,
  isAdmin: getIsAdmin(state),
  role: getRole(state),
});

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(NavList)
);
