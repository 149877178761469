import * as TYPES from '../types';

const elearningReducer = (
  state = {
    items: [],
    byId: {},
    isFetching: false,
    error: undefined,
    query: undefined,
    pageInfo: {
      pageCount: undefined,
      itemCount: undefined,
      page: undefined,
      pageSize: undefined,
    },
  },
  action,
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.CURSURI_BYID_REQUEST:
      return { ...state, isFetching: true, byId: {} };
    case TYPES.CURSURI_BYID_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.CURSURI_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CURSURI_FETCH_SUCCESS:
      return { ...state, isFetching: false, items: payload };
    case TYPES.CURSURI_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.CURSURI_SEARCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CURSURI_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        query: action.query,
        items: payload.cursuri,
        pageInfo: payload.pageInfo,
      };
    default:
      return state;
  }
};

export const getCursuri = state => state.elearning.items;
export const getCursById = state => state.elearning.byId;
export const getCursuriQuery = state => state.elearning.query;
export const getCursuriPageInfo = state => state.elearning.pageInfo;
export default elearningReducer;
