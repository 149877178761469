import * as TYPES from '../types';

const candidatiReducer = (
  state = {
    items: [],
    byId: {},
    isFetching: false,
    query: undefined,
    error: undefined,
    pageInfo: {
      pageCount: undefined,
      itemCount: undefined,
      page: undefined,
      pageSize: undefined,
    },
  },
  action,
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.CANDIDATI_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CANDIDATI_FETCH_SUCCESS:
      return { ...state, isFetching: false, items: payload };
    case TYPES.CANDIDATI_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.CANDIDATI_BYID_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CANDIDATI_BYID_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.CANDIDATI_BYID_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.CANDIDATI_ADD_SUCCESS:
      return { ...state, byId: payload };
    case TYPES.CANDIDATI_SEARCH_SUCCESS:
      return {
        ...state,
        items: payload.dosare,
        pageInfo: payload.pageInfo,
        query: action.query,
      };
    case TYPES.CANDIDATI_UPDATE_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CANDIDATI_UPDATE_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.CANDIDATI_UPDATE_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.CANDIDATI_DELETE_SUCCESS:
      return { ...state, items: state.items.filter(dosar => dosar._id !== payload) };
    default:
      return state;
  }
};

export const getCandidati = state => state.candidati.items;
export const getCandidatiQuery = state => state.candidati.query;
export const getCandidatiPageInfo = state => state.candidati.pageInfo;
export const getCandidatById = state => state.candidati.byId;
export const getIsFetchingCandidati = state => state.candidati.isFetching;

export default candidatiReducer;
