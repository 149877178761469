import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Button,
  withStyles,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { showSnackbar, login, setCandidatType } from "../actions";

const styles = () => {};

class RegisterForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    theme: PropTypes.shape({}).isRequired
  };

  state = {};

  handleSubmit = async values => {
    this.props.setCandidatType(values);
    this.props.history.push("/inscriere");
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            candidatType: "eu"
          }}
          onSubmit={this.handleSubmit}
          render={({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <FormControl component="fieldset">
                <RadioGroup
                  onChange={handleChange}
                  name="candidatType"
                  value={values.candidatType}
                >
                  <FormControlLabel
                    value="eu"
                    control={<Radio color="primary" />}
                    label={t.euCandidate}
                  />
                  <FormControlLabel
                    value="non-eu"
                    control={<Radio color="primary" />}
                    label={t.nonEuCandidate}
                  />
                </RadioGroup>
              </FormControl>

              <div className="lvd-spacer20" />
              <Button variant="contained" color="primary" type="submit">
                {t.startRegistration}
              </Button>
            </Form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang]
});

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      { showSnackbar, login, setCandidatType }
    )(RegisterForm)
  )
);
