import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'video-react/dist/video-react.css';
import { connect } from 'react-redux';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  withStyles,
  Tooltip,
  withMobileDialog,
} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import PlayArrow from '@material-ui/icons/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { Player, BigPlayButton } from 'video-react';
import { getIsAdmin } from '../../reducers/authReducer';
import { fetchConferinte, searchConferinte, deleteConferinta } from '../../actions';
import { getConferinte, getConferinteQuery } from '../../reducers/videoconferinteReducer';
import FilterModal from '../General/FilterModal';
import * as utils from '../../utils/helpers';
import DeleteModal from '../General/DeleteModal';
import { serverUrl } from '../../utils';

const styles = theme => ({
  noMaxWidth: {
    maxWidth: '800px',
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
});

class Videoconferinte extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    theme: PropTypes.shape({}).isRequired,
    classes: PropTypes.shape({}).isRequired,
  };
  state = {
    anchorEl: null,
    currentItem: {},
    filterNo: 0,
    query: undefined,
    openModal: false,
    openDeleteModal: false,
    deleteName: '',
    filter: {},
  };
  static getDerivedStateFromProps = (props, state) => ({
    filterConfig: [
      {
        id: 'titlu',
        label: 'Titlu',
        type: 'string',
        input: 'text',
      },
      {
        id: 'tip',
        label: 'Tip',
        type: 'string',
        input: 'select',
        values: ['sustinere', 'conferinta'],
      },
      {
        id: 'descriere',
        label: 'Descriere',
        type: 'string',
        input: 'text',
      },
      {
        id: 'autori',
        label: 'Autori',
        type: 'string',
        input: 'text',
      },
      {
        id: 'zi',
        label: 'Zi',
        type: 'integer',
        input: 'number',
      },
      {
        id: 'luna',
        label: 'Luna',
        type: 'integer',
        input: 'number',
      },
      {
        id: 'an',
        label: 'An',
        type: 'integer',
        input: 'number',
      },
      // {
      //   id: 'isDeleted',
      //   label: 'Sters',
      //   type: 'boolean',
      //   input: 'radio',
      //   values: [true, false],
      // },
    ],
    query: props.query,
  });
  componentDidMount() {
    this.fetchData(this.props.query);
  }
  fetchData = (query) => {
    if (query) {
      this.props.searchConferinte(query);
      this.setState({
        filterNo: utils.countFilters(query),
        query,
      });
    } else {
      this.props.fetchConferinte();
    }
  };
  openFilterModal = () => {
    this.setState({
      openModal: true,
    });
  };
  handleSetFilterNo = (filterNo) => {
    this.setState({
      filterNo,
    });
  };
  handleSetQuery = (mongoQuery) => {
    this.fetchData(mongoQuery);
  };
  handleCloseFilterModal = (action) => {
    if (action === 'reset') {
      this.setState(({ columns, filter }) => ({
        openModal: false,
        filter: {},
        columns: columns.map(column => ({ ...column, show: false })),
      }));
    } else {
      this.setState({
        openModal: false,
      });
    }
  };
  handleOpenMenu = (e, item) => {
    this.setState({
      anchorEl: e.currentTarget,
      currentItem: item,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      currentItem: {},
    });
  };
  handleViewClick = itemId => () => {
    const { _id } = this.state.currentItem;
    const id = itemId || _id;
    this.props.history.push(`/videoconferinte/vizualizeaza/${id}`);
  };
  handleEditClick = () => {
    const { _id } = this.state.currentItem;
    this.props.history.push(`/videoconferinte/editeaza/${_id}`);
  };
  handleDeleteClick = () => {};
  handleAddClick = () => {
    this.props.history.push('/videoconferinte/adauga');
  };
  handleSetFilterNo = filterNo => this.setState({ filterNo });
  handleSetQuery = (query) => {
    this.setState({
      query,
    });
  };
  handleSetQuery = (mongoQuery) => {
    this.fetchData(mongoQuery);
  };
  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
    });
  };
  handleDeleteClick = () => {
    this.setState({
      openDeleteModal: true,
      deleteName: this.props.deleteName,
    });
  };
  handleDelete = () => {
    this.props.deleteConferinta(this.state.currentItem._id);
    this.handleClose();
    this.handleCloseDeleteModal();
  };
  render() {
    const {
      theme, classes, isAdmin, conferinte, fullScreen,
    } = this.props;
    const {
      anchorEl,
      filterNo,
      filter,
      query,
      openModal,
      filterConfig,
      openDeleteModal,
      deleteName,
    } = this.state;

    // const items = [
    //   {
    //     id: 1,
    //     titlu:
    //       'Lorem ipsum titlu video sustinere publica lucrare doctorat Lorem ipsum titlu video sustinere publica lucrare doctorat Lorem ipsum titlu video sustinere publica lucrare doctorat Lorem ipsum titlu video sustinere publica lucrare doctorat',
    //     autor: 'Adrian Georgescu',
    //     dataEveniment: '12 septembrie 2018',
    //     tipEveniment: 'Sustinere',
    //     descriere: 'Lorem ipsum',
    //     image: 'https://via.placeholder.com/350x250',
    //   },
    //   {
    //     id: 2,
    //     titlu: 'Lorem ipsum titlu video sustinere publica lucrare doctorat',
    //     autor: 'Adrian Georgescu',
    //     dataEveniment: '12 septembrie 2018',
    //     tipEveniment: 'Conferinta',
    //     descriere: 'Lorem ipsum',
    //     image: 'https://via.placeholder.com/350x250',
    //   },
    // ];
    return (
      <div>
        <div className="lvd-spacer20" />
        {isAdmin && (
          <div className="lvd-control-buttons">
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
              onClick={this.handleAddClick}
            >
              <AddIcon />
              Adauga video
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
              onClick={this.openFilterModal}
            >
              <SearchIcon />
              {filterNo !== 0 ? `${filterNo} filtre active` : 'Aplica filtre'}
            </Button>
          </div>
        )}
        {conferinte.map(item => (
          <div key={item._id} className="lvd-video-item">
            <div className="lvd-video-item-left">
              {item.titlu.length > 120 ? (
                <Tooltip
                  title={item.titlu}
                  placement="bottom"
                  classes={{ tooltip: classes.noMaxWidth }}
                >
                  <div className="lvd-video-title">{`${item.titlu.slice(0, 120)}...`}</div>
                </Tooltip>
              ) : (
                <div className="lvd-video-title">{item.titlu}</div>
              )}
              <div className="lvd-video-author">{item.autor}</div>
              <div className="lvd-video-toolbar">
                {isAdmin && (
                  <IconButton
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={event => this.handleOpenMenu(event, item)}
                  >
                    <MoreVert style={{ color: theme.palette.text.primary }} />
                  </IconButton>
                )}
                <IconButton onClick={this.handleViewClick(item._id)}>
                  <PlayArrow style={{ color: theme.palette.text.primary }} />
                </IconButton>
                <div className="lvd-video-date">
                  {moment(item.dataEveniment).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
            <div className="lvd-video-item-right">
              {item.videoEmbed ? (
                <div
                  className="videoWrapper"
                  dangerouslySetInnerHTML={{ __html: item.videoEmbed }}
                />
              ) : (
                <div>
                  <Player src={`${serverUrl}/${item.videoPath}`}>
                    <BigPlayButton position="center" />
                  </Player>
                </div>
                // <img src={item.videoThumbnail} alt={`imagine-video-${item.id}`} />
              )}
            </div>
            <div className="clear" />
            <Menu
              className="lvd-options-container"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {/* <MenuItem onClick={this.handleDownloadClick}>Vezi video</MenuItem> */}
              <MenuItem onClick={this.handleViewClick()}>Vizualizeaza</MenuItem>
              {isAdmin && <MenuItem onClick={this.handleEditClick}>Editeaza</MenuItem>}
              {isAdmin && <MenuItem onClick={this.handleDeleteClick}>Sterge</MenuItem>}
            </Menu>
          </div>
        ))}
        <DeleteModal
          open={openDeleteModal}
          handleClose={this.handleCloseDeleteModal}
          name={deleteName}
          handleDelete={this.handleDelete}
        />
        <FilterModal
          filterNo={filterNo}
          returnOnlyDirty
          handleSubmit={this.handleSubmit}
          handleClose={this.handleCloseFilterModal}
          query={query}
          filter={filter}
          openModal={openModal}
          fullScreen={fullScreen}
          filterConfig={filterConfig}
          handleSetFilterNo={this.handleSetFilterNo}
          handleSetQuery={this.handleSetQuery}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: getIsAdmin(state),
  conferinte: getConferinte(state),
  query: getConferinteQuery(state),
});

export default withMobileDialog()(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  {
    fetchConferinte,
    searchConferinte,
    deleteConferinta,
  },
)(Videoconferinte)));
