import * as TYPES from '../types';

const videoconferinteReducer = (
  state = {
    items: [],
    byId: {},
    isFetching: false,
    error: undefined,
    query: undefined,
  },
  action,
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.CONFERINTE_BYID_REQUEST:
      return { ...state, isFetching: true, byId: {} };
    case TYPES.CONFERINTE_BYID_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.CONFERINTE_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CONFERINTE_FETCH_SUCCESS:
      return { ...state, isFetching: false, items: payload };
    case TYPES.CONFERINTE_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.CONFERINTE_SEARCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.CONFERINTE_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        query: action.query,
        items: payload,
      };
    case TYPES.CONFERINTE_DELETE_SUCCESS:
      return { ...state, items: state.items.filter(item => item._id !== payload) };
    default:
      return state;
  }
};

export const getConferinte = state => state.videoconferinte.items;
export const getConferintaById = state => state.videoconferinte.byId;
export const getConferinteQuery = state => state.videoconferinte.query;
export default videoconferinteReducer;
