import * as TYPES from '../types';

export const showSnackbar = (message, variant) => ({
  type: TYPES.SNACK_SHOW,
  payload: {
    title: '',
    message,
    variant,
  },
});
export const hideSnackbar = () => ({
  type: TYPES.SNACK_HIDE,
});
