import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import {
  AppBar,
  Tabs,
  Tab,
  Paper,
  Button,
  CircularProgress
} from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import * as yup from "yup";
import {
  fetchNomenclatoare,
  fetchTipuriDeDocumente,
  fetchDoctorandById,
  updateDoctorand,
  setAppBarTitle,
  fetchConducatoriForDropdown,
  showSnackbar
} from "../../actions";
import DocumentTable from "../General/DocumentTable";
import AddEditForm from "../General/AddEditForm";
import { getDoctorandById } from "../../reducers/doctoranziReducer";
import { getConducatoriForDropdown } from "../../reducers/conducatoriReducer";
import { getIsAdmin, getToken } from "../../reducers/authReducer";
import { exportDosar } from "../../utils/helpers";
import DoctoranziView from "./DoctoranziView";

class DoctoranziEdit extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchTipuriDeDocumente: PropTypes.func.isRequired,
    fetchDoctorandById: PropTypes.func.isRequired,
    fetchConducatoriForDropdown: PropTypes.func.isRequired,
    setAppBarTitle: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        dosarId: PropTypes.string
      }).isRequired
    }).isRequired,
    dosar: PropTypes.shape({
      nume: PropTypes.string,
      prenume: PropTypes.string
    }).isRequired,
    updateDoctorand: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };
  state = {
    inputs: [],
    currentTab: 0,
    isFetching: false
  };
  static getDerivedStateFromProps = (props, state) => ({
    inputs: props.isAdmin
      ? [
          {
            name: "isEditableByUser",
            label: "Poate fi editat de utilizator",
            type: "checkbox"
          },
          {
            name: "isSigilat",
            label: "Dosarul este sigilat",
            type: "checkbox"
          },
          {
            name: "email",
            label: "Email",
            type: "text",
            disabled: true,
            required: true
          },
          { name: "nrMatricol", label: "Numar matricol", type: "text" },
          {
            name: "nume",
            label: "Nume",
            type: "text",
            required: true
          },
          { name: "numeCasatorie", label: "Nume Casatorie", type: "text" },
          { name: "initialaTatalui", label: "Initiala Tatalui", type: "text" },
          {
            name: "prenume",
            label: "Prenume",
            type: "text",
            required: true
          },
          { name: "cnp", label: "CNP", type: "text" },
          { name: "dataNasterii", label: "Data nasterii", type: "datepicker" },
          { name: "loculNasterii", label: "Locul nasterii", type: "text" },
          { name: "anInmatriculare", label: "An Inmatriculare", type: "text" },
          {
            name: "cetatenie",
            label: "Cetatenie",
            type: "chips",
            options: props.nomenclatoare.cetatenii.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          { name: "telefon", label: "Telefon", type: "text" },
          {
            name: "specializari",
            label: "Specializari",
            type: "chips",
            options: props.nomenclatoare.specializari.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "domeniuDoctorat",
            label: "Domeniu Doctorat",
            type: "seleect",
            options: props.nomenclatoare.domenii.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "conducatorDoctorat",
            label: "Conducator Doctorat",
            type: "select",
            options: props.conducatoriDoctorat
          },
          {
            name: "conducatorInitial",
            label: "Conducator Initial",
            type: "select",
            options: props.conducatoriDoctorat
          },
          {
            name: "conducatorCotutela",
            label: "Conducator in cotutela",
            type: "creatableSelect",
            options: props.conducatoriDoctorat
          },
          {
            name: "transferRetrasDeces",
            label: "Transfer/Retras/Deces",
            type: "text"
          },
          {
            name: "formaDeInvatamant",
            label: "Forma de Invatamant",
            type: "select",
            options: props.nomenclatoare.formeDeInvatamant.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "situatiaScolara",
            label: "Situatia Scolara",
            type: "select",
            options: props.nomenclatoare.situatiiScolare.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "decizieExmatriculare",
            label: "OM/Decizie exmatriculare",
            type: "text"
          },
          {
            name: "limbaStrainaAdmitere",
            label: "Limba Straina Admitere",
            type: "select",
            options: props.nomenclatoare.limbiStraine.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "calificativAdmitere",
            label: "Calificativ Admitere Limba Straina",
            type: "select",
            options: props.nomenclatoare.calificative.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "comisieDeIndrumare",
            label: "Comisie de indrumare",
            type: "text"
          },
          {
            name: "disciplinaDeBaza",
            label: "Disciplina de baza",
            type: "text"
          },
          {
            name: "disciplinaOptionala1",
            label: "Disciplina Optionala 1",
            type: "select",
            options: props.nomenclatoare.discipline.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "disciplinaOptionala2",
            label: "Disciplina Optionala 2",
            type: "select",
            options: props.nomenclatoare.discipline.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "disciplinaOptionala3",
            label: "Disciplina Optionala 3",
            type: "select",
            options: props.nomenclatoare.discipline.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "denumireProiectCercetare",
            label: "Denumirea proiectului de cercetare doctorala",
            type: "text"
          },
          {
            name: "disciplinaElectiva1",
            label: "Disciplina Electiva 1",
            type: "text"
          },
          {
            name: "disciplinaElectiva2",
            label: "Disciplina Electiva 2",
            type: "text"
          },
          {
            name: "raportDeCercetare1",
            label: "Raport de cercetare 1",
            type: "text"
          },
          {
            name: "raportDeCercetare2",
            label: "Raport de cercetare 2",
            type: "text"
          },
          {
            name: "raportDeCercetare3",
            label: "Raport de cercetare 3",
            type: "text"
          },
          {
            name: "credite",
            label: "credite",
            type: "children",
            children: [
              { name: "db1Credite", label: "DB Credite", type: "number" },
              { name: "do1Credite", label: "DO1 Credite", type: "number" },
              { name: "do2Credite", label: "DO2 Credite", type: "number" },
              { name: "do3Credite", label: "DO3 Credite", type: "number" },
              { name: "de1Credite", label: "DE1 Credite", type: "number" },
              { name: "de2Credite", label: "DE2 Credite", type: "number" },
              { name: "de3Credite", label: "DE3 Credite", type: "number" },
              { name: "rc1Credite", label: "RC1 Credite", type: "number" },
              { name: "rc2Credite", label: "RC2 Credite", type: "number" },
              { name: "rc3Credite", label: "RC3 Credite", type: "number" },
              { name: "tezaCredite", label: "Teza Credite", type: "number" },
              { name: "totalCredite", label: "Total credite", type: "number" }
            ]
          },
          {
            name: "rezultate",
            label: "rezultate",
            type: "children",
            children: [
              { name: "db1Rezultate", label: "DB Rezultat", type: "number" },
              { name: "do1Rezultate", label: "DO1 Rezultat", type: "number" },
              { name: "do2Rezultate", label: "DO2 Rezultat", type: "number" },
              { name: "do3Rezultate", label: "DO3 Rezultat", type: "number" },
              { name: "de1Rezultate", label: "DE1 Rezultat", type: "number" },
              { name: "de2Rezultate", label: "DE2 Rezultat", type: "number" },
              { name: "de3Rezultate", label: "DE3 Rezultat", type: "number" },
              { name: "rc1Rezultate", label: "RC1 Rezultat", type: "number" },
              { name: "rc2Rezultate", label: "RC2 Rezultat", type: "number" },
              { name: "rc3Rezultate", label: "RC3 Rezultat", type: "number" },
              { name: "tezaRezultate", label: "Teza Rezultat", type: "number" },
              {
                name: "totalRezultate",
                label: "Total Rezultat",
                type: "number"
              }
            ]
          },
          {
            name: "medii",
            label: "Medii",
            type: "children",
            children: [
              {
                name: "mediaAnilor",
                label: "Media anilor de studiu",
                type: "number"
              },
              { name: "notaLicenta", label: "Nota licenta", type: "number" },
              {
                name: "mediaAnilorMasterat",
                label: "Media anilor de studiu masterat",
                type: "number"
              },
              { name: "notaMasterat", label: "Nota masterat", type: "number" },
              { name: "notaDoctorat", label: "Nota admitere", type: "number" }
            ]
          },
          {
            name: "situatiaTaxelorPlatiteTotal",
            label: "Situatia taxelor - Suma totala",
            type: "number"
          },
          {
            name: "situatiaTaxelorPlatite",
            type: "situatiaTaxelorPlatiteArray"
          },
          {
            name: "dePlataTaxaCrediteRestanteTotal",
            label: "De Plata Taxa Credite Restante Total",
            type: "number"
          },
          {
            name: "dePlataTaxaCrediteRestante",
            type: "dePlataTaxaCrediteRestanteArray"
          },
          {
            name: "plataCPLSauCPV",
            label: "De plata CPL sau CPV",
            type: "text"
          },
          {
            name: "comisiaDeSustinere",
            label: "Comisia de sustinere",
            type: "text"
          },
          {
            name: "dataSustinereTezaInDept",
            label: "Data sustinere teza in dept.",
            type: "datepicker"
          },
          {
            name: "dataSustinerePublica",
            label: "Data sustinere publica",
            type: "datepicker"
          },
          {
            name: "calificativObtinutDupaSustinereaPublica",
            label: "Calificativ obtinut dupa sustinerea publica",
            type: "select",
            options: props.nomenclatoare.calificative.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "linkGoogleScholar",
            label: "Link profil Google Scholar",
            type: "text"
          }
        ]
      : [
          // {
          //   name: 'email',
          //   label: 'Email',
          //   type: 'text',
          //   disabled: true,
          // },
          { name: "nume", label: "Nume", type: "text" },
          { name: "numeCasatorie", label: "Nume Casatorie", type: "text" },
          { name: "initialaTatalui", label: "Initiala Tatalui", type: "text" },
          { name: "prenume", label: "Prenume", type: "text" },
          { name: "cnp", label: "CNP", type: "text" },
          { name: "dataNasterii", label: "Data nasterii", type: "datepicker" },
          { name: "loculNasterii", label: "Locul nasterii", type: "text" },
          {
            name: "cetatenie",
            label: "Cetatenie",
            type: "chips",
            options: props.nomenclatoare.cetatenii.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          { name: "telefon", label: "Telefon", type: "text" },
          {
            name: "specializari",
            label: "Specializari",
            type: "chips",
            options: props.nomenclatoare.specializari.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "conducatorDoctorat",
            label: "Conducator Doctorat",
            type: "select",
            options: props.conducatori
          },
          // {
          //   name: 'conducatorInitial',
          //   label: 'Conducator Initial',
          //   type: 'select',
          //   options: props.conducatoriDoctorat.map(n => ({
          //     value: n._id,
          //     label: `${n.nume} ${n.prenume}`,
          //   })),
          // },
          // {
          //   name: 'conducatorCotutela',
          //   label: 'Conducator in cotutela',
          //   type: 'creatableSelect',
          //   options: props.conducatoriDoctorat.map(n => ({
          //     value: n._id,
          //     label: `${n.nume} ${n.prenume}`,
          //   })),
          // },
          // { name: 'transferRetrasDeces', label: 'Transfer/Retras/Deces', type: 'text' },
          {
            name: "formaDeInvatamantInitiala",
            label: "Forma de Invatamant Initiala",
            type: "select",
            options: props.nomenclatoare.formeDeInvatamant.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          {
            name: "formaDeInvatamantCurenta",
            label: "Forma de Invatamant Curenta",
            type: "select",
            options: props.nomenclatoare.formeDeInvatamant.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          // {
          //   name: 'situatiaScolara',
          //   label: 'Situatia Scolara',
          //   type: 'select',
          //   options: props.nomenclatoare.situatiiScolare.map(n => ({
          //     value: n._id,
          //     label: n.nume,
          //   })),
          // },
          // { name: 'decizieExmatriculare', label: 'OM/Decizie exmatriculare', type: 'text' },
          {
            name: "limbaStrainaAdmitere",
            label: "Limba Straina Admitere",
            type: "select",
            options: props.nomenclatoare.limbiStraine.map(n => ({
              value: n._id,
              label: n.nume
            }))
          },
          // {
          //   name: 'calificativAdmitere',
          //   label: 'Calificativ Admitere',
          //   type: 'select',
          //   options: props.nomenclatoare.calificative.map(n => ({
          //     value: n._id,
          //     label: n.nume,
          //   })),
          // },
          // { name: 'comisieDeIndrumare', label: 'Comisie De Indrumare', type: 'text' },
          // {
          //   name: 'disciplinaOptionala1',
          //   label: 'Disciplina Optionala 1',
          //   type: 'select',
          //   options: props.nomenclatoare.discipline.map(n => ({
          //     value: n._id,
          //     label: n.nume,
          //   })),
          // },
          // {
          //   name: 'disciplinaOptionala2',
          //   label: 'Disciplina Optionala 2',
          //   type: 'select',
          //   options: props.nomenclatoare.discipline.map(n => ({
          //     value: n._id,
          //     label: n.nume,
          //   })),
          // },
          // {
          //   name: 'disciplinaOptionala3',
          //   label: 'Disciplina Optionala 3',
          //   type: 'select',
          //   options: props.nomenclatoare.discipline.map(n => ({
          //     value: n._id,
          //     label: n.nume,
          //   })),
          // },
          // {
          //   name: 'denumireProiectCercetare',
          //   label: 'Denumirea proiectului de cercetare doctorala',
          //   type: 'text',
          // },
          // {
          //   name: 'disciplinaElectiva1',
          //   label: 'Disciplina Electiva 1',
          //   type: 'select',
          //   options: props.nomenclatoare.discipline.map(n => ({
          //     value: n._id,
          //     label: n.nume,
          //   })),
          // },
          // {
          //   name: 'disciplinaElectiva2',
          //   label: 'Disciplina Electiva 2',
          //   type: 'select',
          //   options: props.nomenclatoare.discipline.map(n => ({
          //     value: n._id,
          //     label: n.nume,
          //   })),
          // },
          // { name: 'raportDeCercetare1', label: 'Raport de cercetare 1', type: 'text' },
          // { name: 'raportDeCercetare2', label: 'Raport de cercetare 2', type: 'text' },
          // { name: 'raportDeCercetare3', label: 'Raport de cercetare 3', type: 'text' },
          // {
          //   name: 'credite',
          //   label: 'credite',
          //   type: 'children',
          //   children: [
          //     { name: 'do1', label: 'DO1', type: 'number' },
          //     { name: 'do2', label: 'DO2', type: 'number' },
          //     { name: 'do3', label: 'DO3', type: 'number' },
          //     { name: 'de1', label: 'DE1', type: 'number' },
          //     { name: 'de2', label: 'DE2', type: 'number' },
          //     { name: 'de3', label: 'DE3', type: 'number' },
          //     { name: 'rc1', label: 'RC1', type: 'number' },
          //     { name: 'rc2', label: 'RC2', type: 'number' },
          //     { name: 'rc3', label: 'RC3', type: 'number' },
          //     { name: 'teza', label: 'Teza', type: 'number' },
          //     { name: 'total', label: 'Total credite', type: 'number' },
          //   ],
          // },
          // {
          //   name: 'medii',
          //   label: 'Medii',
          //   type: 'children',
          //   children: [
          //     { name: 'mediaAnilor', label: 'Media anilor de studiu', type: 'number' },
          //     { name: 'notaLicenta', label: 'Nota licenta', type: 'number' },
          //     { name: 'mediaAnilorMasterat', label: 'Media anilor de studiu masterat', type: 'number' },
          //     { name: 'notaMasterat', label: 'Nota masterat', type: 'number' },
          //     { name: 'notaDoctorat', label: 'Nota doctorat', type: 'number' },
          //   ],
          // },
          // { name: 'situatiaTaxelorPlatite', label: 'Situatia taxelor (platite)', type: 'text' },
          // { name: 'plataCPLSauCPV', label: 'De plata CPL sau CPV', type: 'text' },
          // { name: 'dePlataTaxaCrediteRestante', label: 'De plata taxa credite restante', type: 'text' },
          // {
          //   name: 'dataSustinereTezaInDept',
          //   label: 'Data sustinere teza in dept.',
          //   type: 'datepicker',
          // },
          // { name: 'dataSustinerePublica', label: 'Data sustinere publica', type: 'datepicker' },
          {
            name: "linkGoogleScholar",
            label: "Link profil Google Scholar",
            type: "text"
          }
        ]
  });
  async componentDidMount() {
    this.setState({ isFetching: true });
    await this.props.fetchNomenclatoare();
    await this.props.fetchConducatoriForDropdown();
    if (this.props.isUserView)
      await this.props.fetchDoctorandById(this.props.dosarId);
    else await this.props.fetchDoctorandById(this.props.match.params.dosarId);
    if (this.props.dosar) {
      this.props.setAppBarTitle(
        `${this.props.dosar.nume} ${this.props.dosar.prenume}`
      );
    }
    this.setState({ isFetching: false });
  }
  componentWillUnmount() {
    this.props.setAppBarTitle(undefined);
  }

  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab
    });
  };
  handleBackClick = () => {
    this.props.history.push("/doctoranzi");
  };
  handleSubmit = async values => {
    await this.props.updateDoctorand(values);
  };
  handleExport = async () => {
    const { jwt, dosar } = this.props;
    await exportDosar(jwt, dosar._id, this.props.showSnackbar);
  };
  renderTabContent = () => {
    const { inputs, currentTab } = this.state;
    const { dosar, isAdmin } = this.props;
    switch (currentTab) {
      case 0:
        return (
          <AddEditForm
            name="doctoranzi"
            inputs={inputs}
            isViewing={!isAdmin && !dosar.isEditableByUser}
            handleSubmit={this.handleSubmit}
            validationSchema={yup.object().shape({
              email: yup.string().required("Camp obligatoriu"),
              // nrMatricol: yup.number().required('Camp obligatoriu'),
              nume: yup.string().required("Camp obligatoriu"),
              prenume: yup.string().required("Camp obligatoriu")
              // numeCasatorie: yup.string().required('Camp obligatoriu'),
              // initialaTatalui: yup.string().required('Camp obligatoriu'),
              // cnp: yup.string().required('Camp obligatoriu'),
              // anInmatriculare: yup.number().required('Camp obligatoriu'),
              // cetatenie: yup.string().required('Camp obligatoriu'),
              // loculNasterii: yup.string().required('Camp obligatoriu'),
              // dataNasterii: yup
              //   .date()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) => (originalValue === null ? undefined : value)),
              // telefon: yup.string().required('Camp obligatoriu'),
              // specializari: yup.string().required('Camp obligatoriu'),
              // conducatorDoctorat: yup.string().required('Camp obligatoriu'),
              // transferRetrasDeces: yup.string().required('Camp obligatoriu'),
              // formaDeInvatamant: yup.string().required('Camp obligatoriu'),
              // situatiaScolara: yup.string().required('Camp obligatoriu'),
              // decizieExmatriculare: yup.string().required('Camp obligatoriu'),
              // limbaStrainaAdmitere: yup.string().required('Camp obligatoriu'),
              // calificativAdmitere: yup.string().required('Camp obligatoriu'),
              // comisieDeIndrumare: yup.string().required('Camp obligatoriu'),
              // disciplinaOptionala1: yup.string().required('Camp obligatoriu'),
              // disciplinaOptionala2: yup.string().required('Camp obligatoriu'),
              // disciplinaOptionala3: yup.string().required('Camp obligatoriu'),
              // disciplinaElectiva1: yup.string().required('Camp obligatoriu'),
              // disciplinaElectiva2: yup.string().required('Camp obligatoriu'),
              // denumireProiectCercetare: yup.string().required('Camp obligatoriu'),
              // situatiaTaxelorPlatite: yup.string().required('Camp obligatoriu'),
              // plataCPLSauCPV: yup.string().required('Camp obligatoriu'),
              // dePlataTaxaCrediteRestante: yup.string().required('Camp obligatoriu'),
              // dataSustinereTezaInDept: yup
              //   .date()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) => (originalValue === null ? undefined : value)),
              // dataSustinerePublica: yup
              //   .date()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) => (originalValue === null ? undefined : value)),
              // conducatorInitial: yup.string().required('Camp obligatoriu'),
              // conducatorCotutela: yup.string().required('Camp obligatoriu'),
              // raportDeCercetare1: yup.string().required('Camp obligatoriu'),
              // raportDeCercetare2: yup.string().required('Camp obligatoriu'),
              // raportDeCercetare3: yup.string().required('Camp obligatoriu'),
              // do1: yup.number().required('Camp obligatoriu'),
              // do2: yup.number().required('Camp obligatoriu'),
              // do3: yup.number().required('Camp obligatoriu'),
              // de1: yup.number().required('Camp obligatoriu'),
              // de2: yup.number().required('Camp obligatoriu'),
              // de3: yup.number().required('Camp obligatoriu'),
              // rc1: yup.number().required('Camp obligatoriu'),
              // rc2: yup.number().required('Camp obligatoriu'),
              // rc3: yup.number().required('Camp obligatoriu'),
              // teza: yup.number().required('Camp obligatoriu'),
              // total: yup.number().required('Camp obligatoriu'),
              // mediaAnilor: yup.number().required('Camp obligatoriu'),
              // notaLicenta: yup.number().required('Camp obligatoriu'),
              // mediaAnilorMasterat: yup.number().required('Camp obligatoriu'),
              // notaMasterat: yup.number().required('Camp obligatoriu'),
              // notaDoctorat: yup.number().required('Camp obligatoriu'),
              // linkGoogleScholar: yup.string(),
            })}
            initialValues={{
              email: "",
              nrMatricol: "",
              nume: "",
              prenume: "",
              numeCasatorie: "",
              cnp: "",
              anInmatriculare: "",
              initialaTatalui: "",
              loculNasterii: "",
              cetatenie: "",
              telefon: "",
              specializari: "",
              conducatorDoctorat: "",
              transferRetrasDeces: "",
              formaDeInvatamant: "",
              situatiaScolara: "",
              decizieExmatriculare: "",
              limbaStrainaAdmitere: "",
              calificativAdmitere: "",
              comisieDeIndrumare: "",
              disciplinaOptionala1: "",
              disciplinaOptionala2: "",
              disciplinaOptionala3: "",
              disciplinaElectiva1: "",
              disciplinaElectiva2: "",
              denumireProiectCercetare: "",
              situatiaTaxelorPlatiteTotal: "",
              situatiaTaxelorPlatite: [],
              plataCPLSauCPV: "",
              dePlataTaxaCrediteRestanteTotal: "",
              dePlataTaxaCrediteRestante: [],
              conducatorInitial: "",
              conducatorCotutela: "",
              raportDeCercetare1: "",
              raportDeCercetare2: "",
              raportDeCercetare3: "",
              dataSustinereTezaInDept: null,
              dataSustinerePublica: null,
              do1: "",
              do2: "",
              do3: "",
              de1: "",
              de2: "",
              rc1: "",
              rc2: "",
              rc3: "",
              teza: "",
              total: "",
              mediaAnilor: "",
              notaLicenta: "",
              mediaAnilorMasterat: "",
              notaMasterat: "",
              notaDoctorat: "",
              linkGoogleScholar: "",
              isEditableByUser: false,
              isSigilat: false,
              ...dosar
            }}
          />
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente}
            type="edit"
            name="doctoranzi"
          />
        );
      default:
        return "Unknown tab";
    }
  };
  render() {
    const { currentTab, isFetching } = this.state;
    const { dosar, isAdmin } = this.props;
    if (isFetching) {
      return <CircularProgress />;
    }
    if (isAdmin || dosar.isEditableByUser) {
      return (
        <div>
          <div className="lvd-spacer20" />
          {!this.props.stayAfterEdit && (
            <div className="lvd-control-buttons">
              <Button
                onClick={this.handleBackClick}
                variant="contained"
                color="primary"
                className="lvd-button-green-text"
              >
                <ArrowBackIos />
                Inapoi
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="lvd-button-green-text"
                onClick={this.handleExport}
              >
                <CloudDownloadIcon />
                Exporta
              </Button>
            </div>
          )}
          <AppBar position="static" color="default">
            <Tabs
              indicatorColor="primary"
              value={currentTab}
              onChange={this.handleChangeTab}
            >
              <Tab label="Dosar" />
              <Tab label="Documente" />
            </Tabs>
          </AppBar>
          <Paper className="lvd-container-form">
            {this.renderTabContent()}
          </Paper>
        </div>
      );
    }
    return <DoctoranziView />;
  }
}
const mapStateToProps = state => ({
  nomenclatoare: state.nomenclatoare,
  conducatoriDoctorat: getConducatoriForDropdown(state),
  dosar: getDoctorandById(state),
  isAdmin: getIsAdmin(state),
  jwt: getToken(state)
});
export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchDoctorandById,
      fetchNomenclatoare,
      fetchTipuriDeDocumente,
      updateDoctorand,
      fetchConducatoriForDropdown,
      setAppBarTitle,
      showSnackbar
    }
  )(DoctoranziEdit)
);
