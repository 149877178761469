import React, { Component } from "react";
import PropTypes from "prop-types";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import classNames from "classnames";
import {
  getShowSnackbar,
  getSnackbarMessage,
  getSnackbarTitle,
  getSnackbarVariant
} from "../../reducers/snackbarReducer";
import { hideSnackbar } from "../../actions";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};
const styles = theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

const propTypes = {
  showSnackbar: PropTypes.bool.isRequired,
  snackbarMessage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  snackbarTitle: PropTypes.string.isRequired
};

class Snack extends Component {
  static propTypes = {
    hideSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.shape({}).isRequired,
    snackbarVariant: PropTypes.string
  };

  static defaultProps = {
    snackbarVariant: undefined
  };

  state = {
    message: ""
  };

  static getDerivedStateFromProps = (props, state) => {
    let message;
    if (props.snackbarMessage && props.snackbarMessage.response) {
      if (props.snackbarMessage.response.body) {
        message = props.snackbarMessage.response.body.message;
      } else {
        message = props.snackbarMessage.message;
      }
    } else if (typeof props.snackbarMessage === "string") {
      message = props.snackbarMessage;
    }
    return {
      message: message || state.message
    };
  };

  getMessage = prop => {
    if (prop && prop.response) {
      if (prop.response.body) {
        return prop.response.body.message;
      }
      return prop.message;
    }
    if (prop && prop.message) {
      return prop.message;
    }
    return "Error";
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    this.props.hideSnackbar();
  };

  render() {
    const {
      showSnackbar,
      snackbarTitle,
      classes,
      snackbarVariant
    } = this.props;
    const variant = snackbarVariant || "error";
    const Icon = variantIcon[variant];
    const { message } = this.state;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={this.handleClose}
      >
        <SnackbarContent
          open={showSnackbar}
          title={snackbarTitle}
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          }
          className={classNames(classes[variant])}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              // className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

Snack.propTypes = propTypes;

const mapStateToProps = state => ({
  showSnackbar: getShowSnackbar(state),
  snackbarMessage: getSnackbarMessage(state),
  snackbarTitle: getSnackbarTitle(state),
  snackbarVariant: getSnackbarVariant(state)
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { hideSnackbar }
  )(Snack)
);
