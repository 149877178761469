import * as TYPES from '../types';

const evaluariReducer = (
  state = {
    items: [],
    forDropdown: [],
    byId: {},
    isFetching: false,
    error: undefined,
    byCode: {},
    codDeEvaluare: -1,
    idCampanie: -1,
    query: undefined,
    evaluareFinished: false,
    pageInfo: {
      pageCount: undefined,
      itemCount: undefined,
      page: undefined,
      pageSize: undefined,
    },
  },
  action,
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.EVALUARI_FETCH_BYCODE_SUCCESS:
      return {
        ...state,
        byCode: payload,
        idCampanie: action.idCampanie,
        codDeEvaluare: action.codDeEvaluare,
      };
    case TYPES.EVALUARI_ADD_RASPUNS_SUCCESS:
      return { ...state, evaluareFinished: true };
    case TYPES.EVALUARI_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.EVALUARI_FETCH_SUCCESS:
      return { ...state, isFetching: false, items: payload };
    case TYPES.EVALUARI_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.EVALUARI_BYID_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.EVALUARI_BYID_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.EVALUARI_BYID_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.EVALUARI_ADD_SUCCESS:
      return { ...state, byId: payload };
    case TYPES.EVALUARI_UPDATE_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.EVALUARI_UPDATE_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.EVALUARI_UPDATE_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.EVALUARI_DELETE_SUCCESS:
      return { ...state, items: state.items.filter(dosar => dosar._id !== payload) };
    case TYPES.EVALUARI_SEARCH_SUCCESS:
      return {
        ...state, items: payload.evaluari, pageInfo: payload.pageInfo, query: action.query,
      };
    default:
      return state;
  }
};

export const getEvaluari = state => state.evaluari.items;
export const getEvaluariQuery = state => state.evaluari.query;
export const getEvaluariPageInfo = state => state.evaluari.pageInfo;
export const getEvaluareByCode = state => state.evaluari.byCode;
export const getEvaluareById = state => state.evaluari.byId;
export const getEvaluariForDropdown = state => state.evaluari.forDropdown;
export const getIsFetchingEvaluari = state => state.evaluari.isFetching;
export const getCurrentEvaluareInfo = state => ({
  idCampanie: state.evaluari.idCampanie,
  codDeEvaluare: state.evaluari.codDeEvaluare,
});
export const getStatusEvaluare = state => state.evaluari.evaluareFinished;

export default evaluariReducer;
