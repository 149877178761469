import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Formik, Form, FieldArray } from "formik";
import { connect } from "react-redux";
import {
  Button,
  FormControlLabel,
  Switch,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormHelperText,
  FormGroup,
  withWidth,
} from "@material-ui/core";
import TrashIcon from "@material-ui/icons/Delete";
import { DatePicker } from "@material-ui/pickers";
import { isWidthUp } from "@material-ui/core/withWidth";
import { showSnackbar } from "../../actions";
import Chips from "./Chips";
import Select from "./Select";
import CreatableSelect from "./CreatableSelect";
import FormTextField from "./FormTextField";
import OnSubmitValidationError from "./OnSubmitValidationError";

class AddEditForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({}).isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    validationSchema: PropTypes.shape({}).isRequired,
    isViewing: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    inputs: [],
    renderCustomInputs: () => {},
  };

  state = {
    anchorEl: null,
  };

  handleBackClick = () => {
    this.props.history.push(`/${this.props.name}`);
  };

  handleOpenMenu = (event, id) => {
    this.setState({
      anchorEl: event.currentTarget,
      id,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      id: null,
    });
  };

  handleDownloadClick = () => {};

  handleInvalidateClick = () => {};

  handleDeleteClick = () => {};

  onSubmitValidationError = () => {
    this.props.showSnackbar("Formular incomplet");
    // TODO: english wording as well
  };

  renderFields = (
    isViewing,
    {
      type,
      name,
      label,
      options,
      children,
      disabled,
      required,
      multiline,
      addOption,
      childrenTypes,
      captionText,
      ...rest
    },
    { handleChange, handleBlur, values, setFieldValue, errors, touched }
  ) => {
    // console.log(values);
    // console.log(errors);
    switch (type) {
      case "text":
        return (
          !rest.hide && (
            <FormTextField
              fullWidth
              key={name}
              required={required}
              error={errors[name]}
              touched={touched[name]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values[name]}
              name={name}
              label={label}
              disabled={disabled || isViewing}
              isViewing={isViewing}
              multiline={!!multiline}
              type="text"
              {...rest}
            />
          )
        );
      case "number":
        return (
          <FormTextField
            fullWidth
            key={name}
            required={required}
            error={errors[name]}
            touched={touched[name]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values[name]}
            name={name}
            margin="dense"
            label={label}
            disabled={disabled || isViewing}
            isViewing={isViewing}
            type="number"
            {...rest}
          />
        );
      case "textDelimiter":
        return (
          <div className="lvd-form-text-delimiter" key={label}>
            <Typography variant="h5">{label}</Typography>
            <Typography variant="body1">{captionText}</Typography>
          </div>
        );
      case "datepicker":
        return (
          <DatePicker
            margin="dense"
            required={required}
            disabled={isViewing}
            format="DD/MM/YYYY"
            key={name}
            openTo="year"
            fullWidth
            label={label}
            onChange={(date) => setFieldValue(name, date)}
            value={values[name]}
            name={name}
            error={!!errors[name] && !!touched[name]}
            helperText={!!errors[name] && !!touched[name] && errors[name]}
            {...rest}
          />
        );
      case "chips":
        return (
          <Chips
            key={name}
            readOnly={isViewing}
            required={required}
            value={values[name]}
            options={options}
            name={name}
            label={label}
            error={errors[name]}
            touched={touched[name]}
            onChange={setFieldValue}
            margin="dense"
            disabled={isViewing || disabled}
            {...rest}
          />
        );
      case "select":
        return (
          <Select
            disabled={isViewing || disabled}
            required={required}
            key={name}
            readOnly={isViewing}
            value={values[name]}
            options={options}
            name={name}
            label={label}
            error={errors[name]}
            touched={touched[name]}
            margin="dense"
            onChange={setFieldValue}
            {...rest}
          />
        );
      case "creatableSelect":
        return (
          <CreatableSelect
            margin="dense"
            disabled={isViewing || disabled}
            required={required}
            key={name}
            placeholder="Incepe sa scrii un nume..."
            addOption={addOption}
            readOnly={isViewing}
            value={values[name]}
            options={options}
            name={name}
            label={label}
            error={errors[name]}
            touched={touched[name]}
            onChange={setFieldValue}
            {...rest}
          />
        );
      case "radio":
        return (
          <FormControl
            component="fieldset"
            // className={classes.formControl}
          >
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={values[name]}
              onChange={handleChange}
            >
              {options.map((opt) => (
                <FormControlLabel
                  value={opt.value}
                  control={<Radio />}
                  label={opt.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "children":
        return (
          <div key={name}>
            <Grid spacing={2} container wrap="wrap">
              {children.map((child) => (
                <Grid xs={12} sm item key={child.name}>
                  {this.renderFields(
                    isViewing,
                    { ...child },
                    {
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      errors,
                      touched,
                    }
                  )}
                  {/* <FormTextField
                    type="number"
                    fullWidth
                    required={required}
                    key={child.name}
                    readOnly={isViewing}
                    name={child.name}
                    // style={{ marginRight: "5px" }}
                    margin="dense"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values[child.name]}
                    label={child.label}
                    touched={touched[child.name]}
                    error={errors[child.name]}
                  /> */}
                </Grid>
              ))}
            </Grid>
          </div>
        );
      case "checkbox":
        return (
          <FormControlLabel
            key={name}
            control={
              <Switch
                required={required}
                disabled={isViewing}
                checked={values[name]}
                onChange={(e, val) => setFieldValue(name, val)}
                value={name}
                name={name}
                color="primary"
              />
            }
            label={label}
          />
        );
      case "realCheckbox":
        return (
          <FormControl
            key={name}
            margin="dense"
            required
            error={!!errors[name] && !!touched[name]}
          >
            <FormControlLabel
              control={
                <Checkbox
                  required={required}
                  checked={values[name]}
                  disabled={isViewing}
                  onChange={(e, val) => setFieldValue(name, val)}
                  value={name}
                  name={name}
                  color="primary"
                />
              }
              label={label}
            />
            {!!errors[name] && !!touched[name] && (
              <FormHelperText error>{errors[name]}</FormHelperText>
            )}
          </FormControl>
        );
      case "multipleCheckbox":
        return (
          <FormControl component="fieldset" key={name} margin="dense" required>
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup row={isWidthUp("sm", this.props.width)}>
              {children.map((child) => (
                <FormControlLabel
                  key={child.name}
                  control={
                    <Checkbox
                      required={child.required}
                      checked={values[child.name]}
                      disabled={isViewing}
                      onChange={(e, val) => setFieldValue(child.name, val)}
                      value={values[child.name]}
                      name={child.name}
                      color="primary"
                    />
                  }
                  label={child.label}
                />
              ))}
            </FormGroup>
            {!!errors[name] && !!touched[name] && (
              <FormHelperText error>{errors[name]}</FormHelperText>
            )}
          </FormControl>
        );
      case "situatiaTaxelorPlatiteArray":
        return (
          <FieldArray
            name={name}
            key={name}
            render={(arrayHelpers) => (
              <div>
                {values[name].map((child, index) => (
                  <div key={name + index} style={{ display: "flex", flex: 1 }}>
                    <FormTextField
                      required={required}
                      // error={errors[name][index].suma}
                      // touched={touched[name][index].suma}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[name][index].suma}
                      name={`${name}.${index}.suma`}
                      label="Suma"
                      disabled={disabled || isViewing}
                      isViewing={isViewing}
                      type="number"
                      style={{ marginRight: "20px" }}
                      {...rest}
                    />
                    <FormTextField
                      required={required}
                      // error={errors[name][index].detaliiPlata}
                      // touched={touched[name][index].detaliiPlata}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[name][index].detaliiPlata}
                      name={`${name}.${index}.detaliiPlata`}
                      label="Detalii Plata"
                      disabled={disabled || isViewing}
                      isViewing={isViewing}
                      type="text"
                      {...rest}
                    />
                    <Button onClick={() => arrayHelpers.remove(index)}>
                      <TrashIcon />
                    </Button>
                  </div>
                ))}
                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      detaliiPlata: "",
                      suma: "",
                    })
                  }
                >
                  Adauga plata
                </Button>
              </div>
            )}
          />
        );
      case "dePlataTaxaCrediteRestanteArray":
        return (
          <FieldArray
            name={name}
            key={name}
            render={(arrayHelpers) => (
              <div>
                {values[name].map((child, index) => (
                  <div key={name + index} style={{ display: "flex", flex: 1 }}>
                    <FormTextField
                      required={required}
                      // error={errors[name][index].suma}
                      // touched={touched[name][index].suma}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[name][index].suma}
                      name={`${name}.${index}.suma`}
                      label="Suma"
                      disabled={disabled || isViewing}
                      isViewing={isViewing}
                      type="number"
                      style={{ marginRight: "20px" }}
                      {...rest}
                    />
                    <FormTextField
                      required={required}
                      // error={errors[name][index].detaliiPlata}
                      // touched={touched[name][index].detaliiPlata}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[name][index].detaliiPlata}
                      name={`${name}.${index}.detaliiPlata`}
                      label="Detalii Plata"
                      disabled={disabled || isViewing}
                      isViewing={isViewing}
                      type="text"
                      {...rest}
                    />
                    <Button onClick={() => arrayHelpers.remove(index)}>
                      <TrashIcon />
                    </Button>
                  </div>
                ))}
                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      detaliiPlata: "",
                      suma: "",
                    })
                  }
                >
                  Adauga plata
                </Button>
              </div>
            )}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      initialValues,
      inputs,
      validationSchema,
      isViewing,
      handleSubmit,
      saveButtonLabel,
    } = this.props;
    return (
      <div>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          render={({
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <Form>
              <OnSubmitValidationError
                callback={this.onSubmitValidationError}
              />
              {inputs.map((input) =>
                this.renderFields(isViewing, input, {
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                })
              )}
              {this.props.renderCustomInputs({
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                errors,
                touched,
              })}
              <div className="lvd-spacer10" />
              {!isViewing && (
                <Button color="primary" variant="contained" type="submit">
                  {saveButtonLabel || "Salveaza"}
                </Button>
              )}
            </Form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  showSnackbar,
};

export default withWidth()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEditForm))
);
