import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  withMobileDialog,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import FileSaver from "file-saver";
import request from "superagent";
import moment from "moment";
import {
  fetchDoctoranzi,
  fetchNomenclatoare,
  showSnackbar,
  fetchConducatori,
  searchDoctoranzi,
  deleteDoctorand
} from "../../actions";
import {
  getDoctoranzi,
  getDoctoranziQuery,
  getIsFetchingDoctoranzi,
  getDoctoranziPageInfo
} from "../../reducers/doctoranziReducer";
import { serverUrl } from "../../utils";
import { getToken } from "../../reducers/authReducer";
import TableWithFilter from "../General/TableWithFilter";
import { getConducatori } from "../../reducers/conducatoriReducer";
import { exportDosar } from "../../utils/helpers";

class DoctoranziList extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    fetchDoctoranzi: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchConducatori: PropTypes.func.isRequired,
    searchDoctoranzi: PropTypes.func.isRequired,
    deleteDoctorand: PropTypes.func.isRequired
  };
  state = {
    filterconfig: []
  };
  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchConducatori();
  }
  static getDerivedStateFromProps = (props, state) => ({
    filterConfig: [
      {
        id: "nrMatricol",
        label: "Numar matricol",
        type: "string",
        input: "text"
      },
      {
        id: "nume",
        label: "Nume",
        type: "string",
        input: "text"
      },
      {
        id: "numeCasatorie",
        label: "Nume casatorie",
        type: "string",
        input: "text"
      },
      {
        id: "prenume",
        label: "Prenume",
        type: "string",
        input: "text"
      },
      {
        id: "cnp",
        label: "CNP",
        type: "string",
        input: "text"
      },
      {
        id: "anInmatriculare",
        label: "An inmatriculare",
        type: "string",
        input: "text"
      },
      {
        id: "cetatenie.value",
        label: "Cetatenie",
        type: "string",
        input: "select",
        values: props.nomenclatoare.cetatenii.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "email",
        label: "Email",
        type: "string",
        input: "text"
      },
      {
        id: "telefon",
        label: "Telefon",
        type: "string",
        input: "text"
      },
      {
        id: "specializare.value",
        label: "Specializare",
        type: "string",
        input: "select",
        values: props.nomenclatoare.specializari.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "domeniuDoctorat.value",
        label: "Domeniu doctorat",
        type: "string",
        input: "select",
        values: props.nomenclatoare.domenii.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume} ${cv.prenume}`
            }
          ],
          []
        )
      },
      {
        id: "conducatorDoctorat.value",
        label: "Conducator doctorat",
        type: "string",
        input: "select",
        values: props.conducatori.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume} ${cv.prenume}`
            }
          ],
          []
        )
      },
      {
        id: "transferRetrasDeces",
        label: "Transfer/Retras/Deces",
        type: "string",
        input: "text"
      },
      {
        id: "formaDeInvatamantInitiala.value",
        label: "Forma de invatamant initiala",
        type: "string",
        input: "select",
        values: props.nomenclatoare.formeDeInvatamant.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "formaDeInvatamantCurenta.value",
        label: "Forma de invatamant curenta",
        type: "string",
        input: "select",
        values: props.nomenclatoare.formeDeInvatamant.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "situatiaScolara.label",
        label: "Situatia scolara",
        type: "string",
        input: "select",
        values: props.nomenclatoare.situatiiScolare.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "decizieExmatriculare",
        label: "Decizie exmatriculare",
        type: "string",
        input: "text"
      },
      {
        id: "limbaStrainaAdmitere.value",
        label: "Limba straina admitere",
        type: "string",
        input: "select",
        values: props.nomenclatoare.limbiStraine.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "calificativAdmitere.value",
        label: "Calificativ admitere limba straina",
        type: "string",
        input: "select",
        values: props.nomenclatoare.calificative.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "comisieDeIndrumare",
        label: "Comisie de indrumare",
        type: "string",
        input: "text"
      },
      {
        id: "disciplineOptionale.value",
        label: "Discipline optionale",
        type: "string",
        input: "select",
        values: props.nomenclatoare.discipline.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "disciplineElective",
        label: "Discipline elective",
        type: "string",
        input: "text"
      },
      {
        id: "denumireProiectCercetare",
        label: "Denumire proiect cercetare",
        type: "string",
        input: "text"
      },
      {
        id: "raportDeCercetare",
        label: "Raport de cercetare",
        type: "string",
        input: "text"
      },
      {
        id: "totalCredite",
        label: "Total Credite",
        type: "integer",
        input: "number"
      },
      {
        id: "totalRezultat",
        label: "Total Rezultat",
        type: "integer",
        input: "number"
      },
      {
        id: "plataCPLSauCPV",
        label: "Plata CPL sau CPV",
        type: "string",
        input: "text"
      },
      {
        id: "comisiaDeSustinere",
        label: "Comisia de sustinere",
        type: "string",
        input: "text"
      },
      {
        id: "calificativObtinutDupaSustinereaPublica.value",
        label: "Calificativ obtinut dupa sustinerea publica",
        type: "string",
        input: "select",
        values: props.nomenclatoare.calificative.reduce(
          (pv, cv) => [
            ...pv,
            {
              [cv._id]: `${cv.nume}`
            }
          ],
          []
        ),
        operators: ["equal", "not_equal"]
      },
      {
        id: "situatiaTaxelorPlatiteStatus",
        label: "Situatia taxelor platite",
        type: "string",
        input: "select",
        values: [
          {
            platit: "Platit"
          },
          { maiAreDePlata: "Mai are de plata" },
          { platitMaiMult: "A platit mai mult" }
        ],
        operators: ["equal", "not_equal"]
      },
      {
        id: "dePlataTaxaCrediteRestanteStatus",
        label: "Situatia de plata taxa credite restante",
        type: "string",
        input: "select",
        values: [
          {
            platit: "Platit"
          },
          { maiAreDePlata: "Mai are de plata" },
          { platitMaiMult: "A platit mai mult" }
        ],
        operators: ["equal", "not_equal"]
      },
      {
        id: "areToateDocumenteleObligatorii",
        label: "Are toate documentele obligatorii",
        type: "boolean",
        input: "radio",
        values: [true, false]
      },
      {
        id: "areToateDocumenteleObligatoriiValidate",
        label: "Are toate documentele obligatorii validate",
        type: "boolean",
        input: "radio",
        values: [true, false]
      },
      {
        id: "isSigilat",
        label: "Este sigilat",
        type: "boolean",
        input: "radio",
        values: [true, false]
      },
      {
        id: "isEditableByUser",
        label: "Este editabil de catre utilizator",
        type: "boolean",
        input: "radio",
        values: [true, false]
      }
      // {
      //   id: 'isDeleted',
      //   label: 'Este sters',
      //   type: 'boolean',
      //   input: 'radio',
      //   values: [true, false],
      // },
    ]
  });
  handleExportDosar = async dosarId => {
    const { jwt } = this.props;
    exportDosar(jwt, dosarId, this.props.showSnackbar);
  };
  handleExport = async query => {
    const { jwt } = this.props;
    try {
      const res = await request
        .post(`${serverUrl}/export/doctoranzi`)
        .send(query)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  handleDelete = dosarId => {
    this.props.deleteDoctorand(dosarId);
  };
  render() {
    const {
      items,
      isFetching,
      query,
      isFetchingFilters,
      pageInfo
    } = this.props;
    const { filterConfig } = this.state;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick,
      handleExportDosar
    ) => [
      {
        id: "optiuni",
        width: 65,
        resizable: false,
        Header: "Optiuni",
        accessor: row => (
          <React.Fragment>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={event => openMenu(event, row._id)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              className="lvd-options-container"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              // open={false}
              onClose={onClose}
            >
              <MenuItem onClick={handleViewClick}>Vizualizeaza</MenuItem>
              <MenuItem onClick={handleEditClick}>Editeaza</MenuItem>
              <MenuItem onClick={handleExportDosar}>Exporta</MenuItem>
              <MenuItem onClick={handleDeleteClick}>Sterge</MenuItem>
            </Menu>
          </React.Fragment>
        )
      },
      {
        accessor: "_id",
        Header: "Id",
        show: false
      },
      {
        accessor: "nrMatricol",
        Header: "Numar matricol",
        show: true
      },
      {
        accessor: "email",
        Header: "Email"
      },
      {
        accessor: "nume",
        Header: "Nume",
        show: true
      },
      {
        accessor: "prenume",
        Header: "Prenume",
        show: true
      },
      {
        accessor: "numeCasatorie",
        Header: "Nume casatorie"
      },
      {
        accessor: "cnp",
        Header: "CNP"
      },
      {
        accessor: "anInmatriculare",
        Header: "An inmatriculare",
        show: true
      },
      {
        id: "cetatenie",
        accessor: ({ cetatenie }) =>
          cetatenie.map(c => c.label).reduce((pv, cv) => `${pv} ${cv}`, ""),
        Header: "Cetatenie",
        show: true
      },
      {
        accessor: "telefon",
        Header: "Telefon"
      },
      {
        id: "specializari",
        accessor: ({ specializari }) =>
          specializari.map(s => s.label).reduce((pv, cv) => `${pv} ${cv}`, ""),
        Header: "Specializari",
        show: true
      },
      {
        accessor: "conducatorDoctorat.label",
        Header: "Conducator doctorat",
        show: true
      },
      {
        accessor: "transferRetrasDeces",
        Header: "Transfer/Retras/Deces",
        type: "dropdown"
      },
      {
        accessor: "formaDeInvatamant.label",
        Header: "Forma de invatamant"
      },
      {
        accessor: "situatiaScolara.label",
        Header: "Situatia scolara"
      },
      {
        accessor: "decizieExmatriculare",
        Header: "Decizie exmatriculare"
      },
      {
        accessor: "limbaStrainaAdmitere.label",
        Header: "Limba straina admitere"
      },
      {
        accessor: "calificativAdmitere.label",
        Header: "Calificativ admitere"
      },
      {
        accessor: "comisieDeIndrumare",
        Header: "Comisie de indrumare"
      },
      {
        accessor: "disciplinaOptionala1.label",
        Header: "Disciplina optionala 1"
      },
      {
        accessor: "disciplinaOptionala2.label",
        Header: "Disciplina optionala 2"
      },
      {
        accessor: "disciplinaOptionala3.label",
        Header: "Disciplina optionala 3"
      },
      {
        accessor: "disciplinaElectiva1.label",
        Header: "Disciplina electiva 1"
      },
      {
        accessor: "disciplinaElectiva2.label",
        Header: "Disciplina electiva 2"
      },
      {
        accessor: "denumireProiectCercetare",
        Header: "Denumire proiect cercetare"
      },
      {
        accessor: "raportDeCercetare1",
        Header: "Raport de cercetare 1"
      },
      {
        accessor: "raportDeCercetare2",
        Header: "Raport de cercetare 2"
      },
      {
        accessor: "raportDeCercetare3",
        Header: "Raport de cercetare 3"
      },
      // {
      //   accessor: 'situatiaTaxelorPlatite',
      //   Header: 'Situatia taxelor platite',
      // },
      {
        accessor: "plataCPLSauCPV",
        Header: "Plata CPL sau CPV"
      },
      // {
      //   id: 'dePlataTaxaCrediteRestante',
      //   accessor: ({dePlataTaxaCrediteRestante}) => dePlataTaxaCrediteRestante.reduce((pv,cv) => ),
      //   Header: 'De plata taxa credite restante',
      // },
      {
        Header: "Data sustinere teza in dept.",
        id: "dataSustinereTezaInDept",
        accessor: ({ dataSustinereTezaInDept }) =>
          moment(dataSustinereTezaInDept).isValid()
            ? moment(dataSustinereTezaInDept).format("DD/MM/YYYY")
            : "-"
      },
      {
        id: "dataSustinerePublica",
        Header: "Data sustinere publica",
        accessor: ({ dataSustinerePublica }) =>
          moment(dataSustinerePublica).isValid()
            ? moment(dataSustinerePublica).format("DD/MM/YYYY")
            : "-"
      }
    ];
    return (
      <TableWithFilter
        name="doctoranzi"
        baseColumns={baseColumns}
        pageInfo={pageInfo}
        query={query}
        filterConfig={filterConfig}
        fetchItems={this.props.searchDoctoranzi}
        onExport={this.handleExport}
        onDelete={this.handleDelete}
        isFetchingFilters={isFetchingFilters}
        isFetching={isFetching}
        applyFilter={this.props.searchDoctoranzi}
        addButtonText="Adauga Doctorand"
        onExportDosar={this.handleExportDosar}
        menuShowView
        menuShowEdit
        menuShowExport
        menuShowDelete
        items={items}
      />
    );
  }
}
const mapStateToProps = state => ({
  items: getDoctoranzi(state),
  isFetchingFilters: state.nomenclatoare.isFetching,
  isFetching: getIsFetchingDoctoranzi(state),
  nomenclatoare: state.nomenclatoare,
  conducatori: getConducatori(state),
  jwt: getToken(state),
  query: getDoctoranziQuery(state),
  pageInfo: getDoctoranziPageInfo(state)
});

export default withMobileDialog()(
  connect(
    mapStateToProps,
    {
      fetchDoctoranzi,
      showSnackbar,
      fetchNomenclatoare,
      fetchConducatori,
      searchDoctoranzi,
      deleteDoctorand
    }
  )(DoctoranziList)
);
