import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton, MenuItem, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TableWithFilter from "../General/TableWithFilter";
import {
  fetchTemplateuri,
  deleteTemplate,
  searchTemplateuri
} from "../../actions";
import {
  getTemplateuri,
  getIsFetchingTemplateuri,
  getTemplateuriPageInfo,
  getTemplateuriQuery
} from "../../reducers/templateuriReducer";

class TemplateList extends Component {
  static propTypes = {
    fetchTemplateuri: PropTypes.func.isRequired,
    templateuri: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    isFetching: PropTypes.bool.isRequired
  };
  state = {
    filterConfig: [
      {
        id: "titlu",
        label: "Titlu",
        type: "string",
        input: "text"
      }
    ]
  };
  handleDelete = id => {
    this.props.deleteTemplate(id);
  };
  render() {
    const { templateuri, isFetching, pageInfo, query } = this.props;
    const { filterConfig } = this.state;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick,
      handleExportDosar
    ) => [
      {
        id: "optiuni",
        Header: "Optiuni",
        width: 65,
        resizable: false,
        accessor: row => (
          <React.Fragment>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={event => openMenu(event, row._id, row)}
            >
              <MoreVertIcon />
            </IconButton>
          </React.Fragment>
        )
      },
      {
        id: "_id",
        Header: "Id",
        show: false
      },
      {
        accessor: "titlu",
        Header: "Titlu"
      }
    ];
    return (
      <div>
        <TableWithFilter
          name="templateuri"
          // isFetchingFilters={isFetchingFilters}
          isFetching={isFetching}
          query={query}
          pageInfo={pageInfo}
          onExport={this.handleExport}
          onExportDosar={this.handleExportDosar}
          addButtonText="Adauga template"
          onDelete={this.handleDelete}
          baseColumns={baseColumns}
          filterConfig={filterConfig}
          fetchItems={this.props.searchTemplateuri}
          applyFilter={this.props.searchTemplateuri}
          items={templateuri}
          menuComponent={props => {
            return (
              <Menu
                className="lvd-options-container"
                anchorEl={props.anchorEl}
                open={Boolean(props.anchorEl)}
                // open={false}
                onClose={props.onClose}
              >
                <MenuItem onClick={props.handleViewClick}>
                  Vizualizeaza
                </MenuItem>
                <MenuItem
                  disabled={props.row.asociat}
                  onClick={props.handleEditClick}
                >
                  Editeaza
                </MenuItem>
                <MenuItem
                  disabled={props.row.asociat}
                  onClick={props.handleDeleteClick}
                >
                  Sterge
                </MenuItem>
              </Menu>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  templateuri: getTemplateuri(state),
  isFetching: getIsFetchingTemplateuri(state),
  pageInfo: getTemplateuriPageInfo(state),
  query: getTemplateuriQuery(state)
});

const mapDispatchToProps = {
  fetchTemplateuri,
  deleteTemplate,
  searchTemplateuri
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateList);
