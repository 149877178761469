import { combineReducers } from "redux";
import {
  i18nState
  // , setTranslations
} from "redux-i18n";
import doctoranziReducer from "./doctoranziReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import conducatoriReducer from "./conducatoriReducer";
import candidatiReducer from "./candidatiReducer";
import nomenclatoareReducer from "./nomenclatoareReducer";
import tipuriDeDocumenteReducer from "./tipuriDeDocumenteReducer";
import elearningReducer from "./elearningReducer";
import utilizatoriReducer from "./utilizatoriReducer";
import snackbarReducer from "./snackbarReducer";
import videoconferinteReducer from "./videoconferinteReducer";
import publicatiiReducer from "./publicatiiReducer";
import templateuriReducer from "./templateuriReducer";
import inscrisiReducer from "./inscrisiReducer";
import evaluariReducer from "./evaluariReducer";

const reducers = combineReducers({
  i18nState,
  doctoranzi: doctoranziReducer,
  auth: authReducer,
  user: userReducer,
  utilizatori: utilizatoriReducer,
  inscrisi: inscrisiReducer,
  conducatori: conducatoriReducer,
  evaluari: evaluariReducer,
  candidati: candidatiReducer,
  nomenclatoare: nomenclatoareReducer,
  tipuriDeDocumente: tipuriDeDocumenteReducer,
  elearning: elearningReducer,
  videoconferinte: videoconferinteReducer,
  publicatii: publicatiiReducer,
  templateuri: templateuriReducer,
  snackbar: snackbarReducer
});

export default reducers;
