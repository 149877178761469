import React, { Component } from "react";
import PropTypes from "prop-types";
import { withMobileDialog, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FileSaver from "file-saver";
import { connect } from "react-redux";
import request from "superagent";
import {
  fetchConducatori,
  fetchNomenclatoare,
  deleteConducator,
  searchConducatori,
  showSnackbar
} from "../../actions";
import {
  getConducatori,
  getIsFetchingConducatori,
  getConducatoriQuery,
  getConducatoriPageInfo
} from "../../reducers/conducatoriReducer";
import TableWithFilter from "../General/TableWithFilter";
import { serverUrl } from "../../utils";
import { getToken } from "../../reducers/authReducer";

class ConducatoriList extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchConducatori: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    isFetching: PropTypes.bool.isRequired,
    deleteConducator: PropTypes.func.isRequired,
    searchConducatori: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired
  };
  state = {
    filterConfig: []
  };
  componentDidMount() {
    this.props.fetchNomenclatoare();
  }
  static getDerivedStateFromProps = (props, state) => ({
    filterConfig: [
      {
        id: "cnp",
        label: "CNP",
        type: "string",
        input: "text"
      },
      {
        id: "nume",
        label: "Nume",
        type: "string",
        input: "text"
      },
      {
        id: "prenume",
        label: "Prenume",
        type: "string",
        input: "text"
      },
      {
        id: "titulatura",
        label: "Titulatura",
        type: "string",
        input: "text"
      },
      {
        id: "anObtinereConducere",
        label: "An Obtinere Conducere",
        type: "integer",
        input: "number"
      },
      {
        id: "facultate.label",
        label: "Facultate",
        type: "string",
        input: "select",
        values: props.nomenclatoare.facultati.map(n => n.nume)
      },
      {
        id: "Departament",
        label: "Departament",
        type: "string",
        input: "select",
        values: props.nomenclatoare.departamente.map(n => n.nume)
      },
      {
        id: "domeniuDoctorat",
        label: "Domeniu Doctorat",
        input: "select",
        type: "string",
        values: props.nomenclatoare.domenii.map(n => n.nume)
      },
      {
        id: "specializare",
        label: "Specializare",
        type: "string",
        input: "select",
        values: props.nomenclatoare.specializari.map(n => n.nume)
      },
      {
        id: "comisiaDeAbilitare",
        label: "Comisia de abilitare",
        type: "string",
        input: "text"
      },
      {
        id: "telefon",
        label: "Telefon",
        type: "string",
        input: "text"
      },
      {
        id: "email",
        label: "Email",
        type: "string",
        input: "text"
      },
      {
        id: "linkScopus",
        label: "Link Scopus",
        type: "string",
        input: "text"
      },
      {
        id: "linkGoogleScholar",
        label: "Link Google Scholar",
        type: "string",
        input: "text"
      },
      {
        id: "linkISIWebOfScience",
        label: "Link ISI Web Of Science",
        type: "string",
        input: "text"
      },
      {
        id: "linkResearchGate",
        label: "Link Research Gate",
        type: "string",
        input: "text"
      },
      {
        id: "doctoranziInStagiu",
        label: "Doctoranzi In Stagiu",
        type: "string",
        input: "select",
        values: props.doctoranzi ? props.doctoranzi.map(n => n.nume) : []
      }
      // {
      //   id: 'isDeleted',
      //   label: 'Este sters',
      //   type: 'boolean',
      //   input: 'radio',
      //   values: [true, false],
      // },
    ]
  });
  handleDelete = dosarId => {
    this.props.deleteConducator(dosarId);
  };
  handleExport = async query => {
    const { jwt } = this.props;
    try {
      const res = await request
        .post(`${serverUrl}/export/conducatori`)
        .send(query)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  handleExportDosar = async dosarId => {
    const { jwt } = this.props;
    try {
      const res = await request
        .get(`${serverUrl}/export/conducatori/${dosarId}`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  render() {
    const {
      items,
      isFetching,
      isFetchingFilters,
      query,
      pageInfo
    } = this.props;
    const { filterConfig } = this.state;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick,
      handleExportDosar
    ) => [
      {
        id: "optiuni",
        Header: "Optiuni",
        width: 65,
        resizable: false,
        accessor: row => (
          <React.Fragment>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={event => openMenu(event, row._id)}
            >
              <MoreVertIcon />
            </IconButton>
          </React.Fragment>
        )
      },
      {
        id: "_id",
        Header: "Id",
        show: false
      },
      {
        accessor: "email",
        Header: "Email"
      },
      {
        accessor: "nume",
        Header: "Nume"
      },
      {
        accessor: "prenume",
        Header: "Prenume"
      },
      {
        accessor: "cnp",
        Header: "CNP"
      },
      {
        accessor: "titulatura",
        Header: "Titulatura"
      },
      {
        accessor: "anObtinereConducere",
        Header: "An Obtinere Conducere"
      },
      {
        id: "facultate",
        Header: "Facultate",
        accessor: "facultate.label"
      },
      {
        id: "departament",
        Header: "Departament",
        accessor: "departament.label"
      },
      {
        id: "domeniuDoctorat",
        Header: "Domeniu Doctorat",
        accessor: "domeniuDoctorat.label"
      },
      {
        id: "specializari",
        Header: "Specializari",
        accessor: item => item.specializari.map(x => `${x.label} `)
      },
      {
        accessor: "comisiaDeAbilitare",
        Header: "Comisia De Abilitare"
      },
      {
        accessor: "telefon",
        Header: "Telefon"
      },
      {
        accessor: "linkScopus",
        Header: "Link Scopus"
      },
      {
        accessor: "linkGoogleScholar",
        Header: "Link Google Scholar"
      },
      {
        accessor: "linkISIWebOfScience",
        Header: "Link ISI Web Of Science"
      },
      {
        accessor: "linkResearchGate",
        Header: "Link Research Gate"
      }
    ];
    return (
      <TableWithFilter
        name="conducatori"
        isFetchingFilters={isFetchingFilters}
        query={query}
        pageInfo={pageInfo}
        isFetching={isFetching}
        onExport={this.handleExport}
        onExportDosar={this.handleExportDosar}
        addButtonText="Adauga Conducator"
        onDelete={this.handleDelete}
        baseColumns={baseColumns}
        filterConfig={filterConfig}
        fetchItems={this.props.searchConducatori}
        applyFilter={this.props.searchConducatori}
        menuShowExport
        menuShowView
        menuShowDelete
        menuShowEdit
        items={items}
      />
    );
  }
}
const mapStateToProps = state => ({
  items: getConducatori(state),
  nomenclatoare: state.nomenclatoare,
  isFetchingFilters: state.nomenclatoare.isFetching,
  isFetching: getIsFetchingConducatori(state),
  jwt: getToken(state),
  query: getConducatoriQuery(state),
  pageInfo: getConducatoriPageInfo(state)
});

export default withMobileDialog()(
  connect(
    mapStateToProps,
    {
      fetchConducatori,
      fetchNomenclatoare,
      deleteConducator,
      searchConducatori,
      showSnackbar
    }
  )(ConducatoriList)
);
