import request from "superagent";
import FileSaver from "file-saver";
// import * as TYPES from '../types';
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";
import { getToken } from "../reducers/authReducer";
import { fetchConducatorById } from "./conducatori";
import { fetchDoctorandById } from "./doctoranzi";
import { fetchCandidatById } from "./candidati";
import { fetchInscrisById } from "./inscrisi";

export const uploadDocument = ({ entityName, dosarId, doc, name }) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    await request
      .post(`${serverUrl}/documente/${entityName}/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`)
      .attach(name, doc);
    switch (entityName) {
      case "doctoranzi":
        dispatch(fetchDoctorandById(dosarId));
        break;
      case "conducatori":
        dispatch(fetchConducatorById(dosarId));
        break;
      case "candidati":
        dispatch(fetchCandidatById(dosarId));
        break;
      case "inscrisi":
        dispatch(fetchInscrisById(dosarId));
        break;
      default:
        break;
    }
  } catch (e) {
    showSnackbar(e, "error");
  }
};

export const downloadDocument = ({ entityName, dosarId, documentId }) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/documente/${documentId}/${entityName}/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`)
      .responseType("blob");
    const { headers } = res;
    const contentDisposition = headers["content-disposition"];
    const blob = res.body;
    const fileName = contentDisposition.substring(
      contentDisposition.indexOf("=") + 2,
      contentDisposition.lastIndexOf(".")
    );
    const ext = contentDisposition.substring(
      contentDisposition.lastIndexOf(".") + 1,
      contentDisposition.length - 1
    );
    FileSaver.saveAs(blob, `${fileName}.${ext}`);
  } catch (e) {
    showSnackbar(e, "error");
  }
};

export const deleteDocument = ({ entityName, dosarId, documentId }) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    await request
      .delete(`${serverUrl}/documente/${documentId}/${entityName}/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`);
    switch (entityName) {
      case "doctoranzi":
        dispatch(fetchDoctorandById(dosarId));
        break;
      case "conducatori":
        dispatch(fetchConducatorById(dosarId));
        break;
      case "candidati":
        dispatch(fetchCandidatById(dosarId));
        break;
      case "inscrisi":
        dispatch(fetchInscrisById(dosarId));
        break;
      default:
        break;
    }
  } catch (e) {
    showSnackbar(e, "error");
  }
};

export const validateDocument = ({ entityName, dosarId, documentId }) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    await request
      .get(
        `${serverUrl}/documente/${documentId}/${entityName}/${dosarId}/validate`
      )
      .set("Authorization", `Bearer ${jwt}`);
    switch (entityName) {
      case "doctoranzi":
        dispatch(fetchDoctorandById(dosarId));
        break;
      case "conducatori":
        dispatch(fetchConducatorById(dosarId));
        break;
      case "candidati":
        dispatch(fetchCandidatById(dosarId));
        break;
      case "inscrisi":
        dispatch(fetchInscrisById(dosarId));
        break;
      default:
        break;
    }
  } catch (e) {
    showSnackbar(e, "error");
  }
};
export const invalidateDocument = ({
  entityName,
  dosarId,
  documentId,
}) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await request
      .get(
        `${serverUrl}/documente/${documentId}/${entityName}/${dosarId}/invalidate`
      )
      .set("Authorization", `Bearer ${jwt}`);
    switch (entityName) {
      case "doctoranzi":
        dispatch(fetchDoctorandById(dosarId));
        break;
      case "conducatori":
        dispatch(fetchConducatorById(dosarId));
        break;
      case "candidati":
        dispatch(fetchCandidatById(dosarId));
        break;
      case "inscrisi":
        dispatch(fetchInscrisById(dosarId));
        break;
      default:
        break;
    }
  } catch (e) {
    showSnackbar(e, "error");
  }
};
