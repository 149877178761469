import request from 'superagent';
import _ from 'lodash';
import * as TYPES from '../types';
import { serverUrl } from '../utils';
import { showSnackbar } from './snackbar';
import { getToken, getIsAdmin } from '../reducers/authReducer';
import history from '../history';

const fetchTemplateuriRequest = () => ({
  type: TYPES.TEMPLATEURI_FETCH_REQUEST,
});
const fetchTemplateuriSuccess = payload => ({
  type: TYPES.TEMPLATEURI_FETCH_SUCCESS,
  payload,
});
const fetchTemplateuriFailure = error => ({
  type: TYPES.TEMPLATEURI_FETCH_FAILURE,
  error,
});

export const fetchTemplateuri = () => async (dispatch, getState) => {
  dispatch(fetchTemplateuriRequest());
  const jwt = getToken(getState());
  try {
    const res = await request.get(`${serverUrl}/templateuri`).set('Authorization', `Bearer ${jwt}`);
    dispatch(fetchTemplateuriSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(fetchTemplateuriFailure(e));
  }
};

const requestTemplateById = () => ({
  type: TYPES.TEMPLATEURI_BYID_REQUEST,
});
const successTemplateById = payload => ({
  type: TYPES.TEMPLATEURI_BYID_SUCCESS,
  payload,
});
const failTemplateById = error => ({
  type: TYPES.TEMPLATEURI_BYID_FAIL,
  error,
});
export const fetchTemplateById = templateId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestTemplateById());
  try {
    const res = await request
      .get(`${serverUrl}/templateuri/${templateId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successTemplateById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failTemplateById(e));
  }
};
const requestUpdateTemplate = () => ({
  type: TYPES.TEMPLATEURI_UPDATE_REQUEST,
});
const successUpdateTemplate = payload => ({
  type: TYPES.TEMPLATEURI_UPDATE_SUCCESS,
  payload,
});
const failUpdateTemplate = error => ({
  type: TYPES.TEMPLATEURI_UPDATE_FAIL,
  error,
});

export const updateTemplate = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateTemplate());
  try {
    const res = await request
      .put(`${serverUrl}/templateuri/${dosar._id}`)
      .set('Authorization', `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successUpdateTemplate(res.body));
    dispatch(showSnackbar('Editare salvata cu succes', 'success'));
    const isAdmin = getIsAdmin(getState());
    if (isAdmin) history.push('/templateuri');
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failUpdateTemplate(e));
  }
};
const requestAddTemplate = () => ({
  type: TYPES.TEMPLATEURI_ADD_REQUEST,
});
const successAddTemplate = payload => ({
  type: TYPES.TEMPLATEURI_ADD_SUCCESS,
  payload,
});
const failAddTemplate = error => ({
  type: TYPES.TEMPLATEURI_ADD_FAIL,
  error,
});

export const addTemplate = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestAddTemplate());
  try {
    const res = await request
      .post(`${serverUrl}/templateuri`)
      .set('Authorization', `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successAddTemplate(res.body));
    dispatch(showSnackbar('Template salvat cu succes', 'success'));
    history.push('/templateuri');
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failAddTemplate(e));
  }
};

const requestDeleteTemplate = () => ({
  type: TYPES.TEMPLATEURI_DELETE_REQUEST,
});
const successDeleteTemplate = payload => ({
  type: TYPES.TEMPLATEURI_DELETE_SUCCESS,
  payload,
});
const failDeleteTemplate = error => ({
  type: TYPES.TEMPLATEURI_DELETE_FAIL,
  error,
});
export const deleteTemplate = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeleteTemplate());
  try {
    await request
      .delete(`${serverUrl}/templateuri/${dosarId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successDeleteTemplate(dosarId));
    dispatch(showSnackbar('Template sters cu succes', 'success'));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failDeleteTemplate(e));
  }
};
export const requestSearchTemplateuri = () => ({
  type: TYPES.TEMPLATEURI_SEARCH_REQUEST,
});
export const successSearchTemplateuri = (payload, query) => ({
  type: TYPES.TEMPLATEURI_SEARCH_SUCCESS,
  payload,
  query,
});
export const failSearchTemplateuri = error => ({
  type: TYPES.TEMPLATEURI_SEARCH_FAIL,
  error,
});

export const searchTemplateuri = (query, pageInfo) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  query = _.isEmpty(query) ? undefined : query;
  dispatch(requestSearchTemplateuri());
  try {
    const res = await request
      .post(`${serverUrl}/templateuri/search`)
      .send({ query, pageInfo })
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successSearchTemplateuri(res.body, query));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failSearchTemplateuri(e));
  }
};

const fetchTemplateuriForDropdownRequest = () => ({
  type: TYPES.TEMPLATEURI_DROPDOWN_FETCH_REQUEST,
});
const fetchTemplateuriForDropdownSuccess = payload => ({
  type: TYPES.TEMPLATEURI_DROPDOWN_FETCH_SUCCESS,
  payload,
});
const fetchTemplateuriForDropdownFailure = error => ({
  type: TYPES.TEMPLATEURI_DROPDOWN_FETCH_FAILURE,
  error,
});

export const fetchTemplateuriForDropdown = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(fetchTemplateuriForDropdownRequest());
  try {
    const res = await request
      .get(`${serverUrl}/templateuri/dropdown`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(fetchTemplateuriForDropdownSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(fetchTemplateuriForDropdownFailure(e));
  }
};
