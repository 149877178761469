import * as TYPES from '../types';

const tipuriDeDocumenteReducer = (
  state = {
    doctoranzi: [],
    conducatoriDeDoctorat: [],
    candidati: [],
    isFetching: false,
    error: undefined,
  },
  action,
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.TIPURIDEDOCUMENTE_ADD_REQUEST:
      return {
        ...state,
      };
    case TYPES.TIPURIDEDOCUMENTE_ADD_SUCCESS:
      return {
        ...state,
        [payload.entityName]: [...state[payload.entityName], payload],
      };
    case TYPES.TIPURIDEDOCUMENTE_DELETE_SUCCESS:
      return {
        ...state,
        [payload.entityName]: [...state[payload.entityName].filter(val => val._id !== payload.id)],
      };
    case TYPES.TIPURIDEDOCUMENTE_EDIT_SUCCESS:
      return {
        ...state,
        [payload.entityName]: [
          ...state[payload.entityName].map(val => (val._id !== payload._id ? val : { ...val, ...payload })),
        ],
      };
    case TYPES.TIPURIDEDOCUMENTE_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.TIPURIDEDOCUMENTE_FETCH_SUCCESS:
      return { ...state, isFetching: false, ...payload };
    case TYPES.TIPURIDEDOCUMENTE_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    default:
      return state;
  }
};

export const getTipuriDeDocumente = state => state.tipuriDeDocumente;

export default tipuriDeDocumenteReducer;
