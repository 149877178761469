import request from "superagent";
import FileSaver from "file-saver";
import _ from "lodash";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";
import { getToken } from "../reducers/authReducer";
// import history from '../history';

const addDocumentToCurs = (doc, cursId) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await request
      .post(`${serverUrl}/elearning/${cursId}`)
      .set("Authorization", `Bearer ${jwt}`)
      .attach(doc.name, doc.data);
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

const fetchCursuriRequest = () => ({
  type: TYPES.CURSURI_FETCH_REQUEST
});
const fetchCursuriSuccess = payload => ({
  type: TYPES.CURSURI_FETCH_SUCCESS,
  payload
});
const fetchCursuriFailure = error => ({
  type: TYPES.CURSURI_FETCH_FAILURE,
  error
});

export const fetchCursuri = () => async (dispatch, getState) => {
  dispatch(fetchCursuriRequest());
  const jwt = getToken(getState());
  try {
    const res = await request
      .post(`${serverUrl}/elearning/search`)
      .send({})
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchCursuriSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchCursuriFailure(e));
  }
};
export const requestCursById = () => ({
  type: TYPES.CURSURI_BYID_REQUEST
});
export const successCursById = payload => ({
  type: TYPES.CURSURI_BYID_SUCCESS,
  payload
});
export const failCursById = error => ({
  type: TYPES.CURSURI_BYID_FAIL,
  error
});

export const fetchCursById = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestCursById());
  try {
    const res = await request
      .get(`${serverUrl}/elearning/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successCursById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failCursById(e));
  }
};

const requestAddCurs = () => ({
  type: TYPES.CURSURI_ADD_REQUEST
});
const successAddCurs = payload => ({
  type: TYPES.CURSURI_ADD_SUCCESS,
  payload
});
const failAddCurs = error => ({
  type: TYPES.CURSURI_ADD_FAIL,
  error
});

export const addCurs = (curs, doc) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestAddCurs());
  try {
    const res = await request
      .post(`${serverUrl}/elearning`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(curs);
    dispatch(successAddCurs(res.body));
    const cursId = res.body._id;
    if (doc) {
      await dispatch(addDocumentToCurs(doc, cursId));
    }
    dispatch(showSnackbar("Curs salvat cu succes", "success"));
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failAddCurs(e));
    return false;
  }
};

export const requestUpdateCurs = () => ({
  type: TYPES.CURSURI_UPDATE_REQUEST
});
export const successUpdateCurs = payload => ({
  type: TYPES.CURSURI_UPDATE_SUCCESS,
  payload
});
export const failUpdateCurs = error => ({
  type: TYPES.CURSURI_UPDATE_FAIL,
  error
});
export const updateCurs = (curs, doc) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateCurs());
  try {
    const res = await request
      .put(`${serverUrl}/elearning/${curs._id}`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(curs);
    if (doc) {
      dispatch(addDocumentToCurs(doc, curs._id));
    }
    dispatch(showSnackbar("Curs salvat cu success!", "success"));
    dispatch(successUpdateCurs(res.body));
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failUpdateCurs(e));
    return false;
  }
};

export const requestSearchCursuri = () => ({
  type: TYPES.CURSURI_SEARCH_REQUEST
});
export const successSearchCursuri = (payload, query) => ({
  type: TYPES.CURSURI_SEARCH_SUCCESS,
  payload,
  query
});
export const failSearchCursuri = error => ({
  type: TYPES.CURSURI_SEARCH_FAIL,
  error
});

export const searchCursuri = (query, pageInfo) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  query = _.isEmpty(query) ? undefined : query;
  dispatch(requestSearchCursuri());
  try {
    const res = await request
      .post(`${serverUrl}/elearning/search`)
      .send({ query, pageInfo })
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successSearchCursuri(res.body, query));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failSearchCursuri(e));
  }
};

const requestDeleteCurs = () => ({
  type: TYPES.CURSURI_DELETE_REQUEST
});
const successDeleteCurs = payload => ({
  type: TYPES.CURSURI_DELETE_SUCCESS,
  payload
});
const failDeleteCurs = error => ({
  type: TYPES.CURSURI_DELETE_FAIL,
  error
});
export const deleteCurs = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeleteCurs());
  try {
    await request
      .delete(`${serverUrl}/elearning/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successDeleteCurs(dosarId));
    dispatch(showSnackbar("Curs sters cu succes", "success"));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failDeleteCurs(e));
  }
};

export const downloadDocumentFromCurs = documentId => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/elearning/document/${documentId}`)
      .set("Authorization", `Bearer ${jwt}`)
      .responseType("blob");
    const { headers } = res;
    const contentDisposition = headers["content-disposition"];
    const blob = res.body;
    FileSaver.saveAs(
      blob,
      contentDisposition.substr(contentDisposition.indexOf("=") + 1)
    );
  } catch (e) {
    showSnackbar(e, "error");
  }
};
