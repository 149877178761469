import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Paper, Button, CircularProgress, withStyles } from '@material-ui/core';
import moment from 'moment';
import { fetchPublicatieById, downloadDocumentFromPublicatie } from '../../actions';
import { getPublicatieById } from '../../reducers/publicatiiReducer';

const styles = () => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class ViewPublicatie extends Component {
  state = { isDownloading: false };
  componentDidMount() {
    this.props.fetchPublicatieById(this.props.match.params.publicatieId);
  }
  handleDownloadClick = async () => {
    this.setState({ isDownloading: true });
    const { publicatie } = this.props;
    await this.props.downloadDocumentFromPublicatie(publicatie.documentId._id);
    this.setState({ isDownloading: false });
  };
  handleBackClick = () => {
    this.props.history.push('/publicatii');
  };
  render() {
    const { publicatie, classes } = this.props;
    const { isDownloading } = this.state;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          {publicatie.documentId ? (
            <React.Fragment>
              <div className="lvd-upload-button">
                <div className={classes.wrapper}>
                  <Button
                    onClick={this.handleDownloadClick}
                    variant="contained"
                    color="primary"
                    disabled={isDownloading}
                    className="lvd-button-padded-icon"
                  >
                    <CloudDownloadIcon />
                    Descarca fisier{' '}
                  </Button>
                  {isDownloading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </div>
                <div className="lvd-upload-curs">
                  {publicatie.documentId.name} {publicatie.dimensiune} MB
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="lvd-upload-curs" style={{ padding: '0 24px 0 24px' }}>
              Nu are document incarcat
            </div>
          )}
        </Paper>
        <div className="lvd-normal-text-color">
          <Paper className="lvd-container-form">
            <div className="lvd-spacer20" />
            <div className="lvd-video-title">{publicatie.titlu}</div>
            <span>{publicatie.descriere}</span>
            <ul className="lvd-dialog-list">
              <li>
                <span>Autori: </span> {publicatie.autori}
              </li>
              <li>
                <span>Jurnal: </span> {publicatie.jurnal ? publicatie.jurnal.label : '-'}
              </li>
              <li>
                <span>Volum: </span> {publicatie.volum}
              </li>
              <li>
                <span>Numar: </span> {publicatie.numar}
              </li>
              <li>
                <span>Pagini: </span> {publicatie.pagini}
              </li>
              <li>
                <span>Editor: </span> {publicatie.editor}
              </li>
              <li>
                <span>Descriere: </span> {publicatie.descriere}
              </li>
              <li>
                <span>Data publicarii: </span>
                {moment(publicatie.dataPublicarii).format('DD/MM/YYYY')}
              </li>
              <li>
                <span>Dimensiune: </span>{' '}
                {publicatie.documentId ? publicatie.dimensiune : 'Fara document'}
              </li>
            </ul>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  publicatie: getPublicatieById(state),
});

const mapDispatchToProps = {
  fetchPublicatieById,
  downloadDocumentFromPublicatie,
};

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewPublicatie));
