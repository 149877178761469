import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import {
  Button,
  Typography,
  Tabs,
  AppBar,
  Tab,
  Paper,
} from "@material-ui/core";
import { showSnackbar, fetchDosarInscris } from "../../actions";
import { serverUrl, mobilpayUrl } from "../../utils";
import { getToken } from "../../reducers/authReducer";
import { getDosarInscris } from "../../reducers/inscrisiReducer";

class Plata extends Component {
  state = {
    envKey: "",
    data: "",
    currentTab: "card",
    paymentId: "",
    paymentInfo: undefined,
  };

  async componentDidMount() {
    await this.props.fetchDosarInscris();
  }
  //   const { jwt } = this.props;
  //   try {
  //     const res = await request
  //       .post(`${serverUrl}/payment/request`)
  //       .set("Authorization", `Bearer ${jwt}`)
  //       .send();
  //     this.setState({
  //       envKey: res.body.envKey,
  //       data: res.body.envData,
  //       paymentId: res.body.paymentId,
  //     });
  //   } catch (e) {
  //     this.props.showSnackbar(e, "error");
  //   }
  // }

  requestPayment = async () => {
    try {
      const { jwt } = this.props;
      const res = await request
        .post(`${serverUrl}/payment/get-payment-portal`)
        .set("Authorization", `Bearer ${jwt}`);
      console.log(res);
      this.setState({
        paymentInfo: res.body,
      });
    } catch (e) {
      this.props.showSnackbar(e.message, "error");
    }
  };

  handleChangeTab = (e, tab) => {
    this.setState({ currentTab: tab });
  };

  handleSendMail = (type) => async () => {
    const { jwt } = this.props;
    try {
      await request
        .get(`${serverUrl}/inscrisi/requestInformation?type=${type}`)
        .set("Authorization", `Bearer ${jwt}`);
      this.props.showSnackbar("Mailul a fost trimis", "success");
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  render() {
    const { envKey, data, currentTab } = this.state;
    const { t, dosar } = this.props;
    const { paymentInfo } = this.state;
    // const ed = paymentInfo && JSON.parse(paymentInfo.data.ExtraData);
    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            value={currentTab}
            onChange={this.handleChangeTab}
          >
            <Tab label={t.tabLabelCard} value="card" />
            <Tab label={t.tabLabelOP} value="op" />
            {/* <Tab label={t.tabLabelCash} value="casierie" /> */}
          </Tabs>
        </AppBar>
        <Paper className="lvd-container-form" hidden={currentTab !== "card"}>
          <Typography>{t.payByCreditCard}</Typography>
          {paymentInfo ? (
            <form method="post" action={paymentInfo.redirectUrl}>
              {Object.keys(paymentInfo.data).map((key) => (
                <input
                  style={{ display: "none" }}
                  defaultValue={paymentInfo.data[key]}
                  name={key}
                  key={key}
                />
              ))}
              {/* <input
                style={{ display: "none" }}
                name="ExtraData[test]"
                value={ed.test}
              /> */}
              <Button type="submit" variant="contained" color="primary">
                {t.payFeeContinue}
              </Button>
            </form>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={this.requestPayment}
            >
              {t.payFee}
            </Button>
          )}
          {/* <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <form method="POST" action={mobilpayUrl}>
              <input value={envKey} name="env_key" type="hidden" />
              <input value={data} name="data" type="hidden" />
              <Button variant="contained" color="primary" type="submit">
                {t.payFee}
              </Button>
            </form>
          </div> */}
        </Paper>
        <Paper className="lvd-container-form" hidden={currentTab !== "op"}>
          <Typography>
            {t.paymentId}: {dosar.paymentId}
          </Typography>
          <Typography>
            In contul UTCB: <br />
            IBAN RO06RNCB0073005630380004 <br />
            Banca BCR sector 2 <br />
            Beneficiar UTCB <br />
            Cod fiscal 4266570 <br />
            Explicatie Taxa de inscriere, Nume, Prenume Candidat, CNP
          </Typography>
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSendMail("op")}
            >
              {t.sendEmail}
            </Button>
          </div>
        </Paper>
        <Paper
          className="lvd-container-form"
          hidden={currentTab !== "casierie"}
        >
          <Typography>Payment id: {dosar.paymentId}</Typography>

          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            vel urna sed odio porttitor fermentum vel at lorem. Morbi gravida
            eget libero ac venenatis. Curabitur maximus suscipit tortor. Nam at
            urna in justo accumsan tempus eget non nulla. Curabitur sed urna
            eleifend, laoreet nunc et, porta sapien. Vivamus blandit purus
            libero, eget consectetur lacus efficitur ut. Mauris in orci eget
            felis finibus ornare ac eu massa. Quisque elementum metus mi, vitae
            mollis magna bibendum sit amet. Etiam erat turpis, ultrices ut
            consequat in, mollis a leo. Duis et risus commodo, dignissim quam
            id, eleifend mi. Vivamus aliquet dictum risus. Praesent lorem enim,
            mollis in tempus a, congue at ante. Duis auctor elementum massa in
            pulvinar. Mauris vitae sem ultrices est tristique viverra et quis
            lorem. Sed id accumsan sem, nec consectetur erat. Curabitur
            fermentum auctor diam, eget accumsan dolor hendrerit bibendum. Nam
            quis neque nec orci tempor lacinia. Cras dignissim ligula dui, sed
            lacinia risus efficitur eu. Aenean nec laoreet enim. Integer
            tincidunt interdum elit eget aliquet. Phasellus pellentesque magna
            in dui facilisis, sit amet condimentum ante malesuada. Praesent
            commodo ornare odio quis condimentum. Cras augue mi, placerat
            maximus quam sit amet, efficitur ultrices turpis. Morbi dignissim,
            sem in vehicula condimentum, elit dui bibendum leo, a lacinia metus
            elit tincidunt nibh. Curabitur posuere, nunc tristique rutrum
            consequat, lacus magna accumsan arcu, at iaculis metus libero sit
            amet nisl. Proin varius ipsum ac erat viverra faucibus. Aliquam
            varius cursus velit a posuere.
          </Typography>
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSendMail("casierie")}
            >
              {t.sendEmail}
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  dosar: getDosarInscris(state),
  jwt: getToken(state),
});

const mapDispatchToProps = {
  showSnackbar,
  fetchDosarInscris,
};

export default connect(mapStateToProps, mapDispatchToProps)(Plata);
