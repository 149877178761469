import React, { Component } from "react";
// import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  MenuItem,
  FormHelperText,
  Select,
  InputLabel,
  FormControl
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { DatePicker } from "@material-ui/pickers";
import IOSUDSelect from "../General/Select";

class VideoconferintaForm extends Component {
  render() {
    const { initialValues, doctoranziDropdown } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          titlu: "",
          descriere: "",
          autori: "",
          tip: "",
          doctorand: {},
          ...initialValues
        }}
        onSubmit={this.props.handleSubmit}
        render={({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue
        }) => (
          <Form>
            <TextField
              name="titlu"
              margin="dense"
              fullWidth
              label="Titlu"
              value={values.titlu}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.titlu && !!touched.titlu}
              FormHelperTextProps={{
                error: errors.titlu
              }}
            />
            <TextField
              multiline
              margin="dense"
              name="descriere"
              fullWidth
              label="Descriere"
              value={values.descriere}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.descriere && !!touched.descriere}
              FormHelperTextProps={{
                error: errors.descriere
              }}
            />
            <DatePicker
              margin="dense"
              format="DD/MM/YYYY"
              fullWidth
              label="Data Eveniment"
              onChange={date => setFieldValue("dataEveniment", date)}
              value={values.dataEveniment}
              name="dataEveniment"
              error={!!errors.dataEveniment && !!touched.dataEveniment}
              helperText={
                !!errors.dataEveniment &&
                !!touched.dataEveniment &&
                errors.dataEveniment
              }
            />
            {/* <TextField
      multiline
      margin="dense"
      name="dataEveniment"
      fullWidth
      label="Data Eveniment"
      value={values.dataEveniment}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!errors.dataEveniment && !!touched.dataEveniment}
      FormHelperTextProps={{
                error: errors.dataEveniment,
              }}
    /> */}
            <FormControl fullWidth margin="dense">
              <InputLabel htmlFor="tip">Tip</InputLabel>
              <Select
                margin="dense"
                name="tip"
                value={values.tip}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.tip}
              >
                <MenuItem value="conferinta">Conferinta</MenuItem>
                <MenuItem value="sustinere">Sustinere</MenuItem>
              </Select>
              {!!errors.tip && !!touched.tip && (
                <FormHelperText error>{errors.tip}</FormHelperText>
              )}
            </FormControl>
            {values.tip === "sustinere" ? (
              <IOSUDSelect
                name="doctorand"
                margin="dense"
                fullWidth
                label="Doctorand"
                value={values.doctorand}
                options={doctoranziDropdown}
                onChange={(name, value) => setFieldValue(name, value)}
                onBlur={handleBlur}
                error={!!errors.doctorand && !!touched.doctorand}
                FormHelperTextProps={{
                  error: errors.doctorand
                }}
              />
            ) : (
              <TextField
                name="autori"
                margin="dense"
                fullWidth
                label="Autori"
                value={values.autori}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.autori && !!touched.autori}
                FormHelperTextProps={{
                  error: errors.autori
                }}
              />
            )}
            <div className="lvd-spacer10" />
            <Button color="primary" variant="contained" type="submit">
              Salveaza
            </Button>
          </Form>
        )}
      />
    );
  }
}

export default VideoconferintaForm;
