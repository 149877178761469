import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import {
  Button,
  Paper,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { getConducatorById } from '../../reducers/conducatoriReducer';
import { doctoranziSetQuery, fetchConducatorById, setAppBarTitle } from '../../actions';
import DocumentTable from '../General/DocumentTable';
import DosarView from './DosarView';

const mappedNames = {
  email: { label: 'Email' },
  nume: { label: 'Nume' },
  prenume: { label: 'Prenume' },
  cnp: { label: 'CNP' },
  titulatura: { label: 'Titulatura' },
  anObtinereConducere: { label: 'An obtinere conducere' },
  facultate: { label: 'Facultate' },
  departament: { label: 'Departament' },
  domeniuDoctorat: { label: 'Domeniu doctorat' },
  specializari: { label: 'Specializari' },
  comisiaDeAbilitare: { label: 'Comisia de abilitare' },
  telefon: { label: 'Telefon' },
  linkScopus: { label: 'Link Scopus' },
  linkGoogleScholar: { label: 'Link Google Scholar' },
  linkISIWebOfScience: { label: 'Link ISI Web of Science' },
  linkResearchGate: { label: 'Link Research Gate' },
};

class ConducatoriView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        dosarId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    fetchConducatorById: PropTypes.func.isRequired,
  };
  state = {
    currentTab: 0,
  };
  async componentDidMount() {
    await this.props.fetchConducatorById(this.props.match.params.dosarId);
    this.props.setAppBarTitle(`${this.props.dosar.nume} ${this.props.dosar.prenume}`);
  }
  componentWillUnmount() {
    this.props.setAppBarTitle();
  }

  handleBackClick = () => {
    this.props.history.push('/conducatori');
  };
  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab,
    });
  };
  handleShowDoctoranziInStagiu = () => {
    this.props.doctoranziSetQuery({
      $and: [{ isSigilat: false }, { 'conducatorDoctorat.value': this.props.dosar._id }],
    });
    this.props.history.push('/doctoranzi');
  };
  renderTabContent = () => {
    const { currentTab } = this.state;
    const { dosar } = this.props;
    const rows = Object.keys(pick(dosar, [
      'email',
      'nume',
      'prenume',
      'cnp',
      'titulatura',
      'anObtinereConducere',
      'facultate',
      'departament',
      'domeniuDoctorat',
      'specializari',
      'comisiaDeAbilitare',
      'telefon',
      'linkScopus',
      'linkGoogleScholar',
      'linkISIWebOfScience',
      'linkResearchGate',
    ]));
    switch (currentTab) {
      case 0:
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Proprietate</TableCell>
                <TableCell>Valoare</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="doctoranziInStagiu">
                <TableCell>Doctoranzi in stagiu</TableCell>
                <TableCell>
                  <Button onClick={this.handleShowDoctoranziInStagiu}>Vezi lista</Button>
                </TableCell>
              </TableRow>
              <DosarView mappedNames={mappedNames} dosar={dosar} rows={rows} />
            </TableBody>
          </Table>
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente}
            type="view"
            name="conducatori"
          />
        );
      default:
        return <div>Unknown tab</div>;
    }
  };
  render() {
    const { currentTab } = this.state;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-padded-icon"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <AppBar position="static" color="default">
          <Tabs indicatorColor="primary" value={currentTab} onChange={this.handleChangeTab}>
            <Tab label="Dosar" />
            <Tab label="Documente" />
          </Tabs>
        </AppBar>
        <Paper>{this.renderTabContent()}</Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dosar: getConducatorById(state),
});

export default connect(
  mapStateToProps,
  { fetchConducatorById, setAppBarTitle, doctoranziSetQuery },
)(ConducatoriView);
