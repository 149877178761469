import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Button, Paper } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { fetchNomenclatoare, fetchCursById, updateCurs } from "../../actions";
import CursForm from "./CursForm";
import { getCursById } from "../../reducers/elearningReducer";

class EditCurs extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchCursById: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        cursId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };
  state = {
    uploaded: undefined
  };
  static getDerivedStateFromProps = (props, state) => ({
    discipline: props.discipline.map(n => ({
      value: n._id,
      label: n.nume
    })),
    categoriiELearning: props.categoriiELearning.map(n => ({
      value: n._id,
      label: n.nume
    }))
  });
  async componentDidMount() {
    this.props.fetchNomenclatoare();
    await this.props.fetchCursById(this.props.match.params.cursId);
  }
  handleBackClick = () => {
    this.props.history.push("/elearning");
  };
  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const success = await this.props.updateCurs(values, this.state.uploaded);
    setSubmitting(false);
    if (success) {
      this.props.history.push("/elearning");
    }
  };
  uploadInputRef = null;
  render() {
    const { uploaded, discipline, categoriiELearning } = this.state;
    const { curs } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <div className="lvd-upload-button">
            <Button
              onClick={() => this.uploadInputRef.open()}
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
            >
              <CloudDownloadIcon />
              {curs.documentId ? "Inlocuieste fisier" : "Incarca fisier"}
            </Button>
            <Dropzone
              multiple={false}
              ref={node => {
                this.uploadInputRef = node;
              }}
              style={{ display: "none" }}
              onDrop={(accepted, rejected) => {
                this.setState({
                  uploaded: {
                    name: accepted[0].name,
                    size: `${(accepted[0].size / 1024 ** 2).toFixed(2)} MB`,
                    data: accepted[0]
                  }
                });
              }}
            >
              <p>Drop files here.</p>
            </Dropzone>
          </div>
          {this.state.uploaded && (
            <div className="lvd-upload-curs">
              {uploaded.name} {uploaded.size}
            </div>
          )}
        </Paper>
        <Paper className="lvd-container-form">
          <CursForm
            initialValues={curs}
            categoriiELearning={categoriiELearning}
            discipline={discipline}
            onSubmit={this.handleSubmit}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.nomenclatoare,
  curs: getCursById(state)
});

export default connect(
  mapStateToProps,
  { fetchNomenclatoare, fetchCursById, updateCurs }
)(EditCurs);
