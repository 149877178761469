import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { connect } from 'react-redux';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import {
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
  withMobileDialog,
  MobileStepper,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddEditForm from '../General/AddEditForm';
import { fetchNomenclatoare, fetchTipuriDeDocumente, addConducator } from '../../actions';
import { getTipuriDeDocumente } from '../../reducers/tipuriDeDocumenteReducer';
import EmailSelect from '../General/EmailSelect';
import DocumentTable from '../General/DocumentTable';
import { getConducatorById } from '../../reducers/conducatoriReducer';

const steps = ['Adauga/Asociaza cont', 'Completeaza dosar', 'Adauga documente'];

class ConducatoriAdd extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchTipuriDeDocumente: PropTypes.func.isRequired,
    addConducator: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fullScreen: PropTypes.bool.isRequired,
  };
  state = {
    inputs: [],
    activeStep: 0,
    user: {},
  };

  static getDerivedStateFromProps = (props, state) => ({
    inputs: [
      {
        name: 'email',
        label: 'Email',
        type: 'text',
        disabled: true,
      },
      {
        name: 'nume',
        label: 'Nume',
        type: 'text',
        required: true,
      },
      {
        name: 'prenume',
        label: 'Prenume',
        type: 'text',
        required: true,
      },
      { name: 'cnp', label: 'CNP', type: 'text' },
      { name: 'titulatura', label: 'Titulatura', type: 'text' },
      { name: 'anObtinereConducere', label: 'An obtinere conducere', type: 'number' },
      {
        name: 'facultate',
        label: 'Facultate',
        type: 'select',
        options: props.nomenclatoare.facultati.map(n => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: 'departament',
        label: 'Departament',
        type: 'select',
        options: props.nomenclatoare.departamente.map(n => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: 'domeniuDoctorat',
        label: 'Domeniu Doctorat',
        type: 'select',
        options: props.nomenclatoare.domenii.map(n => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: 'specializari',
        label: 'Specializari',
        type: 'chips',
        options: props.nomenclatoare.specializari.map(n => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: 'telefon',
        label: 'Telefon',
        type: 'text',
      },
      {
        name: 'linkProfilUtcb',
        label: 'Link Profil UTCB',
        type: 'text',
      },
      {
        name: 'linkScopus',
        label: 'Link Scopus',
        type: 'text',
      },
      {
        name: 'linkGoogleScholar',
        label: 'Link Google Scholar',
        type: 'text',
      },
      {
        name: 'linkISIWebOfScience',
        label: 'Link ISI Web Of Science',
        type: 'text',
      },
      {
        name: 'linkResearchGate',
        label: 'Link Research Gate',
        type: 'text',
      },
      {
        name: 'hGoogleScholar',
        label: 'H Google Scholar',
        type: 'number',
      },
      {
        name: 'hWebOfScience',
        label: 'H Web Of Science',
        type: 'number',
      },
      {
        name: 'hScopus',
        label: 'H Scopus',
        type: 'number',
      },
    ],
    tipuriDocumente: props.tipuriDeDocumente.conducatori,
  });
  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchTipuriDeDocumente();
  }
  handleSaveUser = (user) => {
    this.setState({ user });
    this.handleNext();
  };
  handleAddDosar = async (values) => {
    await this.props.addConducator(values);
    this.handleNext();
  };
  getStepContent = (step) => {
    const { inputs, tipuriDocumente, user } = this.state;
    const { dosar } = this.props;
    switch (step) {
      case 0:
        return <EmailSelect handleNext={this.handleSaveUser} />;
      case 1:
        return (
          <AddEditForm
            name="conducatori"
            inputs={inputs}
            handleNext={this.handleNext}
            handleSubmit={this.handleAddDosar}
            validationSchema={yup.object().shape({
              nume: yup.string().required('Camp obligatoriu'),
              prenume: yup.string().required('Camp obligatoriu'),
              // cnp: yup.string().required('Camp obligatoriu'),
              // titulatura: yup.string().required('Camp obligatoriu'),
              // anObtinereConducere: yup.number().required('Camp obligatoriu'),
              // facultate: yup
              //   .object()
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value))
              //   .required('Camp obligatoriu'),
              // departament: yup
              //   .object()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // domeniuDoctorat: yup
              //   .object()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // specializari: yup
              //   .array(yup.object())
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || originalValue.length === 0 ? undefined : value)),
              // telefon: yup.string().required('Camp obligatoriu'),
              // email: yup.string().required('Camp obligatoriu'),
              // linkProfilUtcb: yup.string().required('Camp obligatoriu'),
              // linkScopus: yup.string().required('Camp obligatoriu'),
              // linkGoogleScholar: yup.string().required('Camp obligatoriu'),
              // linkISIWebOfScience: yup.string().required('Camp obligatoriu'),
              // linkResearchGate: yup.string().required('Camp obligatoriu'),
              // hGoogleScholar: yup.string().required('Camp obligatoriu'),
              // hWebOfScience: yup.string().required('Camp obligatoriu'),
              // hScopus: yup.string().required('Camp obligatoriu'),
            })}
            initialValues={{
              nume: '',
              prenume: '',
              cnp: '',
              titulatura: '',
              anObtinereConducere: '',
              facultate: {},
              departament: {},
              domeniuDoctorat: {},
              specializari: [],
              telefon: '',
              email: user.email || '',
              linkProfilUtcb: '',
              linkScopus: '',
              linkGoogleScholar: user.linkGoogleScholar || '',
              linkISIWebOfScience: '',
              linkResearchGate: '',
              hGoogleScholar: '',
              hWebOfScience: '',
              hScopus: '',
              userId: user._id,
            }}
          />
        );
      case 2:
        return (
          <DocumentTable
            dosarId={dosar._id}
            tipuriDocumente={tipuriDocumente}
            documente={dosar.documente}
            type="edit"
            name="conducatori"
          />
        );
      default:
        return 'Unknown step';
    }
  };
  handleNext = () => {
    this.setState(({ activeStep }) => ({ activeStep: activeStep + 1 }));
  };
  handleBackClick = () => {
    this.props.history.push('/conducatori');
  };
  handleNewClick = () => {
    this.setState({
      activeStep: 0,
    });
  };
  render() {
    const { activeStep } = this.state;
    const { fullScreen } = this.props;
    return (
      <React.Fragment>
        {fullScreen ? (
          <MobileStepper
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            className="lvd-mobile-stepper"
            // className={classes.mobileStepper}
          />
        ) : (
          <Stepper activeStep={activeStep} style={{ marginTop: '20px' }} className="lvd-stepper">
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Paper className="lvd-container-form">
          <div>{this.getStepContent(activeStep)}</div>
        </Paper>
        {activeStep === steps.length - 1 && (
          <div className="lvd-control-buttons">
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
              onClick={this.handleBackClick}
            >
              <ArrowBackIos />
              Inapoi la lista
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
              onClick={this.handleNewClick}
            >
              <AddIcon />
              Dosar nou
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  nomenclatoare: state.nomenclatoare,
  tipuriDeDocumente: getTipuriDeDocumente(state),
  dosar: getConducatorById(state),
});

export default withMobileDialog()(connect(
  mapStateToProps,
  { fetchNomenclatoare, fetchTipuriDeDocumente, addConducator },
)(ConducatoriAdd));
