import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import AddEditForm from '../General/AddEditForm';
import Select from '../General/Select';

class EvaluareForm extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({}),
    handleSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.shape({}),
  };
  static defaultProps = {
    initialValues: {},
    disabled: {},
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      inputs: [
        {
          name: 'titlu',
          label: 'Titlu',
          type: 'text',
          required: true,
        },
        {
          name: 'nrCoduri',
          label: 'Numar de coduri',
          type: 'number',
          required: true,
          disabled: nextProps.disabled.nrCoduri || false,
        },
        {
          name: 'dataStart',
          label: 'Data Start',
          disablePast: true,
          type: 'datepicker',
          required: true,
        },
        {
          name: 'dataEnd',
          label: 'Data End',
          disablePast: true,
          type: 'datepicker',
          required: true,
        },
        {
          name: 'disciplina',
          label: 'Disciplina',
          type: 'text',
          disabled: nextProps.disabled.disciplina || false,
        },
        {
          name: 'profAsociat',
          label: 'Prof Asociat',
          type: 'text',
          disabled: nextProps.disabled.profAsociat || false,
        },
        nextProps.isEditing
          ? {
            name: 'addCoduriEvaluare',
            label: 'Adauga coduri',
            type: 'number',
          }
          : {},
      ],
    };
  }
  state = {
    inputs: [],
  };
  render() {
    const {
      initialValues, handleSubmit, templates, isEditing,
    } = this.props;
    const { inputs } = this.state;
    return (
      <AddEditForm
        inputs={inputs}
        isViewing={false}
        name="evaluari"
        handleSubmit={handleSubmit}
        renderCustomInputs={({
 setFieldValue, values, errors, touched, handleBlur,
}) => (
  <Select
    label="Template Asociat"
    name="template"
    disabled={isEditing}
    value={values.template}
    error={errors.template}
    touched={touched.template}
    margin="dense"
    onChange={setFieldValue}
    options={templates}
  />
        )}
        validationSchema={yup.object().shape({
          titlu: yup.string().required('Camp obligatoriu'),
          nrCoduri: yup
            .number()
            .min(1, 'Minim un cod')
            .required('Camp obligatoriu'),
          dataStart: yup
            .date()
            .required('Camp obligatoriu')
            .transform((value, originalValue) => (originalValue === null ? undefined : value)),
          dataEnd: yup
            .date()
            .required('Camp obligatoriu')
            .transform((value, originalValue) => (originalValue === null ? undefined : value)),
        })}
        initialValues={{
          titlu: '',
          disciplina: '',
          profAsociat: '',
          nrCoduri: '',
          addCoduriEvaluare: 0,
          dataStart: null,
          dataEnd: null,
          ...initialValues,
        }}
      />
    );
  }
}

EvaluareForm.propTypes = {};

export default EvaluareForm;
