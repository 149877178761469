import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';

export default class DosarView extends Component {
  static propTypes = {
    rows: PropTypes.arrayOf(PropTypes.string).isRequired,
    dosar: PropTypes.shape({}).isRequired,
    mappedNames: PropTypes.shape({}).isRequired,
  };

  render() {
    const { rows, dosar, mappedNames } = this.props;
    return rows.map((row) => {
      if (mappedNames[row].accessor && !Array.isArray(dosar[row])) {
        return (
          <TableRow key={row}>
            <TableCell>{mappedNames[row].label}</TableCell>
            <TableCell>{mappedNames[row].accessor(dosar[row])}</TableCell>
          </TableRow>
        );
      }
      if (Array.isArray(dosar[row])) {
        if (dosar[row].length > 0) {
          if (mappedNames[row].accessor) {
            return mappedNames[row].accessor(dosar[row]);
          }
          return (
            <TableRow key={row}>
              <TableCell>{mappedNames[row].label}</TableCell>
              <TableCell>{dosar[row].map(x => `${x.label} `)}</TableCell>
            </TableRow>
          );
        }
        return (
          <TableRow key={row}>
            <TableCell>{mappedNames[row].label}</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
        );
      }
      if (typeof dosar[row] === 'object') {
        if (dosar[row] && dosar[row].label) {
          return (
            <TableRow key={row}>
              <TableCell>{mappedNames[row].label}</TableCell>
              <TableCell>{dosar[row].label}</TableCell>
            </TableRow>
          );
        }
        return (
          <TableRow key={row}>
            <TableCell>{mappedNames[row].label}</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
        );
      }
      if (dosar[row]) {
        return (
          <TableRow key={row}>
            <TableCell>{mappedNames[row].label}</TableCell>
            <TableCell>{dosar[row]}</TableCell>
          </TableRow>
        );
      }
      return (
        <TableRow key={row}>
          <TableCell>{mappedNames[row].label}</TableCell>
          <TableCell>-</TableCell>
        </TableRow>
      );
    });
  }
}
