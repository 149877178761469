import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Chips from '../General/Chips';

class CursForm extends Component {
  state = {};
  onSubmit = (values, formikBag) => {
    const objToSend = {
      ...values,
      roluriVizibil: values.roluriVizibil.map(rolVizibil => rolVizibil.value),
    };
    this.props.onSubmit(objToSend, formikBag);
  };
  render() {
    const { initialValues, categoriiELearning, discipline } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          denumire: '',
          categorie: [],
          disciplina: [],
          descriere: '',
          autori: '',
          roluriVizibil: null,
          ...initialValues,
        }}
        validationSchema={yup.object().shape({
          denumire: yup.string().required('Camp obligatoriu'),
          disciplina: yup
            .array(yup.object())
            .required('Camp obligatoriu')
            .transform((value, originalValue) =>
              (originalValue === null || originalValue.length === 0 ? undefined : value)),
          categorie: yup
            .array(yup.object())
            .required('Camp obligatoriu')
            .transform((value, originalValue) =>
              (originalValue === null || originalValue.length === 0 ? undefined : value)),
          descriere: yup.string().required('Camp obligatoriu'),
          autori: yup.string().required('Camp obligatoriu'),
        })}
        onSubmit={this.onSubmit}
        render={({
 handleChange, handleBlur, values, errors, touched, setFieldValue,
}) => (
  <Form>
    <Chips
      label="Roluri pentru care cursul este vizibil"
      margin="dense"
      error={errors.roluriVizibil}
      touched={errors.roluriVizibil}
      name="roluriVizibil"
      onChange={setFieldValue}
      value={values.roluriVizibil}
      options={[
                {
                  value: 'others',
                  label: 'Altii',
                },
                {
                  value: 'utilizator',
                  label: 'Utilizator',
                },
                {
                  value: 'masterand',
                  label: 'Masterand',
                },
                {
                  value: 'doctorand',
                  label: 'Doctorand',
                },
                {
                  value: 'candidat',
                  label: 'Candidat la abilitare',
                },
                {
                  value: 'conducator',
                  label: 'Conducator de doctorat',
                },
              ]}
    />
    <TextField
      name="denumire"
      margin="dense"
      fullWidth
      label="Denumire"
      value={values.denumire}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!errors.denumire && !!touched.denumire}
      FormHelperTextProps={{
                error: true,
              }}
      helperText={!!errors.denumire && !!touched.denumire ? errors.denumire : ''}
    />
    <Chips
      value={values.categorie}
      options={categoriiELearning}
      name="categorie"
      label="Categorie"
      error={errors.categorie}
      touched={touched.categorie}
      onChange={setFieldValue}
    />
    <Chips
      value={values.disciplina}
      options={discipline}
      name="disciplina"
      label="Disciplina"
      error={errors.disciplina}
      touched={touched.disciplina}
      onChange={setFieldValue}
    />
    <TextField
      multiline
      margin="dense"
      name="descriere"
      fullWidth
      label="Descriere"
      value={values.descriere}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!errors.descriere && !!touched.descriere}
      FormHelperTextProps={{
                error: true,
              }}
      helperText={!!errors.descriere && !!touched.descriere ? errors.descriere : ''}
    />
    <TextField
      name="autori"
      margin="dense"
      fullWidth
      label="Autori"
      value={values.autori}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!errors.autori && !!touched.autori}
      FormHelperTextProps={{
                error: true,
              }}
      helperText={!!errors.autori && !!touched.autori ? errors.autori : ''}
    />
    <div className="lvd-spacer10" />
    <Button color="primary" variant="contained" type="submit">
              Salveaza
    </Button>
  </Form>
        )}
      />
    );
  }
}

CursForm.propTypes = {};

export default CursForm;
