import request from 'superagent';
import FileSaver from 'file-saver';
import * as TYPES from '../types';
import { serverUrl } from '../utils';
import { showSnackbar } from './snackbar';
import { getToken } from '../reducers/authReducer';
// import history from '../history';

const addVideoToConferinta = (video, conferintaId) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await request
      .post(`${serverUrl}/videoconferinte/${conferintaId}`)
      .set('Authorization', `Bearer ${jwt}`)
      .attach(video.name, video.data);
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
  }
};

export const addEmbedCodeToConferinta = (videoEmbed, conferintaId) => async (
  dispatch,
  getState,
) => {
  const jwt = getToken(getState());
  try {
    await request
      .put(`${serverUrl}/videoconferinte/${conferintaId}`)
      .set('Authorization', `Bearer ${jwt}`)
      .send({ videoEmbed });
    dispatch(showSnackbar('Cod embed salvat cu succes', 'success'));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
  }
};

const fetchConferinteRequest = () => ({
  type: TYPES.CONFERINTE_FETCH_REQUEST,
});
const fetchConferinteSuccess = payload => ({
  type: TYPES.CONFERINTE_FETCH_SUCCESS,
  payload,
});
const fetchConferinteFailure = error => ({
  type: TYPES.CONFERINTE_FETCH_FAILURE,
  error,
});

export const fetchConferinte = () => async (dispatch, getState) => {
  dispatch(fetchConferinteRequest());
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/videoconferinte`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(fetchConferinteSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(fetchConferinteFailure(e));
  }
};
export const requestConferintaById = () => ({
  type: TYPES.CONFERINTE_BYID_REQUEST,
});
export const successConferintaById = payload => ({
  type: TYPES.CONFERINTE_BYID_SUCCESS,
  payload,
});
export const failConferintaById = error => ({
  type: TYPES.CONFERINTE_BYID_FAIL,
  error,
});

export const fetchConferintaById = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestConferintaById());
  try {
    const res = await request
      .get(`${serverUrl}/videoconferinte/${dosarId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successConferintaById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failConferintaById(e));
  }
};

const requestAddConferinta = () => ({
  type: TYPES.CONFERINTE_ADD_REQUEST,
});
const successAddConferinta = payload => ({
  type: TYPES.CONFERINTE_ADD_SUCCESS,
  payload,
});
const failAddConferinta = error => ({
  type: TYPES.CONFERINTE_ADD_FAIL,
  error,
});

export const addConferinta = (curs, embedCode, video) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestAddConferinta());
  try {
    if (!video) {
      const res = await request
        .post(`${serverUrl}/videoconferinte`)
        .set('Authorization', `Bearer ${jwt}`)
        .send({ ...curs, videoEmbed: embedCode });
      dispatch(successAddConferinta(res.body));
    } else {
      const res = await request
        .post(`${serverUrl}/videoconferinte`)
        .set('Authorization', `Bearer ${jwt}`)
        .send({ ...curs, videoEmbed: embedCode });
      const conferintaId = res.body._id;
      await dispatch(addVideoToConferinta(video, conferintaId));
    }
    dispatch(showSnackbar('Conferinta salvata cu succes', 'success'));
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failAddConferinta(e));
    return false;
  }
};

export const requestUpdateConferinta = () => ({
  type: TYPES.CONFERINTE_UPDATE_REQUEST,
});
export const successUpdateConferinta = payload => ({
  type: TYPES.CONFERINTE_UPDATE_SUCCESS,
  payload,
});
export const failUpdateConferinta = error => ({
  type: TYPES.CONFERINTE_UPDATE_FAIL,
  error,
});
export const updateConferinta = (curs, embedCode, video) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateConferinta());
  try {
    if (!video) {
      const res = await request
        .put(`${serverUrl}/videoconferinte/${curs._id}`)
        .set('Authorization', `Bearer ${jwt}`)
        .send({ ...curs, videoEmbed: embedCode });
      dispatch(successUpdateConferinta(res.body));
    } else {
      const res = await request
        .put(`${serverUrl}/videoconferinte/${curs._id}`)
        .set('Authorization', `Bearer ${jwt}`)
        .send({ ...curs, videoEmbed: embedCode });
      dispatch(successUpdateConferinta(res.body));
      const conferintaId = res.body._id;
      await dispatch(addVideoToConferinta(video, conferintaId));
    }
    dispatch(showSnackbar('Conferinta salvata cu success!', 'success'));
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failUpdateConferinta(e));
    return false;
  }
};

export const requestSearchConferinte = () => ({
  type: TYPES.CONFERINTE_SEARCH_REQUEST,
});
export const successSearchConferinte = (payload, query) => ({
  type: TYPES.CONFERINTE_SEARCH_SUCCESS,
  payload,
  query,
});
export const failSearchConferinte = error => ({
  type: TYPES.CONFERINTE_SEARCH_FAIL,
  error,
});

export const searchConferinte = query => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestSearchConferinte());
  try {
    const res = await request
      .post(`${serverUrl}/videoconferinte/search`)
      .send(query)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successSearchConferinte(res.body, query));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failSearchConferinte(e));
  }
};

const requestDeleteConferinta = () => ({
  type: TYPES.CONFERINTE_DELETE_REQUEST,
});
const successDeleteConferinta = payload => ({
  type: TYPES.CONFERINTE_DELETE_SUCCESS,
  payload,
});
const failDeleteConferinta = error => ({
  type: TYPES.CONFERINTE_DELETE_FAIL,
  error,
});
export const deleteConferinta = conferintaId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeleteConferinta());
  try {
    await request
      .delete(`${serverUrl}/videoconferinte/${conferintaId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successDeleteConferinta(conferintaId));
    dispatch(showSnackbar('Conferinta stearsa cu succes', 'success'));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failDeleteConferinta(e));
  }
};

export const downloadVideo = videoumentId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/videoconferinte/videoument/${videoumentId}`)
      .set('Authorization', `Bearer ${jwt}`)
      .responseType('blob');
    const { headers } = res;
    const contentDisposition = headers['content-disposition'];
    const blob = res.body;
    FileSaver.saveAs(blob, contentDisposition.substr(contentDisposition.indexOf('=') + 1));
  } catch (e) {
    showSnackbar(e, 'error');
  }
};
