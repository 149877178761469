import * as TYPES from '../types';

export default (
  state = {
    show: false,
    message: {},
    title: '',
    variant: undefined,
  },
  action,
) => {
  const { payload } = action;
  switch (action.type) {
    case TYPES.SNACK_SHOW:
      return {
        ...state,
        show: true,
        message: payload.message,
        title: payload.title,
        variant: payload.variant,
      };
    case TYPES.SNACK_HIDE:
      return {
        ...state,
        show: false,
        title: '',
        message: undefined,
      };
    default:
      return state;
  }
};

export const getShowSnackbar = state => state.snackbar.show;
export const getSnackbarMessage = state => state.snackbar.message;
export const getSnackbarTitle = state => state.snackbar.title;
export const getSnackbarVariant = state => state.snackbar.variant;
