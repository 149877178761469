import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';

import { addTemplate } from '../../actions';
import TemplateForm from './TemplateForm';

class AddTemplate extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    addTemplate: PropTypes.func.isRequired,
  };
  handleBackClick = () => {
    this.props.history.push('/templateuri');
  };
  handleSubmit = (values) => {
    this.props.addTemplate(values);
  };
  render() {
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <TemplateForm handleSubmit={this.handleSubmit} />
      </div>
    );
  }
}

AddTemplate.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  addTemplate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTemplate);
