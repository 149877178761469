import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText
} from "@material-ui/core";

class FormTextField extends Component {
  static propTypes = {
    error: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    touched: PropTypes.bool,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.shape({}))
    ]),
    isViewing: PropTypes.bool,
    type: PropTypes.string,
    fullWidth: PropTypes.bool
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      _.isEqual(nextProps.value, this.props.value) &&
      _.isEqual(nextProps.error, this.props.error) &&
      _.isEqual(nextProps.label, this.props.label) &&
      _.isEqual(nextProps.touched, this.props.touched) &&
      _.isEqual(nextProps.disabled, this.props.disabled)
    ) {
      return false;
    }
    return true;
  }

  render() {
    const {
      error,
      disabled,
      name,
      touched,
      label,
      handleChange,
      handleBlur,
      value,
      isViewing,
      type,
      fullWidth,
      style,
      required,
      helperText,
      ...rest
    } = this.props;
    if (type === "text") {
      return (
        <FormControl
          key={name}
          error={!!error && !!touched}
          disabled={disabled}
          fullWidth
          margin="dense"
          style={{ ...style }}
        >
          <InputLabel
            htmlFor={name}
            error={!!error && !!touched}
            required={required}
          >
            {label}
          </InputLabel>
          <Input
            id={name}
            disabled={isViewing || disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            name={name}
            error={!!error && !!touched}
            type={name === "email" ? "email" : "text"}
            {...rest}
          />
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
          {!!error && !!touched && (
            <FormHelperText error>{error}</FormHelperText>
          )}
        </FormControl>
      );
    }
    return (
      <FormControl
        key={name}
        error={!!error && !!touched}
        fullWidth={fullWidth}
        margin="dense"
        style={{ ...style }}
      >
        <InputLabel
          htmlFor={name}
          error={!!error && !!touched}
          required={required}
        >
          {label}
        </InputLabel>
        <Input
          id={name}
          readOnly={isViewing}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value || ""}
          disabled={disabled}
          name={name}
          error={!!error && !!touched}
          type="number"
          inputProps={{
            step: 1
          }}
          {...rest}
        />
        {!!error && !!touched && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

export default FormTextField;
