import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
};

const defaultProps = {
  name: undefined,
};

const DeleteModal = (props) => {
  const {
    open, handleClose, name, handleDelete,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Sterge element</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Sunteti sigur ca vreti sa stergeti {name ? `"${name}"` : ' acest element'}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Renunta
        </Button>
        <Button onClick={handleDelete} color="primary">
          Da
        </Button>
      </DialogActions>
    </Dialog>
  );
};
DeleteModal.propTypes = propTypes;
DeleteModal.defaultProps = defaultProps;
export default DeleteModal;
