import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Code from '@material-ui/icons/Code';
import {
  Button,
  Paper,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  withMobileDialog,
} from '@material-ui/core';
import { addConferinta, fetchDoctoranziForDropdown } from '../../actions';
import VideoconferintaForm from './VideoconferintaForm';
import { getDoctoranziForDropdown } from '../../reducers/doctoranziReducer';

class AddVideoconferinta extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fullScreen: PropTypes.bool.isRequired,
  };
  state = {
    uploaded: undefined,
    openCodeModal: false,
    embedCode: '',
  };
  componentDidMount() {
    this.props.fetchDoctoranziForDropdown();
  }

  handleSubmit = async (values) => {
    const success = await this.props.addConferinta(
      values,
      this.state.embedCode,
      this.state.uploaded,
    );
    if (success) {
      this.props.history.push('/videoconferinte');
    }
  };
  handleOpenCodeModal = () => {
    this.setState({
      openCodeModal: true,
    });
  };
  handleChangeCode = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleBackClick = () => {
    this.props.history.push('/videoconferinte');
  };
  handleSubmitCode = () => {
    this.setState({
      openCodeModal: false,
    });
  };
  handleClose = () => {
    this.setState({
      openCodeModal: false,
    });
  };
  uploadInputRef = null;
  render() {
    const { uploaded, openCodeModal } = this.state;
    const { fullScreen, doctoranziDropdown } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <div className="lvd-upload-button">
            <Button
              onClick={() => this.uploadInputRef.open()}
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
            >
              <CloudDownloadIcon />
              Incarca fisier
            </Button>
            <Dropzone
              multiple={false}
              ref={(node) => {
                this.uploadInputRef = node;
              }}
              style={{ display: 'none' }}
              onDrop={(accepted, rejected) => {
                this.setState({
                  uploaded: {
                    name: accepted[0].name,
                    size: (accepted[0].size / 1024 ** 2).toFixed(2),
                    data: accepted[0],
                  },
                });
              }}
            >
              <p>Drop files here.</p>
            </Dropzone>
            <div className="lvd-upload-sau">sau</div>
            <Button
              onClick={this.handleOpenCodeModal}
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
            >
              <Code />
              Cod embed
            </Button>
          </div>
          {uploaded && (
            <div className="lvd-upload-curs">
              {uploaded.name} {uploaded.size} MB
            </div>
          )}
        </Paper>
        <Paper className="lvd-container-form">
          <VideoconferintaForm
            doctoranziDropdown={doctoranziDropdown}
            initialValues={{ dataEveniment: null }}
            handleSubmit={this.handleSubmit}
          />
        </Paper>
        <Dialog
          fullScreen={fullScreen}
          open={openCodeModal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Adauga cod embed
            <Button className="lvd-button-close-modal" onClick={this.handleClose}>
              Inchide
            </Button>
          </DialogTitle>
          <DialogContent style={{ minWidth: '550px' }}>
            <TextField
              fullWidth
              label="Cod embed"
              multiline
              margin="dense"
              onChange={this.handleChangeCode}
              value={this.state.embedCode}
              name="embedCode"
            />
          </DialogContent>
          <DialogActions style={{ padding: '14px' }}>
            <Button
              onClick={this.handleSubmitCode}
              type="submit"
              variant="contained"
              color="primary"
            >
              {'Salveaza'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddVideoconferinta.propTypes = {};

const mapStateToProps = state => ({
  doctoranziDropdown: getDoctoranziForDropdown(state),
});

export default withMobileDialog()(connect(
  mapStateToProps,
  { addConferinta, fetchDoctoranziForDropdown },
)(AddVideoconferinta));
