import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import QueryBuilder from "../General/QueryBuilder";

class FilterModal extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    filterConfig: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    handleSetFilterNo: PropTypes.func.isRequired,
    handleSetQuery: PropTypes.func.isRequired,
    filterNo: PropTypes.number.isRequired,
    query: PropTypes.shape({})
  };
  static defaultProps = {
    query: undefined
  };
  state = {};
  render() {
    const {
      filterNo,
      openModal,
      fullScreen,
      filterConfig,
      handleSetFilterNo,
      handleSetQuery,
      query,
      handleClose
    } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {filterNo !== 0 ? `${filterNo} filtre active` : "Filtreaza"}
          <Button className="lvd-button-close-modal" onClick={handleClose}>
            Inchide
          </Button>
        </DialogTitle>
        <DialogContent>
          <QueryBuilder
            saveQuery={handleSetQuery}
            filterConfig={filterConfig}
            setFilterNo={handleSetFilterNo}
            query={query}
            handleClose={this.props.handleClose}
          />
        </DialogContent>
        <DialogActions style={{ padding: "28px" }} />
      </Dialog>
    );
  }
}

export default FilterModal;
