import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { fetchTemplateById, updateTemplate } from '../../actions';
import { getTemplateById } from '../../reducers/templateuriReducer';
import TemplateForm from './TemplateForm';

class EditTemplate extends Component {
  static propTypes = {
    fetchTemplateById: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        templateId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    updateTemplate: PropTypes.func.isRequired,
  };
  componentDidMount = () => {
    this.props.fetchTemplateById(this.props.match.params.templateId);
  };
  handleBackClick = () => {
    this.props.history.push('/templateuri');
  };
  handleSubmit = (values) => {
    this.props.updateTemplate(values);
  };
  render() {
    const { template } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <TemplateForm initialValues={template} handleSubmit={this.handleSubmit} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  template: getTemplateById(state),
});

const mapDispatchToProps = {
  fetchTemplateById,
  updateTemplate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditTemplate);
