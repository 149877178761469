import request from 'superagent';
import FileSaver from 'file-saver';
import _ from 'lodash';
import * as TYPES from '../types';
import { serverUrl } from '../utils';
import { showSnackbar } from './snackbar';
import { getToken } from '../reducers/authReducer';
import history from '../history';
// import history from '../history';

const addDocumentToPublicatie = (doc, publicatieId) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await request
      .post(`${serverUrl}/publicatii/${publicatieId}`)
      .set('Authorization', `Bearer ${jwt}`)
      .attach(doc.name, doc.data);
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
  }
};

const fetchPublicatiiRequest = () => ({
  type: TYPES.PUBLICATII_FETCH_REQUEST,
});
const fetchPublicatiiSuccess = payload => ({
  type: TYPES.PUBLICATII_FETCH_SUCCESS,
  payload,
});
const fetchPublicatiiFailure = error => ({
  type: TYPES.PUBLICATII_FETCH_FAILURE,
  error,
});

export const fetchPublicatii = () => async (dispatch, getState) => {
  dispatch(fetchPublicatiiRequest());
  const jwt = getToken(getState());
  try {
    const res = await request
      .post(`${serverUrl}/publicatii/search`)
      .send({})
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(fetchPublicatiiSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(fetchPublicatiiFailure(e));
  }
};
export const requestPublicatieById = () => ({
  type: TYPES.PUBLICATII_BYID_REQUEST,
});
export const successPublicatieById = payload => ({
  type: TYPES.PUBLICATII_BYID_SUCCESS,
  payload,
});
export const failPublicatieById = error => ({
  type: TYPES.PUBLICATII_BYID_FAIL,
  error,
});

export const fetchPublicatieById = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestPublicatieById());
  try {
    const res = await request
      .get(`${serverUrl}/publicatii/${dosarId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successPublicatieById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failPublicatieById(e));
  }
};

const requestAddPublicatie = () => ({
  type: TYPES.PUBLICATII_ADD_REQUEST,
});
const successAddPublicatie = payload => ({
  type: TYPES.PUBLICATII_ADD_SUCCESS,
  payload,
});
const failAddPublicatie = error => ({
  type: TYPES.PUBLICATII_ADD_FAIL,
  error,
});

export const addPublicatie = (publicatie, doc) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestAddPublicatie());
  try {
    const res = await request
      .post(`${serverUrl}/publicatii`)
      .set('Authorization', `Bearer ${jwt}`)
      .send(publicatie);
    dispatch(successAddPublicatie(res.body));
    const publicatieId = res.body._id;
    if (doc) {
      await dispatch(addDocumentToPublicatie(doc, publicatieId));
    }
    history.push('/publicatii');
    dispatch(showSnackbar('Publicatie salvata cu succes', 'success'));
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failAddPublicatie(e));
    return false;
  }
};

export const requestUpdatePublicatie = () => ({
  type: TYPES.PUBLICATII_UPDATE_REQUEST,
});
export const successUpdatePublicatie = payload => ({
  type: TYPES.PUBLICATII_UPDATE_SUCCESS,
  payload,
});
export const failUpdatePublicatie = error => ({
  type: TYPES.PUBLICATII_UPDATE_FAIL,
  error,
});
export const updatePublicatie = (publicatie, doc) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdatePublicatie());
  try {
    const res = await request
      .put(`${serverUrl}/publicatii/${publicatie._id}`)
      .set('Authorization', `Bearer ${jwt}`)
      .send(publicatie);
    if (doc) {
      dispatch(addDocumentToPublicatie(doc, publicatie._id));
    }
    history.push('/publicatii');
    dispatch(showSnackbar('Publicatie salvata cu success!', 'success'));
    dispatch(successUpdatePublicatie(res.body));
    return true;
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failUpdatePublicatie(e));
    return false;
  }
};

export const requestSearchPublicatii = () => ({
  type: TYPES.PUBLICATII_SEARCH_REQUEST,
});
export const successSearchPublicatii = (payload, query) => ({
  type: TYPES.PUBLICATII_SEARCH_SUCCESS,
  payload,
  query,
});
export const failSearchPublicatii = error => ({
  type: TYPES.PUBLICATII_SEARCH_FAIL,
  error,
});

export const searchPublicatii = (query, pageInfo) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  query = _.isEmpty(query) ? undefined : query;
  dispatch(requestSearchPublicatii());
  try {
    const res = await request
      .post(`${serverUrl}/publicatii/search`)
      .send({ query, pageInfo })
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successSearchPublicatii(res.body, query));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failSearchPublicatii(e));
  }
};

const requestDeletePublicatie = () => ({
  type: TYPES.PUBLICATII_DELETE_REQUEST,
});
const successDeletePublicatie = payload => ({
  type: TYPES.PUBLICATII_DELETE_SUCCESS,
  payload,
});
const failDeletePublicatie = error => ({
  type: TYPES.PUBLICATII_DELETE_FAIL,
  error,
});
export const deletePublicatie = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeletePublicatie());
  try {
    await request
      .delete(`${serverUrl}/publicatii/${dosarId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successDeletePublicatie(dosarId));
    dispatch(showSnackbar('Publicatie stearsa cu succes', 'success'));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failDeletePublicatie(e));
  }
};

export const downloadDocumentFromPublicatie = documentId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/publicatii/document/${documentId}`)
      .set('Authorization', `Bearer ${jwt}`)
      .responseType('blob');
    const { headers } = res;
    const contentDisposition = headers['content-disposition'];
    const blob = res.body;
    FileSaver.saveAs(blob, contentDisposition.substr(contentDisposition.indexOf('=') + 1));
  } catch (e) {
    showSnackbar(e, 'error');
  }
};
