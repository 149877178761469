import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Button, Paper } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { fetchNomenclatoare, addCurs } from '../../actions';
import CursForm from './CursForm';
import { getIsAdmin } from '../../reducers/authReducer';

class AddCurs extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };
  state = {
    uploaded: undefined,
  };
  static getDerivedStateFromProps = (props, state) => ({
    discipline: props.discipline.map(n => ({
      value: n._id,
      label: n.nume,
    })),
    categoriiELearning: props.categoriiELearning.map(n => ({
      value: n._id,
      label: n.nume,
    })),
  });
  componentDidMount() {
    this.props.fetchNomenclatoare();
  }
  handleBackClick = () => {
    this.props.history.push('/elearning');
  };
  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const success = await this.props.addCurs(values, this.state.uploaded);
    setSubmitting(false);
    if (success) {
      this.props.history.push('/elearning');
    }
  };
  uploadInputRef = null;
  render() {
    const { uploaded, discipline, categoriiELearning } = this.state;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <div className="lvd-upload-button">
            <Button
              onClick={() => this.uploadInputRef.open()}
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
            >
              <CloudDownloadIcon />
              Incarca fisier
            </Button>
            <Dropzone
              multiple={false}
              ref={(node) => {
                this.uploadInputRef = node;
              }}
              style={{ display: 'none' }}
              onDrop={(accepted, rejected) => {
                this.setState({
                  uploaded: {
                    name: accepted[0].name,
                    size: (accepted[0].size / 1024 ** 2).toFixed(2),
                    data: accepted[0],
                  },
                });
              }}
            >
              <p>Drop files here.</p>
            </Dropzone>
          </div>
          {this.state.uploaded && (
            <div className="lvd-upload-curs">
              {uploaded.name} {uploaded.size} MB
            </div>
          )}
        </Paper>
        <Paper className="lvd-container-form">
          <CursForm
            onSubmit={this.handleSubmit}
            categoriiELearning={categoriiELearning}
            discipline={discipline}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.nomenclatoare,
  isAdmin: getIsAdmin(state),
});

export default connect(
  mapStateToProps,
  { fetchNomenclatoare, addCurs },
)(AddCurs);
