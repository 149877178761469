import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Button,
  MenuItem,
  FormHelperText,
  Select,
  InputLabel,
  FormControl
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Form, Formik } from "formik";
import CreatableSelect from "../General/CreatableSelect";

class PublicatieForm extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    validationSchema: PropTypes.shape({}).isRequired,
    jurnale: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
  };
  render() {
    const {
      initialValues,
      handleSubmit,
      validationSchema,
      jurnale
    } = this.props;
    return (
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          titlu: "",
          autori: "",
          dataPublicarii: null,
          tipPublicatie: "",
          tip: "",
          jurnal: {},
          proceedings: "",
          volum: "",
          numar: "",
          pagini: "",
          editor: "",
          descriere: "",
          bazaDeDateDinCareEsteCitat: "",
          ...initialValues
        }}
        onSubmit={handleSubmit}
        render={({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          setFieldValue
        }) => (
          <Form>
            <TextField
              name="titlu"
              margin="dense"
              required
              fullWidth
              label="Titlu"
              value={values.titlu}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.titlu && !!touched.titlu}
              helperText={!!errors.titlu && !!touched.titlu && errors.titlu}
              FormHelperTextProps={{
                error: errors.titlu
              }}
            />
            <TextField
              name="autori"
              margin="dense"
              required
              fullWidth
              label="Autori"
              value={values.autori}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.autori && !!touched.autori}
              helperText={!!errors.autori && !!touched.autori && errors.autori}
              FormHelperTextProps={{
                error: !!errors.autori
              }}
            />
            <DatePicker
              margin="dense"
              format="DD/MM/YYYY"
              fullWidth
              label="Data Publicarii"
              onChange={date => setFieldValue("dataPublicarii", date)}
              value={values.dataPublicarii}
              name="dataPublicarii"
              error={!!errors.dataPublicarii && !!touched.dataPublicarii}
              helperText={
                !!errors.dataPublicarii &&
                !!touched.dataPublicarii &&
                errors.dataPublicarii
              }
            />
            <FormControl fullWidth margin="dense">
              <InputLabel htmlFor="tipPublicatie">Tip Publicatie</InputLabel>
              <Select
                margin="dense"
                name="tipPublicatie"
                value={values.tipPublicatie}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.tipPublicatie}
              >
                <MenuItem value="jurnal">Jurnal</MenuItem>
                <MenuItem value="proceeding">Proceeding</MenuItem>
              </Select>
              {!!errors.tipPublicatie && !!touched.tipPublicatie && (
                <FormHelperText error>{errors.tipPublicatie}</FormHelperText>
              )}
            </FormControl>
            {values.tipPublicatie !== "" &&
              (values.tipPublicatie === "jurnal" ? (
                <CreatableSelect
                  margin="dense"
                  // placeholder="Incepe sa scrii un nume..."
                  value={values.jurnal}
                  name="jurnal"
                  label="Jurnal"
                  error={errors.jurnal}
                  touched={touched.jurnal}
                  onChange={setFieldValue}
                  addOption="[Adauga jurnal sau alege din lista]"
                  options={jurnale}
                />
              ) : (
                <TextField
                  name="proceeding"
                  margin="dense"
                  fullWidth
                  label="Proceeding"
                  value={values.proceeding}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.proceeding && !!touched.proceeding}
                  helperText={
                    !!errors.proceeding &&
                    !!touched.proceeding &&
                    errors.proceeding
                  }
                  FormHelperTextProps={{
                    error: !!errors.proceeding
                  }}
                />
              ))}
            <TextField
              name="volum"
              margin="dense"
              fullWidth
              label="Volum"
              value={values.volum}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.volum && !!touched.volum}
              helperText={!!errors.volum && !!touched.volum && errors.volum}
              FormHelperTextProps={{
                error: errors.volum
              }}
            />
            <TextField
              name="numar"
              margin="dense"
              fullWidth
              label="Numar"
              value={values.numar}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.numar && !!touched.numar}
              helperText={!!errors.numar && !!touched.numar && errors.numar}
              FormHelperTextProps={{
                error: errors.numar
              }}
            />
            <TextField
              name="pagini"
              margin="dense"
              fullWidth
              label="Pagini"
              value={values.pagini}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.pagini && !!touched.pagini}
              helperText={!!errors.pagini && !!touched.pagini && errors.pagini}
              FormHelperTextProps={{
                error: errors.pagini
              }}
            />
            <TextField
              name="editor"
              margin="dense"
              fullWidth
              label="Editor"
              value={values.editor}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.editor && !!touched.editor}
              helperText={!!errors.editor && !!touched.editor && errors.editor}
              FormHelperTextProps={{
                error: errors.editor
              }}
            />
            <TextField
              name="bazaDeDateDinCareEsteCitat"
              margin="dense"
              fullWidth
              label="Baza de date din care este citat"
              value={values.bazaDeDateDinCareEsteCitat}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.bazaDeDateDinCareEsteCitat &&
                !!touched.bazaDeDateDinCareEsteCitat
              }
              helperText={
                !!errors.bazaDeDateDinCareEsteCitat &&
                !!touched.bazaDeDateDinCareEsteCitat &&
                errors.bazaDeDateDinCareEsteCitat
              }
              FormHelperTextProps={{
                error: errors.bazaDeDateDinCareEsteCitat
              }}
            />
            <TextField
              name="descriere"
              margin="dense"
              multiline
              fullWidth
              label="Descriere"
              value={values.descriere}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.descriere && !!touched.descriere}
              helperText={
                !!errors.descriere && !!touched.descriere && errors.descriere
              }
              FormHelperTextProps={{
                error: errors.descriere
              }}
            />
            <div className="lvd-spacer10" />
            <Button color="primary" variant="contained" type="submit">
              Salveaza
            </Button>
          </Form>
        )}
      />
    );
  }
}

PublicatieForm.propTypes = {};

export default PublicatieForm;
