import * as TYPES from "../types";

const authReducer = (
  state = {
    appBarTitle: "",
    user: {},
    token: {},
    returnUrl: "/",
    accountType: ""
  },
  action
) => {
  const { payload, error, title } = action;
  switch (action.type) {
    case TYPES.SET_REGISTER_ACCOUNT_TYPE:
      return { ...state, accountType: payload };
    case TYPES.REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token
      };
    case TYPES.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token
      };
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token
      };
    case TYPES.LOGIN_FAIL:
      return { ...state, error };
    case TYPES.SET_RETURN_URL:
      return { ...state, returnUrl: action.returnUrl };
    case TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        user: {},
        token: {}
      };
    case TYPES.LOGIN_REFRESH_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token: {
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken
        },
        user: { ...state.user, ...payload.user }
      };
    case TYPES.APPBAR_TITLE_SET:
      return { ...state, appBarTitle: title };
    case TYPES.APPBAR_TITLE_REMOVE:
      return { ...state, appBarTitle: undefined };
    default:
      return state;
  }
};

export const getRegisterAccountType = state => state.auth.accountType;
export const getUser = state => state.auth.user;
export const getToken = state => state.auth.token.accessToken;
export const getRefreshToken = state => state.auth.token.refreshToken;
export const getIsLoggedIn = state => state.auth.isLoggedIn;
export const getRole = state => state.auth.user.role;
export const getIsAdmin = state =>
  state.auth.user.role === "secretara" ||
  state.auth.user.role === "administrator";
export const getAppBarTitle = state => state.auth.appBarTitle;
export const getDosareCandidat = state => state.auth.user.dosareCandidat || [];
export const getDosareDoctorand = state =>
  state.auth.user.dosareDoctorand || [];
export const getDosareConducator = state =>
  state.auth.user.dosareConducator || [];

export default authReducer;
