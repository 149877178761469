import request from "superagent";
import _ from "lodash";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";
import { getToken, getIsAdmin } from "../reducers/authReducer";
import history from "../history";

const fetchDoctoranziForDropdownRequest = () => ({
  type: TYPES.DOCTORANZI_DROPDOWN_FETCH_REQUEST
});
const fetchDoctoranziForDropdownSuccess = payload => ({
  type: TYPES.DOCTORANZI_DROPDOWN_FETCH_SUCCESS,
  payload
});
const fetchDoctoranziForDropdownFailure = error => ({
  type: TYPES.DOCTORANZI_DROPDOWN_FETCH_FAILURE,
  error
});

export const fetchDoctoranziForDropdown = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(fetchDoctoranziForDropdownRequest());
  try {
    const res = await request
      .get(`${serverUrl}/doctoranzi/dropdown`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchDoctoranziForDropdownSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchDoctoranziForDropdownFailure(e));
  }
};

const fetchDoctoranziRequest = () => ({
  type: TYPES.DOCTORANZI_FETCH_REQUEST
});
const fetchDoctoranziSuccess = payload => ({
  type: TYPES.DOCTORANZI_FETCH_SUCCESS,
  payload
});
const fetchDoctoranziFailure = error => ({
  type: TYPES.DOCTORANZI_FETCH_FAILURE,
  error
});

export const fetchDoctoranzi = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(fetchDoctoranziRequest());
  try {
    const res = await request
      .post(`${serverUrl}/doctoranzi/search`)
      .send({})
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchDoctoranziSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchDoctoranziFailure(e));
  }
};

const requestAddDoctorand = () => ({
  type: TYPES.DOCTORANZI_ADD_REQUEST
});
const successAddDoctorand = payload => ({
  type: TYPES.DOCTORANZI_ADD_SUCCESS,
  payload
});
const failAddDoctorand = error => ({
  type: TYPES.DOCTORANZI_ADD_FAIL,
  error
});

export const addDoctorand = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestAddDoctorand());
  try {
    const res = await request
      .post(`${serverUrl}/doctoranzi`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successAddDoctorand(res.body));
    dispatch(showSnackbar("Dosar salvat cu succes", "success"));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failAddDoctorand(e));
  }
};

export const requestDoctorandById = () => ({
  type: TYPES.DOCTORANZI_FETCH_BYID_REQUEST
});
export const successDoctorandById = payload => ({
  type: TYPES.DOCTORANZI_FETCH_BYID_SUCCESS,
  payload
});
export const failDoctorandById = error => ({
  type: TYPES.DOCTORANZI_FETCH_BYID_FAIL,
  error
});
export const fetchDoctorandById = id => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDoctorandById());
  try {
    const res = await request
      .get(`${serverUrl}/doctoranzi/${id}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successDoctorandById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failDoctorandById(e));
  }
};

export const requestUpdateDoctorand = () => ({
  type: TYPES.DOCTORANZI_UPDATE_REQUEST
});
export const successUpdateDoctorand = payload => ({
  type: TYPES.DOCTORANZI_UPDATE_SUCCESS,
  payload
});
export const failUpdateDoctorand = error => ({
  type: TYPES.DOCTORANZI_UPDATE_FAIL,
  error
});
export const updateDoctorand = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateDoctorand());
  try {
    const res = await request
      .put(`${serverUrl}/doctoranzi/${dosar._id}`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successUpdateDoctorand(res.body));
    dispatch(showSnackbar("Dosarul a fost salvat cu succes", "success"));
    const isAdmin = getIsAdmin(getState());
    if (isAdmin) history.push("/doctoranzi");
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failUpdateDoctorand(e));
  }
};

export const requestSearchDoctoranzi = () => ({
  type: TYPES.DOCTORANZI_SEARCH_REQUEST
});
export const successSearchDoctoranzi = (payload, query) => ({
  type: TYPES.DOCTORANZI_SEARCH_SUCCESS,
  payload,
  query
});
export const failSearchDoctoranzi = error => ({
  type: TYPES.DOCTORANZI_SEARCH_FAIL,
  error
});

export const searchDoctoranzi = (query, pageInfo) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  query = _.isEmpty(query) ? undefined : query;
  dispatch(requestSearchDoctoranzi());
  try {
    const res = await request
      .post(`${serverUrl}/doctoranzi/search`)
      .send({ query, pageInfo })
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successSearchDoctoranzi(res.body, query));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failSearchDoctoranzi(e));
  }
};

const requestDeleteDoctorand = () => ({
  type: TYPES.DOCTORANZI_DELETE_REQUEST
});
const successDeleteDoctorand = payload => ({
  type: TYPES.DOCTORANZI_DELETE_SUCCESS,
  payload
});
const failDeleteDoctorand = error => ({
  type: TYPES.DOCTORANZI_DELETE_FAIL,
  error
});
export const deleteDoctorand = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeleteDoctorand());
  try {
    await request
      .delete(`${serverUrl}/doctoranzi/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successDeleteDoctorand(dosarId));
    dispatch(showSnackbar("Dosar sters cu succes", "success"));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failDeleteDoctorand(e));
  }
};

export const doctoranziSetQuery = query => ({
  type: TYPES.DOCTORANZI_SET_QUERY,
  payload: query
});
