import request from 'superagent';
import _ from 'lodash';
import * as TYPES from '../types';
import { serverUrl } from '../utils';
import { showSnackbar } from './snackbar';
import { getToken, getIsAdmin } from '../reducers/authReducer';
import history from '../history';

const fetchConducatoriRequest = () => ({
  type: TYPES.CONDUCATORI_FETCH_REQUEST,
});
const fetchConducatoriSuccess = payload => ({
  type: TYPES.CONDUCATORI_FETCH_SUCCESS,
  payload,
});
const fetchConducatoriFailure = error => ({
  type: TYPES.CONDUCATORI_FETCH_FAILURE,
  error,
});

export const fetchConducatori = () => async (dispatch, getState) => {
  dispatch(fetchConducatoriRequest());
  const jwt = getToken(getState());
  try {
    const res = await request.get(`${serverUrl}/conducatori`).set('Authorization', `Bearer ${jwt}`);
    dispatch(fetchConducatoriSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(fetchConducatoriFailure(e));
  }
};
const requestFetchConducatoriForDropdown = () => ({
  type: TYPES.CONDUCATORI_KEYVALUE_REQUEST,
});
const successFetchConducatoriForDropdown = payload => ({
  type: TYPES.CONDUCATORI_KEYVALUE_SUCCESS,
  payload,
});
const failFetchConducatoriForDropdown = error => ({
  type: TYPES.CONDUCATORI_KEYVALUE_FAILURE,
  error,
});

export const fetchConducatoriForDropdown = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestFetchConducatoriForDropdown());
  try {
    const res = await request
      .get(`${serverUrl}/conducatori/list`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successFetchConducatoriForDropdown(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failFetchConducatoriForDropdown(e));
  }
};

const requestConducatorById = () => ({
  type: TYPES.CONDUCATORI_BYID_REQUEST,
});
const successConducatorById = payload => ({
  type: TYPES.CONDUCATORI_BYID_SUCCESS,
  payload,
});
const failConducatorById = error => ({
  type: TYPES.CONDUCATORI_BYID_FAIL,
  error,
});
export const fetchConducatorById = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestConducatorById());
  try {
    const res = await request
      .get(`${serverUrl}/conducatori/${dosarId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successConducatorById(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failConducatorById(e));
  }
};
const requestUpdateConducator = () => ({
  type: TYPES.CONDUCATORI_UPDATE_REQUEST,
});
const successUpdateConducator = payload => ({
  type: TYPES.CONDUCATORI_UPDATE_SUCCESS,
  payload,
});
const failUpdateConducator = error => ({
  type: TYPES.CONDUCATORI_UPDATE_FAIL,
  error,
});

export const updateConducator = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateConducator());
  try {
    const res = await request
      .put(`${serverUrl}/conducatori/${dosar._id}`)
      .set('Authorization', `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successUpdateConducator(res.body));
    dispatch(showSnackbar('Editare salvata cu succes', 'success'));
    const isAdmin = getIsAdmin(getState());
    if (isAdmin) history.push('/conducatori');
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failUpdateConducator(e));
  }
};
const requestAddConducator = () => ({
  type: TYPES.CONDUCATORI_ADD_REQUEST,
});
const successAddConducator = payload => ({
  type: TYPES.CONDUCATORI_ADD_SUCCESS,
  payload,
});
const failAddConducator = error => ({
  type: TYPES.CONDUCATORI_ADD_FAIL,
  error,
});

export const addConducator = dosar => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestAddConducator());
  try {
    const res = await request
      .post(`${serverUrl}/conducatori`)
      .set('Authorization', `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successAddConducator(res.body));
    dispatch(showSnackbar('Dosar salvat cu succes', 'success'));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failAddConducator(e));
  }
};

const requestDeleteConducator = () => ({
  type: TYPES.CONDUCATORI_DELETE_REQUEST,
});
const successDeleteConducator = payload => ({
  type: TYPES.CONDUCATORI_DELETE_SUCCESS,
  payload,
});
const failDeleteConducator = error => ({
  type: TYPES.CONDUCATORI_DELETE_FAIL,
  error,
});
export const deleteConducator = dosarId => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeleteConducator());
  try {
    await request
      .delete(`${serverUrl}/conducatori/${dosarId}`)
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successDeleteConducator(dosarId));
    dispatch(showSnackbar('Dosar sters cu succes', 'success'));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failDeleteConducator(e));
  }
};
export const requestSearchConducatori = () => ({
  type: TYPES.CONDUCATORI_SEARCH_REQUEST,
});
export const successSearchConducatori = ({ dosare, pageInfo }, query) => ({
  type: TYPES.CONDUCATORI_SEARCH_SUCCESS,
  payload: {
    dosare,
    pageInfo,
  },
  query,
});
export const failSearchConducatori = error => ({
  type: TYPES.CONDUCATORI_SEARCH_FAIL,
  error,
});

export const searchConducatori = (query, pageInfo) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  query = _.isEmpty(query) ? undefined : query;
  dispatch(requestSearchConducatori());
  try {
    const res = await request
      .post(`${serverUrl}/conducatori/search`)
      .send({ query, pageInfo })
      .set('Authorization', `Bearer ${jwt}`);
    dispatch(successSearchConducatori(res.body, query));
  } catch (e) {
    dispatch(showSnackbar(e, 'error'));
    dispatch(failSearchConducatori(e));
  }
};
