/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center"
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    boxShadow:
      "0px 2px 2px -1px rgba(0, 0, 0,1) 0px 1px 2px 0px rgba(0, 0, 0, 1) 0px 1px 4px 0px rgba(0, 0, 0, 1)",
    zIndex: 1,
    marginTop: "1px",
    left: 0,
    right: 0
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      margin="dense"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return null;
  // <Typography
  //   color="textSecondary"
  //   className={props.selectProps.classes.placeholder}
  //   {...props.innerProps}
  // >
  //   {props.children}
  // </Typography>
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      style={{
        boxShadow:
          "0px 2px 2px -1px rgba(0, 0, 0,1) 0px 1px 2px 0px rgba(0, 0, 0, 1) 0px 1px 4px 0px rgba(0, 0, 0, 1)"
      }}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class IntegrationReactSelect extends React.Component {
  state = {
    single: null,
    multi: [],
    typing: false
  };
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      _.isEqual(nextProps.options, this.props.options) &&
      _.isEqual(nextProps.value, this.props.value) &&
      _.isEqual(nextProps.error, this.props.error) &&
      _.isEqual(nextProps.touched, this.props.touched)
    ) {
      return false;
    }
    return true;
  }

  changeInputVal = val => {
    this.setState({
      typing: !!val
    });
  };
  handleChange = name => value => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const {
      classes,
      theme,
      name,
      options,
      label,
      error,
      touched,
      value,
      onChange,
      disabled
    } = this.props;
    const { typing } = this.state;
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    return (
      <Select
        onInputChange={(val, action) => this.changeInputVal(val, action)}
        classes={classes}
        styles={selectStyles}
        isDisabled={disabled}
        closeMenuOnSelect={false}
        textFieldProps={{
          error: !!error && !!touched,
          FormHelperTextProps: {
            error: !!error && !!touched
          },
          helperText: !!error && !!touched && error,
          // test: 'test',
          label,
          InputLabelProps: {
            shrink: typing || (!!value && value.length !== 0)
          }
        }}
        options={options}
        components={components}
        value={value}
        onChange={val => onChange(name, val)}
        isMulti
      />
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
