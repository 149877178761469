import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Paper, Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as yup from 'yup';
import { updatePublicatie, fetchNomenclatoare, fetchPublicatieById } from '../../actions';
import { getIsAdmin, getUser } from '../../reducers/authReducer';
import { getNomenclatorJurnale } from '../../reducers/nomenclatoareReducer';
import { getPublicatieById } from '../../reducers/publicatiiReducer';
import PublicatieForm from './PublicatieForm';

class EditPublicatie extends Component {
  state = { inputs: [], uploaded: undefined };
  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchPublicatieById(this.props.match.params.publicatieId);
  }
  handleSubmit = async (values) => {
    await this.props.updatePublicatie(values, this.state.uploaded);
  };
  handleBackClick = () => {
    this.props.history.push('/publicatii');
  };
  render() {
    const { uploaded } = this.state;
    const {
      isAdmin, user, publicatie, jurnale,
    } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <div className="lvd-upload-button">
            <Button
              onClick={() => this.uploadInputRef.open()}
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
            >
              <CloudDownloadIcon />
              {publicatie.documentId ? 'Inlocuieste fisier' : 'Incarca fisier'}
            </Button>
            <Dropzone
              multiple={false}
              ref={(node) => {
                this.uploadInputRef = node;
              }}
              style={{ display: 'none' }}
              onDrop={(accepted, rejected) => {
                this.setState({
                  uploaded: {
                    name: accepted[0].name,
                    size: (accepted[0].size / 1024 ** 2).toFixed(2),
                    data: accepted[0],
                  },
                });
              }}
            >
              <p>Drop files here.</p>
            </Dropzone>
          </div>
          {this.state.uploaded && (
            <div className="lvd-upload-curs">
              {uploaded.name} {uploaded.size} MB
            </div>
          )}
        </Paper>
        <Paper className="lvd-container-form">
          <PublicatieForm
            initialValues={{
              asociatCu: isAdmin ? user.id : user.id,
              ...publicatie,
            }}
            jurnale={jurnale}
            handleSubmit={this.handleSubmit}
            validationSchema={yup.object().shape({
              titlu: yup.string().required('Camp obligatoriu'),
              autori: yup.string().required('Camp obligatoriu'),
            })}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  jurnale: getNomenclatorJurnale(state),
  isAdmin: getIsAdmin(state),
  user: getUser(state),
  publicatie: getPublicatieById(state),
});

const mapDispatchToProps = {
  fetchNomenclatoare,
  updatePublicatie,
  fetchPublicatieById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPublicatie);
