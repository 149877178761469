import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { getUser } from "../../reducers/authReducer";

class ValidareFinala extends Component {
  render() {
    const { user } = this.props;
    if (!user) {
      return <div />;
    }
    return (
      <div>
        {user.statusInscriere === "finalizat" ? (
          <>
            <Typography gutterBottom variant="h3">
              Felicitari
            </Typography>
            <Typography>Ati parcurs toate etapele de inscriere</Typography>
          </>
        ) : (
          <>
            <Typography gutterBottom variant="h3">
              Asteptaţi validarea finală
            </Typography>
            <Typography>
              Veţi fi contactat de către Secretariatul Şcolii Doctorale în cel
              mai scurt timp.
            </Typography>
          </>
        )}
      </div>
    );
  }
}

ValidareFinala.propTypes = {};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(ValidareFinala);
