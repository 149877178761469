import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  withMobileDialog,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import TableWithFilter from "../General/TableWithFilter";
import {
  getUtilizatori,
  getUtilizatoriPageInfo
} from "../../reducers/utilizatoriReducer";
import { fetchUtilizatori, deleteUtilizator } from "../../actions";

const filterConfig = [
  {
    id: "email",
    label: "Email",
    type: "string",
    input: "text"
  },
  {
    id: "role",
    label: "Rol",
    type: "string",
    input: "select",
    values: [
      {
        administrator: "Administrator"
      },
      {
        secretara: "Secretara"
      },
      {
        conducator: "Conducator"
      },
      {
        candidat: "Candidat"
      },
      {
        doctorand: "Doctorand"
      },
      {
        utilizator: "Utilizator"
      },
      {
        others: "Altii"
      }
    ],
    operators: ["equal", "not_equal"]
  }
];

class UtilizatoriList extends Component {
  static propTypes = {
    fetchUtilizatori: PropTypes.func.isRequired,
    deleteUtilizator: PropTypes.func.isRequired
  };
  handleDelete = userId => {
    this.props.deleteUtilizator(userId);
  };
  handleExport = () => {};
  render() {
    const { items, pageInfo } = this.props;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick
    ) => [
      {
        id: "optiuni",
        width: 65,
        resizable: false,
        Header: "Optiuni",
        accessor: row => (
          <React.Fragment>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={event => openMenu(event, row._id)}
            >
              <MoreVertIcon />
            </IconButton>
          </React.Fragment>
        )
      },
      {
        accessor: "_id",
        Header: "Id",
        show: false
      },
      {
        accessor: "email",
        Header: "Email",
        show: true
      },
      {
        id: "role",
        accessor: ({ role }) => _.upperFirst(role),
        Header: "Rol",
        show: true
      }
    ];

    return (
      <TableWithFilter
        name="utilizatori"
        addButtonText="Adauga utilizator"
        pageInfo={pageInfo}
        baseColumns={baseColumns}
        filterConfig={filterConfig}
        fetchItems={this.props.fetchUtilizatori}
        applyFilter={this.props.fetchUtilizatori}
        items={items}
        onDelete={this.handleDelete}
        menuComponent={props => (
          <Menu
            className="lvd-options-container"
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            // open={false}
            onClose={props.onClose}
          >
            <MenuItem onClick={props.handleViewClick}>Vizualizeaza</MenuItem>
            <MenuItem onClick={props.handleEditClick}>Editeaza</MenuItem>
            <MenuItem onClick={props.handleDeleteClick}>Sterge</MenuItem>
          </Menu>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  items: getUtilizatori(state),
  pageInfo: getUtilizatoriPageInfo(state)
});

export default connect(
  mapStateToProps,
  { fetchUtilizatori, deleteUtilizator }
)(withMobileDialog()(UtilizatoriList));
