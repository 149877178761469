import React, { Component } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import DocumentTable from "../General/DocumentTable";
import {
  fetchTipuriDeDocumente,
  fetchDosarInscris,
  sendToValidation
} from "../../actions";
import { getTipuriDeDocumente } from "../../reducers/tipuriDeDocumenteReducer";
import { getDosarInscris } from "../../reducers/inscrisiReducer";

yup.addMethod(yup.mixed, "sameAs", function a(ref, message) {
  return this.test("sameAs", message, function b(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});
class IncarcareDocumente extends Component {
  state = {
    dosar: { _id: "" }
  };

  async componentDidMount() {
    this.props.setIsLoading(1);
    await this.props.fetchDosarInscris();
    await this.props.fetchTipuriDeDocumente();
    this.props.setIsLoading(-1);
  }

  handleSendToValidationClick = async () => {
    await this.props.sendToValidation();
    this.props.handleNext();
  };

  render() {
    const { t, tipuriDeDocumente, dosar, isLoading } = this.props;
    const tipuriDoc = tipuriDeDocumente.inscrisi;
    return (
      <div>
        {isLoading === 0 && (
          <div>
            <DocumentTable
              dosarId={dosar._id}
              documente={dosar.documente || []}
              tipuriDocumente={tipuriDoc}
              name="inscrisi"
              type="registration"
            />
            <div style={{ paddingTop: "24px" }}>
              <Button
                disabled={!dosar.areToateDocumenteleObligatorii}
                onClick={this.handleSendToValidationClick}
                variant="contained"
                color="primary"
              >
                {t.sendToValidation}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  dosar: getDosarInscris(state),
  tipuriDeDocumente: getTipuriDeDocumente(state)
});

const mapDispatchToProps = {
  fetchTipuriDeDocumente,
  fetchDosarInscris,
  sendToValidation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncarcareDocumente);
