import * as TYPES from "../types";

const doctoranziReducer = (
  state = {
    items: [],
    byId: {},
    isFetching: false,
    error: undefined,
    query: undefined,
    dropdown: [],
    pageInfo: {
      pageCount: undefined,
      itemCount: undefined,
      page: undefined,
      pageSize: undefined
    }
  },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.DOCTORANZI_DROPDOWN_FETCH_SUCCESS:
      return { ...state, dropdown: payload };
    case TYPES.DOCTORANZI_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.DOCTORANZI_FETCH_SUCCESS:
      return { ...state, isFetching: false, items: payload };
    case TYPES.DOCTORANZI_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.DOCTORANZI_ADD_SUCCESS:
      return { ...state, byId: payload };
    case TYPES.DOCTORANZI_FETCH_BYID_SUCCESS:
      return { ...state, byId: payload };
    case TYPES.DOCTORANZI_SEARCH_SUCCESS:
      return {
        ...state,
        items: payload.dosare,
        query: action.query,
        pageInfo: payload.pageInfo
      };
    case TYPES.DOCTORANZI_UPDATE_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.DOCTORANZI_UPDATE_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.DOCTORANZI_UPDATE_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.DOCTORANZI_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(dosar => dosar._id !== payload)
      };
    case TYPES.DOCTORANZI_SET_QUERY:
      return { ...state, query: payload, items: [] };
    default:
      return state;
  }
};

export const getDoctoranzi = state => state.doctoranzi.items;
export const getDoctoranziForDropdown = state => state.doctoranzi.dropdown;
export const getDoctorandById = state => state.doctoranzi.byId;
export const getDoctoranziQuery = state => state.doctoranzi.query;
export const getDoctoranziPageInfo = state => state.doctoranzi.pageInfo;
export const getIsFetchingDoctoranzi = state => state.doctoranzi.isFetching;

export default doctoranziReducer;
