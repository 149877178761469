import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import {
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Input,
  FormHelperText
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { forgotPassword } from "../../actions";
import OldLoginLayout from "../Layout/OldLoginLayout";

class ResetPassword extends Component {
  state = {
    mailSent: false
  };

  handleSubmit = async values => {
    const res = await this.props.forgotPassword(values);
    if (res) this.setState({ mailSent: true });
  };

  render() {
    if (this.state.mailSent) {
      return (
        <OldLoginLayout>
          Un mail a fost trimis. Folositi linkul pentru a va reseta parola.
          <div className="lvd-spacer20" />
          <Button
            component={React.forwardRef((props, ref) => (
              <Link {...props} innerRef={ref} />
            ))}
            to="/login"
            href="/login"
          >
            Inapoi la login
          </Button>
        </OldLoginLayout>
      );
    }
    return (
      <OldLoginLayout>
        <Formik
          initialValues={{
            email: ""
          }}
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .email()
              .required("Camp obligatoriu")
          })}
          onSubmit={this.handleSubmit}
          render={({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <FormControl fullWidth margin="dense">
                <InputLabel>Email</InputLabel>
                <Input
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={!!touched.email && !!errors.email}
                  endAdornment={
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
                {!!touched.email && !!errors.email && (
                  <FormHelperText error={!!touched.email && !!errors.email}>
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>
              <div className="lvd-spacer20" />
              <Button variant="contained" color="primary" type="submit">
                Trimite mail
              </Button>
              <span className="lvd-text-spacer" />
              <Button
                component={React.forwardRef((props, ref) => (
                  <Link {...props} innerRef={ref} />
                ))}
                to="/login"
                href="/login"
              >
                Inapoi la login
              </Button>
            </Form>
          )}
        />
      </OldLoginLayout>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  forgotPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
