import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, FieldArray } from "formik";
import {
  TextField,
  Button,
  Paper,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TrashIcon from "@material-ui/icons/Delete";
import * as yup from "yup";

const options = [
  {
    value: "rating",
    label: "Rating"
  },
  {
    value: "text",
    label: "Text"
  }
];

class TemplateForm extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({}),
    handleSubmit: PropTypes.func.isRequired
  };
  static defaultProps = {
    initialValues: {}
  };
  state = {};
  render() {
    const { initialValues, handleSubmit } = this.props;
    return (
      <div>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            intrebari: [{ intrebare: "", tipRaspuns: "" }],
            titlu: "",
            ...initialValues
          }}
          validationSchema={yup.object().shape({
            titlu: yup.string().required("Camp obligatoriu"),
            intrebari: yup
              .array()
              .of(
                yup.object().shape({
                  intrebare: yup.string().required("Camp obligatoriu"),
                  tipRaspuns: yup.string().required("Camp obligatoriu")
                })
              )
              .required("Camp obligatoriu")
          })}
          render={({ handleBlur, handleChange, values, errors, touched }) => {
            return (
              <Form>
                <Paper className="lvd-container-form">
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    name="titlu"
                    label="Titlu"
                    value={values.titlu}
                    error={!!errors.titlu && !!touched.titlu}
                    helperText={
                      !!errors.titlu && !!touched.titlu && errors.titlu
                    }
                    FormHelperTextProps={{ error: true }}
                  />
                </Paper>

                {typeof errors.intrebari === "string" && (
                  <div>Templateul trebuie sa contina minim o intrebare</div>
                )}
                <FieldArray
                  name="intrebari"
                  render={arrayHelpers => (
                    <div>
                      {values.intrebari.map((intrebare, index) => (
                        <Paper className="lvd-container-form" key={index}>
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: 9 }}>
                              <TextField
                                name={`intrebari.${index}.intrebare`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={intrebare.intrebare || ""}
                                error={
                                  typeof errors.intrebari !== "string" &&
                                  errors.intrebari &&
                                  touched.intrebari &&
                                  touched.intrebari[index] &&
                                  errors.intrebari[index] &&
                                  !!errors.intrebari[index].intrebare &&
                                  !!touched.intrebari[index].intrebare
                                }
                                helperText={
                                  typeof errors.intrebari !== "string" &&
                                  errors.intrebari &&
                                  touched.intrebari &&
                                  touched.intrebari[index] &&
                                  errors.intrebari[index] &&
                                  !!errors.intrebari[index].intrebare &&
                                  !!touched.intrebari[index].intrebare &&
                                  errors.intrebari[index].intrebare
                                }
                                FormHelperTextProps={{
                                  error: true
                                }}
                                margin="dense"
                                fullWidth
                                label="Intrebare"
                              />
                              <FormControl
                                margin="dense"
                                fullWidth
                                error={
                                  typeof errors.intrebari !== "string" &&
                                  errors.intrebari &&
                                  touched.intrebari &&
                                  touched.intrebari[index] &&
                                  errors.intrebari[index] &&
                                  !!errors.intrebari[index].tipRaspuns &&
                                  !!touched.intrebari[index].tipRaspuns
                                }
                              >
                                <InputLabel
                                  htmlFor={`intrebari.${index}.tipRaspuns}`}
                                >
                                  Tip Raspuns
                                </InputLabel>
                                <Select
                                  name={`intrebari.${index}.tipRaspuns`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={intrebare.tipRaspuns || ""}
                                >
                                  {options.map(opt => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                      {opt.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {typeof errors.intrebari !== "string" &&
                                  errors.intrebari &&
                                  touched.intrebari &&
                                  touched.intrebari[index] &&
                                  errors.intrebari[index] &&
                                  !!errors.intrebari[index].tipRaspuns &&
                                  !!touched.intrebari[index].tipRaspuns && (
                                    <FormHelperText error>
                                      {errors.intrebari[index].tipRaspuns}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </div>
                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <div>
                                <Button
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <TrashIcon />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Paper>
                      ))}
                      <Button
                        style={{ marginBottom: "20px", marginTop: "0px" }}
                        variant="contained"
                        className="lvd-button-green-text"
                        color="primary"
                        onClick={() =>
                          arrayHelpers.push({
                            intrebare: "",
                            tipRaspuns: ""
                          })
                        }
                      >
                        <AddIcon />
                        Adauga intrebare
                      </Button>
                    </div>
                  )}
                />

                <Button color="primary" variant="contained" type="submit">
                  Salveaza
                </Button>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

TemplateForm.propTypes = {};

export default TemplateForm;
