import React, { Component } from "react";
import PropTypes from "prop-types";
import { Paper, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { showSnackbar, login } from "../../actions";
import mainLogo from "../../svg/logo-utcb.svg";
import footer from "../../svg/logo-footer.svg";
import Snack from "../General/Snackbar";

const styles = () => {};

class OldLoginLayout extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    theme: PropTypes.shape({}).isRequired
  };

  state = {};

  render() {
    const { theme } = this.props;
    return (
      <div
        className="lvd-login-container"
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <div className="lvd-login-logo">
          <img src={mainLogo} alt="logo" />
        </div>
        <div className="lvd-login-form">
          <Paper className="lvd-login-paper">
            {this.props.children}
            <div className="lvd-login-footer">
              <img src={footer} alt="footer-logo" />
              <span style={{ color: theme.palette.text.primary }}>
                IOSUD UTCB
              </span>
            </div>
          </Paper>
        </div>
        <Snack />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    { showSnackbar, login }
  )(OldLoginLayout)
);
