import * as TYPES from "../types";

const nomenclatoareReducer = (
  state = {
    facultati: [],
    specializari: [],
    domeniuDoctoratInscriere: [],
    departamente: [],
    domenii: [],
    discipline: [],
    cetatenii: [],
    formeDeInvatamant: [],
    situatiiScolare: [],
    limbiStraine: [],
    calificative: [],
    categoriiELearning: [],
    domeniiInscriere: [],
    jurnale: [],
    isFetching: false,
    error: undefined
  },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.NOMENCLATOARE_EDIT_SUCCESS:
      return {
        ...state,
        [payload.nomenclatorName]: [
          ...state[payload.nomenclatorName].map(n =>
            n._id === payload.id ? payload : n
          )
        ]
      };
    case TYPES.NOMENCLATOARE_ADD_REQUEST:
      return {
        ...state
      };
    case TYPES.NOMENCLATOARE_ADD_SUCCESS:
      return {
        ...state,
        [payload.nomenclatorName]: [...state[payload.nomenclatorName], payload]
      };
    case TYPES.NOMENCLATOARE_DELETE_REQUEST:
      return {
        ...state,
        [payload.nomenclatorName]: [
          ...state[payload.nomenclatorName].filter(
            val => val._id !== payload.id
          )
        ]
      };
    case TYPES.NOMENCLATOARE_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.NOMENCLATOARE_FETCH_SUCCESS:
      return { ...state, isFetching: false, ...payload };
    case TYPES.NOMENCLATOARE_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    default:
      return state;
  }
};

export const getNomenclatorJurnale = state =>
  state.nomenclatoare.jurnale.map(jurnal => ({
    value: jurnal._id,
    label: jurnal.nume
  }));

export default nomenclatoareReducer;
